import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './project.action';
import * as types from './project.types';
import * as API from './project.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* getProjectFunc(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const project = yield call(API.getProject, getQueryString(params));
    yield put(actions.getProjectSuccess({ project }));
  } catch (error) {
    yield put(actions.getProjectFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchPropertiesInProjectFunc(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const properties = yield call(API.fetchProperties, getQueryString(params));

    yield put(actions.fetchPropertiesInProjectSuccess({ properties }));
  } catch (error) {
    yield put(actions.fetchPropertiesInProjectFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchProjectsFunc(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    let projects = yield call(API.fetchProjects, getQueryString(params));
    yield put(actions.fetchListProjectsSuccess({ projects }));
  } catch (error) {
    yield put(actions.fetchListProjectsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* projectsSaga() {
  yield takeEvery(types.GET_PROJECT_REQUEST, getProjectFunc);
  yield takeEvery(types.FETCH_PROPERTIES_IN_PROJECT_REQUEST, fetchPropertiesInProjectFunc);
  yield takeEvery(types.FETCH_LIST_PROJECTS_REQUEST, fetchProjectsFunc);
}
