const FETCH_CITIES_REQUEST = 'FETCH_CITIES_REQUEST';
const FETCH_CITIES_SUCCESS = 'FETCH_CITIES_SUCCESS';
const FETCH_CITIES_FAILURE = 'FETCH_CITIES_FAILURE';

const FETCH_DISTRICTS_BY_CITYID_REQUEST = 'FETCH_DISTRICTS_BY_CITYID_REQUEST';
const FETCH_DISTRICTS_BY_CITYID_SUCCESS = 'FETCH_DISTRICTS_BY_CITYID_SUCCESS';
const FETCH_DISTRICTS_BY_CITYID_FAILURE = 'FETCH_DISTRICTS_BY_CITYID_FAILURE';

const GET_BANNER_REQUEST = 'GET_BANNER_REQUEST';
const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
const GET_BANNER_FAILURE = 'GET_BANNER_FAILURE';

const FETCH_LIST_CONDITION_REQUEST = 'FETCH_LIST_CONDITION_REQUEST';
const FETCH_LIST_CONDITION_SUCCESS = 'FETCH_LIST_CONDITION_SUCCESS';
const FETCH_LIST_CONDITION_FAILURE = 'FETCH_LIST_CONDITION_FAILURE';

const GET_UNITS_REQUEST = 'GET_UNITS_REQUEST';
const GET_UNITS_SUCCESS = 'GET_UNITS_SUCCESS';
const GET_UNITS_FAILURE = 'GET_UNITS_FAILURE';

const FETCH_CITIES_BY_LOCALE_REQUEST = 'FETCH_CITIES_BY_LOCALE_REQUEST';
const FETCH_CITIES_BY_LOCALE_SUCCESS = 'FETCH_CITIES_BY_LOCALE_SUCCESS';
const FETCH_CITIES_BY_LOCALE_FAILURE = 'FETCH_CITIES_BY_LOCALE_FAILURE';

const FETCH_CUSTOMER_CONTACT_REQUEST = 'FETCH_CUSTOMER_CONTACT_REQUEST';
const FETCH_CUSTOMER_CONTACT_SUCCESS = 'FETCH_CUSTOMER_CONTACT_SUCCESS';
const FETCH_CUSTOMER_CONTACT_FAILURE = 'FETCH_CUSTOMER_CONTACT_FAILURE';

const FETCH_PROJECT_SEARCH_REQUEST = 'FETCH_PROJECT_SEARCH_REQUEST';
const FETCH_PROJECT_SEARCH_SUCCESS = 'FETCH_PROJECT_SEARCH_SUCCESS';
const FETCH_PROJECT_SEARCH_FAILURE = 'FETCH_PROJECT_SEARCH_FAILURE';

const GET_FOOTER_INFORMATION_REQUEST = 'GET_FOOTER_INFORMATION_REQUEST';
const GET_FOOTER_INFORMATION_SUCCESS = 'GET_FOOTER_INFORMATION_SUCCESS';
const GET_FOOTER_INFORMATION_FAILURE = 'GET_FOOTER_INFORMATION_FAILURE';

const DOWNLOAD_MAGAZINE_REQUEST = 'DOWNLOAD_MAGAZINE_REQUEST';
const DOWNLOAD_MAGAZINE_SUCCESS = 'DOWNLOAD_MAGAZINE_SUCCESS';
const DOWNLOAD_MAGAZINE_FAILURE = 'DOWNLOAD_MAGAZINE_FAILURE';

const FETCH_RANGE_REQUEST = 'FETCH_RANGE_REQUEST';
const FETCH_RANGE_SUCCESS = 'FETCH_RANGE_SUCCESS';
const FETCH_RANGE_FAILURE = 'FETCH_RANGE_FAILURE';

const SUBSCRIBE_NEWSLETTER_REQUEST = 'SUBSCRIBE_NEWSLETTER_REQUEST';

const SET_LOADING = 'SET_LOADING';

const POST_CUSTOMER_SUPPORT_REQUEST = 'POST_CUSTOMER_SUPPORT_REQUEST';

const GET_PARTNER_REQUEST = 'GET_PARTNER_REQUEST';
const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
const GET_PARTNER_FAILURE = 'GET_PARTNER_FAILURE';

export {
  FETCH_CITIES_REQUEST,
  FETCH_CITIES_SUCCESS,
  FETCH_CITIES_FAILURE,
  FETCH_DISTRICTS_BY_CITYID_REQUEST,
  FETCH_DISTRICTS_BY_CITYID_SUCCESS,
  FETCH_DISTRICTS_BY_CITYID_FAILURE,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAILURE,
  SET_LOADING,
  FETCH_LIST_CONDITION_REQUEST,
  FETCH_LIST_CONDITION_SUCCESS,
  FETCH_LIST_CONDITION_FAILURE,
  GET_UNITS_REQUEST,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAILURE,
  FETCH_CITIES_BY_LOCALE_REQUEST,
  FETCH_CITIES_BY_LOCALE_SUCCESS,
  FETCH_CITIES_BY_LOCALE_FAILURE,
  FETCH_CUSTOMER_CONTACT_REQUEST,
  FETCH_CUSTOMER_CONTACT_SUCCESS,
  FETCH_CUSTOMER_CONTACT_FAILURE,
  FETCH_PROJECT_SEARCH_REQUEST,
  FETCH_PROJECT_SEARCH_SUCCESS,
  FETCH_PROJECT_SEARCH_FAILURE,
  GET_FOOTER_INFORMATION_REQUEST,
  GET_FOOTER_INFORMATION_SUCCESS,
  GET_FOOTER_INFORMATION_FAILURE,
  SUBSCRIBE_NEWSLETTER_REQUEST,
  DOWNLOAD_MAGAZINE_REQUEST,
  DOWNLOAD_MAGAZINE_SUCCESS,
  DOWNLOAD_MAGAZINE_FAILURE,
  FETCH_RANGE_REQUEST,
  FETCH_RANGE_SUCCESS,
  FETCH_RANGE_FAILURE,
  POST_CUSTOMER_SUPPORT_REQUEST,
  GET_PARTNER_REQUEST,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_FAILURE,
};
