import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './home.action';
import * as types from './home.types';
import * as API from './home.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* fetchLastProperties(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const lastProperties = yield call(API.fetchLastProperties, getQueryString(params));
    yield put(actions.fetchLastestPropertiesSuccess({ lastProperties }));
  } catch (error) {
    yield put(actions.fetchLastestPropertiesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchHotProperties(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const hotProperties = yield call(API.fetchHotProperties, getQueryString(params));
    yield put(actions.fetchHotPropertiesSuccess({ hotProperties }));
  } catch (error) {
    yield put(actions.fetchHotPropertiesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchBlogs(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const blogs = yield call(API.fetchBlogs, getQueryString(params));
    yield put(actions.fetchBlogsSuccess({ blogs }));
  } catch (error) {
    yield put(actions.fetchBlogsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchProjects(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const projects = yield call(API.fetchProjects, getQueryString(params));
    yield put(actions.fetchProjectsSuccess({ projects }));
  } catch (error) {
    yield put(actions.fetchProjectsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchSpecialLists(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const specialList = yield call(API.fetchSpecialList, getQueryString(params));
    yield put(actions.fetchSpecialListSuccess({ specialList }));
  } catch (error) {
    yield put(actions.fetchSpecialListFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* homeSaga() {
  yield takeEvery(types.FETCH_LASTEST_PROPERTIES_REQUEST, fetchLastProperties);
  yield takeEvery(types.FETCH_HOT_PROPERTIES_REQUEST, fetchHotProperties);
  yield takeEvery(types.FETCH_BLOGS_REQUEST, fetchBlogs);
  yield takeEvery(types.FETCH_PROJECTS_REQUEST, fetchProjects);
  yield takeEvery(types.FETCH_SPECIAL_LIST_REQUEST, fetchSpecialLists);
}
