import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './offices.action';
import * as types from './offices.types';
import * as API from './offices.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* fetchOffiesLastest(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const lastProperties = yield call(API.fetchLastProperties, getQueryString(params));
    yield put(actions.fetchOfficesLastestSuccess({ lastProperties }));
  } catch (error) {
    yield put(actions.fetchOfficesLastestFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchOffiesHot(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const hotProperties = yield call(API.fetchHotProperties, getQueryString(params));
    yield put(actions.fetchOfficesHotSuccess({ hotProperties }));
  } catch (error) {
    yield put(actions.fetchOfficesHotFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchOffiesBlog(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const blogs = yield call(API.fetchBlogs, getQueryString(params));
    yield put(actions.fetchOfficesBlogSuccess({ blogs }));
  } catch (error) {
    yield put(actions.fetchOfficesBlogFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* officesSaga() {
  yield takeEvery(types.FETCH_OFFICES_LASTEST_REQUEST, fetchOffiesLastest);
  yield takeEvery(types.FETCH_OFFICES_HOT_REQUEST, fetchOffiesHot);
  yield takeEvery(types.FETCH_OFFICES_BLOGS_REQUEST, fetchOffiesBlog);
}
