import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { useTranslation } from 'react-i18next';
import EventBus from 'eventing-bus';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ICON_LEFT, ICON_RIGHT } from '../../../../utils/constants';
import { getPathLanguage } from '../../../../utils/params';
import { Location, HeartIcon, SizeIcon, BedIcon, VideoIcon } from '../../../../layouts/commons/icons';
import { formatMoney } from '../../../../utils/objects';
// import favorite404 from '../../../../assets/images/notfound-icon.svg';
import imageDefault from '../../../../assets/images/loading-image.jpg';

const ResidenceSearchResutlsComponent = props => {
  const imageThumbnails = useRef(null);
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();

  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    dots: true,
    autoplay: false,
    autoHeight: true,
    responsive: {},
    loop: true,
  };

  const [likeData, setLikeData] = useState([]);

  useEffect(() => {
    let listLike = [];
    if (props.section === 'residences') {
      listLike = localStorage.getItem('RESIDENCES_LIKE') ? localStorage.getItem('RESIDENCES_LIKE').split(',') : [];
    } else if (props.section === 'offices') {
      listLike = localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : [];
    }

    setLikeData(listLike);
  }, [props.section]);

  const onClickLike = id => {
    let listData = [];
    if (likeData && likeData.includes(id)) listData = likeData.filter(i => i !== id);
    else listData = [...likeData, id];

    setLikeData(listData);
    if (props.section === 'residences') localStorage.setItem('RESIDENCES_LIKE', listData);
    else if (props.section === 'offices') localStorage.setItem('OFFICES_LIKE', listData);

    EventBus.publish('RESET_LIKE', true);
  };

  return (
    <div className="section-block background-gray pt-4">
      <div className="container">
        <div className="seach-results">
          <div className="row">
            {props.residencesSearch &&
              props.residencesSearch.items &&
              props.residencesSearch.items.length &&
              props.residencesSearch.items.map((item, index) => {
                if (item.name) item.name = item.name.replace('　', ' ');
                return (
                  <div
                    key={index}
                    className={`col-12 ${props.propertyType === '0' ? 'col-md-4 mb-4' : 'col-md-3 mb-3'}`}
                  >
                    {props.propertyType === '0' ? (
                      <div className="block-inner favorite search element-carousel">
                        <div className="block-image">
                          {item.images && item.images.length ? (
                            <div className="thumbail-slides">
                              <OwlCarousel ref={imageThumbnails} options={options}>
                                {item.images.map((image, i) => (
                                  <Link
                                    to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}
                                    key={i}
                                    className="w-100"
                                  >
                                    <div>
                                      {/* <LazyLoadImage
                                        alt=""
                                        src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${image}`}
                                        effect="blur"
                                      /> */}
                                      <img
                                        alt=""
                                        src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${image}`}
                                        className="w-100"
                                      />
                                    </div>
                                  </Link>
                                ))}
                              </OwlCarousel>
                            </div>
                          ) : (
                            <Link to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}>
                              <div className="w-100">
                                <img src={imageDefault} className="favourite-notfound" alt="" />
                                {/* <LazyLoadImage src={favorite404}
                                className="favourite-notfound" alt="" effect="blur" /> */}
                              </div>
                            </Link>
                          )}
                          <span
                            onClick={() => onClickLike(item.id)}
                            className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                          >
                            <HeartIcon />
                          </span>
                          {item.videoLink ? (
                            <span className="icon-video">
                              <VideoIcon />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <Link className="w-100" to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}>
                          <div className="block-text none-split">
                            <h3>{item.name}</h3>
                            <div className="property-info">
                              <ul>
                                <li className="icon-district">
                                  {item.district && (
                                    <>
                                      <Location />
                                      {item.district}
                                    </>
                                  )}
                                </li>
                                <li className="bedroom-icon-detail icon-bedroom">
                                  <BedIcon />
                                  {item.bedroommin ? item.bedroommin : t('home_page.studio')}
                                  {item.bedroomMax ? ` ~ ${item.bedroomMax}` : ''}
                                  <span>&nbsp;{t('search_page.bedrooms')}</span>
                                </li>
                              </ul>

                              <div className="info-residence">
                                {item.leasePriceMin || item.leasePriceMax ? (
                                  <div className="price">
                                    {item.leasePriceMin || item.leasePriceMax
                                      ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                      : ''}
                                    {item.leasePriceMax ? ' ~ ' : ''}
                                    {item.leasePriceMax ? `$${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                    {item.leasePriceMin || item.leasePriceMax ? (
                                      <span>/{t('search_page.month')}</span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div className="size">
                                  {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                  {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                  {item.sqmMax ? ' ~ ' : ''}
                                  {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                  {item.sqmmin || item.sqmMax ? (
                                    <>
                                      m<sup>2</sup>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <div className="block-inner owl-carousel-office">
                        <div className="block-image">
                          <div className="thumbail-slides">
                            {item.images && item.images.length ? (
                              <Link
                                to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}
                                className="w-100"
                              >
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item.images[0]}`}
                                  className="w-100"
                                />
                                {/* <LazyLoadImage
                                alt=""
                                src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item.images[0]}`}
                                effect="blur"
                              /> */}
                              </Link>
                            ) : (
                              <Link to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}>
                                <img alt="" src={imageDefault} />
                                {/* <LazyLoadImage alt="" src={imageDefault} effect="blur" /> */}
                              </Link>
                            )}
                          </div>
                          <span
                            onClick={() => onClickLike(item.id)}
                            className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                          >
                            <HeartIcon />
                          </span>
                          {item.videoLink ? (
                            <span className="icon-video">
                              <VideoIcon />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <Link className="w-100" to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}>
                          <div className="block-text block-office none-split">
                            <h4>{item.name}</h4>
                            <div className="property-info">
                              <ul>
                                <li className="icon-district">
                                  <Location />
                                  {item.district}
                                </li>
                                <li className="icon-size">
                                  {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                  {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                  {item.sqmMax ? ' ~ ' : ''}
                                  {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                  {item.sqmmin || item.sqmMax ? (
                                    <>
                                      m<sup>2</sup>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </li>
                              </ul>
                              <div className="price">
                                {item.leasePriceMin || item.leasePriceMax
                                  ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                  : ''}
                                {item.leasePriceMax ? ' ~ ' : ''}
                                {item.leasePriceMax ? `$${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                {item.leasePriceMin || item.leasePriceMax ? (
                                  <span>
                                    /m<sup>2</sup>/{t('search_page.month')}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResidenceSearchResutlsComponent;
