import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import { getLanguage } from '../../../../utils/params';
import minus from '../../../../assets/images/minus.svg';
import plus from '../../../../assets/images/plus.svg';
import { MapHaNoi, MapHoChiMinh } from '../../../commons/iconService';
import clock from '../../../../assets/images/clock.svg';

const ServiceApartment = props => {
  const location = useLocation();
  const { t } = useTranslation();
  const language = getLanguage(location);
  const { detail, topicActive, listArea } = props;

  const [isShowCollapse, setIsShowCollapse] = useState({ key: '', value: false });
  const livingAreas = (title, list, images) => {
    return (
      <>
        <h3 className="useful-head jp-bold-600">{title}</h3>
        <div className="">
          {list && list.length
            ? list.map((item, index) => {
                item.description = item.description.replace(/\n/g, '<br />');
                return (
                  <div key={index} className="main-content">
                    <div
                      className={`useful-content click-collapse ${
                        isShowCollapse.key === item.id && isShowCollapse.value ? 'actived' : 'collapsed'
                      }`}
                      onClick={() =>
                        setIsShowCollapse({
                          key: item.id,
                          value: !(isShowCollapse.key === item.id && isShowCollapse.value),
                        })
                      }
                    >
                      <div className="useful-title">{item.name || ''}</div>
                      <img
                        src={isShowCollapse.key === item.id && isShowCollapse.value ? minus : plus}
                        className="img-plus"
                        alt=""
                      />
                    </div>
                    <div
                      className={`useful-description collapse ${
                        isShowCollapse.key === item.id && isShowCollapse.value ? 'show' : ''
                      }`}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.description || '',
                        }}
                      ></div>
                      <img src={images[`mapsvg${index + 1}`]} alt="" />
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </>
    );
  };
  const renderArea = useMemo(() => {
    if (listArea && listArea.length) {
      return (
        <>
          <div className="useful-right useful-area">
            {livingAreas(t('useful_page.hanoi'), listArea.slice(0, 6), MapHaNoi)}
          </div>
          <div className="useful-right useful-area">
            {livingAreas(`${t('useful_page.hochiminh')} ${t('useful_page.city')}`, listArea.slice(6, 11), MapHoChiMinh)}
          </div>
        </>
      );
    }
    // eslint-disable-next-line
  }, [listArea, isShowCollapse]);

  return (
    <>
      <div className="main-useful-right main-useful-apartment content-useful">
        {topicActive && topicActive.slug === 'area-guide' ? (
          <>
            <div className="useful-content-title useful-area-guide">
              {location.pathname.endsWith('/useful-service-apartment') 
                || location.pathname.endsWith('/useful-service-apartment/') ? 
                <h2 className="title jp-bold-600">{topicActive && topicActive.title ? topicActive.title : ''}</h2>
                :
                <h1 className="title jp-bold-600">{topicActive && topicActive.title ? topicActive.title : ''}</h1>
              }
              
              <div className="jp-font-sawarabi-gothic">{detail && detail.description ? detail.description : ''}</div>
              {/* <img
                src={require(`../../../../assets/images/image-apartment-topic2.jpg`)}
                className="w-100 mt-3 img-topic"
                alt=""
              /> */}
              <LazyLoadImage
                src={require(`../../../../assets/images/image-apartment-topic2.jpg`)}
                className="w-100 mt-3 img-topic"
                alt=""
                effect="blur"
              />
            </div>
            {renderArea}
          </>
        ) : (
          <>
            <div className="useful-content-title">
              <h1 className="title jp-bold-600">{detail && detail.title ? detail.title : ''}</h1>
              <div className="update-day">
                <img src={clock} alt="" className="icon-clock" />
                {detail && detail.publicDate
                  ? language === 'en'
                    ? moment(detail.publicDate).format('MMM DD, YYYY')
                    : moment(detail.publicDate).format('YYYY-MM-DD')
                  : ''}
              </div>

              {detail && detail.description ? (
                <>
                  <div className="lower-border-title"></div>{' '}
                  <div className="jp-font-sawarabi-gothic">
                    {detail && detail.description ? detail.description : ''}
                  </div>
                </>
              ) : (
                ''
              )}

              {/* <div>{detail && detail.publicDate ? detail.publicDate : ''}</div> */}

              {/* <img src={detail && detail.image ? detail.image : ''} className="w-100 mt-3 img-topic" alt="" /> */}
              <LazyLoadImage
                src={detail && detail.image ? detail.image : ''}
                className="w-100 img-topic mt-3"
                alt=""
                effect="blur"
              />
            </div>
            <div className="useful-right">
              <div className="main-content">
                <div
                  dangerouslySetInnerHTML={{ __html: detail && detail.post ? detail.post : '' }}
                  className="jp-font-sawarabi-gothic"
                ></div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ServiceApartment;
