import * as types from './project.types';

export const getProjectRequest = params => ({ type: types.GET_PROJECT_REQUEST, params });
export const getProjectSuccess = data => ({ type: types.GET_PROJECT_SUCCESS, data });
export const getProjectFailure = error => ({ type: types.GET_PROJECT_FAILURE, error });

export const fetchPropertiesInProjectRequest = params => ({ type: types.FETCH_PROPERTIES_IN_PROJECT_REQUEST, params });
export const fetchPropertiesInProjectSuccess = data => ({ type: types.FETCH_PROPERTIES_IN_PROJECT_SUCCESS, data });
export const fetchPropertiesInProjectFailure = error => ({ type: types.FETCH_PROPERTIES_IN_PROJECT_FAILURE, error });

export const fetchListProjectsRequest = params => ({ type: types.FETCH_LIST_PROJECTS_REQUEST, params });
export const fetchListProjectsSuccess = data => ({ type: types.FETCH_LIST_PROJECTS_SUCCESS, data });
export const fetchListProjectsFailure = error => ({ type: types.FETCH_LIST_PROJECTS_FAILURE, error });
