import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguage, getPathLanguage } from '../../../utils/params';
import { get } from 'lodash';

const BreadcrumbResidence = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const language = getLanguage(useLocation());
  const location = useLocation();

  const renderText = () => {
    if (location.search) {
      if (location.search === '?last') return t('detail.lastest_properties');
      else if (location.search === '?hot') return t('detail.hot_properties');
    } else {
      if (props.data && props.data.propertyType) {
        if (props.data.propertyType === 'residence') return t('detail.residence');
        return t('detail.office');
      }
    }
  };

  const cityActive = get(props.allState, 'layout.cityActive.defaultLocationId', null);

  const renderLink = () => {
    if (location.search) {
      return `${pathLanguage}/list-properties/${location.search === '?last' ? 'latest' : 'hot'}/residence`;
    } else if (cityActive) {
      // eslint-disable-next-line
      return `${pathLanguage}/residences/search?language=${language}&propertyType=0&cityId=${cityActive}&leasePriceMin=0&leasePriceMax=5000&pageIndex=1&pageSize=15&sort=-leasePriceMin`;
    }
    return '';
  };

  return (
    <div className="section-block background-gray p-0">
      <div className="container">
        <div className="row breadcrumb align-items-center">
          <div className="col-12">
            <div className="breadcrumb-item row">
              <ul>
                <li className="first">
                  <Link className="home" to={pathLanguage}>
                    <span>{t('residence_detail.home')}</span>
                  </Link>
                </li>
                <li className="item">
                  <span className="breadcrumb-dot">&#8226;</span>
                  <span className="normal">
                    {/* {t('residence_detail.search_results')} */}
                    <Link to={renderLink()}>{renderText()}</Link>
                  </span>
                </li>
                <li className="item">
                  <span className="breadcrumb-dot">&#8226;</span>
                  <span className="active">{t('residence_detail.detail_apartment')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbResidence;
