const FETCH_ABOUT_ISN_REQUEST = 'FETCH_ABOUT_ISN_REQUEST';
const FETCH_ABOUT_ISN_SUCCESS = 'FETCH_ABOUT_ISN_SUCCESS';
const FETCH_ABOUT_ISN_FAILURE = 'FETCH_ABOUT_ISN_FAILURE';

const GET_GROUP_COMPANY_REQUEST = 'GET_GROUP_COMPANY_REQUEST';
const GET_GROUP_COMPANY_SUCCESS = 'GET_GROUP_COMPANY_SUCCESS';
const GET_GROUP_COMPANY_FAILURE = 'GET_GROUP_COMPANY_FAILURE';

const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';

const GET_NEWS_RELEASE_REQUEST = 'GET_NEWS_RELEASE_REQUEST';
const GET_NEWS_RELEASE_SUCCESS = 'GET_NEWS_RELEASE_SUCCESS';
const GET_NEWS_RELEASE_FAILURE = 'GET_NEWS_RELEASE_FAILURE';

const GET_OFFICE_INFORMATION_REQUEST = 'GET_OFFICE_INFORMATION_REQUEST';
const GET_OFFICE_INFORMATION_SUCCESS = 'GET_OFFICE_INFORMATION_SUCCESS';
const GET_OFFICE_INFORMATION_FAILURE = 'GET_OFFICE_INFORMATION_FAILURE';

const POST_CONTACT_US_REQUEST = 'POST_CONTACT_US_OF_ABOUT_REQUEST';

const GET_NEWS_CONTENT_REQUEST = 'GET_NEWS_CONTENT_REQUEST';
const GET_NEWS_CONTENT_SUCCESS = 'GET_NEWS_CONTENT_SUCCESS';
const GET_NEWS_CONTENT_FAILURE = 'GET_NEWS_CONTENT_FAILURE';

const FETCH_OTHER_NEWS_REQUEST = 'FETCH_OTHER_NEWS_REQUEST';
const FETCH_OTHER_NEWS_SUCCESS = 'FETCH_OTHER_NEWS_SUCCESS';
const FETCH_OTHER_NEWS_FAILURE = 'FETCH_OTHER_NEWS_FAILURE';

export {
  FETCH_ABOUT_ISN_REQUEST,
  FETCH_ABOUT_ISN_SUCCESS,
  FETCH_ABOUT_ISN_FAILURE,
  GET_GROUP_COMPANY_REQUEST,
  GET_GROUP_COMPANY_SUCCESS,
  GET_GROUP_COMPANY_FAILURE,
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  GET_NEWS_RELEASE_REQUEST,
  GET_NEWS_RELEASE_SUCCESS,
  GET_NEWS_RELEASE_FAILURE,
  GET_OFFICE_INFORMATION_REQUEST,
  GET_OFFICE_INFORMATION_SUCCESS,
  GET_OFFICE_INFORMATION_FAILURE,
  POST_CONTACT_US_REQUEST,
  GET_NEWS_CONTENT_REQUEST,
  GET_NEWS_CONTENT_SUCCESS,
  GET_NEWS_CONTENT_FAILURE,
  FETCH_OTHER_NEWS_REQUEST,
  FETCH_OTHER_NEWS_SUCCESS,
  FETCH_OTHER_NEWS_FAILURE,
};
