import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';

import * as actions from '../../forOwners.action';
import { getLanguage } from '../../../../utils/params';

const Questions = props => {
  const language = getLanguage(useLocation());
  const [isShowCollapse, setIsShowCollapse] = useState({ key: '', value: false });

  useEffect(() => {
    props.getFaqsOwnersRequest({ language, categorySlug: 'faqs-for-owners' });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [language]);

  return (
    <div className="box-white">
      <div id="accordion">
        {props.forOwners.questions.length &&
          props.forOwners.questions.map((item, index) => {
            let description = item.description || '';
            description = description.replace(/\n/gi, '<br />');
            // description = description.replace(/\r\n/gi, 'ABC');
            return (
              <div className="card" key={index}>
                <div className="card-header" id="headingOne">
                  <h5 className="mb-0">
                    <button
                      className={`btn btn-link link-red-bold ${
                        !(item.id === isShowCollapse.key && isShowCollapse.value) ? 'collapsed' : ''
                      }`}
                      onClick={() =>
                        setIsShowCollapse({
                          key: item.id,
                          value: !(item.id === isShowCollapse.key && isShowCollapse.value),
                        })
                      }
                    >
                      {item.name}
                      <span></span>
                    </button>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  className={`collapse  ${item.id === isShowCollapse.key && isShowCollapse.value ? 'show' : ''}`}
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body" dangerouslySetInnerHTML={{ __html: description }} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getFaqsOwnersRequest: actions.getFaqsOwnersRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
