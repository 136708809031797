import { put, takeEvery, call } from 'redux-saga/effects';

import * as actions from './layout.action';
import * as types from './layout.types';
import * as API from './layout.api';
import { getQueryString } from '../../utils/params';
import { setLoading } from '../loading/loading.action';
import Service from '../../services';
import { get } from 'lodash';

export function* fetchCities(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchCities, getQueryString(params));
    yield put(actions.fetchCitiesSuccess(data));
  } catch (error) {
    yield put(actions.fetchCitiesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchCitiesByLocale(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchCitiesByLocale, getQueryString(params));
    yield put(actions.fetchCitiesByLocaleSuccess(data));
  } catch (error) {
    yield put(actions.fetchCitiesByLocaleFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchDistrictsByCityId(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchDistrictsByCityId, getQueryString(params));
    yield put(actions.fetchDistrictsByCityIdSuccess(data));
  } catch (error) {
    yield put(actions.fetchDistrictsByCityIdFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getBanner(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.getBanner, getQueryString(params));
    yield put(actions.getBannerSuccess(data));
  } catch (error) {
    yield put(actions.getBannerFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchListCondition(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchListCondition, getQueryString(params));
    yield put(actions.fetchListConditionSuccess(data));
  } catch (error) {
    yield put(actions.fetchListConditionFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getUnits(action) {
  const { params = {} } = action;
  // yield put(setLoading(true));
  try {
    const data = yield call(API.getUnits, getQueryString(params));
    yield put(actions.getUnitsSuccess(data));
  } catch (error) {
    yield put(actions.getUnitsFailure(error));
  }
  // finally {
  //   yield put(setLoading(false));
  // }
}

export function* fetchCustomerContactSaga(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchCustomerContact, getQueryString(params));
    yield put(actions.fetchCustomerContactSuccess(data));
  } catch (error) {
    yield put(actions.fetchCustomerContactFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchProjectsSearchSaga(action) {
  const { params = {} } = action;
  try {
    const data = yield call(API.fetchProjectsSearch, getQueryString(params));
    yield put(actions.fetchProjectsSearchSuccess(data));
  } catch (error) {
    yield put(actions.fetchProjectsSearchFailure(error));
  }
}

export function* postSubscribeNewsletter(action) {
  const { email, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    yield Service.post(`/api/newsletters`, { email }, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* getOfficeInformationSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getOfficeInformation, getQueryString(params));

    yield put(actions.getOfficeInformatioSuccess(data));
  } catch (error) {
    yield put(actions.getOfficeInformatioFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* downloadMagazineSaga(action) {
  const { params = {} } = action;
  try {
    const data = yield call(API.downloadMagazine, getQueryString(params));
    yield put(actions.downloadMagazineSuccess(data));
  } catch (error) {
    yield put(actions.downloadMagazineFailure(error));
  }
}

export function* fetchRangeSaga() {
  yield put(setLoading(true));
  try {
    const data = yield call(API.fetchRange, {}) || {};
    yield put(actions.fetchRangeSuccess(data));
  } catch (error) {
    yield put(actions.fetchRangeFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* postCustomerSupportSaga(action) {
  const { info, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    yield Service.post(`/api/contacts/customer_contact`, info, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* getPartnerSaga(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const data = yield call(API.getPartner, getQueryString(params));
    yield put(actions.getPartnerSuccess(data));
  } catch (error) {
    yield put(actions.getPartnerFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* layoutSaga() {
  yield takeEvery(types.FETCH_CITIES_REQUEST, fetchCities);
  yield takeEvery(types.FETCH_CITIES_BY_LOCALE_REQUEST, fetchCitiesByLocale);
  yield takeEvery(types.FETCH_DISTRICTS_BY_CITYID_REQUEST, fetchDistrictsByCityId);
  yield takeEvery(types.GET_BANNER_REQUEST, getBanner);
  yield takeEvery(types.FETCH_LIST_CONDITION_REQUEST, fetchListCondition);
  yield takeEvery(types.GET_UNITS_REQUEST, getUnits);
  yield takeEvery(types.FETCH_CUSTOMER_CONTACT_REQUEST, fetchCustomerContactSaga);
  yield takeEvery(types.FETCH_PROJECT_SEARCH_REQUEST, fetchProjectsSearchSaga);
  yield takeEvery(types.SUBSCRIBE_NEWSLETTER_REQUEST, postSubscribeNewsletter);
  yield takeEvery(types.GET_FOOTER_INFORMATION_REQUEST, getOfficeInformationSaga);
  yield takeEvery(types.DOWNLOAD_MAGAZINE_REQUEST, downloadMagazineSaga);
  yield takeEvery(types.FETCH_RANGE_REQUEST, fetchRangeSaga);
  yield takeEvery(types.POST_CUSTOMER_SUPPORT_REQUEST, postCustomerSupportSaga);
  yield takeEvery(types.GET_PARTNER_REQUEST, getPartnerSaga);
}
