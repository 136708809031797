import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useLocation, useParams } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Location } from '../../../layouts/commons/icons';
import { getLanguage, getPathLanguage } from '../../../utils/params';
import { ICON_LEFT, ICON_RIGHT } from '../../../utils/constants';
import OurProperties from '../../residencePage/pages/details/Properties.component';

const ProjectDetailComponent = props => {
  const paramsUrl = useParams();
  const { id, slug } = paramsUrl;
  const language = getLanguage(useLocation());
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();
  const projectDetailRef = useRef(null);

  const [googleMarket, setGoogleMarket] = useState({ showingInfoWindow: false, activeMarker: {}, selectedPlace: {} });
  const { project, properties, error } = props.projects;
  const [imageActive, setImageActive] = useState(null);

  useEffect(() => {
    props.getProjectRequest({ id, language });
    props.fetchPropertiesInProjectRequest({
      projectId: id,
      language,
      pageIndex: 1,
      pageSize: 6,
    });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setImageActive(project && project.imageActive);
    // eslint-disable-next-line
  }, [project]);

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

  const renderData = useMemo(() => {
    const onMarkerClick = (props, marker) =>
      setGoogleMarket({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      });

    const onMapClicked = props => {
      if (googleMarket.showingInfoWindow) {
        setGoogleMarket({ selectedPlace: props, showingInfoWindow: false, activeMarker: null });
      }
    };

    const options = {
      items: 4,
      margin: 0,
      nav: true,
      navText: [ICON_LEFT, ICON_RIGHT],
      dots: false,
      loop: false,
      responsive: {
        0: {
          items: 3,
        },
        1000: {
          items: 4,
        },
      },
    };

    const onChangeImage = image => {
      var elem = document.getElementById('backgroud-project-detail');
      elem.style.opacity = 0;
      setImageActive(image);

      sleep(300).then(() => {
        elem.style.opacity = 1;
        elem.style.transition = 'opacity .3s';
      });
    };

    if (project) {
      if (project.description) project.description = project.description.replace(/\n/gi, '<br />');

      return (
        <>
          <div className="section-block section-breadcrumb background-gray">
            <div className="container">
              <div className="row breadcrumb align-items-center">
                <div className="col-6 col-12">
                  <div className="breadcrumb-item row">
                    <ul>
                      <li className="first">
                        <Link className="home" to={`${pathLanguage}`}>
                          {t('project_page.home')}
                        </Link>
                      </li>
                      <li className="item">
                        <span className="breadcrumb-dot">&#8226;</span>
                        <span className="active"> {t('project_page.project')}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-block background-gray section-page-content">
            <div className="container">
              <div className="header-details">
                <div className="header-hero">
                  <div className="header-slides">
                    <div className="header-item">
                      {/* <img src={imageActive} className="w-100 h-100 " alt="" id="backgroud-project-detail" /> */}
                      <LazyLoadImage
                        src={imageActive}
                        className="w-100 h-100"
                        effect="blur"
                        id="backgroud-project-detail"
                      />
                      <div className="header-caption">
                        <h1 className="project-title jp-project-title">{(project && project.name) || ''}</h1>
                        <div className="location capitalize">
                          <Location /> {t('project_page.address')}: {(project && project.street) || ''}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="header-thumbnail">
                    {project && project.images && project.images.length ? (
                      <OwlCarousel
                        ref={projectDetailRef}
                        options={options}
                        className="banner owl-carousel owl-theme owl-loaded owl-drag"
                      >
                        {project.images.map((item, index) => (
                          <div className="item" key={index} onClick={() => onChangeImage(item)}>
                            <img src={item} className="project-image-slide" alt="" />
                          </div>
                        ))}
                      </OwlCarousel>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {project && project.description ? (
                  <div className="description">
                    <div className="row">
                      <div className="col-12">
                        <div
                          className="wrap_description jp-400-sawarabi"
                          dangerouslySetInnerHTML={{ __html: project.description }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="header-info jp-font-sawarabi-gothic">
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <div className="box-item">
                        <h3 className="link-red uppercase">{t('project_page.general_information')}</h3>
                        <ul>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.developer')}</span>
                            {(project && project.developer) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.completion_year')}</span>
                            {(project && project.completionYear) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.security')}</span>
                            {(project && project.security) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.total_units')}</span>
                            {(project && project.totalUnits) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.floors')}</span>
                            {(project && project.floors) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.bedroom')}</span>{' '}
                            {(project && project.bedroom) || ''}
                          </li>
                          <li>
                            <span className="jp-400-sawarabi">
                              <span className="capitalize jp-400-sawarabi">{t('project_page.unit_size')}</span>&nbsp;(m
                              <sup>2</sup>)
                            </span>
                            {project && project.unitSize}
                            {/* {(project && project.unitSizeMin) || ''}{' '}
                        {project && project.unitSizeMin && project.unitSizeMax ? '-' : ''}{' '}
                        {(project && project.unitSizeMax) || ''} */}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box-item">
                        <h3 className="link-red uppercase">{t('project_page.facilities')}</h3>
                        <ul>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.pool')}</span>
                            {(project && project.pool) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.gym')}</span>
                            {(project && project.gym) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.shopping_center')}</span>
                            {(project && project.shoppingCenter) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.convenient_store')}</span>
                            {(project && project.convenientStore) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.playground')}</span>
                            {(project && project.playground) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.japanese_school_bus')}</span>
                            {(project && project.japaneseSchoolBus) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.others')}</span>
                            {(project && project.others) || ''}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="box-item">
                        <h3 className="link-red uppercase">{t('project_page.apartment')}</h3>
                        <ul>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.kitchen')}</span>
                            {(project && project.kitchen) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.bathtub')}</span>
                            {(project && project.bathtub) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.balcony')}</span>
                            {(project && project.balcony) || ''}
                          </li>
                          {/* <li className="capitalize">
                        <span className="jp-600-sawarabi">{t('project_page.furniture')}</span>
                        {(project && project.funiture) || ''}
                      </li> */}
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.central_heating')}</span>
                            {(project && project.centralHeating) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.air_conditioner')}</span>
                            {(project && project.airConditioner) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.flooring')}</span>
                            {(project && project.flooring) || ''}
                          </li>
                          <li className="capitalize">
                            <span className="jp-400-sawarabi">{t('project_page.other_apartment')}</span>
                            {(project && project.otherApartment) || ''}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-location">
                  <hr />
                  <h2 className="block-title uppercase jp-bold-600">{t('project_page.maps')}</h2>
                  <div className="row">
                    <div className="col-12">
                      <div className="gmap" style={{ height: 500 }}>
                        {project && project.latitude && project.longitude ? (
                          <Map
                            google={props.google}
                            zoom={14}
                            initialCenter={{
                              lat: project.latitude,
                              lng: project.longitude,
                            }}
                            center={{
                              lat: project.latitude,
                              lng: project.longitude,
                            }}
                            style={{ height: 500, width: '97%' }}
                            onClick={onMapClicked}
                          >
                            <Marker onClick={onMarkerClick} name={(project && project.name) || ''}
                              position={{
                                lat: project.latitude,
                                lng: project.longitude
                              }}
                            />
                          </Map>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {properties && properties.items && properties.items.length ? (
            <div className="project-our-properties">
              <div className="container">
                <div className="row">
                  <div className="col-12 uppercase properties-title">
                    <h2 className="jp-bold-600 our-properties-text font-size24">
                      <Link to={`${pathLanguage}/project/list/${slug}/${id}`} className="list-properties">
                        {t('project_page.our_properties')}
                      </Link>
                    </h2>
                    <span className="show-all-properties our-properties-all font-size24">
                      <Link to={`${pathLanguage}/project/list/${slug}/${id}`} className="jp-bold-700">
                        {t('home_page.all')}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
              <OurProperties headerTitle="" data={properties} />
            </div>
          ) : (
            ''
          )}
        </>
      );
    } else if (error && error.response.status === 404) {
      window.location.href = pathLanguage ? pathLanguage : '/';
    }
  }, [pathLanguage, properties, project, imageActive, t, id, slug, googleMarket, props, error]);

  return <>{renderData}</>;
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_KEY_GOOGLE_MAP,
})(ProjectDetailComponent);
