import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbForOwners, HeaderForOwners, Questions } from '../common';
import background from '../../../assets/images/backgound-faqs-owners.jpg';

const FaqsComponent = () => {
  const { t } = useTranslation();
  const breadCrumb = [
    { title: t('for_owners.home'), url: '/' },
    { title: t('for_owners.for_owners'), url: '/for-owners' },
    { title: t('for_owners.faqs'), url: '/for-owners/faqs' },
  ];
  const headerContent = {
    vietnameseTitle: 'Các câu hỏi thường gặp!',
    englishTitle: 'Frequently asked questions!',
    background,
  };

  return (
    <>
      <BreadcrumbForOwners breadCrumb={breadCrumb} />
      <HeaderForOwners content={headerContent} />

      <div className="container">
        <div className="faqs">
          <div className="row">
            <div className="col-12">
              <Questions />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqsComponent;
