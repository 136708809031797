import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

const CompanyProfileContent = props => {
  const { data } = props.aboutIsn;
  const { t } = useTranslation();

  return (
    <div className="profile-table">
      <div
        className="company-profile-description jp-font-sawarabi-gothic"
        dangerouslySetInnerHTML={{
          __html: t('about_isn.company_overview_page.isn_corporation'),
        }}
      ></div>
      {data &&
        data.length &&
        data.map((item, index) => (
          <div key={index} className="company-profile-border">
            <div className="row h-100">
              <div className="col-4 col-sm-3">
                <div className="profile-title jp-500-sawarabi">{item.name}</div>
              </div>
              <div className="col-8 col-sm-9">
                <div className="profile-description jp-font-sawarabi-gothic">{item.description}</div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileContent);
