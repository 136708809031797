import React from 'react';
import { useTranslation } from 'react-i18next';

import { DetailContent, OtherNews, BreadcrumbAboutIsn } from '../../common';

const NewsDetailComponent = () => {
  const { t } = useTranslation();

  const breadCrumb = [
    { title: t('about_isn.home'), url: '/' },
    { title: t('about_isn.about_isn'), url: '/about-isn' },
    { title: t('about_isn.new_relaese'), url: '/about-isn/news-release' },
    { title: t('about_isn.news_detail') },
  ];

  return (
    <>
      <BreadcrumbAboutIsn breadCrumb={breadCrumb} />

      <div className="section-block background-gray section-page-content">
        <div className="container">
          <div className="post-content-detail">
            <div className="row">
              <DetailContent />
              <OtherNews />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetailComponent;
