import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './officeDetail.action';
import * as types from './officeDetail.types';
import * as API from './officeDetail.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* getOfficeDetail(action) {
  const { params } = action;

  try {
    yield put(setLoading(true));

    let officeDetail = null;
    if (Object.keys(params).length) officeDetail = yield call(API.getOfficeDetail, getQueryString(params));

    yield put(actions.getOfficeDetailSuccess(officeDetail));
  } catch (error) {
    yield put(actions.getOfficeDetailFailure(error));
  } finally {
    window.scrollTo(0, 0);
    yield put(setLoading(false));
  }
}

export function* getSimilarList(action) {
  const { params } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getSimilarList, getQueryString(params));

    yield put(actions.getSimilarListSuccess(data));
  } catch (error) {
    yield put(actions.getSimilarListFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* officeDetailSaga() {
  yield takeEvery(types.GET_OFFICE_DETAIL_REQUEST, getOfficeDetail);
  yield takeEvery(types.GET_SIMILAR_LIST_REQUEST, getSimilarList);
}
