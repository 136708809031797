import { lazy } from 'react';
import { PublicLayout } from '../layouts';

const HomeContainer = lazy(() => import('../modules').then(module => ({ default: module.HomeContainer })));

/* Residence */
const ResidenceContainer = lazy(() => import('../modules').then(module => ({ default: module.ResidenceContainer })));
const ResidenceSearchContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ResidenceSearchContainer })),
);

/* Offices */
const OfficesContainer = lazy(() => import('../modules').then(module => ({ default: module.OfficesContainer })));
const OfficeDetailContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.OfficeDetailContainer })),
);

/* Blog */
const BlogsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.BlogsContainer.BlogsContainer })),
);
const BlogDetailContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.BlogsContainer.BlogDetailContainer })),
);

/* About ISN */
const MainAboutContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MainAboutContainer })),
);
/* Company overview of About ISN */
const CompanyProfileContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.CompanyProfileContainer })),
);
const CompanyPhilosophyContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.CompanyPhilosophyContainer })),
);
const OurCompanyContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.OurCompanyContainer })),
);
const MeetOurContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MeetOurContainer })),
);
const MessageFromRepresentativeContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MessageFromRepresentativeContainer })),
);
/* ISN Group of About ISN */
const GroupCompaniesContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.GroupCompaniesContainer })),
);
const HistoryContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.HistoryContainer })),
);
const MainServicesContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.MainServicesContainer })),
);
const WhyChooseContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.WhyChooseContainer })),
);
const PreparationContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.PreparationContainer })),
);
/* News Release & Contact Us of About ISN */
const NewsReleaseContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.NewsReleaseContainer })),
);
const NewsDetailContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.NewsDetailContainer })),
);
const ContactUsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.ContactUsContainer })),
);
const NotificationContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.AboutIsnContainer.NotificationContainer })),
);

/*For owners */
const MainForOwnersContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ForOwnersContainer.MainForOwnersContainer })),
);
const FormCreateInfomationContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ForOwnersContainer.FormCreateContainer })),
);
const FaqsForOwnersContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ForOwnersContainer.FaqsContainer })),
);

/* Normal PageContainer */
const FavoritesContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.FavoritesHistoriesContainer.Favorites })),
);
const HistoriesContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.FavoritesHistoriesContainer.Histories })),
);
const NormalPageContainer = lazy(() => import('../modules').then(module => ({ default: module.NormalPageContainer })));
const ProjectDetailContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ProjectsContainer.ProjectDetail })),
);
const ListingProjectsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.ProjectsContainer.ListingProjects })),
);
const Projects = lazy(() => import('../modules').then(module => ({ default: module.ProjectsContainer.Projects })));

/*UsefulContainer */
const ServiceApartmentContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.UsefulContainer.ServiceApartmentContainer })),
);
const FaqsContainer = lazy(() =>
  import('../modules').then(module => ({ default: module.UsefulContainer.FaqsContainer })),
);

const PropertiesContainer = lazy(() => import('../modules').then(module => ({ default: module.PropertiesContainer })));

/* Special page */
const SpecialContainer = lazy(() => import('../modules').then(module => ({ default: module.SpecialContainer })));

/* Member */
const MemberPage = lazy(() => import('../modules').then(module => ({ default: module.PreparationPage })));

const publicRouters = {
  layout: PublicLayout,
  subRoutes: [
    /* home */
    { path: '/', component: HomeContainer, exact: true },
    { path: '/en', component: HomeContainer, exact: true },
    { path: '/jp', component: HomeContainer, exact: true },

    { path: '/residences', component: HomeContainer, exact: true },
    { path: '/en/residences', component: HomeContainer, exact: true },
    { path: '/jp/residences', component: HomeContainer, exact: true },
    /* home */

    /* residence search */
    { path: '/residences/search', component: ResidenceSearchContainer, exact: true },
    { path: '/en/residences/search', component: ResidenceSearchContainer, exact: true },
    { path: '/jp/residences/search', component: ResidenceSearchContainer, exact: true },
    /* residence search */

    /* residence - location */
    { path: '/residence/location/:slug', component: ResidenceContainer, exact: true },
    { path: '/en/residence/location/:slug', component: ResidenceContainer, exact: true },
    { path: '/jp/residence/location/:slug', component: ResidenceContainer, exact: true },
    /* residence - location */

    /* residence */
    { path: '/residence/detail/:id/:slug', component: ResidenceContainer, exact: true },
    { path: '/en/residence/detail/:id/:slug', component: ResidenceContainer, exact: true },
    { path: '/jp/residence/detail/:id/:slug', component: ResidenceContainer, exact: true },
    /* residence */

    /* office */
    { path: '/offices', component: OfficesContainer, exact: true },
    { path: '/en/offices', component: OfficesContainer, exact: true },
    { path: '/jp/offices', component: OfficesContainer, exact: true },
    /* office */

    /* office detail */
    { path: '/office/detail/:id/:slug?', component: OfficeDetailContainer, exact: true },
    { path: '/en/office/detail/:id/:slug?', component: OfficeDetailContainer, exact: true },
    { path: '/jp/office/detail/:id/:slug?', component: OfficeDetailContainer, exact: true },
    /* office detail */

    /* office search */
    { path: '/offices/search', component: ResidenceSearchContainer, exact: true },
    { path: '/en/offices/search', component: ResidenceSearchContainer, exact: true },
    { path: '/jp/offices/search', component: ResidenceSearchContainer, exact: true },
    /* office search */

    /* blogs */
    { path: '/blogs/:category?/:id?/:pageIndex?', component: BlogsContainer, exact: true },
    { path: '/en/blogs/:category?/:id?/:pageIndex?', component: BlogsContainer, exact: true },
    { path: '/jp/blogs/:category?/:id?/:pageIndex?', component: BlogsContainer, exact: true },
    /* blogs */

    /* blogs slug */
    { path: '/blog/detail/:id/:slug?', component: BlogDetailContainer, exact: true },
    { path: '/en/blog/detail/:id/:slug?', component: BlogDetailContainer, exact: true },
    { path: '/jp/blog/detail/:id/:slug?', component: BlogDetailContainer, exact: true },
    /* blogs slug */

    /* projects */
    { path: '/project/list/:slug/:id', component: ListingProjectsContainer, exact: true },
    { path: '/en/project/list/:slug/:id', component: ListingProjectsContainer, exact: true },
    { path: '/jp/project/list/:slug/:id', component: ListingProjectsContainer, exact: true },
    /* projects */

    /* project detail slug */
    { path: '/project/detail/:id/:slug', component: ProjectDetailContainer, exact: true },
    { path: '/en/project/detail/:id/:slug', component: ProjectDetailContainer, exact: true },
    { path: '/jp/project/detail/:id/:slug', component: ProjectDetailContainer, exact: true },
    /* project detail slug */

    { path: '/projects', component: Projects, exact: true },
    { path: '/en/projects', component: Projects, exact: true },
    { path: '/jp/projects', component: Projects, exact: true },

    /* for owners */
    { path: '/for-owners', component: MainForOwnersContainer, exact: true },
    { path: '/en/for-owners', component: MainForOwnersContainer, exact: true },
    { path: '/jp/for-owners', component: MainForOwnersContainer, exact: true },

    { path: '/for-owners/create-your-property', component: FormCreateInfomationContainer, exact: true },
    { path: '/en/for-owners/create-your-property', component: FormCreateInfomationContainer, exact: true },
    { path: '/jp/for-owners/create-your-property', component: FormCreateInfomationContainer, exact: true },

    { path: '/for-owners/faqs', component: FaqsForOwnersContainer, exact: true },
    { path: '/en/for-owners/faqs', component: FaqsForOwnersContainer, exact: true },
    { path: '/jp/for-owners/faqs', component: FaqsForOwnersContainer, exact: true },
    /* for owners */

    /* about isn */
    { path: '/about-isn', component: MainAboutContainer, exact: true },
    { path: '/en/about-isn', component: MainAboutContainer, exact: true },
    { path: '/jp/about-isn', component: MainAboutContainer, exact: true },

    { path: '/about-isn/company-profile', component: CompanyProfileContainer, exact: true },
    { path: '/en/about-isn/company-profile', component: CompanyProfileContainer, exact: true },
    { path: '/jp/about-isn/company-profile', component: CompanyProfileContainer, exact: true },

    { path: '/about-isn/company-philosophy', component: CompanyPhilosophyContainer, exact: true },
    { path: '/en/about-isn/company-philosophy', component: CompanyPhilosophyContainer, exact: true },
    { path: '/jp/about-isn/company-philosophy', component: CompanyPhilosophyContainer, exact: true },

    { path: '/about-isn/our-company-feature', component: OurCompanyContainer, exact: true },
    { path: '/en/about-isn/our-company-feature', component: OurCompanyContainer, exact: true },
    { path: '/jp/about-isn/our-company-feature', component: OurCompanyContainer, exact: true },

    { path: '/about-isn/meet-our-team', component: MeetOurContainer, exact: true },
    { path: '/en/about-isn/meet-our-team', component: MeetOurContainer, exact: true },
    { path: '/jp/about-isn/meet-our-team', component: MeetOurContainer, exact: true },

    { path: '/about-isn/mess-from-rep', component: MessageFromRepresentativeContainer, exact: true },
    { path: '/en/about-isn/mess-from-rep', component: MessageFromRepresentativeContainer, exact: true },
    { path: '/jp/about-isn/mess-from-rep', component: MessageFromRepresentativeContainer, exact: true },

    { path: '/about-isn/group-companies', component: GroupCompaniesContainer, exact: true },
    { path: '/en/about-isn/group-companies', component: GroupCompaniesContainer, exact: true },
    { path: '/jp/about-isn/group-companies', component: GroupCompaniesContainer, exact: true },

    { path: '/about-isn/preparation-page', component: PreparationContainer, exact: true },
    { path: '/en/about-isn/preparation-page', component: PreparationContainer, exact: true },
    { path: '/jp/about-isn/preparation-page', component: PreparationContainer, exact: true },

    { path: '/about-isn/history', component: HistoryContainer, exact: true },
    { path: '/en/about-isn/history', component: HistoryContainer, exact: true },
    { path: '/jp/about-isn/history', component: HistoryContainer, exact: true },

    { path: '/about-isn/our-main-services', component: MainServicesContainer, exact: true },
    { path: '/en/about-isn/our-main-services', component: MainServicesContainer, exact: true },
    { path: '/jp/about-isn/our-main-services', component: MainServicesContainer, exact: true },

    { path: '/about-isn/why-choose-us', component: WhyChooseContainer, exact: true },
    { path: '/en/about-isn/why-choose-us', component: WhyChooseContainer, exact: true },
    { path: '/jp/about-isn/why-choose-us', component: WhyChooseContainer, exact: true },

    { path: '/about-isn/news-release', component: NewsReleaseContainer, exact: true },
    { path: '/en/about-isn/news-release', component: NewsReleaseContainer, exact: true },
    { path: '/jp/about-isn/news-release', component: NewsReleaseContainer, exact: true },

    { path: '/about-isn/news-release-detail/:id/:slug', component: NewsDetailContainer, exact: true },
    { path: '/en/about-isn/news-release-detail/:id/:slug', component: NewsDetailContainer, exact: true },
    { path: '/jp/about-isn/news-release-detail/:id/:slug', component: NewsDetailContainer, exact: true },

    { path: '/about-isn/contact-us', component: ContactUsContainer, exact: true },
    { path: '/en/about-isn/contact-us', component: ContactUsContainer, exact: true },
    { path: '/jp/about-isn/contact-us', component: ContactUsContainer, exact: true },

    { path: '/notification/:type', component: NotificationContainer, exact: true },
    { path: '/en/notification/:type', component: NotificationContainer, exact: true },
    { path: '/jp/notification/:type', component: NotificationContainer, exact: true },
    /* about isn */

    /* favorites */
    { path: '/favorites', component: FavoritesContainer, exact: true },
    { path: '/en/favorites', component: FavoritesContainer, exact: true },
    { path: '/jp/favorites', component: FavoritesContainer, exact: true },
    /* favorites */

    /* histories */
    { path: '/histories', component: HistoriesContainer, exact: true },
    { path: '/en/histories', component: HistoriesContainer, exact: true },
    { path: '/jp/histories', component: HistoriesContainer, exact: true },
    /* histories */

    /* slug */
    // { path: '/:slug', component: NormalPageContainer, exact: true },
    { path: '/en/normal/:slug', component: NormalPageContainer, exact: true },
    { path: '/jp/normal/:slug', component: NormalPageContainer, exact: true },
    /* slug */

    /* useful */

    { path: '/useful-information/our-customer-support-service', component: FaqsContainer, exact: true },
    { path: '/en/useful-information/our-customer-support-service', component: FaqsContainer, exact: true },
    { path: '/jp/useful-information/our-customer-support-service', component: FaqsContainer, exact: true },

    { path: '/useful-information/rental-flow', component: FaqsContainer, exact: true },
    { path: '/en/useful-information/rental-flow', component: FaqsContainer, exact: true },
    { path: '/jp/useful-information/rental-flow', component: FaqsContainer, exact: true },

    { path: '/useful-information/useful-information-for-tenants', component: FaqsContainer, exact: true },
    { path: '/en/useful-information/useful-information-for-tenants', component: FaqsContainer, exact: true },
    { path: '/jp/useful-information/useful-information-for-tenants', component: FaqsContainer, exact: true },

    { path: '/useful-information/faq', component: FaqsContainer, exact: true },
    { path: '/en/useful-information/faq', component: FaqsContainer, exact: true },
    { path: '/jp/useful-information/faq', component: FaqsContainer, exact: true },

    { path: '/useful-information/useful-service-apartment', component: ServiceApartmentContainer, exact: true },
    { path: '/en/useful-information/useful-service-apartment', component: ServiceApartmentContainer, exact: true },
    { path: '/jp/useful-information/useful-service-apartment', component: ServiceApartmentContainer, exact: true },

    { path: '/useful-information/useful-service-apartment/:topic', component: ServiceApartmentContainer, exact: true },
    {
      path: '/en/useful-information/useful-service-apartment/:topic',
      component: ServiceApartmentContainer,
      exact: true,
    },
    {
      path: '/jp/useful-information/useful-service-apartment/:topic',
      component: ServiceApartmentContainer,
      exact: true,
    },

    { path: '/useful-information/all', component: ServiceApartmentContainer, exact: true },
    { path: '/en/useful-information/all', component: ServiceApartmentContainer, exact: true },
    { path: '/jp/useful-information/all', component: ServiceApartmentContainer, exact: true },

    /* special */
    { path: '/special/:id', component: SpecialContainer, exact: true },
    { path: '/en/special/:id', component: SpecialContainer, exact: true },
    { path: '/jp/special/:id', component: SpecialContainer, exact: true },

    /* member */
    { path: '/member', component: MemberPage, exact: true },
    { path: '/en/member', component: MemberPage, exact: true },
    { path: '/jp/member', component: MemberPage, exact: true },

    { path: '/list-properties/:type/:props', component: PropertiesContainer, exact: true },
    { path: '/en/list-properties/:type/:props', component: PropertiesContainer, exact: true },
    { path: '/jp/list-properties/:type/:props', component: PropertiesContainer, exact: true },
  ],
};

export default publicRouters;
