import React, { Fragment, useEffect } from 'react';
import { ResidenceImageSlides, DetailsInfo, Lists, Description, Project, Map, ButtonNextPrev } from './details/';
import { connect } from 'react-redux';
import { getPathLanguage } from '../../../utils/params';
import { useLocation, useHistory } from 'react-router-dom';

const ResidenceDetailsComponent = props => {
  const pathLanguage = getPathLanguage(useLocation());
  const history = useHistory();
  const dataHistory = localStorage.getItem('HISTORY') ? localStorage.getItem('HISTORY').split(',') : [];
  let resultSearch = [];
  if ('resultResidenceSearch' in localStorage) {
    resultSearch = JSON.parse(localStorage.getItem('resultResidenceSearch'));
  }
  const handleClickNext = (id, action) => {
    if (resultSearch.length != 0) {
      const index = resultSearch.findIndex(x => x.id == id);
      let idNext = 0;
      let slugItem = '';
      if (index === 0 && action == "PREV") {
        const resultSearchLength = resultSearch.length;
        idNext = resultSearch[resultSearchLength - 1].id;
        slugItem = resultSearch[resultSearchLength - 1].slug;
      } else if (index === (resultSearch.length - 1) && action == "NEXT") {
        idNext = resultSearch[0].id;
        slugItem = resultSearch[0].slug;
      } else {
        if (action == "NEXT") {
          idNext = resultSearch[index + 1].id;
          slugItem = resultSearch[index + 1].slug;
        } else if (action == "PREV") {
          idNext = resultSearch[index - 1].id;
          slugItem = resultSearch[index - 1].slug;
        }
      }
      history.push(`${pathLanguage}/residence/detail/${idNext}/${slugItem}`);
    }
  };
  const { residencesSearch } = props.residence;
  useEffect(() => {
    if (residencesSearch?.items.length > 0) {
      localStorage.setItem('resultResidenceSearch', JSON.stringify(residencesSearch?.items))
    }
  }, [residencesSearch])

  useEffect(() => {
    if (props.data && props.data.id) {
      const { id } = props.data;
      let dataHistoryClone = dataHistory;
      if (!dataHistory.includes(id)) dataHistoryClone = [...dataHistoryClone, id];

      localStorage.setItem('HISTORY', dataHistoryClone);
    }

    // eslint-disable-next-line
  }, [dataHistory]);

  return (
    <Fragment>
      {resultSearch.length > 0 &&
        <div className="background-gray pt-4">
          <ButtonNextPrev onHandleChange={handleClickNext} dataDetai={props.data} />
        </div>
      }
      <div className="section-block background-gray pt-4">
        <div className="container">
          <div className="post-details jp-font-sawarabi-gothic">
            <div className="row mb-5">
              <div className="col-12 col-md-7">
                <ResidenceImageSlides
                  images={props.data && props.data.images && props.data.images.length ? props.data.images : []}
                />
              </div>
              <div className="col-12 col-md-5 summary">
                <DetailsInfo data={props.data || null} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 mb-3 block-content">
                {props.data.videoLink ? (
                  <div className="properties-detail-video">
                    <iframe
                      className="video-iframe"
                      width="100%"
                      height={502}
                      src={`https://www.youtube.com/embed/${props.data.videoLink}`}
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="frame"
                    />
                  </div>
                ) : (
                  ''
                )}
                <Description content={(props.data && props.data.description) || ''} />
                <Lists
                  included={
                    props.data && props.data.includeServices && props.data.includeServices.length
                      ? props.data.includeServices
                      : []
                  }
                />
                <Lists
                  interior={
                    props.data && props.data.interiorFacilities && props.data.interiorFacilities.length
                      ? props.data.interiorFacilities
                      : []
                  }
                />
                <Lists
                  building={
                    props.data && props.data.buildingFacilities && props.data.buildingFacilities.length
                      ? props.data.buildingFacilities
                      : []
                  }
                />
                <Project data={props.data || ''} />
                <Map
                  longitude={(props.data && props.data.longitude) || null}
                  latitude={(props.data && props.data.latitude) || null}
                  name={(props.data && props.data.name) || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = () => {
};

export default connect(mapStateToProps, mapDispatchToProps)(ResidenceDetailsComponent);
