// eslint-disable-next-line no-unused-vars
import { all } from 'redux-saga/effects';

import { layoutSaga } from '../layouts';
import { homeSaga } from '../modules/homePage';
import { officesSaga } from '../modules/officesPage';
import { residenceSaga } from '../modules/residencePage';
import { blogsSaga } from '../modules/blogsPage';
import { officeDetailSaga } from '../modules/officeDetailPage';
import { forOwnersSaga } from '../modules/forOwnersPage';
import { projectsSaga } from '../modules/projectsPage';
import { favoritesSaga } from '../modules/favoriteHistoryPage';
import { aboutIsnSaga } from '../modules/aboutIsnPage';
import { usefulInformationSaga } from '../modules/usefulPage';
import { specialSaga } from '../modules/specialPage';
import { propertiesSaga } from '../modules/propertiesPage';

export default function* rootSaga() {
  yield all([
    homeSaga(),
    officesSaga(),
    layoutSaga(),
    residenceSaga(),
    blogsSaga(),
    officeDetailSaga(),
    forOwnersSaga(),
    projectsSaga(),
    favoritesSaga(),
    aboutIsnSaga(),
    usefulInformationSaga(),
    specialSaga(),
    propertiesSaga(),
  ]);
}
