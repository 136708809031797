// import EventBus from 'eventing-bus';
import * as types from './favorite.types';

const initialState = {
  favorites: null,
  histories: null,
  error: null,
};

const favoritesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FAVORITES_REQUEST:
      return { ...state };
    case types.FETCH_FAVORITES_SUCCESS: {
      const data = {
        residences: [],
        offices: [],
      };
      const { items } = action.data.favorites || [];
      if (items && items.length) {
        items.forEach(i => {
          if (i.propertyType === 'residence') data.residences.push(i.id);
          else if (i.propertyType === 'office') data.offices.push(i.id);
        });
      }
      //   if (data.residences.length) localStorage.setItem('RESIDENCES_LIKE', data.residences);
      //   else localStorage.removeItem('RESIDENCES_LIKE');
      //   if (data.offices.length) localStorage.setItem('OFFICES_LIKE', data.offices);
      //   else localStorage.removeItem('OFFICES_LIKE');

      //   EventBus.publish('RESET_LIKE', true);

      return { ...state, favorites: action.data.favorites };
    }
    case types.FETCH_FAVORITES_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_HISTORIES_REQUEST:
      return { ...state };
    case types.FETCH_HISTORIES_SUCCESS: {
      //   const { items } = action.data.histories || [];
      //   if (items.length) {
      //     const newItems = items.map(i => i.id);
      //     localStorage.setItem('HISTORY', newItems);
      //   }

      //   EventBus.publish('RESET_LIKE', true);

      return { ...state, histories: action.data.histories };
    }

    case types.FETCH_HISTORIES_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default favoritesReducer;
