import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import ArrowRightRed from '../../../../assets/images/arrow-right-red.svg';
import { getPathLanguage } from '../../../../utils/params';

const GroupCompaniesContent = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const data = props.aboutIsn.groupCompany;

  return (
    <>
      {data && data.length
        ? data.map((item, index) => (
            <div className="group-info" key={index}>
              <div className={`group-info-head ${pathLanguage === '/jp' ? 'group-info-head-jp' : ''}`}>
                <ul>
                  <li className="corporation-name jp-bold-600">{item.name}</li>
                  <li className="visit-web jp-400-sawarabi">
                    <Link to={`${pathLanguage}/about-isn/preparation-page`} target="_blank">
                      {t('about_isn.isn_group_page.visit_website')}{' '}
                      {/* <LazyLoadImage alt="" src={ArrowRightRed} effect="blur" /> */}
                      <img src={ArrowRightRed} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="group-info-content">
                <div className="row">
                  <div className="col-4 col-md-2 capitalize-group-title jp-500-sawarabi">
                    {' '}
                    {t('about_isn.isn_group_page.established')}{' '}
                  </div>
                  <div className="col-8 col-md-10 jp-font-sawarabi-gothic">{item.established}</div>
                  <div className="col-4 col-md-2 capitalize-group-title jp-500-sawarabi">
                    {' '}
                    {t('about_isn.isn_group_page.officed')}{' '}
                  </div>
                  <div className="col-8 col-md-10 jp-font-sawarabi-gothic">{item.office}</div>
                  <div className="col-4 col-md-2 capitalize-group-title jp-500-sawarabi">
                    {' '}
                    {t('about_isn.isn_group_page.business')}{' '}
                  </div>
                  <div className="col-8 col-md-10 jp-font-sawarabi-gothic">{item.bussiness}</div>
                </div>
              </div>
            </div>
          ))
        : ''}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GroupCompaniesContent);
