import * as types from './blogs.types';

export const fetchBlogsRequest = params => ({ type: types.FETCH_BLOGS_LIST_REQUEST, params });
export const fetchBlogsSuccess = data => ({ type: types.FETCH_BLOGS_LIST_SUCCESS, data });
export const fetchBlogsFailure = error => ({ type: types.FETCH_BLOGS_LIST_FAILURE, error });

export const getMainBlogsRequest = params => ({ type: types.GET_MAIN_BLOGS_REQUEST, params });
export const getMainBlogsSuccess = data => ({ type: types.GET_MAIN_BLOGS_SUCCESS, data });
export const getMainBlogsFailure = error => ({ type: types.GET_MAIN_BLOGS_FAILURE, error });

export const getBlogRequest = params => ({ type: types.GET_BLOG_REQUEST, params });
export const getBlogSuccess = data => ({ type: types.GET_BLOG_SUCCESS, data });
export const getBlogFailure = error => ({ type: types.GET_BLOG_FAILURE, error });

export const fetchOtherBlogsRequest = params => ({ type: types.FETCH_OTHER_BLOGS_REQUEST, params });
export const fetchOtherBlogsSuccess = data => ({ type: types.FETCH_OTHER_BLOGS_SUCCESS, data });
export const fetchOtherBlogsFailure = error => ({ type: types.FETCH_OTHER_BLOGS_FAILURE, error });

export const fetchAboutIsnRequest = params => ({ type: types.FETCH_ABOUT_ISN_REQUEST, params });
export const fetchAboutIsnSuccess = data => ({ type: types.FETCH_ABOUT_ISN_SUCCESS, data });
export const fetchAboutIsnFailure = error => ({ type: types.FETCH_ABOUT_ISN_FAILURE, error });
