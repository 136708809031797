import { get } from 'lodash';
import Service from '../../services';

export const getOfficeDetail = async params => {
  const response = await Service.get(`/api/properties/offices/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getSimilarList = async params => {
  const response = await Service.get(`/api/properties/similar${params}`, res => res);
  return response;
};
