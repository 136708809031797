import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPathLanguage } from '../../../../utils/params';

const ProjectComponent = props => {
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();

  return (
    <div className="hidden-print">
      <h2 className="uppercase jp-bold-600">{t('residence_detail.project_information')}</h2>
      <p className="jp-font-sawarabi-gothic">
        <Link
          className="project-info"
          to={`${pathLanguage}/project/detail/${props.data && props.data.projectId}/${
            props.data && props.data.projectSlug
          }`}
          target="_blank"
        >
          {(props.data && props.data.projectName) || ''}
        </Link>
      </p>
      <hr />
    </div>
  );
};

export default ProjectComponent;
