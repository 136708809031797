import React from 'react';
import { useTranslation } from 'react-i18next';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Image } from '../../../commons/iconService';

const OfficeList = props => {
  const { services } = props;
  const { t } = useTranslation();
  const images = Image.funitures;

  return (
    <>
      <h2 className="uppercase jp-bold-600">{t('office_detail.features')}</h2>
      <ul className="list-furniture jp-font-sawarabi-gothic">
        {services.length &&
          services.map((item, index) => (
            <li className={` furniture-item ${!item.isShow ? 'disabled' : ''}`} key={index}>
              {index >= (images && Object.keys(images).length) ? (
                <img alt="default" src={Image.defaultIcon} />
              ) : (
                // <LazyLoadImage alt="default" src={Image.defaultIcon} effect="blur" />
                <img alt="" src={images[`funitures${index + 1}`]} />
                // <LazyLoadImage alt="" src={images[`funitures${index + 1}`]} effect="blur" />
              )}
              {item.name}
            </li>
          ))}
      </ul>
      <hr />
    </>
  );
};

export default OfficeList;
