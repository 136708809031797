import home from './home.json';
import navigation from './navigation.json';
import normal from './normal.json';
import footer from './footer.json';
import detail from './detail.json';
import blogs from './blogs.json';
import search from './search.json';
import favorite from './favorite.json';
import forOwners from './forOwners.json';
import project from './project.json';
import about from './about.json';
import useful from './useful.json';
import preparation from './preparation.json';
import title from './title.json';
import seo from './seo.json';

const en = {
  ...home,
  ...navigation,
  ...normal,
  ...footer,
  ...detail,
  ...blogs,
  ...search,
  ...favorite,
  ...forOwners,
  ...project,
  ...about,
  ...useful,
  ...preparation,
  ...title,
  ...seo,
};

export { en };
