import * as types from './residence.types';

export const getResidenceRequest = params => ({ type: types.GET_RESIDENCE_REQUEST, params });
export const getResidenceSuccess = data => ({ type: types.GET_RESIDENCE_SUCCESS, data });
export const getResidenceFailure = error => ({ type: types.GET_RESIDENCE_FAILURE, error });

export const getSimilarPropertiesRequest = params => ({ type: types.GET_SIMILAR_PROPERTIES_REQUEST, params });
export const getSimilarPropertiesSuccess = data => ({ type: types.GET_SIMILAR_PROPERTIES_SUCCESS, data });
export const getSimilarPropertiesFailure = error => ({ type: types.GET_SIMILAR_PROPERTIES_FAILURE, error });

export const fetchResidencesSearchRequest = params => ({ type: types.FETCH_RESIDECES_SEARCH_REQUEST, params });
export const fetchResidencesSearchSuccess = data => ({ type: types.FETCH_RESIDECES_SEARCH_SUCCESS, data });
export const fetchResidencesSearchFailure = error => ({ type: types.FETCH_RESIDECES_SEARCH_FAILURE, error });

export const postContactUsRequest = params => ({ type: types.POST_CONTACT_US_REQUEST, params });
