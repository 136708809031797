import React from 'react';
import { useTranslation } from 'react-i18next';

const ResidenceDescription = props => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="uppercase jp-bold-600">{t('residence_detail.descriptions')}</h2>
      <p
        className="jp-font-sawarabi-gothic"
        dangerouslySetInnerHTML={{ __html: props.content ? props.content.replace(/\n/gi, '<br />') : '' }}
      ></p>
      <hr />
    </>
  );
};

export default ResidenceDescription;
