const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST';
const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
const GET_PROJECT_FAILURE = 'GET_PROJECT_FAILURE';

const FETCH_PROPERTIES_IN_PROJECT_REQUEST = 'FETCH_PROPERTIES_IN_PROJECT_REQUEST';
const FETCH_PROPERTIES_IN_PROJECT_SUCCESS = 'FETCH_PROPERTIES_IN_PROJECT_SUCCESS';
const FETCH_PROPERTIES_IN_PROJECT_FAILURE = 'FETCH_PROPERTIES_IN_PROJECT_FAILURE';

const FETCH_LIST_PROJECTS_REQUEST = 'FETCH_LIST_PROJECTS_REQUEST';
const FETCH_LIST_PROJECTS_SUCCESS = 'FETCH_LIST_PROJECTS_SUCCESS';
const FETCH_LIST_PROJECTS_FAILURE = 'FETCH_LIST_PROJECTS_FAILURE';

export {
  GET_PROJECT_REQUEST,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  FETCH_PROPERTIES_IN_PROJECT_REQUEST,
  FETCH_PROPERTIES_IN_PROJECT_SUCCESS,
  FETCH_PROPERTIES_IN_PROJECT_FAILURE,
  FETCH_LIST_PROJECTS_REQUEST,
  FETCH_LIST_PROJECTS_SUCCESS,
  FETCH_LIST_PROJECTS_FAILURE,
};
