import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbForOwners, HeaderForOwners, FormOwnerInfomation } from '../common';

import background from '../../../assets/images/background-form-for-owners.jpg';

const FormCreateComponent = () => {
  const { t } = useTranslation();
  const breadCrumb = [
    { title: t('for_owners.home'), url: '/' },
    { title: t('for_owners.for_owners'), url: '/for-owners' },
    { title: t('for_owners.create_your_property'), url: '/for-owners/create-your-property' },
  ];
  const headerContent = {
    vietnameseTitle: 'Cảm ơn bạn đã liên hệ với ISN Vietnamhousing!',
    englishTitle: 'Thank you for contacting ISN Vietnamhousing!',
    background,
  };

  return (
    <>
      <BreadcrumbForOwners breadCrumb={breadCrumb} />
      <HeaderForOwners content={headerContent} />

      <div className="section-block background-gray">
        <div className="container">
          <div className="post-content-detail page-for-owners">
            <div className="row warning-message">
              <div className="col-12 text-center">
                <span className="link-red-bold ">
                  Xin vui lòng điền vào mẫu đăng ký (Please fill in the registration form for lease below)
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <FormOwnerInfomation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormCreateComponent;
