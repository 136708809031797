import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  AboutIsnComponent,
  CompanyProfileComponent,
  CompanyPhilosophyComponent,
  OurCompanyComponent,
  // MeetOurComponent,
  MessageFromRepresentativeComponent,
  GroupCompaniesComponent,
  HistoryComponent,
  MainServicesComponent,
  WhyChooseComponent,
  NewsReleaseComponent,
  NewsDetailComponent,
  ContactUsComponent,
  PreparationComponent,
  NotificationComponent,
} from '../pages';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default {
  MainAboutContainer: connect(mapStateToProps, mapDispatchToProps)(AboutIsnComponent),
  //Company overview
  CompanyProfileContainer: connect(mapStateToProps, mapDispatchToProps)(CompanyProfileComponent),
  CompanyPhilosophyContainer: connect(mapStateToProps, mapDispatchToProps)(CompanyPhilosophyComponent),
  OurCompanyContainer: connect(mapStateToProps, mapDispatchToProps)(OurCompanyComponent),
  // MeetOurContainer: connect(mapStateToProps, mapDispatchToProps)(MeetOurComponent),
  MessageFromRepresentativeContainer: connect(mapStateToProps, mapDispatchToProps)(MessageFromRepresentativeComponent),
  //ISN Group
  GroupCompaniesContainer: connect(mapStateToProps, mapDispatchToProps)(GroupCompaniesComponent),
  HistoryContainer: connect(mapStateToProps, mapDispatchToProps)(HistoryComponent),
  MainServicesContainer: connect(mapStateToProps, mapDispatchToProps)(MainServicesComponent),
  WhyChooseContainer: connect(mapStateToProps, mapDispatchToProps)(WhyChooseComponent),
  PreparationContainer: connect(mapStateToProps, mapDispatchToProps)(PreparationComponent),
  //News Release
  NewsReleaseContainer: connect(mapStateToProps, mapDispatchToProps)(NewsReleaseComponent),
  NewsDetailContainer: connect(mapStateToProps, mapDispatchToProps)(NewsDetailComponent),
  //Contact Us
  ContactUsContainer: connect(mapStateToProps, mapDispatchToProps)(ContactUsComponent),
  NotificationContainer: connect(mapStateToProps, mapDispatchToProps)(NotificationComponent),
};
