import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import * as action from '../../redux/layout.action';
import { history } from '../../../utils/history';
import { getPathLanguage, getLanguage } from '../../../utils/params';

const Subscribe = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const language = getLanguage(useLocation());
  const { handleSubmit, register, errors, reset } = useForm();
  const onSuccess = () => {
    // toast.success(t('home_page.newsletter.message_success'));
    history.push(`${pathLanguage}/notification/subscribe`);
    reset();
  };
  const onError = err => toast.error(err);
  const OnSubmit = value => {
    if (value) {
      const { email } = value;
      props.postSubscribeNewsletter({ email, onSuccess, onError });
    }
  };

  useEffect(() => {
    return () => reset();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="section-block subscribe">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h3>{t('footer.subscribe_to_our_newletter')}</h3>
              <p>{t('footer.content_subscribe')}</p>
            </div>
            <div className="col-12 col-md-6">
              <form onSubmit={handleSubmit(OnSubmit)}>
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      placeholder="hello@example.com"
                      ref={register({
                        required: t('home_page.newsletter.email_require'),
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: t('home_page.newsletter.email_invalid'),
                        },
                      })}
                    />
                    <small className="error, text-danger">
                      {errors.email && errors.email.message ? errors.email.message : ''}
                    </small>
                  </div>
                  <div className="col-6 col-sm-4 col-md-4">
                    <button type="submit" className="button-red button-black button-hover">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-bell"
                      >
                        <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                        <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                      </svg>{' '}
                      {t('footer.subscribe')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {language === 'jp' ?
            <div className='row'>
              <div className="col-12 col-md-6" style={{ margin: "auto", paddingTop: "10px" }}>
                <h3>{t('footer.line_account')}</h3>
                <p>{t('footer.line_content')}</p>
              </div>
              <div className="col-12 col-md-6">
                <div className="col-12 col-sm-8 col-md-8 text-center">
                  <div>
                    <p style={{ marginBottom: "unset" }}>{t('footer.line_qr')}</p>
                  </div>
                  <img src="https://qr-official.line.me/sid/M/153xewhx.png?shortenUrl=true" />
                </div>
              </div>
            </div> : ''
          }
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postSubscribeNewsletter: action.subscribeNewsletterRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
