import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import * as actions from '../../aboutIsn.action';
import { getLanguage, getIdDetail } from '../../../../utils/params';
import clock from '../../../../assets/images/clock.svg';

const DetailContent = props => {
  const id = getIdDetail(useLocation());
  const language = getLanguage(useLocation());

  useEffect(() => {
    if (id && language) {
      props.getNewsContentRequest({ language, id });
    }
    // eslint-disable-next-line
  }, [id]);

  const { newsContent } = props.aboutIsn;

  return (
    <div className="col-12 col-md-8">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <h1>{(newsContent && newsContent.title) || ''}</h1>
          <div className="created blog-time">
            {newsContent && newsContent.publicDate && (
              //   <>
              //     <LazyLoadImage src={clock} className="blog-clock" alt="" effect="blur" />{' '}
              //     <span>{newsContent.publicDate || ''}</span>
              //   </>
              <>
                <img src={clock} className="blog-clock" alt="" /> <span>{newsContent.publicDate || ''}</span>
              </>
            )}
          </div>
          <div
            className="post-content-text"
            dangerouslySetInnerHTML={{ __html: (newsContent && newsContent.blogContent) || '' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNewsContentRequest: actions.getNewsContentRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailContent);
