import * as types from './offices.types';

export const fetchOfficesLastestRequest = params => ({ type: types.FETCH_OFFICES_LASTEST_REQUEST, params });
export const fetchOfficesLastestSuccess = data => ({ type: types.FETCH_OFFICES_LASTEST_SUCCESS, data });
export const fetchOfficesLastestFailure = error => ({ type: types.FETCH_OFFICES_LASTEST_FAILURE, error });

export const fetchOfficesHotRequest = params => ({ type: types.FETCH_OFFICES_HOT_REQUEST, params });
export const fetchOfficesHotSuccess = data => ({ type: types.FETCH_OFFICES_HOT_SUCCESS, data });
export const fetchOfficesHotFailure = error => ({ type: types.FETCH_OFFICES_HOT_FAILURE, error });

export const fetchOfficesBlogRequest = params => ({ type: types.FETCH_OFFICES_BLOGS_REQUEST, params });
export const fetchOfficesBlogSuccess = data => ({ type: types.FETCH_OFFICES_BLOGS_SUCCESS, data });
export const fetchOfficesBlogFailure = error => ({ type: types.FETCH_OFFICES_BLOGS_FAILURE, error });
