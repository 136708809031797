import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import { get } from 'lodash';
// import EventBus from 'eventing-bus';
import { toast } from 'react-toastify';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReCAPTCHA from 'react-google-recaptcha';

import Upload from '../../../../assets/images/Upload.svg';
import CloseImage from '../../../../assets/images/Icon_x.svg';
import * as action from '../../forOwners.action';
import { Select } from '../../../../components';

const FormOwnerInfomation = props => {
  const { handleSubmit, register, errors, reset } = useForm();

  const { bedrooms, bathrooms, propertyTypes, cities } = props.forOwners;
  const currency = [
    { name: 'USD', value: 'USD' },
    { name: 'VND', value: 'VND' },
  ];
  const [idLocation, setIdLocation] = useState('');
  const [files, setFiles] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [isShowSubmit, setIsShowSubmit] = useState(false);

  useEffect(() => {
    props.fetchForOwnersRequest({ language: 'en' });
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const propsId = get(props, 'layout.cityActive.defaultLocationId', null);
    setIdLocation(propsId);

    return () => reset();
    // eslint-disable-next-line
  }, [props.layout.cityActive]);

  const onSubmit = values => {
    if (values) {
      const formData = new FormData();

      formData.append('language', 'en');
      formData.append('location', idLocation);
      formData.append('fullName', values.fullName);
      formData.append('phone', values.phone);
      formData.append('email', values.email);
      formData.append('cityId', values.cityId);
      formData.append('propertyType', values.propertyType);
      formData.append('currency', values.currency);
      formData.append('numberOfBedroom', parseInt(values.numberOfBedroom));
      formData.append('numberOfBadroom', parseInt(values.numberOfBadroom));
      if (values.addressForLease) formData.append('addressForLease', values.addressForLease);
      if (values.leasableArea) formData.append('leasableArea', values.leasableArea);
      if (values.descriptionFacilities) formData.append('descriptionFacilities', values.descriptionFacilities);
      if (values.descriptionAmentities) formData.append('descriptionAmentities', values.descriptionAmentities);
      if (values.leasePrice) formData.append('leasePrice', parseInt(values.leasePrice));
      files.forEach(file => {
        formData.append('images', file, file.name);
      });

      props.postInformationRequest({ formData, onSuccess, onError });
    }
  };

  const messageSuccess = (
    <>
      <div>
        Quý khách đã đăng ký cho thuê thành công. Chúng tôi sẽ liên hệ lại ngay nếu cần thêm thông tin. Rất hân hạnh
        được hợp tác!
      </div>
      <div>
        Your registration form was sent successfully. We will contact you soon should we need more information. Thank
        you for your cooperation!
      </div>
    </>
  );

  const onSuccess = () => {
    toast.success(messageSuccess);
    reset();
    setFiles([]);
  };
  const onError = err => toast.error(err);

  const removeImage = index => {
    let filesCopy = [...files];
    filesCopy.splice(index, 1);

    setFiles(filesCopy);

    const images = filesCopy.map(item => URL.createObjectURL(item));
    setFilePreview(images);
  };

  const checkCaptcha = value => {
    if (value) setIsShowSubmit(true);
  };

  return (
    <form className="box-white" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="uppercase form-owner-title">Thông tin chủ nhà (property owner information)</h3>
      <div className="group">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label>
                Họ và tên / Full name <span className="link-red-bold ">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Nguyen Van Anh"
                name="fullName"
                ref={register({ required: 'Không được để trống' })}
              />
              <small className="error">
                {errors.fullName && errors.fullName.message ? errors.fullName.message : ''}
              </small>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label>
                Số điện thoại / Telephone <span className="link-red-bold ">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="phone"
                ref={register({
                  required: 'Không được để trống',
                  // pattern: {
                  //   value: /^[0-9]+$/,
                  //   message: 'Số điện thoại không hợp lệ',
                  // },
                })}
              />
              <small className="error">{errors.phone && errors.phone.message ? errors.phone.message : ''}</small>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label>
                Thư điện tử / Email <span className="link-red-bold ">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                ref={register({
                  required: 'Không được để trống',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Email không hợp lệ',
                  },
                })}
              />
              <small className="error">{errors.email && errors.email.message ? errors.email.message : ''}</small>
            </div>
          </div>
        </div>
      </div>
      <h3 className="uppercase form-owner-title">Thông tin căn hộ (apartment information)</h3>
      <div className="group">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="form-group">
              <label>Thành phố / City</label>
              <Select
                options={
                  cities && cities.length ? cities.map(item => ({ value: item.cityId, label: item.cityName })) : []
                }
                class_name="form-control select-control"
                errors={errors.cityId}
                register={register()}
                name="cityId"
              />
            </div>
          </div>
          <div className="col-12 col-md-8">
            <div className="form-group">
              <label>Địa chỉ cho thuê / Address for lease</label>
              <input
                type="text"
                className="form-control"
                placeholder="Xin vui lòng điền đầy đủ thông tin số căn hộ, tòa nhà, dự án, địa chỉ dự án…"
                name="addressForLease"
                ref={register()}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Loại hình địa ốc / Property type</label>
              <Select
                options={
                  propertyTypes && propertyTypes.length
                    ? propertyTypes.map(item => ({ value: item.key, label: item.name }))
                    : []
                }
                class_name="form-control select-control"
                errors={errors.propertyType}
                register={register()}
                name="propertyType"
              />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Diện tích / Leasable area</label>
              <input type="text" className="form-control" name="leasableArea" ref={register()} />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Số phòng ngủ / Number of bedroom </label>
              <Select
                options={
                  bedrooms && bedrooms.length ? bedrooms.map(item => ({ value: item.key, label: item.name })) : []
                }
                class_name="form-control select-control select-number-room"
                isAll={{ value: -1, label: 'Select One' }}
                errors={errors.numberOfBedroom}
                register={register()}
                name="numberOfBedroom"
              />
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="form-group">
              <label className="label-owner">Số phòng tắm / Number of bathroom</label>
              <Select
                options={
                  bathrooms && bathrooms.length ? bathrooms.map(item => ({ value: item.key, label: item.name })) : []
                }
                class_name="form-control select-control select-number-room"
                isAll={{ value: -1, label: 'Select One' }}
                errors={errors.numberOfBadroom}
                register={register()}
                name="numberOfBadroom"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Mô tả nội thất, tiện nghi / Description of furnitures and facilities</label>
              <textarea
                className="form-control w-100 area-owner"
                name="descriptionFacilities"
                ref={register()}
              ></textarea>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form-group">
              <label>Mô tả tiện ích xung quanh / Description of amenities</label>
              <textarea
                className="form-control w-100 area-owner"
                name="descriptionAmentities"
                ref={register()}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Giá thuê / Lease Price</label>
              <input
                type="text"
                className="form-control"
                name="leasePrice"
                ref={register({
                  pattern: {
                    value: /^[0-9]+$/,
                    message: 'Giá không hợp lệ',
                  },
                })}
              />
              <small className="error">
                {errors.leasePrice && errors.leasePrice.message ? errors.leasePrice.message : ''}
              </small>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="form-group">
              <label>Đơn vị tiền tệ / Currency</label>
              <Select
                options={
                  currency && currency.length ? currency.map(item => ({ value: item.value, label: item.name })) : []
                }
                class_name="form-control select-control"
                errors={errors.currency}
                register={register()}
                name="currency"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="form-group">
              <label>Tải hình ảnh lên / Upload image </label>
              <Dropzone
                onDrop={acceptedFiles => {
                  let checkErr = false;
                  let selectedFiles = [];

                  acceptedFiles.forEach(file => {
                    if (file.type.includes('image') && file.size < 1.9e6) {
                      selectedFiles.push(file);
                    } else {
                      checkErr = true;
                    }
                  });

                  if (checkErr) toast.error('File vượt quá dung lượng cho phép.');

                  let arrayFiles = [];

                  if (selectedFiles.length + files.length <= 10) arrayFiles = [...files, ...selectedFiles];
                  else arrayFiles = [...files, ...selectedFiles].slice(0, 10);

                  setFiles(arrayFiles);

                  const images = arrayFiles.map(item => URL.createObjectURL(item));
                  setFilePreview(images);
                }}
                accept="image/*"
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="drag-drop-files" {...getRootProps()}>
                    <div className="image-upload">
                      <label htmlFor="file-input">
                        <img src={Upload} alt="" />
                        {/* <LazyLoadImage src={Upload} alt="" effect="blur" /> */}
                      </label>
                      <input {...getInputProps()} />
                    </div>
                    <label>Click chuột hoặc kéo thả tập tin! (Click or drag here)</label>
                    <small>
                      Định dạng file được hỗ trợ : .jpeg, .png, .gif, … (Supported image format, max size: 2,5MB)
                    </small>
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="preview-image">
              <div className="row">
                <div className="col-12 col-md-12 image-all">
                  {filePreview.length
                    ? filePreview.map((item, index) => (
                        <div key={index} className="image-selected">
                          <img src={CloseImage} alt="" className="icon-close" onClick={() => removeImage(index)} />

                          <img src={item} alt="" className="image" />

                          {/* <LazyLoadImage
                            src={CloseImage}
                            alt=""
                            className="icon-close"
                            onClick={() => removeImage(index)}
                            effect="blur"
                          />
                          <LazyLoadImage src={item} alt="" className="image" effect="blur" /> */}
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="group">
        <div className="row justify-content-end">
          <div className="col-12 col-md-10 pb-2">
            <ReCAPTCHA sitekey={process.env.REACT_APP_KEY_CAPTCHA} onChange={checkCaptcha} />
          </div>
          <div className="col-12 col-md-2">
            <button type="submit" className="button-red uppercase" disabled={!isShowSubmit}>
              Gửi / Send
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchForOwnersRequest: action.fetchForOwnersRequest,
      postInformationRequest: action.postInformationRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FormOwnerInfomation);
