import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SizeIcon, Location, BedIcon } from '../../../../layouts/commons/icons';
import { getPathLanguage } from '../../../../utils/params';
import imageDefault from '../../../../assets/images/loading-image.jpg';
import { formatMoney } from '../../../../utils/objects';

const FavoriteItem = props => {
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();

  if (props.favorite.name) props.favorite.name = props.favorite.name.replace('　', ' ');

  return (
    <>
      <div className="col-12 col-md-4 mb-4">
        <div className="block-inner favorite single-owl-carousel">
          <div className="block-image">
            <div className="thumbail-slides">
              <Link
                to={
                  props.favorite.propertyType === 'residence'
                    ? `${pathLanguage}/residence/detail/${props.favorite.id}/${props.favorite.slug}`
                    : `${pathLanguage}/office/detail/${props.favorite.id}/${props.favorite.slug}`
                }
                className="block-handle-click"
              >
                {/* <span> */}
                {props.favorite.images && props.favorite.images[0] ? (
                  <img src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${props.favorite.images[0]}`} alt="" />
                ) : (
                  <img src={imageDefault} alt="" />
                )}
                {/* </span> */}
              </Link>
            </div>
            <span data-toggle="modal" data-target="#confirmModal" className="d-none">
              <span className="square-checked circle"></span>
            </span>
            <div className="favourite-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="checkbox-input"
                  id="check1"
                  value={props.favorite.id}
                  checked={props.contactIds.length && props.contactIds.includes(props.favorite.id)}
                  onChange={() => {
                    if (props.favorite.images && props.favorite.images.length) {
                      props.chooseItemContact({
                        id: props.favorite.id,
                        image: props.favorite.images[0],
                        propertyId: props.favorite.propertyId,
                      });
                    } else {
                      props.chooseItemContact({
                        id: props.favorite.id,
                        image: imageDefault,
                        propertyId: props.favorite.propertyId,
                      });
                    }
                  }}
                />
                <span className="favorites-checkbox-label" htmlFor="checkbox1"></span>
              </label>
            </div>
            {/* <span
              onClick={() => props.handleLikeProperties(props.favorite.id)}
              className={`heart circle ${
                props.listIds && props.listIds.includes(props.favorite.id) ? 'active-like' : ''
              }`}
            >
              <HeartIcon />
            </span> */}
          </div>
          <Link
            className="w-100"
            to={
              props.favorite.propertyType === 'residence'
                ? `${pathLanguage}/residence/detail/${props.favorite.id}/${props.favorite.slug}`
                : `${pathLanguage}/office/detail/${props.favorite.id}/${props.favorite.slug}`
            }
          >
            <div className="block-text none-split">
              <h4>{props.favorite.name || ''}</h4>
              <div className="property-info">
                <ul>
                  <li className="icon-district">
                    {props.favorite.district ? <Location /> : ''}
                    {props.favorite.district || ''}
                  </li>
                  <li className="icon-bedroom">
                    {props.favorite.propertyType === 'residence' ? (
                      <>
                        <BedIcon />
                        {props.favorite.bedroommin ? props.favorite.bedroommin : t('home_page.studio')}
                        {props.favorite.bedroomMax ? ` ~ ${props.favorite.bedroomMax}` : ''}
                        <span>&nbsp;{t('home_page.partial.bedrooms')}</span>
                      </>
                    ) : (
                      ''
                    )}
                    {props.favorite.propertyType === 'office' ? (
                      <>
                        {props.favorite.sqmmin || props.favorite.sqmMax ? <SizeIcon /> : ''}
                        {props.favorite.sqmmin || props.favorite.sqmMax ? formatMoney(props.favorite.sqmmin, 0, 0) : ''}
                        {props.favorite.sqmMax ? ' ~ ' : ''}
                        {props.favorite.sqmMax ? formatMoney(props.favorite.sqmMax, 0, 0) : ''}
                        {props.favorite.sqmmin || props.favorite.sqmMax ? (
                          <>
                            m<sup>2</sup>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </li>
                </ul>

                {props.favorite.propertyType === 'residence' ? (
                  <div className="info-residence">
                    {props.favorite.leasePriceMin || props.favorite.leasePriceMax ? (
                      <div className="price">
                        {props.favorite.leasePriceMin || props.favorite.leasePriceMax
                          ? `$${formatMoney(props.favorite.leasePriceMin, 0, 0)}`
                          : ''}
                        {props.favorite.leasePriceMax ? ` ~ $${formatMoney(props.favorite.leasePriceMax, 0, 0)}` : ''}
                        {props.favorite.leasePriceMin || props.favorite.leasePriceMax ? (
                          <span>/{t('home_page.partial.month')}</span>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="size">
                      {props.favorite.sqmmin || props.favorite.sqmMax ? <SizeIcon /> : ''}
                      {props.favorite.sqmmin || props.favorite.sqmMax ? formatMoney(props.favorite.sqmmin, 0, 0) : ''}
                      {props.favorite.sqmMax ? ' ~ ' : ''}
                      {props.favorite.sqmMax ? formatMoney(props.favorite.sqmMax, 0, 0) : ''}
                      {props.favorite.sqmmin || props.favorite.sqmMax ? (
                        <>
                          m<sup>2</sup>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {props.favorite.propertyType === 'office' ? (
                  <div className="price">
                    {props.favorite.leasePriceMin || props.favorite.leasePriceMax
                      ? `$${formatMoney(props.favorite.leasePriceMin, 0, 0)}`
                      : ''}
                    {props.favorite.leasePriceMax ? ` ~ $${formatMoney(props.favorite.leasePriceMax, 0, 0)}` : ''}
                    {props.favorite.leasePriceMin || props.favorite.leasePriceMax ? (
                      <span>
                        /m<sup>2</sup>/{t('home_page.partial.month')}
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default FavoriteItem;
