import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import owners1 from '../../../../assets/images/pexels-bongkarn-thanyakij-3843338.jpg';
import owners2 from '../../../../assets/images/pexels-bongkarn-thanyakij-3803219.jpg';
import { getPathLanguage } from '../../../../utils/params';
import { ArrowRightCircle } from '../../../../layouts/commons/icons';

const ForOwnersContent = () => {
  const pathLanguage = getPathLanguage(useLocation());

  return (
    <div className="overview-company">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 pl-0 pr-0">
            <div className="overview-company-image">
              <Link className="w-100 h-100 company-image-link" to={`${pathLanguage}/for-owners/faqs`}>
                <img src={owners1} className="w-100 h-100" alt="" />
                {/* <LazyLoadImage src={owners1} className="w-100 h-100" alt="" effect="blur" /> */}
                <div className="overview-company-overlay w-100 h-100" />
                <div className="overview-company-title">
                  Các câu hỏi thường gặp
                  <br />
                  <br />
                  Frequently asked questions
                  <div className="see-more">
                    <ArrowRightCircle />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 pl-0 pr-0">
            <div className="overview-company-image">
              <Link className="h-100 w-100 company-image-link" to={`${pathLanguage}/for-owners/create-your-property`}>
                <img src={owners2} className="overview-company-image w-100 h-100" alt="" />
                {/* <LazyLoadImage src={owners2}
                className="overview-company-image w-100 h-100" alt="" effect="blur" /> */}
                <div className="overview-company-overlay w-100 h-100" />
                <div className="overview-company-title">
                  Đăng ký cho thuê nhà
                  <br />
                  <br /> Register property for rent
                  <div className="see-more">
                    <ArrowRightCircle />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForOwnersContent);
