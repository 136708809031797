import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BreadcrumbAboutIsn, BannerAbout, RightMenu, WhyChooseContent } from '../../common';
import GroupImg from '../../../../assets/images/high-rise-buildings-1907046.jpg';
import { getLanguage } from '../../../../utils/params';
import * as action from '../../aboutIsn.action';

const WhyChooseComponent = props => {
  const { t } = useTranslation();
  const language = getLanguage(useLocation());

  const breadCrumb = [
    { title: t('about_isn.home'), url: '/' },
    { title: t('about_isn.about_isn'), url: '/about-isn' },
    { title: t('about_isn.isn_group_page.why_choose_us') },
  ];
  const banner = {
    img: GroupImg,
    title: t('about_isn.isn_group_page.why_choose_us'),
  };

  useEffect(() => {
    props.fetchAboutIsnRequest({ language, slug: 'why-choose-us' });
    // window.scrollTo(0, 0);
    const scrollTo = document.querySelector('#position-scroll');
    if (scrollTo) {
      scrollTo.scrollIntoView({ behavior: 'smooth' });
    }
    //eslint-disable-next-line
  }, [language]);

  return (
    <>
      <BreadcrumbAboutIsn breadCrumb={breadCrumb} />
      <section className="group-company">
        <BannerAbout banner={banner} />
        <div id="position-scroll"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <WhyChooseContent />
            </div>
            <div className="col-12 col-md-3">
              <RightMenu />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchAboutIsnRequest: action.fetchAboutIsnRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WhyChooseComponent);
