import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { useTranslation } from 'react-i18next';
import EventBus from 'eventing-bus';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { formatMoney } from '../../../utils/objects';
import { Location, HeartIcon, BedIcon, SizeIcon, VideoIcon } from '../../../layouts/commons/icons';
import { ICON_LEFT, ICON_RIGHT } from '../../../utils/constants';
import { getPathLanguage } from '../../../utils/params';
import imageDefault from '../../../assets/images/loading-image.jpg';

const BlockResidenceComponent = props => {
  const location = useLocation();
  const imageThumbnails = useRef(null);
  const { t } = useTranslation();
  let pathLanguage = getPathLanguage(location);

  const { post } = props;

  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    dots: true,
    autoplay: false,
    autoHeight: true,
    responsive: {},
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    freeDrag: false,
    loop: true,
  };

  const [likeData, setLikeData] = useState([]);

  useEffect(() => {
    setLikeData(localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : []);
    // eslint-disable-next-line
  }, []);

  const onClickLike = () => {
    const data = localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : [];
    let listData = [];
    if (data.includes(post.id)) listData = data.filter(i => i !== post.id);
    else listData = [...data, post.id];
    setLikeData(listData);
    localStorage.setItem(props.section, listData);
    EventBus.publish('RESET_LIKE', true);
  };

  if (post.name) post.name = post.name.replace('　', ' ');

  return (
    <>
      <div className="block-inner element-carousel">
        <div className="block-image">
          <div className="thumbail-slides thubnail-custom">
            {post.images && post.images.length ? (
              <OwlCarousel ref={imageThumbnails} options={options}>
                {post.images.map((item, index) => (
                  <Link
                    key={index}
                    to={`${pathLanguage}/residence/detail/${post.id}/${post.slug}${
                      props.metaKey === 'last' ? '?last' : props.metaKey === 'hot' ? '?hot' : ''
                    }`}
                    className="block-carousel-residence"
                  >
                    <div style={{ backgroundImage: item ? '' : `url("${imageDefault}")` }} className="thumbnail">
                      {/* <img alt="post 1" src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item}`} /> */}
                      <LazyLoadImage
                        alt="post 1"
                        src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item}`}
                        effect="blur"
                      />
                    </div>
                  </Link>
                ))}
              </OwlCarousel>
            ) : (
              !props.loading && (
                <Link
                  className="carousel-link"
                  to={`${pathLanguage}/residence/detail/${post.id}/${post.slug}${
                    props.metaKey === 'last' ? '?last' : props.metaKey === 'hot' ? '?hot' : ''
                  }`}
                >
                  <div className="thumbnail">
                    <span>
                      {/* <img alt="post 1" className="loading-image" src={imageDefault} /> */}
                      <LazyLoadImage alt="post 1" className="loading-image" src={imageDefault} effect="blur" />
                    </span>
                  </div>
                </Link>
              )
            )}
            <span
              onClick={() => onClickLike()}
              className={`heart circle ${likeData.includes(post.id) ? 'active-like' : ''}`}
            >
              <HeartIcon />
            </span>
            {post.videoLink ? (
              <span className="icon-video">
                <VideoIcon />
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
        <Link
          className="w-100 h-100"
          to={`${pathLanguage}/residence/detail/${post.id}/${post.slug}${
            props.metaKey === 'last' ? '?last' : props.metaKey === 'hot' ? '?hot' : ''
          }`}
        >
          <div className="block-text none-split">
            <h3>{post.name}</h3>
            <div className="property-info">
              <ul>
                <li className="icon-district">
                  <Location />
                  {post.district || '-'}
                </li>
                <li className="icon-bedroom">
                  <BedIcon />
                  {post.bedroommin ? post.bedroommin : t('home_page.studio')}
                  {post.bedroomMax ? ` ~ ${post.bedroomMax}` : ''}
                  <span>&nbsp;{t('home_page.partial.bedrooms')}</span>
                </li>
              </ul>

              <div className="info-residence">
                {post.leasePriceMin || post.leasePriceMax ? (
                  <div className="price">
                    {post.leasePriceMin || post.leasePriceMax ? `$${formatMoney(post.leasePriceMin, 0, 0)}` : ''}
                    {post.leasePriceMax ? ` ~ $${formatMoney(post.leasePriceMax, 0, 0)}` : ''}
                    {post.leasePriceMin || post.leasePriceMax ? <span>/{t('home_page.partial.month')}</span> : ''}
                  </div>
                ) : (
                  ''
                )}

                <div className="size">
                  {post.sqmmin || post.sqmMax ? <SizeIcon /> : ''}
                  {post.sqmmin || post.sqmMax ? formatMoney(post.sqmmin, 0, 0) : ''}
                  {post.sqmMax ? ' ~ ' : ''}
                  {post.sqmMax ? formatMoney(post.sqmMax, 0, 0) : ''}
                  {post.sqmmin || post.sqmMax ? (
                    <>
                      m<sup>2</sup>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlockResidenceComponent;
