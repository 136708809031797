import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './aboutIsn.action';
import * as types from './aboutIsn.types';
import * as API from './aboutIsn.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';
import Service from '../../services';
import { get } from 'lodash';

export function* fetchAboutIsnSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));

    const data = yield call(
      API.getCompanyProfile,
      getQueryString({ language: params.language, categorySlug: params.slug }),
    );

    yield put(actions.fetchAboutIsnSuccess(data));
  } catch (error) {
    yield put(actions.fetchAboutIsnFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getGroupCompanySaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getGroupCompany, getQueryString(params));
    yield put(actions.getGroupCompanySuccess(data));
  } catch (error) {
    yield put(actions.getGroupCompanyFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getHistorySaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getHistory, getQueryString(params));
    yield put(actions.getHistorySuccess(data));
  } catch (error) {
    yield put(actions.getHistoryFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getNewsReleaseSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const news = yield call(API.getNewsRelease, getQueryString(params));
    yield put(actions.getNewsReleaseSuccess({ news }));
  } catch (error) {
    yield put(actions.getNewsReleaseFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getOfficeInformationSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getOfficeInformation, getQueryString(params));

    yield put(actions.getOfficeInformatioSuccess(data));
  } catch (error) {
    yield put(actions.getOfficeInformatioFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* postContactUs(action) {
  const { info, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    yield Service.post(`/api/contacts`, info, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* getNewsContentSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));

    const newsContent = yield call(API.getNewsContent, getQueryString(params));

    yield put(actions.getNewsContentSuccess({ newsContent }));
  } catch (error) {
    yield put(actions.getNewsContentFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchOtherNewsSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));

    const otherNews = yield call(API.fetchOtherNews, getQueryString(params));
    yield put(actions.fetchOtherNewsSuccess({ otherNews }));
  } catch (error) {
    yield put(actions.fetchOtherNewsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* aboutIsnSaga() {
  yield takeEvery(types.FETCH_ABOUT_ISN_REQUEST, fetchAboutIsnSaga);
  yield takeEvery(types.GET_GROUP_COMPANY_REQUEST, getGroupCompanySaga);
  yield takeEvery(types.GET_HISTORY_REQUEST, getHistorySaga);
  yield takeEvery(types.GET_NEWS_RELEASE_REQUEST, getNewsReleaseSaga);
  yield takeEvery(types.GET_OFFICE_INFORMATION_REQUEST, getOfficeInformationSaga);
  yield takeEvery(types.POST_CONTACT_US_REQUEST, postContactUs);
  yield takeEvery(types.GET_NEWS_CONTENT_REQUEST, getNewsContentSaga);
  yield takeEvery(types.FETCH_OTHER_NEWS_REQUEST, fetchOtherNewsSaga);
}
