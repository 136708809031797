import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchListPropertiesRequest } from '../properties.action';
import { Properties } from '../pages';

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => bindActionCreators({ fetchListPropertiesRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
