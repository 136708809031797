import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isMobile } from '../../../../utils/constants';

import * as actions from '../../blogs.action';
import { getLanguage } from '../../../../utils/params';
import clock from '../../../../assets/images/clock.svg';

const LeftContent = props => {
  const { id } = useParams();
  const language = getLanguage(useLocation());

  useEffect(() => {
    if (id) {
      props.getBlogRequest({ language, id });
      window.scrollTo(0, 0);
    }

    // eslint-disable-next-line
  }, [id]);

  const { blog } = props.blogs;

  return (
    <>
      <div className="col-12 col-md-8">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10">
            <h1 className="jp-600-sawarabi">{(blog && blog.title) || ''}</h1>
            <div className="created blog-time jp-bold-300">
              {blog && blog.publicDate && (
                <>
                  <img src={clock} className="blog-clock" alt="" />{' '}
                  <span>
                    {blog.publicDate
                      ? language === 'en'
                        ? moment(new Date(blog.publicDate)).format('MMM DD, YYYY')
                        : moment(new Date(blog.publicDate)).format('YYYY-MM-DD')
                      : ''}
                  </span>
                </>
                //   <>
                //     <LazyLoadImage src={clock} className="blog-clock" alt="" effect="blur" />{' '}
                //     <span>{blog.publicDate || ''}</span>
                //   </>
              )}
            </div>
            <div className="content-blog" dangerouslySetInnerHTML={{ __html: (blog && blog.blogContent) || '' }}></div>
          </div>
        </div>
      </div>
      {isMobile ? <div className="content-blog-mobile"></div> : ''}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlogRequest: actions.getBlogRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LeftContent);
