import * as types from './favorite.types';

export const fetchFavoritesRequest = params => ({ type: types.FETCH_FAVORITES_REQUEST, params });
export const fetchFavoritesSuccess = data => ({ type: types.FETCH_FAVORITES_SUCCESS, data });
export const fetchFavoritesFailure = error => ({ type: types.FETCH_FAVORITES_FAILURE, error });

export const fetchHistoriesRequest = params => ({ type: types.FETCH_HISTORIES_REQUEST, params });
export const fetchHistoriesSuccess = data => ({ type: types.FETCH_HISTORIES_SUCCESS, data });
export const fetchHistoriesFailure = error => ({ type: types.FETCH_HISTORIES_FAILURE, error });

export const postContactFavoriteRequest = params => ({ type: types.POST_CONTACT_FAVORITE_REQUEST, params });

export const postContactHistoryRequest = params => ({ type: types.POST_CONTACT_HISTORY_REQUEST, params });
