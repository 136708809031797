import * as types from './offices.types';

const initialState = {
  lastProperties: [],
  hotProperties: [],
  blogs: [],
  error: null,
};

const officesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_OFFICES_LASTEST_REQUEST:
      return { ...state };
    case types.FETCH_OFFICES_LASTEST_SUCCESS:
      return { ...state, lastProperties: action.data.lastProperties };
    case types.FETCH_OFFICES_LASTEST_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_OFFICES_HOT_REQUEST:
      return { ...state };
    case types.FETCH_OFFICES_HOT_SUCCESS:
      return { ...state, hotProperties: action.data.hotProperties };
    case types.FETCH_OFFICES_HOT_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_OFFICES_BLOGS_REQUEST:
      return { ...state };
    case types.FETCH_OFFICES_BLOGS_SUCCESS:
      return { ...state, blogs: action.data.blogs };
    case types.FETCH_OFFICES_BLOGS_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default officesReducer;
