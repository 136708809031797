import React from 'react';

const BedIcon = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" viewBox="0 0 24 18">
        <g id="XMLID_641_" transform="translate(0 -61.25)">
          <g id="Group_902" data-name="Group 902" transform="translate(0 61.25)">
            <g id="Group_901" data-name="Group 901">
              <path
                id="Path_2467"
                d={`M21.75 69.69v-4.028a4.417 4.417 0 0 0-4.412-4.412H6.662a4.417
                4.417 0 0 0-4.412 4.412v4.028A3.369 3.369 0 0 0 0
                72.864v.773a3.367 3.367 0 0 0 1.875 3.015v2.6h.75v-2.335a3.359
                3.359 0 0 0 .739.083h17.272a3.359 3.359 0 0 0 .739-.083v2.333h.75v-2.6A3.367
                3.367 0 0 0 24 73.636v-.773a3.369 3.369 0 0 0-2.25-3.173zM3 65.662A3.666
                3.666 0 0 1 6.662 62h10.676A3.666 3.666 0 0 1 21 65.662v2.979a3.029
                3.029 0 0 0-1.5-.921V66.6a1.6 1.6 0 0 0-1.6-1.6h-3.553a1.6 1.6
                0 0 0-1.6 1.6v1.027h-1.5V66.6a1.6 1.6 0 0 0-1.6-1.6H6.1a1.6 1.6 0
                0 0-1.6 1.6v1.12a3.03 3.03 0 0 0-1.5.921v-2.979zm15.75 2.712A2.263
                2.263 0 0 1 20.7 69.5H3.3a2.263 2.263 0 0 1 1.95-1.128zm-13.5-.75V66.6a.848.848
                0 0 1 .85-.85h3.553a.848.848 0 0 1 .847.847v1.027zm13.5 0H13.5V66.6a.848.848
                0 0 1 .847-.847H17.9a.848.848 0 0 1 .847.847v1.027zm1.886 8.626H3.364A2.617
                2.617 0 0 1 .75 73.636v-.773a2.617 2.617 0 0 1 2.614-2.613h17.272a2.617
                2.617 0 0 1 2.614 2.614v.773a2.617 2.617 0 0 1-2.614 2.613z`}
                className="cls-1"
                data-name="Path 2467"
                transform="translate(0 -61.25)"
              />
              <path
                id="Rectangle_678"
                d="M0 0H18.75V0.75H0z"
                className="cls-1"
                data-name="Rectangle 678"
                transform="translate(2.625 13.125)"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default BedIcon;
