import * as types from './project.types';

const initialState = {
  project: null,
  properties: [],
  error: null,
  projects: null,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROJECT_REQUEST:
      return { ...state };
    case types.GET_PROJECT_SUCCESS:
      return { ...state, project: action.data.project };
    case types.GET_PROJECT_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_PROPERTIES_IN_PROJECT_REQUEST:
      return { ...state };
    case types.FETCH_PROPERTIES_IN_PROJECT_SUCCESS:
      return { ...state, properties: action.data.properties };
    case types.FETCH_PROPERTIES_IN_PROJECT_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_LIST_PROJECTS_REQUEST:
      return { ...state };
    case types.FETCH_LIST_PROJECTS_SUCCESS:
      return { ...state, projects: action.data.projects };
    case types.FETCH_LIST_PROJECTS_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default projectsReducer;
