import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
// import EventBus from 'eventing-bus';
import { get } from 'lodash';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReCAPTCHA from 'react-google-recaptcha';

import IconContact from '../../../../assets/images/Group2676.svg';
import IconMap from '../../../../assets/images/map-pin.svg';
import IconMail from '../../../../assets/images/mail.svg';
import IconPhone from '../../../../assets/images/phone.svg';
import { getLanguage, getPathLanguage } from '../../../../utils/params';
import { InputDate } from '../../../../components';
import * as action from '../../aboutIsn.action';
import { history } from '../../../../utils/history';

const ContactUsContent = props => {
  const { handleSubmit, register, errors, reset } = useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const [isShowSubmit, setIsShowSubmit] = useState(false);

  const { office } = props.aboutIsn;
  const [locationId, setLocationId] = useState('');
  const [date, setDate] = useState({
    dateFrom: moment(),
    dateTo: moment(),
  });

  useEffect(() => {
    const propsId = get(props, 'layout.cityActive.defaultLocationId', null);
    if (propsId) setLocationId(propsId);

    return () => reset();
    // eslint-disable-next-line
  }, [props.layout.cityActive]);

  const onSubmit = values => {
    if (values) {
      const info = {};
      info.language = language;
      info.cityId = locationId;
      info.expectedCheckInDate = date.dateFrom ? date.dateFrom : moment();
      info.expectedTourInspectionDate = date.dateTo ? date.dateTo : moment();
      info.fullName = values.fullName;
      info.email = values.email;
      info.phone = values.phone;
      if (values.companyName) info.companyName = values.companyName;
      if (values.otherRequirements) info.otherRequirements = values.otherRequirements;

      props.postContactUsRequest({ info, onSuccess, onError });
    }
  };

  const onSuccess = () => {
    // toast.success(t('about_isn.contact_us_page.message_success'));
    reset();
    history.push(`${pathLanguage}/notification/contact-us`);
  };
  const onError = err => toast.error(err);

  const onChangeDatePicker = (key, value) => {
    if (key === 'dateFrom') setDate({ ...date, ...{ dateFrom: value } });
    else if (key === 'dateTo') setDate({ ...date, ...{ dateTo: value } });
  };

  //   EventBus.on('change_location', data => {
  //     if (data) setLocationId(data);
  //   });

  const checkCaptcha = value => {
    if (value) setIsShowSubmit(true);
  };

  return (
    <>
      <div>
        <div className="contact-form">
          <div className="contact-form-head">
            <div className="row">
              <div className="col-7 col-md-9">
                <div
                  className="contact-form-title jp-bold-600"
                  dangerouslySetInnerHTML={{
                    __html: t('residence_detail.contact_form.title_contact'),
                  }}
                ></div>
              </div>
              <div className="col-5 col-md-3 text-right">
                <img src={IconContact} alt="" />
                {/* <LazyLoadImage src={IconContact} alt="" effect="blur" /> */}
              </div>
              <div className="col-12 jp-font-sawarabi-gothic">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t('residence_detail.contact_form.content_thank_you'),
                  }}
                ></div>
                <div
                  className="contact-content"
                  dangerouslySetInnerHTML={{
                    __html: t('residence_detail.contact_form.our_person'),
                  }}
                ></div>
                <hr className="contact-form-line" />
              </div>
            </div>
          </div>
          <form id="contact" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>
                {t('residence_detail.contact_form.full_name')} <span className="link-red-bold ">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="fullName"
                ref={register({ required: t('residence_detail.contact_form.err_required') })}
              />
              <small className="error">
                {errors.fullName && errors.fullName.message ? errors.fullName.message : ''}
              </small>
            </div>

            <div className="form-group">
              <label>
                {t('residence_detail.contact_form.email')} <span className="link-red-bold ">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="email"
                ref={register({
                  required: t('residence_detail.contact_form.err_required'),
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('residence_detail.contact_form.err_email'),
                  },
                })}
              />
              <small className="error">{errors.email && errors.email.message ? errors.email.message : ''}</small>
            </div>

            <div className="form-group">
              <label>
                {t('residence_detail.contact_form.phone_number')} <span className="link-red-bold ">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="phone"
                ref={register({
                  required: t('residence_detail.contact_form.err_required'),
                  // pattern: {
                  //   value: /^[0-9]+$/,
                  //   message: t('residence_detail.contact_form.err_phone'),
                  // },
                })}
              />
              <small className="error">{errors.phone && errors.phone.message ? errors.phone.message : ''}</small>
            </div>

            <div className="form-group">
              <label>{t('residence_detail.contact_form.company_name')}</label>
              <input type="text" className="form-control" name="companyName" ref={register()} />
            </div>

            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <InputDate
                  class_name="form-control datetime"
                  formName="dateFrom"
                  name="dateA"
                  label={t('residence_detail.contact_form.check_in_date')}
                  onChange={onChangeDatePicker}
                />
              </div>
              <div className="col-12 col-md-6 ">
                <InputDate
                  class_name="form-control datetime"
                  formName="dateTo"
                  name="dateB"
                  label={t('residence_detail.contact_form.inspection_date')}
                  onChange={onChangeDatePicker}
                />
              </div>
            </div>

            <div className="form-group">
              <label>{t('residence_detail.contact_form.other_requirements')}</label>
              <textarea className="form-control area-requirement" name="otherRequirements" ref={register()}></textarea>
            </div>

            <div className="row">
              <div className="col-6 pb-2">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                  onChange={checkCaptcha}
                  hl={`${language === 'en' ? 'en' : 'ja'}`}
                />
              </div>
              <div className="col-12 col-md-6 button-handle">
                <button
                  type="submit"
                  id="send"
                  className="button button-red uppercase modal-send-button"
                  disabled={!isShowSubmit}
                >
                  {t('residence_detail.contact_form.send')}
                </button>
                <button
                  id="cancel"
                  onClick={() => history.push(pathLanguage)}
                  className="button button-red button-white uppercase modal-send-button"
                >
                  {t('residence_detail.contact_form.cancel')}
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="contact-list-offices">
          <div className="row">
            {office && office.length
              ? office.map((item, index) => {
                  item.phone = item.phone.replace(/\n/gi, '<br />');
                  return (
                    <div className="col-12 col-md-6" key={index}>
                      <div className="contact-information jp-font-sawarabi-gothic">
                        {/* <div className="contact-office-number">
                          {(index + 1).toString() && (index + 1).toString().length < 2 ? `0${index + 1}` : index + 1}
                        </div> */}
                        <div className="contact-office-name jp-600-sawarabi">{item.name}</div>
                        <hr className="contact-form-line" />
                        <div className="contact-address">
                          <div className="row">
                            <div className="col-1">
                              <img className="contact-icon" src={IconMap} alt="" />
                              {/* <LazyLoadImage className="contact-icon" src={IconMap} alt="" effect="blur" /> */}
                            </div>
                            <div className="col-11 address-information">{item.address}</div>
                          </div>
                        </div>
                        <div className="contact-mail">
                          <div className="row">
                            <div className="col-1">
                              <img className="contact-icon" src={IconMail} alt="" />
                              {/* <LazyLoadImage className="contact-icon" src={IconMail} alt="" effect="blur" /> */}
                            </div>
                            <div className="col-11">{item.email}</div>
                          </div>
                        </div>
                        <div className="contact-phone">
                          <div className="row">
                            <div className="col-1">
                              <img className="contact-icon" src={IconPhone} alt="" />
                              {/* <LazyLoadImage className="contact-icon" src={IconPhone} alt="" effect="blur" /> */}
                            </div>
                            <div className="col-11">
                              <div
                                className="phone-number"
                                dangerouslySetInnerHTML={{
                                  __html: item.phone || '',
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="contact-overlay w-100 h-100"></div>
                        <img
                          className="w-100 h-100 contact-block-hover"
                          src={require(`../../../../assets/images/contact-image${index}.jpg`)}
                          alt=""
                        />
                        {/* <LazyLoadImage
                          className="w-100 h-100 contact-block-hover"
                          src={require(`../../../../assets/images/contact-image${index}.jpg`)}
                          alt=""
                          effect="blur"
                        /> */}
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postContactUsRequest: action.postContactUsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsContent);
