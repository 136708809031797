import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ProjectDetailComponent, ListingProjectsComponent, Projects } from '../pages';
import { getProjectRequest, fetchPropertiesInProjectRequest, fetchListProjectsRequest } from '../project.action';
import { setLoading } from '../../../layouts/loading/loading.action';

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getProjectRequest, fetchPropertiesInProjectRequest, fetchListProjectsRequest, setLoading },
    dispatch,
  );

export default {
  ProjectDetail: connect(mapStateToProps, mapDispatchToProps)(ProjectDetailComponent),
  ListingProjects: connect(mapStateToProps, mapDispatchToProps)(ListingProjectsComponent),
  Projects: connect(mapStateToProps, mapDispatchToProps)(Projects),
};
