import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ClockIcon } from '../../../layouts/commons/icons';
import { getLanguage, getPathLanguage } from '../../../utils/params';
import markNew from '../../../assets/images/New.svg';
import markNewJP from '../../../assets/images/New_JP.svg';

const BlockBlogComponent = props => {
  const language = getLanguage(useLocation());
  const pathLanguage = getPathLanguage(useLocation());
  const { post } = props;

  if (post.blogTitle) post.blogTitle = post.blogTitle.replace('　', ' ');

  return (
    <>
      <div className="block-inner">
        <div className="block-image">
          <Link
            className="block-image-link block-handle-click"
            to={`${pathLanguage}/blog/detail/${post.id}/${post.slug}` || ''}
          >
            {post.isNew ? (
              language === 'jp' ? (
                <img src={markNewJP} alt="" className="mark-new" />
              ) : (
                <img src={markNew} alt="" className="mark-new" />
              )
            ) : (
              ''
            )}
            <LazyLoadImage alt="" src={post.blogThumnail} className="img-blog" effect="blur" />
          </Link>
        </div>
        <Link className="w-100 h-100" to={`${pathLanguage}/blog/detail/${post.id}/${post.slug}` || ''}>
          <div className="block-text blog-description">
            <h3>{post.blogTitle || 'No title'}</h3>
            {!!post.date && (
              <div className="created">
                <ClockIcon />
                <span>{post.date}</span>
              </div>
            )}
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlockBlogComponent;
