const GET_OFFICE_DETAIL_REQUEST = 'GET_OFFICE_DETAIL_REQUEST';
const GET_OFFICE_DETAIL_SUCCESS = 'GET_OFFICE_DETAIL_SUCCESS';
const GET_OFFICE_DETAIL_FAILURE = 'GET_OFFICE_DETAIL_FAILURE';

const GET_SIMILAR_LIST_REQUEST = 'GET_SIMILAR_LIST_REQUEST';
const GET_SIMILAR_LIST_SUCCESS = 'GET_SIMILAR_LIST_SUCCESS';
const GET_SIMILAR_LIST_FAILURE = 'GET_SIMILAR_LIST_FAILURE';

export {
  GET_OFFICE_DETAIL_REQUEST,
  GET_OFFICE_DETAIL_SUCCESS,
  GET_OFFICE_DETAIL_FAILURE,
  GET_SIMILAR_LIST_REQUEST,
  GET_SIMILAR_LIST_SUCCESS,
  GET_SIMILAR_LIST_FAILURE,
};
