import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import loadingReducer from '../layouts/loading/loading.reducer';

import { homeReducer } from '../modules/homePage';
import { officesReducer } from '../modules/officesPage';
import { layoutReducer } from '../layouts';
import { residenceReducer } from '../modules/residencePage';
import { blogsReducer } from '../modules/blogsPage';
import { officeDetailReducer } from '../modules/officeDetailPage';
import { forOwnersReducer } from '../modules/forOwnersPage';
import { projectsReducer } from '../modules/projectsPage';
import { favoritesReducer } from '../modules/favoriteHistoryPage';
import { aboutIsnReducer } from '../modules/aboutIsnPage';
import { usefulInformationReducer } from '../modules/usefulPage';
import { specialReducer } from '../modules/specialPage';
import { propertiesReducer } from '../modules/propertiesPage';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),

    home: homeReducer,
    offices: officesReducer,
    residence: residenceReducer,
    layout: layoutReducer,
    blogs: blogsReducer,
    loading: loadingReducer,
    officeDetail: officeDetailReducer,
    forOwners: forOwnersReducer,
    projects: projectsReducer,
    favorites: favoritesReducer,
    aboutIsn: aboutIsnReducer,
    usefulInfo: usefulInformationReducer,
    special: specialReducer,
    properties: propertiesReducer,
  });

export default rootReducer;
