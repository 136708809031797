import React from 'react';
import Properties from './details/Properties.component';
import { useTranslation } from 'react-i18next';

const SimilarPropertiesComponent = props => {
  const { t } = useTranslation();

  return (
    <div>
      <Properties headerTitle={t('residence_detail.similar_properties')} data={props.data} />
    </div>
  );
};

export default SimilarPropertiesComponent;
