// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import Service from '../../services';

export const fetchListInfomation = async params => {
  const response = await Service.get(`/api/filters${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const postInformation = async params => {
  const response = await Service.post(`/api/owners`, params);
  const data = get(response, 'data', null);
  return data;
};

export const getFaqsOwners = async params => {
  const response = await Service.get(`/api/generalinformations/get-by-slug-category${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchCities = async params => {
  const response = await Service.get(`/api/cities${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};
