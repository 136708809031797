import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getLanguage, getPathLanguage } from '../../../../utils/params';
import { ResetIcon, SearchIcon, CloseIcon } from '../../icons';
import { formatMoney } from '../../../../utils/objects';
import { Input, Select, Checkbox, RangeComponent } from '../../../../components';
import * as actions from '../../../redux/layout.action';
import { getQueryString } from '../../../../utils/params';

import { get } from 'lodash';

const SearchOfficesForm = props => {
  const { t } = useTranslation();
  const language = getLanguage(useLocation());
  const pathLanguage = getPathLanguage(useLocation());

  const [showTotalBudget, setShowTotalBudget] = useState('price');
  const [districts, setDistricts] = useState([]);
  const [cityId, setCityId] = useState('');

  const [unitPrice, setRangeSliderUnitPirce] = useState([0, 100]);
  const [totalBudget, setRangeSlideTotalBudget] = useState([0, 30000]);
  const [officeSize, setRangeSize] = useState([0, 3000]);

  const [isShowMore, setIsShowMore] = useState({ key: '', value: false });

  const [isChange, setIsChange] = useState(false);

  const { handleSubmit, register, errors, watch } = useForm();

  const keyword = watch('office_keyword', '');

  const options = [
    { label: t('home_page.search.unit_price'), value: 'price' },
    { label: t('home_page.search.total_budget'), value: 'budget' },
  ];

  const handleChangeUnitBudget = value => {
    setShowTotalBudget(value);
    setIsChange(true);
  };

  const nonAccentVietnamese =(str)=> {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  }
  const handleClickOutside = e => {
    if (isShowMore.value) {
      if (e.target.className && !e.target.className.toString().includes('flag-dropdown')) {
        setIsShowMore({ key: '', value: false });
      }
    }
  };

  const cityLocale = get(props, 'layout.cityActive.defaultLocationId', null) || localStorage.getItem('LOCATION_CITY');

  useEffect(() => {
    if (cityLocale) {
      setCityId(cityLocale);
      props.fetchDistrictsByCityIdRequest({ cityId: cityLocale, language });
    }

    if (!props.layout.range) props.fetchRange();

    const params = {
      language,
      propertyType: 2,
      cityId: cityLocale,
      officePriceSearchType: 1,
      leasePriceMin: 0,
      leasePriceMax: 100,
      officeSizeFrom: 0,
      officeSizeTo: 3000,
    };
    props.getUnitsRequest(params);

    // eslint-disable-next-line
  }, [cityLocale]);

  useEffect(() => {
    if (!isChange) return;
    let handlerUnit = setTimeout(() => {
      const params = { language, propertyType: 2 };

      if (cityId) params.cityId = cityId;
      if (districts.length) params.districtId = districts.toString();
      if (keyword) params.keyword = keyword;

      if (showTotalBudget === 'price') {
        params.officePriceSearchType = 1;
        if (unitPrice.length && unitPrice[0]) params.leasePriceMin = unitPrice[0];
        else params.leasePriceMin = unitPrice[0] || 0;
        if (unitPrice.length && unitPrice[1]) params.leasePriceMax = unitPrice[1];
        else params.leasePriceMax = 100;
      } else if (showTotalBudget === 'budget') {
        params.officePriceSearchType = 2;
        if (totalBudget.length && totalBudget[0]) params.leasePriceMin = totalBudget[0];
        else params.leasePriceMin = totalBudget[0] || 0;
        if (totalBudget.length && totalBudget[1]) params.leasePriceMax = totalBudget[1];
        else params.leasePriceMax = 30000;
      }

      if (officeSize.length && officeSize[0]) params.officeSizeFrom = officeSize[0];
      else params.officeSizeFrom = officeSize[0] || 0;
      if (officeSize.length && officeSize[1]) params.officeSizeTo = officeSize[1];
      else params.officeSizeTo = 3000;

      props.getUnitsRequest(params);
    }, 1000);

    // clearTimeout(handlerUnit);
    return () => {
      clearTimeout(handlerUnit);
    };

    // eslint-disable-next-line
  }, [unitPrice, totalBudget, officeSize, isChange, districts, keyword, showTotalBudget, cityId]);

  useEffect(() => {
    if (isShowMore.value) {
      document.addEventListener('click', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

    // eslint-disable-next-line
  }, [isShowMore]);

  const onChangeFormData = (key, value) => {
    let cloneFormData = [...districts];
    if (districts.includes(value)) {
      cloneFormData = districts.filter(item => item !== value);
    } else {
      cloneFormData.push(value);
    }
    setIsChange(true);
    setDistricts(cloneFormData);
  };

  const handleOnChange = value => {
    if (value)
      setTimeout(() => {
        props.fetchDistrictsByCityIdRequest({ cityId: value, language });
        setCityId(value);
        setDistricts([]);
      }, 500);
    // else setTimeout(() => props.fetchDistrictsByCityIdRequest({ language }), 1);
  };

  const keyWord = useMemo(() => {
    if (keyword) setIsChange(true);
  }, [keyword]);

  const getObjectParams = values => {
    const params = {};
    params.language = language;
    params.propertyType = 2;
    if (values.office_keyword) params.keyword = nonAccentVietnamese(keyword).trim();
    if (values.districts && values.districts.length) params.districtId = values.districts.toString();

    if (showTotalBudget === 'price') {
      params.officePriceSearchType = 1;
      if (unitPrice.length && unitPrice[0]) params.leasePriceMin = unitPrice[0];
      else params.leasePriceMin = 0;
      if (unitPrice.length && unitPrice[1]) params.leasePriceMax = unitPrice[1];
    } else if (showTotalBudget === 'budget') {
      params.officePriceSearchType = 2;
      if (totalBudget.length && totalBudget[0]) params.leasePriceMin = totalBudget[0];
      else params.leasePriceMin = 0;
      if (totalBudget.length && totalBudget[1]) params.leasePriceMax = totalBudget[1];
    }

    if (officeSize.length && officeSize[0]) params.officeSizeFrom = officeSize[0];
    else params.officeSizeFrom = 0;
    if (officeSize.length && officeSize[1]) params.officeSizeTo = officeSize[1];

    params.pageIndex = 1;
    params.pageSize = 16;

    return params;
  };

  const onSubmit = values => {
    let params = {};
    if (values && Object.keys(values).length) {
      params = getObjectParams(values);
    }

    params.cityId = cityId;
    params.sort = '-leasePriceMin';
    const query = getQueryString(params);
    // history.push(`${pathLanguage}/offices/search${query}`);
    window.location.href = `${pathLanguage}/offices/search${query}`;
  };

  const resetForm = () => {
    setDistricts([]);
    setRangeSize([0, 3000]);
    setRangeSlideTotalBudget([0, 30000]);
    setRangeSliderUnitPirce([0, 100]);
    setShowTotalBudget('price');
    document.getElementsByName('office_keyword')[0].value = '';

    // if (cityLocale && cityId !== cityLocale) {
    //   setCityId(cityLocale);
    //   props.fetchDistrictsByCityIdRequest({ cityId: cityLocale, language });
    // }

    // reset();
  };

  const {
    officeBudgetMax,
    officeBudgetMin,
    officePriceMax,
    officePriceMin,
    officeSizeMax,
    officeSizeMin,
    stepOfficeBudget,
    stepOfficePrice,
    stepOfficeSize,
  } = props.layout.range ? props.layout.range : {};

  const listCities = get(props, 'layout.cities', []);
  const listDistricts = get(props, 'layout.districts', []);

  const listCitiesDropdown = useMemo(() => {
    if (listCities && listCities.length) {
      return (
        <Select
          defaultValue={cityId}
          options={listCities.map(item => ({ value: item.cityId, label: item.cityName }))}
          class_name="form-control"
          name="location"
          onChange={handleOnChange}
        />
      );
    }
    return (
      <Select
        disabled
        options={[]}
        isAll={{ value: '', label: t('home_page.search.city') }}
        class_name="form-control"
      />
    );

    // eslint-disable-next-line
  }, [listCities, cityId]);

  const listOptionsCheck = useMemo(() => {
    if (options && options.length) {
      return options.map((item, index) => (
        <div className={`radio-item form-item`} key={index}>
          <label htmlFor={`chk-${index}`}>
            <input
              type="radio"
              id={`chk-${index}`}
              name="unit_budget"
              value={item.value}
              checked={item.value === showTotalBudget}
              onChange={event => handleChangeUnitBudget(event.target.value)}
            />
            <span htmlFor={`chk-${index}`}>{item.label}</span>
          </label>
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [options]);

  const listDistrictsDropdown = useMemo(() => {
    if (listDistricts && listDistricts.length) {
      return listDistricts.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            register={register({})}
            name={'districts'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.districtName}
            value={item.id}
            defaultValue={districts}
            checkItemClass="form-item"
            multiple={true}
            formName="districts"
            onChangeFormData={onChangeFormData}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [listDistricts, districts]);

  const renderPrice = () => {
    return showTotalBudget === 'price' ? (
      <div className={`row conditions ${showTotalBudget === 'price' ? '' : 'd-none'}`}>
        <div className="col-12 mb-3">
          <div className="slide-bar slide-bar-office">
            <div className="slide-prices">
              <div className="slide-container">
                <RangeComponent
                  maxValue={parseInt(officePriceMax) || 100}
                  minValue={parseInt(officePriceMin) || 0}
                  value={unitPrice}
                  step={stepOfficePrice}
                  onChangeUnit={values => {
                    if (values[0] < values[1]) {
                      setRangeSliderUnitPirce(values);
                      setIsChange(true);
                    }
                  }}
                />
              </div>

              <div className="slide-price-numner">
                <span className="min">${officePriceMin && formatMoney(officePriceMin, 0, 0)}</span>
                <span className="max float-right">
                  ${officePriceMax && formatMoney(officePriceMax, 0, 0)}
                  {unitPrice.length && unitPrice[1] === parseInt(officePriceMax) ? '~' : ''}
                </span>
              </div>
            </div>
            <div className="m2-month d-none d-md-block">
              ($/m<sup>2</sup>/{t('home_page.partial.month')})
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={`row conditions ${showTotalBudget === 'budget' ? '' : 'd-none'}`}>
        <div className="col-12 mb-3">
          <div className="slide-bar slide-bar-office">
            <div className="slide-prices">
              <div className="slide-container">
                <RangeComponent
                  maxValue={parseInt(officeBudgetMax) || 30000}
                  minValue={parseInt(officeBudgetMin) || 0}
                  value={totalBudget}
                  step={stepOfficeBudget}
                  onChangeUnit={values => {
                    if (values[0] < values[1]) {
                      setRangeSlideTotalBudget(values);
                      setIsChange(true);
                    }
                  }}
                />
              </div>

              <div className="slide-price-numner">
                <span className="min">${officeBudgetMin && formatMoney(officeBudgetMin, 0, 0)}</span>
                <span className="max float-right">
                  ${officeBudgetMax && formatMoney(officeBudgetMax, 0, 0)}
                  {totalBudget.length && totalBudget[1] === parseInt(officeBudgetMax) ? '~' : ''}
                </span>
              </div>
            </div>
            <div className="m2-month d-none d-md-block">($/{t('home_page.partial.month')})</div>
          </div>
        </div>
      </div>
    );
  };

  const textDistrict = useMemo(() => {
    if (districts.length === 1) {
      const detail = listDistricts.find(i => i.id === districts[0]);
      return detail && detail.districtName ? detail.districtName : t('home_page.search.district');
    } else if (districts.length > 1) {
      return t('home_page.search.selected');
    }

    return t('home_page.search.district');
  }, [districts, listDistricts, t]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="group-control">
        <div className="row">
          <div className="col-12 col-md-5 search-name">
            <div className="row">
              <div className="col-9 col-md-12 mb-3">
                <Input
                  class_name="form-control"
                  type="text"
                  placeholder={t('home_page.search.hint_popup_office')}
                  errors={errors.office_keyword}
                  register={register()}
                  name="office_keyword"
                  id="mySearch"
                />
                {keyWord}
              </div>
              <div className="col-3 col-md-6 mb-2 d-block d-md-none">
                <div className="units text-right">
                  <div>{props.layout && props.layout.units ? props.layout.units : 0}</div>
                  {/* <div className="units-text">{t('home_page.search.units')}</div> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mb-3">{listCitiesDropdown}</div>
              <div className="col-6 mb-3">
                <div
                  className={`form-control select-control${
                    textDistrict !== t('home_page.search.district') ? ' search-selected' : ''
                  }`}
                  id="facilities"
                  onClick={() => {
                    setIsShowMore({ key: 'showMoreDistrict', value: true });
                  }}
                >
                  {textDistrict}
                </div>
              </div>
              <div
                className={`facilities office-facilities fade-in flag-dropdown ${
                  (isShowMore.key === 'showMoreDistrict' && isShowMore.value ? 'show ' : '') || ''
                }`}
                id="fcDropDown"
              >
                <div className="row flag-dropdown">
                  <div className="col-10 flag-dropdown">
                    <span className="link-red uppercase flag-dropdown">{t('home_page.search.district')}</span>
                  </div>
                  <div className="col-2 text-right flag-dropdown">
                    <span
                      className="close-more flag-dropdown"
                      onClick={() => {
                        setIsShowMore({ key: 'showMoreDistrict', value: false });
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </div>
                <div className="row building flag-dropdown">{listDistrictsDropdown}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="row align-items-center" id="officePriceBugget">
                  {/* <div className="col-3 col-md-6 mb-2">
                    <div className="units d-block d-md-none">
                      <div>{props.layout && props.layout.units ? props.layout.units : 0}</div>
                      <div className="units-text">{t('home_page.search.units')}</div>
                    </div>
                  </div> */}
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6 col-md-3 mb-2 btn-submit d-block d-md-none">
                        <button type="submit" className="button button-red button-search-form button-hover">
                          <SearchIcon /> {t('home_page.search.text_btn_search')}
                        </button>
                      </div>
                      <div className="col-5 col-md-3 mb-2 d-block d-md-none">
                        <button
                          onClick={() => resetForm()}
                          type="button"
                          className="button button-no-border button-grayish
                          button-search-form button-transparent button-hover"
                        >
                          <ResetIcon /> {t('home_page.search.reset_criteria')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 search-range">
            <div className="row">
              <div className="col-12 mb-3 offices-size">
                <div className="slide-bar slide-bar-square">
                  <div className="slide-prices">
                    <div className="slide-container area-icon">
                      <RangeComponent
                        maxValue={parseInt(officeSizeMax) || 3000}
                        minValue={parseInt(officeSizeMin) || 0}
                        value={officeSize}
                        step={stepOfficeSize}
                        onChangeUnit={values => {
                          if (values[0] < values[1]) {
                            setRangeSize(values);
                            setIsChange(true);
                          }
                        }}
                        isPrice={true}
                      />
                    </div>

                    {/* <div className="slide-price-numner">
                      <span className="min">
                        {officeSizeMin && formatMoney(officeSizeMin, 0, 0)}m<sup>2</sup>
                      </span>
                      <span className="max float-right">
                        {officeSizeMax && formatMoney(officeSizeMax, 0, 0)}m<sup>2</sup>
                      </span>
                    </div> */}
                    <div className="mt-4"></div>
                  </div>
                  <div className="units d-none text-md-center d-md-block">
                    <span>{props.layout && props.layout.units ? props.layout.units : 0}</span>
                    <br />
                    {t('home_page.search.units')}
                  </div>
                </div>
              </div>
            </div>

            {renderPrice()}

            <div className="row align-items-center" id="officePriceBugget">
              <div className="col-12 col-md-12 col-lg-8 mb-2 d-none d-md-block p-0">
                <div className="btn-search-home">
                  <div className="btn-submit-home btn-submit-office">
                    <button type="submit" className="button button-search-form button-red button-hover">
                      <SearchIcon /> {t('home_page.search.text_btn_search')}
                    </button>
                  </div>
                  <div className="btn-reset-home btn-reset-office">
                    <button
                      onClick={() => resetForm()}
                      type="button"
                      className="button button-no-border  button-search-form button-transparent
                          button-hover button-grayish"
                    >
                      <ResetIcon /> {t('home_page.search.reset_criteria')}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-4 mb-2 p-0">
                <div className="unit-price-total-budget unit-price-total-budget-home">{listOptionsCheck}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDistrictsByCityIdRequest: actions.fetchDistrictsByCityIdRequest,
      getUnitsRequest: actions.getUnitsRequest,
      fetchRange: actions.fetchRangeRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchOfficesForm);
