import React from 'react';
import SearchOfficesForm from '../office/searchOfficesForm';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { getPathLanguage } from '../../../../utils/params';

const SearchOffices = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const languagePath = getPathLanguage(location);

  return (
    <>
      <div className="container search-wrapper">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-10">
            <div className="search-form jp-font-sawarabi-gothic">
              <ul className="nav nav-tabs" id="searchForm" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link "
                    id="Residence-tab"
                    data-toggle="tab"
                    href={`${languagePath}/residences`}
                    role="tab"
                    aria-controls="Residence"
                    aria-selected="true"
                  >
                    {t('home_page.search.residence')}
                  </a>
                </li>
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="Office-tab"
                    data-toggle="tab"
                    href={`${languagePath}/offices`}
                    role="tab"
                    aria-controls="Office"
                    aria-selected="false"
                  >
                    {t('home_page.search.office')}
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="searchFormContent">
                <div className="tab-pane fade" id="Residence" role="tabpanel" aria-labelledby="Residence-tab"></div>
                <div className="tab-pane fade show active" id="Office" role="tabpanel" aria-labelledby="Office-tab">
                  <SearchOfficesForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchOffices;
