import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { useTranslation } from 'react-i18next';

import BlockResidenceComponent from '../../commons/block/BlockResidence.component';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../utils/constants';
import { getPathLanguage } from '../../../utils/params';

const Properties = props => {
  let listItems = props.data && props.data.items && props.data.items.length ? props.data.items : null;
  const pathLanguage = getPathLanguage(useLocation());
  const latestProperties = useRef('');
  const { t } = useTranslation();
  const options = {
    items: 3,
    margin: 30,
    nav: true,
    dots: false,
    loop: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {
      0: { items: 1, nav: true },
      600: { items: 3, nav: true },
      1000: { items: 3, nav: true },
    },
  };

  return (
    <div className={(props.class || '') + ' container block-home-properties'}>
      <div className="row">
        <div className="col-12 properties-title">
          <h2 className="block-title">
            <Link
              to={`${pathLanguage}/list-properties/${props.metaKey === 'last' ? 'latest' : 'hot'}/residence`}
              className="jp-bold-700"
            >
              {props.headerTitle || ''}
            </Link>
          </h2>
          <span className="show-all-properties">
            <Link
              to={`${pathLanguage}/list-properties/${props.metaKey === 'last' ? 'latest' : 'hot'}/residence`}
              className="jp-bold-700"
            >
              {t('home_page.all')}
            </Link>
          </span>
        </div>
        <div className="col-12 d-none d-md-block">
          {listItems && listItems.length ? (
            <OwlCarousel
              ref={latestProperties}
              options={options}
              style={{ maxHeight: 420 }}
              className="nav-top owl-carousel owl-theme owl-loaded owl-drag owl-home-properties"
            >
              {listItems.map((element, index) => (
                <div key={index} className="col-item">
                  <BlockResidenceComponent metaKey={props.metaKey} section={props.section} post={element} />
                </div>
              ))}
            </OwlCarousel>
          ) : (
            ''
          )}
        </div>
        <div className="col-12 properties d-block d-md-none">
          {listItems && listItems.length ? (
            <>
              {listItems.slice(0, 3).map((element, index) => (
                <div key={index} className="col-item item-property">
                  <BlockResidenceComponent metaKey={props.metaKey} section={props.section} post={element} />
                </div>
              ))}
              {listItems.length > 3 ? (
                <div className="show-more">
                  <Link
                    to={`${pathLanguage}/list-properties/${props.metaKey === 'last' ? 'latest' : 'hot'}/residence`}
                    className="btn-show-more"
                  >
                    {t('home_page.show_more')}
                  </Link>
                </div>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Properties;
