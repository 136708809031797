import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { getPathLanguage } from '../../../../utils/params';

const RightMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [isShowCollapse, setIsShowCollapse] = useState({ key: '', value: false });
  const pathLanguage = getPathLanguage(location);

  // eslint-disable-next-line max-len
  const companyOverviewList = ['company-profile', 'company-philosophy', 'our-company-feature', 'mess-from-rep'];
  const isnGroupList = ['group-companies', 'history', 'our-main-services', 'why-choose-us'];
  const newRelaesePath = 'news-release';
  const contactUsPath = 'contact-us';

  const arrayPath = location.pathname.split('/');
  const pathName = arrayPath.length === 4 ? arrayPath[3] : arrayPath[2];

  const menuOverview = [
    { title: t('about_isn.company_overview_page.company_profile'), url: '/about-isn/company-profile' },
    { title: t('about_isn.company_overview_page.company_philosophy'), url: '/about-isn/company-philosophy' },
    { title: t('about_isn.company_overview_page.our_company_feature'), url: '/about-isn/our-company-feature' },
    // { title: t('about_isn.company_overview_page.meet_our_team'), url: '/about-isn/meet-our-team' },
    { title: t('about_isn.company_overview_page.mess_from_rep'), url: '/about-isn/mess-from-rep' },
  ];

  const menuGroup = [
    { title: t('about_isn.isn_group_page.group_companies'), url: '/about-isn/group-companies' },
    { title: t('about_isn.isn_group_page.history'), url: '/about-isn/history' },
    { title: t('about_isn.isn_group_page.our_main_services'), url: '/about-isn/our-main-services' },
    { title: t('about_isn.isn_group_page.why_choose_us'), url: '/about-isn/why-choose-us' },
  ];

  useEffect(() => {
    if (companyOverviewList.includes(pathName)) {
      setIsShowCollapse({ key: 'CompanyOverview', value: true });
    } else if (isnGroupList.includes(pathName)) {
      setIsShowCollapse({ key: 'IsnGroup', value: true });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="widget widget-mobile">
      <div
        className={`navbar-toggler toggle-btn click-collapse profile-widget company-overview w-100
        ${companyOverviewList.includes(pathName) ? 'click-active' : ''}
        ${isShowCollapse.key === 'CompanyOverview' && isShowCollapse.value ? 'turn-icon' : 'collapsed'} `}
        data-toggle="collapse"
        data-target="#menu-company-overview"
        id="toggle-overview"
        aria-expanded={isShowCollapse.key === 'CompanyOverview' && isShowCollapse.value}
        onClick={() =>
          setIsShowCollapse({
            key: 'CompanyOverview',
            value: !(isShowCollapse.key === 'CompanyOverview' && isShowCollapse.value),
          })
        }
      >
        <span>{t('about_isn.about_isn')} </span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.207" viewBox="0 0 13.414 7.207">
          <path
            id="chevron-down"
            fill="none"
            stroke="#131313"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 9l6 6 6-6"
            transform="translate(-5.293 -8.293)"
          />
        </svg>
      </div>
      <div
        className={`collapse in profile-widget-menu
        ${'CompanyOverview' === isShowCollapse.key && isShowCollapse.value ? 'show' : ''}`}
        id="menu-company-overview"
      >
        {menuOverview &&
          menuOverview.length &&
          menuOverview.map((item, index) => (
            <div key={index}>
              <Link
                to={`${pathLanguage}${item.url}`}
                className={pathName.includes(companyOverviewList[index]) ? 'click-active' : ''}
              >
                {item.title}
              </Link>
            </div>
          ))}
      </div>

      <div
        className={`navbar-toggler toggle-btn click-collapse profile-widget company-overview w-100
        ${isnGroupList.includes(pathName) ? 'click-active' : ''}
        ${isShowCollapse.key === 'IsnGroup' && isShowCollapse.value ? 'turn-icon' : 'collapsed'}`}
        data-toggle="collapse"
        data-target="#menu-isn-group"
        id="toggle-isn-group"
        aria-expanded={isShowCollapse.key === 'IsnGroup' && isShowCollapse.value}
        onClick={() =>
          setIsShowCollapse({
            key: 'IsnGroup',
            value: !(isShowCollapse.key === 'IsnGroup' && isShowCollapse.value),
          })
        }
      >
        <span> {t('about_isn.isn_group')}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.207" viewBox="0 0 13.414 7.207">
          <path
            id="chevron-down"
            fill="none"
            stroke="#131313"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 9l6 6 6-6"
            transform="translate(-5.293 -8.293)"
          />
        </svg>
      </div>
      <div
        className={`collapse in profile-widget-menu
        ${'IsnGroup' === isShowCollapse.key && isShowCollapse.value ? 'show' : ''}`}
        id="menu-isn-group"
      >
        {menuGroup &&
          menuGroup.length &&
          menuGroup.map((item, index) => (
            <div key={index}>
              <Link
                to={`${pathLanguage}${item.url}`}
                className={pathName.includes(isnGroupList[index]) ? 'click-active' : ''}
              >
                {item.title}
              </Link>
            </div>
          ))}
      </div>

      <Link
        className={`profile-widget new-release w-100 ${newRelaesePath.includes(pathName) ? 'click-active' : ''}`}
        to={`${pathLanguage}/blogs/news/1`}
      >
        <span>{t('about_isn.new_relaese')}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.207" viewBox="0 0 13.414 7.207">
          <path
            id="chevron-down"
            fill="none"
            stroke="#131313"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 9l6 6 6-6"
            transform="translate(-5.293 -8.293)"
          />
        </svg>
      </Link>

      <Link
        className={`profile-widget new-release w-100 ${contactUsPath.includes(pathName) ? 'click-active' : ''}`}
        to={`${pathLanguage}/about-isn/contact-us`}
      >
        <span>{t('about_isn.contact_us')}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.207" viewBox="0 0 13.414 7.207">
          <path
            id="chevron-down"
            fill="none"
            stroke="#131313"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 9l6 6 6-6"
            transform="translate(-5.293 -8.293)"
          />
        </svg>
      </Link>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);
