import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fromPairs } from 'lodash';

import { getPathLanguage } from '../../../utils/params';

const BreadcrumbResidenceSearch = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const [sortParams, setSortParams] = useState('');
  // const [pageSizeParams, setPageSizeParams] = useState('');

  const optionSort =
    props.propertyType === '0'
      ? [
          { value: 'sqmmin', label: t('search_page.option_sort_by.size_ascending') },
          { value: '-sqmmin', label: t('search_page.option_sort_by.size_descending') },
          { value: 'leasePriceMin', label: t('search_page.option_sort_by.price_ascending') },
          { value: '-leasePriceMin', label: t('search_page.option_sort_by.price_descending') },
        ]
      : [
          { value: 'sqmmin', label: t('search_page.option_sort_by.size_ascending') },
          { value: '-sqmmin', label: t('search_page.option_sort_by.size_descending') },
          { value: 'leasePriceMin', label: t('search_page.option_sort_by.price_ascending') },
          { value: '-leasePriceMin', label: t('search_page.option_sort_by.price_descending') },
        ];

  const showOption =
    props.propertyType === '0'
      ? [
          { value: 15, label: 15 },
          { value: 30, label: 30 },
          { value: 45, label: 45 },
          { value: 60, label: 60 },
          { value: 75, label: 75 },
          { value: 90, label: 90 },
        ]
      : [
          { value: 16, label: 16 },
          { value: 32, label: 32 },
          { value: 48, label: 48 },
          { value: 64, label: 64 },
          { value: 80, label: 80 },
          { value: 96, label: 96 },
        ];
  const urlParams = fromPairs(
    location.search
      .substring(1)
      .split('&')
      .map(item => item.split('=')),
  );

  useEffect(() => {
    if (urlParams.sort) setSortParams(urlParams.sort);
    // if (urlParams.pageSize) setPageSizeParams(parseInt(urlParams.pageSize));

    // eslint-disable-next-line
  }, [urlParams.sort]);

  const { residencesSearch } = props;

  return (
    <div className="section-block background-gray p-0">
      <div className="container">
        <div className="row breadcrumb align-items-center">
          <div className="col-12 col-md-6">
            <div className="breadcrumb-item row">
              <ul>
                <li className="first">
                  <Link className="home" to={pathLanguage}>
                    {t('search_page.home')}
                  </Link>
                </li>
                <li className="item">
                  <span>
                    &#8226;&nbsp;&nbsp;&nbsp;<span className="active"> {t('search_page.search_result')}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 text-right">
            <div className="sort-page row">
              <div className="wrap-sort">
                <div className="sort-item first">
                  <span className="gray">{t('search_page.sort_by')}:</span>
                  <div className="select-control no-border sort-search">
                    <select
                      className="sort-select"
                      onChange={e => props.handleChangeSortBy(e.target.value)}
                      value={sortParams}
                    >
                      {optionSort &&
                        optionSort.length &&
                        optionSort.map((item, index) => (
                          <option className="sort-option" key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {residencesSearch &&
                residencesSearch.pageIndex &&
                residencesSearch.pageSize &&
                residencesSearch.totalRecords ? (
                  <>
                    <div className="page-of sort-item second second-none">
                      <span className="bold">
                        {residencesSearch.pageIndex * residencesSearch.pageSize - residencesSearch.pageSize + 1} -{' '}
                        {residencesSearch.totalRecords < residencesSearch.pageIndex * residencesSearch.pageSize
                          ? residencesSearch.totalRecords
                          : residencesSearch.pageIndex * residencesSearch.pageSize}
                      </span>{' '}
                      <span>{t('search_page.option_sort_by.of')}</span>{' '}
                      <span>{residencesSearch.totalRecords || ''}</span>
                    </div>
                    <div className="page-size sort-item third third-none">
                      <div className="select-control show-search">
                        <select
                          value={residencesSearch.pageSize}
                          onChange={e => props.handleChangeShowItem(e.target.value)}
                        >
                          {showOption &&
                            showOption.length &&
                            showOption.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbResidenceSearch;
