import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import Pagination from 'react-js-pagination';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPaginate from 'react-paginate';

import { BreadcrumbAboutIsn, BannerAbout, RightMenu, NewsReleaseContent } from '../../common';
import NewReleaseImg from '../../../../assets/images/markus-spiske-2G8mnFvH8xk-unsplash.jpg';
import { getLanguage } from '../../../../utils/params';
import * as action from '../../aboutIsn.action';
import previousIcon from '../../../../assets/images/arrow-left.png';
import nextIcon from '../../../../assets/images/arrow-right.png';
import { isMobile } from '../../../../utils/constants';

const NewsReleaseComponent = props => {
  const { t } = useTranslation();
  const language = getLanguage(useLocation());

  const [pageNo, setPageNo] = useState(1);

  const breadCrumb = [
    { title: t('about_isn.home'), url: '/' },
    { title: t('about_isn.about_isn'), url: '/about-isn' },
    { title: t('about_isn.new_relaese') },
  ];
  const banner = {
    img: NewReleaseImg,
    title: t('about_isn.new_relaese'),
  };

  useEffect(() => {
    props.getNewsReleaseRequest({ language, postType: 2, pageSize: 6, pageIndex: 1 });
    // window.scrollTo(0, 0);
    const scrollTo = document.querySelector('#position-scroll');
    if (scrollTo) {
      scrollTo.scrollIntoView({ behavior: 'smooth' });
    }
    //eslint-disable-next-line
  }, [language]);

  const handlePageChange = pageNumber => {
    pageNumber = parseInt(pageNumber.selected) + 1;
    setPageNo(pageNumber);
    props.getNewsReleaseRequest({ language, postType: 2, pageSize: 6, pageIndex: pageNumber });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <BreadcrumbAboutIsn breadCrumb={breadCrumb} />
      <section className="group-company">
        <BannerAbout banner={banner} />
        <div id="position-scroll"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-9">
              <NewsReleaseContent data={props.aboutIsn.newsRelease} />
              {props.aboutIsn.newsRelease.totalRecords && props.aboutIsn.newsRelease.totalRecords > 6 ? (
                <div className="pagination">
                  <ReactPaginate
                    // activePage={pageNo}
                    // itemsCountPerPage={6}
                    // totalItemsCount={props.aboutIsn.newsRelease.totalRecords || 0}
                    // pageRangeDisplayed={5}
                    // hideFirstLastPages={true}
                    // // nextPageText={<img src={nextIcon} alt="" />}
                    // // prevPageText={<img src={previousIcon} alt="" />}
                    // nextPageText={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                    // prevPageText={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                    // activeClass={'pagination-active'}
                    // onChange={page => handlePageChange(page)}
                    // itemClass={'pagination-pager-item'}
                    forcePage={pageNo - 1}
                    pageCount={props.aboutIsn.newsRelease.pageCount}
                    pageRangeDisplayed={1}
                    marginPagesDisplayed={isMobile ? 1 : 2}
                    previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                    nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                    activeClassName={'pagination-active'}
                    onPageChange={handlePageChange}
                    pageClassName={'pagination-pager-item'}
                    disabledClassName={'opacity-disable'}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="col-12 col-md-3">
              <RightMenu />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getNewsReleaseRequest: action.getNewsReleaseRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewsReleaseComponent);
