import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  fetchFavoritesRequest,
  postContactFavoriteRequest,
  fetchHistoriesRequest,
  postContactHistoryRequest,
} from '../favorite.action';
import { FavoritesComponent, HistoryComponent } from '../pages';

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { fetchFavoritesRequest, postContactFavoriteRequest, fetchHistoriesRequest, postContactHistoryRequest },
    dispatch,
  );

export default {
  Favorites: connect(mapStateToProps, mapDispatchToProps)(FavoritesComponent),
  Histories: connect(mapStateToProps, mapDispatchToProps)(HistoryComponent),
};
