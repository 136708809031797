import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';

import BlockROfficesComponent from '../../commons/block/BlockOffice.component';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../utils/constants';
import { getPathLanguage } from '../../../utils/params';

const PropertiesList = props => {
  let listItems = props.data && props.data.items && props.data.items.length ? props.data.items : null;
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();

  const options = {
    items: 4,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    loop: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {
      0: { items: 1, nav: true },
      600: { items: 3, nav: true },
      1000: { items: 4, nav: true },
    },
  };

  const officeProperties = useRef('');

  return (
    <div className={`section-block background-gray ${props.wrapperClass ?? ''}`}>
      <div className={`${props.innerClass || ''} container`}>
        <Row>
          <div className="col-12 properties-title">
            <h3 className="block-title">
              <Link
                to={`${pathLanguage}/list-properties/${props.metaKey === 'last' ? 'latest' : 'hot'}/office`}
                className="jp-bold-700"
              >
                {props.headerTitle || ''}
              </Link>
            </h3>
            <h3 className="show-all-properties">
              <Link
                to={`${pathLanguage}/list-properties/${props.metaKey === 'last' ? 'latest' : 'hot'}/office`}
                className="jp-bold-700"
              >
                {t('home_page.all')}
              </Link>
            </h3>
          </div>
          <div className="col-12 d-none d-md-block">
            {listItems && listItems.length ? (
              <OwlCarousel
                ref={officeProperties}
                options={options}
                className="nav-top owl-carousel owl-theme owl-loaded owl-drag owl-carousel-offices"
              >
                {listItems.map((element, index) => (
                  <div key={index} className="col-item">
                    <BlockROfficesComponent metaKey={props.metaKey} section={props.section} post={element} />
                  </div>
                ))}
              </OwlCarousel>
            ) : (
              ''
            )}
          </div>
          <div className="col-12 properties d-block d-md-none">
            {listItems && listItems.length ? (
              <>
                {listItems.slice(0, 3).map((element, index) => (
                  <div key={index} className="col-item item-property">
                    <BlockROfficesComponent metaKey={props.metaKey} section={props.section} post={element} />
                  </div>
                ))}
                {listItems.length > 3 ? (
                  <div className="show-more">
                    <Link
                      to={`${pathLanguage}/list-properties/${props.metaKey === 'last' ? 'latest' : 'hot'}/office`}
                      className="btn-show-more"
                    >
                      {t('home_page.show_more')}
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default PropertiesList;
