import * as types from './officeDetail.types';

const initialState = {
  detail: null,
  similarProperties: [],
  error: null,
};

const officeDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_OFFICE_DETAIL_REQUEST:
      return { ...state };
    case types.GET_OFFICE_DETAIL_SUCCESS:
      return { ...state, detail: action.data };

    case types.GET_OFFICE_DETAIL_FAILURE:
      return { ...state, error: action.error };

    case types.GET_SIMILAR_LIST_REQUEST:
      return { ...state };
    case types.GET_SIMILAR_LIST_SUCCESS: {
      return { ...state, similarProperties: action.data };
    }
    case types.GET_SIMILAR_LIST_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default officeDetailReducer;
