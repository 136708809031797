import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPathLanguage } from '../../../utils/params';

const HomeSpecialistComponent = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());

  return (
    <div className="section-block specialist">
      <div className="container">
        <div className="row">
          <div className="col-12 offset-md-6 col-md-6">
            <div className="block-list background-black">
              <h2 className="block-title">{t('home_page.special_list')}</h2>
              <div className="block-content content-specials jp-font-sawarabi-gothic">
                <ul className={`list-special-list list-scroll`}>
                  {props.data && props.data.items && props.data.items.length
                    ? props.data.items.map((item, index) => (
                        <li key={index}>
                          <Link to={`${pathLanguage}/special/${item.id}`}>{item.name}</Link>
                        </li>
                      ))
                    : ''}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSpecialistComponent;
