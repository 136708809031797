import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const ButtonNextPrevComponent = props => {
    const url = window.location.href;
    const [id, setId] = useState(0);
    const { t } = useTranslation();
    useEffect(() => {
        if (props.dataDetai) {
            setId(props.dataDetai.id)
        }
    }, [props.dataDetai])
    return (
        <>
            <div className="container " style={{ display: "flex", flexWrap: "nowrap" }}>
                <div className="col-md-6" style={{ padding: "unset" }}>
                    <text className="uppercase jp-700-sawarabi text-next-pre"
                        onClick={() => props.onHandleChange(id, "PREV")}>
                        {(url.indexOf("/blog/") > -1)
                            ? t('title.previous_button_blog')
                            : t('title.previous_button')
                        }
                    </text>
                </div>
                <div className="col-md-6" style={{ textAlign: "right", padding: "unset" }}>
                    <text className="uppercase jp-700-sawarabi text-next-pre"
                        onClick={() => props.onHandleChange(id, "NEXT")}>
                        {(url.indexOf("/blog/") > -1)
                            ? t('title.next_button_blog')
                            : t('title.next_button')
                        }
                    </text>
                </div>
            </div>
        </>
    )
}
export default ButtonNextPrevComponent;
