import React, { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import BreadcrumbResidence from '../../../layouts/commons/breadcrumb/Breadcrumb.Residence';
import ResidenceDetails from './ResidenceDetails.component';
import SimilarProperties from './SimilarProperties.component';
import { getIdDetail, getLanguage, getPathLanguage } from '../../../utils/params';

const ResidenceComponent = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const id = getIdDetail(location);
  const { detail, similarProperties, error } = props.residence;
  const params = useParams();

  useEffect(() => {
    if (id && language) {
      let isPreview = params.slug === 'isPreview';
      props.getResidenceRequest({ id, language, isPreview });
      if(!isPreview){
        props.getSimilarPropertiesRequest({ currentId: id,
          language, propertiesType: 0, pageIndex: 1, pageSize: 6 });
      }   
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [id]);

  const renderData = useMemo(() => {
    if (detail) {
      return (
        <>
          <BreadcrumbResidence allState={props} data={detail} />
          <ResidenceDetails data={detail} />
          <SimilarProperties data={similarProperties || []} />
        </>
      );
    } else if (error && (error.response.status === 404 || error.response.status === 400)) {
      window.location.href = pathLanguage ? pathLanguage : '/';
    }
  }, [props, detail, error, pathLanguage, similarProperties]);

  return <>{renderData}</>;
};

export default ResidenceComponent;
