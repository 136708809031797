import React from 'react';
import { PreparationPage } from '../../../PreparationPage';

const PreparationComponent = () => {
  return (
    <>
      <PreparationPage />
    </>
  );
};

export default PreparationComponent;
