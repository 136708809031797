import React from 'react';
import imageDefault from '../../../../assets/images/loading-image.jpg';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const OfficeImage = props => {
  const { imgSrc } = props;

  return (
    <>
      {imgSrc ? (
        <img
          alt=""
          src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${imgSrc}`}
          className="w-100 h-100 office-image"
        />
      ) : (
        <img alt="" src={imageDefault} className="w-100 h-100 office-image" />
      )}

      {/* <LazyLoadImage
        alt=""
        src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${imgSrc}`}
        className="w-100 h-100 office-image"
        effect="blur"
      /> */}
    </>
  );
};

export default OfficeImage;
