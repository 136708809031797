import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';

import SearchResidence from '../searchs/residence/Residence';
import SearchOffices from '../searchs/office/Office';
import * as actions from '../../redux/layout.action';
import { bindActionCreators } from 'redux';

const BannerSlides = props => {
  const { showResidenceSearch, checkResidenceAndOffice } = props;

  const slideRef = useRef(null);
  const office= "OFFICE";
  const residense= "RESIDENCE";
  const options = {
    items: 1,
    margin: 0,
    nav: false,
    dots: true,
    loop: true,
    autoplay: true,
    autoHeight: true,
    slideTransition: 'linear',
    autoplaySpeed: true,
    autoplayTimeout: 5000,
    responsive: {},
    animateOut: 'fadeOut',
  };

  const cityId =
    props.layout.cityActive && props.layout.cityActive.defaultLocationId
      ? props.layout.cityActive.defaultLocationId
      : localStorage.getItem('LOCATION_CITY');

  useEffect(() => {
    props.getBannerRequest({ language: 'en', cityId });
    // eslint-disable-next-line
  }, [cityId]);

  const banners = props.layout.banner || null;

  const slideBannerOffice = useMemo(() => {
    if (banners && banners.length) {
      return (
        <OwlCarousel
        options={options}
        ref={slideRef}
        className="banner nav-top owl-carousel owl-theme owl-loaded owl-drag"
        >
        {banners.map((item, index) => (
              item.name === office ?
              /* eslint-disable */
              <div
                data={item.imageUrl}
                className="slides header-banner"
                style={{ backgroundImage: `url("${process.env.REACT_APP_URL_CRM_API}/images/banners/${item.imageUrl}")` }}
                key={index}
              ></div>
              : <></>
              /* eslint-enable */
          ))}
        </OwlCarousel>
      );
    }

    // eslint-disable-next-line
  }, [banners]);

  const slideBannerResidence = useMemo(() => {
    if (banners && banners.length) {
      return (
        <OwlCarousel
        options={options}
        ref={slideRef}
        className="banner nav-top owl-carousel owl-theme owl-loaded owl-drag"
        >
        {banners.map((item, index) => (
              item.name === residense ?
              /* eslint-disable */
              <div
                data={item.imageUrl}
                className="slides header-banner"
                style={{ backgroundImage: `url("${process.env.REACT_APP_URL_CRM_API}/images/banners/${item.imageUrl}")` }}
                key={index}
              ></div>
              : <></>
              /* eslint-enable */
          ))}
        </OwlCarousel>
      );
    }

    // eslint-disable-next-line
  }, [banners]);

  return (
    <>
      <section className="section-block section-banner p-0">
        {checkResidenceAndOffice ? slideBannerResidence : slideBannerOffice}
        {showResidenceSearch ? <SearchResidence /> : <SearchOffices />}
      </section>
    </>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => bindActionCreators({ getBannerRequest: actions.getBannerRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BannerSlides);
