import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, useLocation } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import Clock from '../../../../assets/images/clock.svg';
import { getPathLanguage } from '../../../../utils/params';

const NewsReleaseContent = props => {
  const { data } = props;
  const pathLanguage = getPathLanguage(useLocation());

  return (
    <div className="new-release">
      <div className="row">
        {data && data.items && data.items.length
          ? data.items.map((item, index) => (
              <div className="col-12 col-md-6 new-release-data" key={index}>
                <Link
                  className="new-release-block"
                  to={`${pathLanguage}/about-isn/news-release-detail/${item.id}/${item.slug}`}
                >
                  <img src={item.blogThumnail} className="w-100 news-banner" alt="" />
                  {/* <LazyLoadImage src={item.blogThumnail} className="w-100 news-banner" alt="" effect="blur" /> */}
                  <div className="new-release-title">{item.blogTitle}</div>
                  <div className="new-release-property">
                    <ul>
                      <li className="icon-clock">
                        <img src={Clock} alt="" />
                        {/* <LazyLoadImage src={Clock} alt="" effect="blur" /> */}
                      </li>
                      <li>{item.date}</li>
                    </ul>
                  </div>
                </Link>
              </div>
            ))
          : ''}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewsReleaseContent);
