import React, { useEffect, useState } from 'react';
import BreadcrumbProject from '../common/breadcrumb/Breadcrumb.componnent';
import ProjectBlock from '../common/listing/ProjectBlock.component';
import { useParams, useLocation } from 'react-router-dom';
import { getLanguage } from '../../../utils/params';

const ListingProjects = props => {
  const params = useParams();
  const language = getLanguage(useLocation());
  const [page, setPage] = useState({ pageIndex: 1, pageSize: 9 });

  useEffect(() => {
    props.fetchPropertiesInProjectRequest({
      projectId: params.id,
      language,
      pageIndex: page.pageIndex,
      pageSize: page.pageSize,
    });
    props.getProjectRequest({ id: params.id, language });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const changeItemInPage = data => {
    if (data && data.pageSize) {
      setPage({ pageIndex: 1, pageSize: data.pageSize });
    }

    if (data && data.pageIndex) {
      setPage({ ...page, pageIndex: data.pageIndex });
    }

    props.fetchPropertiesInProjectRequest({
      projectId: params.id,
      language,
      pageIndex: (data && data.pageIndex) || 1,
      pageSize: (data && data.pageSize) || page.pageSize,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <BreadcrumbProject
        data={props.projects.properties || []}
        changeItemInPage={changeItemInPage}
        title={props.projects.project && props.projects.project.name ? props.projects.project.name : ''}
      />
      <ProjectBlock
        data={props.projects.properties || []}
        page={page}
        pageCount={
          props.projects.properties && props.projects.properties.pageCount ? props.projects.properties.pageCount : 0
        }
        changeItemInPage={changeItemInPage}
        section="RESIDENCES_LIKE"
      />
    </>
  );
};

export default ListingProjects;
