import { get } from 'lodash';
import Service from '../../services';

export const fetchSpecialProperties = async params => {
  const response = await Service.get(`/api/speciallists/properties${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getSpecialDetail = async params => {
  const response = await Service.get(`/api/speciallists/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};
