import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BlockBlogComponent from '../../commons/block/BlockBlog.component';
import OwlCarousel from 'react-owl-carousel2';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../utils/constants';
import { getPathLanguage } from '../../../utils/params';

const BlogComponent = props => {
  const { t } = useTranslation();
  const owlBlog = useRef(null);
  const pathLanguage = getPathLanguage(useLocation());

  const data = props.data && props.data.items && props.data.items.length ? props.data.items : null;

  const options = {
    items: 3,
    margin: 30,
    nav: true,
    dots: false,
    autoplay: false,
    loop: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 3,
        nav: true,
      },
      1000: {
        items: 3,
        nav: true,
      },
    },
  };

  return (
    <>
      <div className="section-block blogs background-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 properties-title">
              <h2 className="block-title">
                <Link to={`${pathLanguage}/blogs/all`} className="jp-bold-700">
                  {t('home_page.blog')}
                </Link>
              </h2>
              <span className="show-all-properties">
                <Link to={`${pathLanguage}/blogs/all`} className="jp-bold-700">
                  {t('home_page.all')}
                </Link>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {data && data.length ? (
                <OwlCarousel
                  ref={owlBlog}
                  options={options}
                  className="nav-top owl-carousel owl-theme owl-loaded owl-drag"
                >
                  {data.map((blog, i) => {
                    if (!blog) return null;
                    return (
                      <div key={i} className="col-item">
                        <BlockBlogComponent post={blog} />
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogComponent;
