import * as actions from './layout.types';

const initialState = {
  cities: null,
  cityActive: null,
  districts: [],
  units: '',
  listCondition: null,
  banner: null,
  error: null,
  customerContact: null,
  projectsSearch: [],
  office: [],
  fileDownload: '',
  range: null,
  partner: null,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    // FETCH CITIES
    case actions.FETCH_CITIES_REQUEST:
      return { ...state };
    case actions.FETCH_CITIES_SUCCESS: {
      if (localStorage.getItem('LOCATION_CITY')) {
        const city = action.data.locations.find(i => i.cityId === localStorage.getItem('LOCATION_CITY'));
        return {
          ...state,
          cities: action.data.locations,
          cityActive: { defaultLocationId: city.cityId, defaultLocationName: city.cityName },
        };
      }
      localStorage.setItem('LOCATION_CITY', action.data.defaultLocation.defaultLocationId);
      return { ...state, cities: action.data.locations, cityActive: action.data.defaultLocation };
    }
    case actions.FETCH_CITIES_FAILURE:
      return { ...state, error: action.error };

    // FETCH CITIES
    case actions.FETCH_CITIES_BY_LOCALE_REQUEST:
      return { ...state };
    case actions.FETCH_CITIES_BY_LOCALE_SUCCESS: {
      if (localStorage.getItem('LOCATION_CITY')) {
        const city = action.data.locations.find(i => i.cityId === localStorage.getItem('LOCATION_CITY'));
        return {
          ...state,
          cities: action.data.locations,
          cityActive: { defaultLocationId: city.cityId, defaultLocationName: city.cityName },
        };
      }
      localStorage.setItem('LOCATION_CITY', action.data.defaultLocation.defaultLocationId);
      return { ...state, cities: action.data.locations, cityActive: action.data.defaultLocation };
    }
    case actions.FETCH_CITIES_BY_LOCALE_FAILURE:
      return { ...state, error: action.error };

    // FETCH DISTRICT BY ID
    case actions.FETCH_DISTRICTS_BY_CITYID_REQUEST:
      return { ...state };
    case actions.FETCH_DISTRICTS_BY_CITYID_SUCCESS:
      return { ...state, districts: action.data };
    case actions.FETCH_DISTRICTS_BY_CITYID_FAILURE:
      return { ...state, error: action.error };

    // FETCH DISTRICT BY ID
    case actions.GET_BANNER_REQUEST:
      return { ...state };
    case actions.GET_BANNER_SUCCESS:
      return { ...state, banner: action.data };
    case actions.GET_BANNER_FAILURE:
      return { ...state, error: action.error };

    // FETCH LIST CONDITION
    case actions.FETCH_LIST_CONDITION_REQUEST:
      return { ...state };
    case actions.FETCH_LIST_CONDITION_SUCCESS:
      return { ...state, listCondition: action.data };
    case actions.FETCH_LIST_CONDITION_FAILURE:
      return { ...state, error: action.error };

    // GET UNITS
    case actions.GET_UNITS_REQUEST:
      return { ...state };
    case actions.GET_UNITS_SUCCESS:
      return { ...state, units: action.data };
    case actions.GET_UNITS_FAILURE:
      return { ...state, error: action.error };

    // FETCH CUSTOMER CONTACT
    case actions.FETCH_CUSTOMER_CONTACT_REQUEST:
      return { ...state };
    case actions.FETCH_CUSTOMER_CONTACT_SUCCESS:
      return { ...state, customerContact: action.data[0] };
    case actions.FETCH_CUSTOMER_CONTACT_FAILURE:
      return { ...state, error: action.error };

    // FETCH PROJECT SEARCH
    case actions.FETCH_PROJECT_SEARCH_REQUEST:
      return { ...state };
    case actions.FETCH_PROJECT_SEARCH_SUCCESS:
      return { ...state, projectsSearch: action.data };
    case actions.FETCH_PROJECT_SEARCH_FAILURE:
      return { ...state, error: action.error };

    // Office info
    case actions.GET_FOOTER_INFORMATION_REQUEST:
      return { ...state };
    case actions.GET_FOOTER_INFORMATION_SUCCESS:
      return { ...state, office: action.data };
    case actions.GET_FOOTER_INFORMATION_FAILURE:
      return { ...state, error: action.error };

    case actions.DOWNLOAD_MAGAZINE_REQUEST:
      return { ...state };
    case actions.DOWNLOAD_MAGAZINE_SUCCESS:
      return { ...state, fileDownload: action.data };
    case actions.DOWNLOAD_MAGAZINE_FAILURE:
      return { ...state, error: action.error };

    // FETCH RANGE
    case actions.FETCH_RANGE_REQUEST:
      return { ...state };
    case actions.FETCH_RANGE_SUCCESS:
      return { ...state, range: action.data };
    case actions.FETCH_RANGE_FAILURE:
      return { ...state, error: action.error };

    // GET PARTNER
    case actions.GET_PARTNER_REQUEST:
      return { ...state };
    case actions.GET_PARTNER_SUCCESS:
      return { ...state, partner: action.data };
    case actions.GET_PARTNER_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default layoutReducer;
