import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../../../../utils/objects';
import moment from 'moment';
import { FacebookShareButton } from 'react-share';
import { useLocation } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Location, BedIcon, SizeIcon, EmailIcon, HeartIcon, Printer } from '../../../../layouts/commons/icons';
import ModalComponent from './Modal.component';
import fb from '../../../../assets/images/bxl-facebook-circle.svg';
import BathRooms from '../../../../assets/images/Mask58.svg';
import { getLanguage } from '../../../../utils/params';

const ResidenceInfo = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const [modalShow, setModalShow] = useState(false);
  const { data } = props;
  const { t } = useTranslation();
  const urlShare = data ? `${process.env.REACT_APP_URL}${location.pathname}` : process.env.REACT_APP_URL;

  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const listResidencesLike = localStorage.getItem('RESIDENCES_LIKE')
      ? localStorage.getItem('RESIDENCES_LIKE').split(',')
      : [];

    if (listResidencesLike.length && data && data.id) {
      setLiked(listResidencesLike.includes(data.id));
    }

    // eslint-disable-next-line
  }, [data]);

  const handleLikePost = () => {
    if (data && data.id) {
      const dataLocal = localStorage.getItem('RESIDENCES_LIKE')
        ? localStorage.getItem('RESIDENCES_LIKE').split(',')
        : [];
      let listData = [];
      if (dataLocal.includes(data.id)) {
        listData = dataLocal.filter(i => i !== data.id);
        setLiked(false);
      } else {
        listData = [...dataLocal, data.id];
        setLiked(true);
      }
      localStorage.setItem('RESIDENCES_LIKE', listData);

      EventBus.publish('RESET_LIKE', true);
    }
  };

  const showModel = isShow => {
    EventBus.publish('checkBodyScroll', isShow);
    setModalShow(isShow);
  };

  return (
    <>
      <div className="box-white">
        <div className="row code-created">
          <div className="col-5 col-md-6">
            <span className="link-red">{data && data.propertyIdC}</span>
          </div>
          <div className="col-7 col-md-6 text-right">
            <span className="link-gray created date-update">
              {t('residence_detail.last_update')}:{' '}
              {data && data.lastUpdateDate
                ? language === 'en'
                  ? moment(data.lastUpdateDate).format('MMM DD, YYYY')
                  : moment(data.lastUpdateDate).format('YYYY-MM-DD')
                : ''}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="post-title jp-bold-700">{data && data.name}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {data && (data.leasePrice || data.leasePriceMax) ? (
              <div className="price-details link-red">
                <span>
                  {data && (data.leasePrice || data.leasePriceMax) ? `$${formatMoney(data.leasePrice, 0, 0)}` : ''}
                  {data && data.leasePriceMax ? ' ~ ' : ''}
                  {data && data.leasePriceMax ? `$${formatMoney(data.leasePriceMax, 0, 0)}` : ''}
                </span>
                /{t('residence_detail.month')}
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tool-bar">
              <span className={`favourite jp-300-sawarabi ${liked ? 'actived' : ''} `} onClick={() => handleLikePost()}>
                <HeartIcon /> {t('residence_detail.favourite')}
              </span>
              <span
                className="print jp-300-sawarabi"
                onClick={() => {
                  window.print();
                }}
              >
                <Printer /> {t('residence_detail.print')}
              </span>
              <FacebookShareButton url={urlShare} quote={''} hashtag={''}>
                <span className="share icon-face jp-300-sawarabi">
                  <LazyLoadImage alt="" src={fb} effect="blur" /> {t('residence_detail.share')}
                </span>
              </FacebookShareButton>
              <hr className="toolbar-line"></hr>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="facilities-bar">
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">
                  <span>
                    <Location />
                  </span>
                  {t('residence_detail.district')}
                </span>
                {data && data.district}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">
                  <span>
                    <SizeIcon />
                  </span>
                  {t('residence_detail.size')}
                </span>
                {data && (data.size || data.sizeMax) ? formatMoney(data.size, 0, 0) : ''}
                {data && data.sizeMax ? ' ∼ ' : ''}
                {data && data.sizeMax ? formatMoney(data.sizeMax, 0, 0) : ''}
                {data && (data.size || data.sizeMax) ? (
                  <>
                    m<sup>2</sup>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">
                  <span>
                    <BedIcon />
                  </span>
                  {t('residence_detail.bedrooms')}
                </span>
                {data && data.bedroom >= 0 && typeof data.bedroom === 'number'
                  ? data.bedroom
                    ? data.bedroom
                    : t('home_page.studio')
                  : ''}
                {data && data.bedroom >= 0 && typeof data.bedroom === 'number' && data.bedroomMax ? ' ∼ ' : ''}
                {data && data.bedroomMax ? data.bedroomMax : ''}
                {data && (data.bedroom || data.bedroomMax) ? 'BR' : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">
                  <span>
                    {/* <img alt="" src={BathRooms} /> */}
                    <LazyLoadImage alt="" src={BathRooms} effect="blur" />
                  </span>
                  {t('residence_detail.bathrooms')}
                </span>
                {data && (data.bathroom || data.bathroomMax) ? data.bathroom : ''}
                {data && data.bathroomMax ? ' ∼ ' : ''}
                {data && data.bathroomMax ? data.bathroomMax : ''}
                {language === 'jp' && data && (data.bathroom || data.bathroomMax) ? '室' : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  disabled">
                  <span>
                    {/* <img alt="" src={BathRooms} /> */}
                    <LazyLoadImage alt="" src={BathRooms} effect="blur" />
                  </span>
                  {t('residence_detail.type')}
                </span>
                {data && data.type}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  disabled">
                  <span>
                    {/* <img alt="" src={BathRooms} /> */}
                    <LazyLoadImage alt="" src={BathRooms} effect="blur" />
                  </span>
                  {t('residence_detail.furnishings')}
                </span>
                {data && data.funishings}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  total-units disabled">
                  <span>
                    {/* <img alt="" src={BathRooms} /> */}
                    <LazyLoadImage alt="" src={BathRooms} effect="blur" />
                  </span>
                  {t('residence_detail.total_units')}
                </span>
                {data && data.totalUnit}
                {language === 'jp' && data && data.totalUnit ? '戸' : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  completion disabled">
                  <span>
                    {/* <img alt="" src={BathRooms} /> */}
                    <LazyLoadImage alt="" src={BathRooms} effect="blur" />
                  </span>
                  {t('residence_detail.completion_year')}
                </span>
                {data && data.completionYearNewC}
                {language === 'jp' && data && data.completionYearNewC ? '年' : ''}
              </div>
            </div>
            <div className="contact-us">
              <button
                className="button button-red uppercase jp-700-sawarabi"
                onClick={() => {
                  showModel(true);
                }}
              >
                <EmailIcon /> {t('residence_detail.contact_us')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent show={modalShow} onCloseModal={() => showModel(false)} />
    </>
  );
};

export default ResidenceInfo;
