import { get } from 'lodash';
import Service from '../../services';

export const getUsefulInformation = async params => {
  const response = await Service.get(`/api/usefulinformation/get-by-category${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getAboutRenting = async params => {
  const response = await Service.get(`/api/generalinformations/get-about-renting${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getTopicsApartment = async params => {
  const response = await Service.get(`/api/usefulinformation/topics${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getDetailApartment = async params => {
  const response = await Service.get(`/api/usefulinformation/post${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};
