import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Image } from '../../../commons/iconService';

const ResidenceList = props => {
  const { t } = useTranslation();

  const [facilityTitle, setFacilityTitle] = useState('');
  const [image, setImage] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.included) {
      setData(props.included);
      setImage(Image.included);
      setFacilityTitle(t('residence_detail.included_services'));
    } else if (props.building) {
      setData(props.building);
      setImage(Image.building);
      setFacilityTitle(t('residence_detail.building_facilities'));
    } else if (props.interior) {
      setData(props.interior);
      setImage(Image.interior);
      setFacilityTitle(t('residence_detail.interior_facilities'));
    }

    // eslint-disable-next-line
  }, [props]);

  return (
    <>
      <h2 className="uppercase jp-bold-600">{facilityTitle}</h2>
      <ul className="jp-font-sawarabi-gothic">
        {data && data.length
          ? data.map((item, index) => (
              <li className={!item.isShow ? 'disabled' : ''} key={index}>
                <img
                  alt=""
                  src={
                    index >= (image && Object.keys(image).length)
                      ? Image.defaultIcon
                      : image[`${Object.keys(image)[index]}`]
                  }
                />
                {/* <LazyLoadImage
                  alt=""
                  src={
                    index >= (image && Object.keys(image).length)
                      ? Image.defaultIcon
                      : image[`${Object.keys(image)[index]}`]
                  }
                  effect="blur"
                /> */}

                {item.facilityName}
              </li>
            ))
          : ''}
      </ul>
      <hr />
    </>
  );
};

export default ResidenceList;
