import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import EventBus from 'eventing-bus';
// import MessengerCustomerChat from 'react-messenger-customer-chat';

import { SearchIcon, PhoneCall, EmailIcon, LineIcon48, LineIcon64} from '../icons';
import SearchPopup from './SearchPopup.component';
import SearchOfficesPopup from './SearchOfficesPopup.component';
import { getPathLanguage, getLanguage } from '../../../utils/params';
import { fetchCustomerContactRequest } from '../../redux/layout.action';
import { get } from 'lodash';
import ModalComponent from '../../../modules/residencePage/pages/details/Modal.component';
import CustomerSupportPopup from './CustomerSupportPopup.component';

const Supports = props => {
  const location = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const pathLanguage = getPathLanguage(location);
  const language = getLanguage(location);
  const [support, setShowSupport] = useState({ phone: false, email: false, chat: false, selectSearch: false });

  const [isShowPopupSearch, setIsShowPopupSearch] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [customerSupportShow, setCustomerSupportShow] = useState(false);

  const [showSearchResidence, setShowSearchResidence] = useState(false);
  const [showSearchOffice, setShowSearchOffice] = useState(false);

  let searchModal =
    location.search ||
    ((location.pathname.includes(`${pathLanguage}/residence`) ||
      location.pathname.includes(`${pathLanguage}/office`)) &&
      params.id) ||
    location.pathname.includes(`${pathLanguage}/list-properties/latest/residence`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/hot/residence`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/latest/office`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/hot/office`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/similar/residence`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/similar/office`);

  const onClosePopup = () => {
    setIsShowPopupSearch(false);
    setShowSearchResidence(false);
    setShowSearchOffice(false);
    EventBus.publish('checkBodyScroll', false);
  };

  const cityId = get(props, 'layout.cityActive.defaultLocationId', null) || localStorage.getItem('LOCATION_CITY');

  useEffect(() => {
    if (cityId) {
      props.fetchCustomerContactRequest({ cityId, language });
    }

    // eslint-disable-next-line
  }, [cityId]);

  const customerData = get(props, 'layout.customerContact', null);

  const isShowButtonSearch =
    location.pathname.includes(`${pathLanguage}/residence/`) ||
    location.pathname.includes(`${pathLanguage}/office/`) ||
    location.pathname.includes(`${pathLanguage}/residences/search`) ||
    location.pathname.includes(`${pathLanguage}/offices/search`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/latest/residence`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/hot/residence`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/latest/office`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/hot/office`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/similar/residence`) ||
    location.pathname.includes(`${pathLanguage}/list-properties/similar/office`);

  const buttonSearchHome =
    location.pathname === '/' ||
    location.pathname === pathLanguage ||
    location.pathname === `${pathLanguage}/` ||
    location.pathname === `${pathLanguage}/residences` ||
    location.pathname === `${pathLanguage}/offices`;

  return (
    <>
      <div className={`supports show d-none d-md-block`}>
        <ul>
          {isShowButtonSearch ? (
            <li
              className={`box-phone ${isShowPopupSearch ? 'active' : ''}`}
              onClick={() => {
                EventBus.publish('checkBodyScroll', !isShowPopupSearch);
                setIsShowPopupSearch(!isShowPopupSearch);
              }}
            >
              <span className="circle search">
                <SearchIcon />
              </span>
            </li>
          ) : (
            ''
          )}
          {!isShowButtonSearch ? (
            <>
              {buttonSearchHome ? (
                <li>
                  <div
                    className="link-search"
                    onClick={() => {
                      setShowSupport({
                        ...support,
                        ...{ phone: false, email: false, chat: false, selectSearch: false },
                      });
                      document.getElementById('mySearch').focus();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="circle search">
                      <SearchIcon />
                    </div>
                  </div>
                </li>
              ) : (
                <li className={(support.selectSearch && 'active') || null}>
                  <span
                    className="circle call"
                    onClick={() => {
                      if (support.selectSearch)
                        setShowSupport({
                          ...support,
                          ...{ phone: false, email: false, chat: false, selectSearch: false },
                        });
                      else
                        setShowSupport({
                          ...support,
                          ...{ phone: false, email: false, chat: false, selectSearch: true },
                        });
                    }}
                  >
                    <div className="circle search">
                      <SearchIcon />
                    </div>
                  </span>
                  <div
                    className={`buttons phone text-center jp-500-sawarabi ${(support.selectSearch && 'active') || ''}`}
                  >
                    <div
                      className="button button-red btn-select-search"
                      onClick={() => {
                        EventBus.publish('checkBodyScroll', true);
                        setShowSearchResidence(true);
                        setShowSupport({
                          ...support,
                          ...{ phone: false, email: false, chat: false, selectSearch: false },
                        });
                      }}
                    >
                      <span className="capitalize">{t('home_page.search.residence')}</span>
                    </div>
                    <div
                      className="button button-red button-gray btn-select-search"
                      onClick={() => {
                        EventBus.publish('checkBodyScroll', true);
                        setShowSearchOffice(true);
                        setShowSupport({
                          ...support,
                          ...{ phone: false, email: false, chat: false, selectSearch: false },
                        });
                      }}
                    >
                      <span className="capitalize">{t('home_page.search.office')}</span>
                    </div>
                  </div>
                </li>
              )}
            </>
          ) : (
            ''
          )}
          <li className={(support.phone && 'active') || null}>
            <span
              className="circle call btn-white"
              onClick={() => {
                if (support.phone)
                  setShowSupport({ ...support, ...{ phone: false, email: false, chat: false, selectSearch: false } });
                else setShowSupport({ ...support, ...{ phone: true, email: false, chat: false, selectSearch: false } });
              }}
            >
              <PhoneCall />
            </span>
            <div className={`buttons phone ${(support.phone && 'active') || ''}`}>
              <div className="button button-red">
                <span className="capitalize">{t('home_page.for_inquiry')}</span>
                <a href={`tel: ${customerData && customerData.phone1 ? customerData.phone1 : ''}`}>
                  <span>{customerData && customerData.phone1 ? customerData.phone1 : ''}</span>
                </a>
              </div>
              <div className="button button-red button-gray">
                <span className="capitalize">{t('home_page.customer_support')}</span>
                <a href={`tel: ${customerData && customerData.phone2 ? customerData.phone2 : ''}`}>
                  <span>{customerData && customerData.phone2 ? customerData.phone2 : ''}</span>
                </a>
              </div>
            </div>
          </li>
          <li className={`box-email ${(support.email && 'active') || ''}`}>
            <span
              className="circle email btn-white"
              onClick={() => {
                if (support.email)
                  setShowSupport({ ...support, ...{ phone: false, email: false, chat: false, selectSearch: false } });
                else setShowSupport({ ...support, ...{ phone: false, email: true, chat: false, selectSearch: false } });
              }}
            >
              <EmailIcon />
            </span>
            <div className={`buttons phone ${(support.email && 'active') || ''}`}>
              <div
                className="button button-red"
                onClick={() => {
                  setModalShow(true);
                  EventBus.publish('checkBodyScroll', true);
                }}
              >
                <span className="capitalize">{t('home_page.for_inquiry')}</span>
                <span>
                  <span>{customerData && customerData.emailSupport1 ? customerData.emailSupport1 : ''}</span>
                </span>
              </div>
              <div
                className="button button-red button-gray"
                onClick={() => {
                  setCustomerSupportShow(true);
                  EventBus.publish('checkBodyScroll', true);
                }}
              >
                <span className="capitalize">{t('home_page.customer_support')}</span>
                <span>{customerData && customerData.emailSupport2 ? customerData.emailSupport2 : ''}</span>
              </div>
            </div>
          </li>
        </ul>
        {
          language === 'jp' ?
            <div className='line-responsive'>
              <ul>
                <li>
                  <a href="https://lin.ee/UGeV0LI" target="_blank" rel="noopener noreferrer">
                    <LineIcon48 />
                  </a>
                </li>
              </ul>
            </div>
            : ''
        }
      </div>
      <div className={`supports support-responsive show d-block d-md-none`}>
        <ul>
          {!isShowButtonSearch ? (
            <>
              {buttonSearchHome ? (
                <li className={`box-phone`}>
                  <div
                    className="link-search"
                    onClick={() => {
                      setShowSupport({
                        ...support,
                        ...{ phone: false, email: false, chat: false, selectSearch: false },
                      });
                      document.getElementById('mySearch').focus();
                      window.scrollTo(0, 0);
                    }}
                  >
                    <div className="circle search">
                      <SearchIcon />
                    </div>
                    <div className="title-nav">{t('home_page.search.search')}</div>
                  </div>
                </li>
              ) : (
                <li
                  onClick={() => {
                    if (support.selectSearch)
                      setShowSupport({
                        ...support,
                        ...{ phone: false, email: false, chat: false, selectSearch: false },
                      });
                    else
                      setShowSupport({
                        ...support,
                        ...{ phone: false, email: false, chat: false, selectSearch: true },
                      });
                  }}
                >
                  <div className="circle search">
                    <SearchIcon />
                  </div>
                  <div className="title-nav">{t('home_page.search.search')}</div>

                  <div className={`buttons phone jp-500-sawarabi ${(support.selectSearch && 'active') || ''}`}>
                    <div
                      className="button button-red"
                      onClick={() => {
                        setShowSearchResidence(true);
                        EventBus.publish('checkBodyScroll', true);
                      }}
                    >
                      <span className="capitalize">{t('home_page.search.residence')}</span>
                    </div>
                    <div
                      className="button button-red button-gray"
                      onClick={() => {
                        setShowSearchOffice(true);
                        EventBus.publish('checkBodyScroll', true);
                      }}
                    >
                      <span className="capitalize">{t('home_page.search.office')}</span>
                    </div>
                  </div>
                </li>
              )}
            </>
          ) : (
            ''
          )}
          {isShowButtonSearch ? (
            <li
              className={`box-phone ${isShowPopupSearch ? 'active' : ''}`}
              onClick={() => {
                EventBus.publish('checkBodyScroll', !isShowPopupSearch);
                setIsShowPopupSearch(!isShowPopupSearch);
              }}
            >
              <div className="circle search">
                <SearchIcon />
              </div>
              <div className="title-nav">{t('home_page.search.search')}</div>
            </li>
          ) : (
            ''
          )}
          <li
            className={(support.phone && 'active') || null}
            onClick={() => {
              if (support.phone)
                setShowSupport({ ...support, ...{ phone: false, email: false, chat: false, selectSearch: false } });
              else setShowSupport({ ...support, ...{ phone: true, email: false, chat: false, selectSearch: false } });
            }}
          >
            <div className="circle call">
              <PhoneCall />
            </div>
            <div className="title-nav">{t('home_page.search.call')}</div>
            <div className={`buttons phone ${(support.phone && 'active') || ''}`}>
              <div className="button button-red">
                <span className="capitalize">{t('home_page.for_inquiry')}</span>
                <a href={`tel: ${customerData && customerData.phone1 ? customerData.phone1 : ''}`}>
                  <span>{customerData && customerData.phone1 ? customerData.phone1 : ''}</span>
                </a>
              </div>
              <div className="button button-red button-gray">
                <span className="capitalize">{t('home_page.customer_support')}</span>
                <a href={`tel: ${customerData && customerData.phone2 ? customerData.phone2 : ''}`}>
                  <span>{customerData && customerData.phone2 ? customerData.phone2 : ''}</span>
                </a>
              </div>
            </div>
          </li>
          <li
            className={`box-email ${(support.email && 'active') || ''}`}
            onClick={() => {
              if (support.email)
                setShowSupport({ ...support, ...{ phone: false, email: false, chat: false, selectSearch: false } });
              else setShowSupport({ ...support, ...{ phone: false, email: true, chat: false, selectSearch: false } });
            }}
          >
            <div className="circle email">
              <EmailIcon />
            </div>
            <div className="title-nav">{t('home_page.search.mail')}</div>
            <div className={`buttons phone ${(support.email && 'active') || ''}`}>
              <div
                className="button button-red"
                onClick={() => {
                  setModalShow(true);
                  EventBus.publish('checkBodyScroll', true);
                }}
              >
                <span className="capitalize">{t('home_page.for_inquiry')}</span>
                <span>
                  <span>{customerData && customerData.emailSupport1 ? customerData.emailSupport1 : ''}</span>
                </span>
              </div>
              <div
                className="button button-red button-gray"
                onClick={() => {
                  setCustomerSupportShow(true);
                  EventBus.publish('checkBodyScroll', true);
                }}
              >
                <span className="capitalize">{t('home_page.customer_support')}</span>
                <span>{customerData && customerData.emailSupport2 ? customerData.emailSupport2 : ''}</span>
              </div>
            </div>
          </li>
          <li className="box-chat d-none d-md-block">
            {/* <span
              className="circle chat"
              onClick={() => {
                setShowSupport({ ...support, ...{ phone: false, email: false, chat: true } });
              }}
            >
              <ChatIcon />
            </span> */}
            {/* <MessengerCustomerChat
              pageId={process.env.REACT_APP_KEY_PAGE_FACEBOOK}
              appId={process.env.REACT_APP_KEY_FACEBOOK}
              htmlRef={'sdvsdvsdvdsvnds sdvsdv '}
            /> */}
          </li>
        </ul>
        {
          language === 'jp' ?
            <div className='line-responsive'>
              <ul>
                <li>
                  <a href="https://lin.ee/UGeV0LI" target="_blank" rel="noopener noreferrer">
                    <LineIcon64 />
                  </a>
                </li>
              </ul>
            </div>
            : ''
        }
      </div>
      <div
        className={`overlay ${support.phone ||
          support.email ||
          support.chat ||
          isShowPopupSearch ||
          support.selectSearch ||
          showSearchResidence ||
          showSearchOffice
          ? 'active'
          : ''
          }`}
        onClick={() => {
          setShowSupport({ ...support, ...{ phone: false, email: false, chat: false, selectSearch: false } });
        }}
      ></div>

      {searchModal ? (
        <>
          {isShowPopupSearch ? (
            location.pathname.includes(`${pathLanguage}/residence`) ||
              location.pathname.includes(`${pathLanguage}/list-properties/latest/residence`) ||
              location.pathname.includes(`${pathLanguage}/list-properties/hot/residence`) ||
              location.pathname.includes(`${pathLanguage}/list-properties/similar/residence`) ? (
              <SearchPopup onClosePopup={onClosePopup} isShow={isShowPopupSearch} />
            ) : location.pathname.includes(`${pathLanguage}/office`) ||
              location.pathname.includes(`${pathLanguage}/list-properties/latest/office`) ||
              location.pathname.includes(`${pathLanguage}/list-properties/hot/office`) ||
              location.pathname.includes(`${pathLanguage}/list-properties/similar/office`) ? (
              <SearchOfficesPopup onClosePopup={onClosePopup} isShow={isShowPopupSearch} />
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          {showSearchResidence ? <SearchPopup onClosePopup={onClosePopup} isShow={showSearchResidence} /> : ''}
          {showSearchOffice ? <SearchOfficesPopup onClosePopup={onClosePopup} isShow={showSearchOffice} /> : ''}
        </>
      )}

      {modalShow ? (
        <ModalComponent
          show={modalShow}
          onCloseModal={() => {
            setModalShow(false);
            EventBus.publish('checkBodyScroll', false);
          }}
          onCloseSupport={() => setShowSupport({ ...support, ...{ phone: false, email: false, chat: false } })}
        />
      ) : (
        ''
      )}
      {customerSupportShow ? (
        <CustomerSupportPopup
          show={customerSupportShow}
          onCloseModal={() => {
            setCustomerSupportShow(false);
            EventBus.publish('checkBodyScroll', false);
          }}
          onCloseSupport={() => setShowSupport({ ...support, ...{ phone: false, email: false, chat: false } })}
        />
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch => bindActionCreators({ fetchCustomerContactRequest }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Supports);
