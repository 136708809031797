import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
// import EventBus from 'eventing-bus';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { history } from '../../../../utils/history';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { InputDate } from '../../../../components';
import IconContact from '../../../../assets/images/Group2676.svg';
import { getLanguage, getPathLanguage } from '../../../../utils/params';
import * as action from '../../residence.action';
import ReCAPTCHA from 'react-google-recaptcha';

const ModalComponent = props => {
  const { show, onCloseModal } = props;
  const { handleSubmit, register, errors, reset } = useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const wrapperRef = useRef(null);

  const [locationId, setLocationId] = useState('');
  const [date, setDate] = useState({
    dateFrom: moment(),
    dateTo: moment(),
  });
  const [propertyId, setPropertyId] = useState('');
  const [isShowSubmit, setIsShowSubmit] = useState(false);

  const onSubmit = values => {
    if (values) {
      const info = {};

      info.language = language;
      info.cityId = locationId;
      info.expectedCheckInDate = date.dateFrom;
      info.expectedTourInspectionDate = date.dateTo;
      info.fullName = values.fullName;
      info.email = values.email;
      info.phone = values.phone;
      if (propertyId) info.propertyId = propertyId;
      if (values.companyName) info.companyName = values.companyName;
      if (values.otherRequirements) info.otherRequirements = values.otherRequirements;

      props.postContactUsRequest({ info, onSuccess, onError });
      onCloseModal();
      setIsShowSubmit(false);
    }
  };

  const onSuccess = () => {
    history.push(`${pathLanguage}/notification/contact-us`);
    props.onCloseSupport();
  };
  const onError = err => toast.error(err);

  useEffect(() => {
    const propsId = get(props, 'layout.cityActive.defaultLocationId', null);
    if (propsId) setLocationId(propsId);

    return () => reset();
    // eslint-disable-next-line
  }, [props.layout.cityActive]);

  useEffect(() => {
    let propertyId = null;
    if (location.pathname.includes('/residence/detail')) {
      propertyId = get(props, 'residence.detail.propertyIdC', null);
    } else if (location.pathname.includes('/office/detail')) {
      propertyId = get(props, 'officeDetail.detail.propertyId', null);
    }
    if (propertyId) setPropertyId(propertyId);
    // eslint-disable-next-line
  }, [props]);

  const onChangeDatePicker = (key, value) => {
    if (key === 'dateFrom') setDate({ ...date, ...{ dateFrom: value } });
    else if (key === 'dateTo') setDate({ ...date, ...{ dateTo: value } });
  };

  //   EventBus.on('change_location', data => {
  //     if (data) setLocationId(data);
  //   });

  const checkCaptcha = value => {
    if (value) setIsShowSubmit(true);
  };

  const handleClickOutsidePopup = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) props.onCloseModal();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);

    return () => document.removeEventListener('mousedown', handleClickOutsidePopup);
    // eslint-disable-next-line
  }, [wrapperRef]);

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <form className={`search-form contact-us-form ${language}`} onSubmit={handleSubmit(onSubmit)} ref={wrapperRef}>
          <div className="group-control">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="title-form-contact">
                  <div className="row">
                    <div className="col-9 col-md-9">
                      <div
                        className="title jp-bold-600"
                        dangerouslySetInnerHTML={{
                          __html: t('residence_detail.contact_form.title_contact'),
                        }}
                      ></div>
                    </div>
                    <div className="col-3 col-md-3 img-right">
                      <img src={IconContact} alt="" />
                      {/* <LazyLoadImage src={IconContact} alt="" effect="blur" /> */}
                    </div>
                    <div className="col-12 jp-font-sawarabi-gothic">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('residence_detail.contact_form.content_thank_you'),
                        }}
                      ></div>
                      <div
                        className="contact-content"
                        dangerouslySetInnerHTML={{
                          __html: t('residence_detail.contact_form.our_person'),
                        }}
                      ></div>
                      <hr className="contact-line" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>
                    {t('residence_detail.contact_form.full_name')} <span className="link-red-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="fullName"
                    ref={register({ required: t('residence_detail.contact_form.err_required') })}
                  />
                  <small className="error">
                    {errors.fullName && errors.fullName.message ? errors.fullName.message : ''}
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>
                    {t('residence_detail.contact_form.email')} <span className="link-red-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    ref={register({
                      required: t('residence_detail.contact_form.err_required'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('residence_detail.contact_form.err_email'),
                      },
                    })}
                  />
                  <small className="error">{errors.email && errors.email.message ? errors.email.message : ''}</small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>
                    {t('residence_detail.contact_form.phone_number')} <span className="link-red-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    ref={register({
                      required: t('residence_detail.contact_form.err_required'),
                    })}
                  />
                  <small className="error">{errors.phone && errors.phone.message ? errors.phone.message : ''}</small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>{t('residence_detail.contact_form.company_name')}</label>
                  <input type="text" className="form-control" name="companyName" ref={register()} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 mb-3">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <InputDate
                      class_name="form-control datetime"
                      formName="dateFrom"
                      name="dateA"
                      label={t('residence_detail.contact_form.check_in_date')}
                      onChange={onChangeDatePicker}
                    />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <InputDate
                      class_name="form-control datetime"
                      formName="dateTo"
                      name="dateB"
                      label={t('residence_detail.contact_form.inspection_date')}
                      onChange={onChangeDatePicker}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>{t('residence_detail.contact_form.other_requirements')}</label>
                  <textarea
                    className="form-control content-requirement"
                    name="otherRequirements"
                    ref={register()}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pb-2">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                onChange={checkCaptcha}
                hl={`${language === 'en' ? 'en' : 'ja'}`}
              />
            </div>
            <div className="col-12 col-md-6 button-handle">
              <button type="submit" className="button button-red uppercase modal-send-button" disabled={!isShowSubmit}>
                {t('residence_detail.contact_form.send')}
              </button>
              <button
                type="button"
                className="button button-red button-white uppercase modal-send-button"
                onClick={() => {
                  onCloseModal();
                  setIsShowSubmit(false);
                }}
              >
                {t('residence_detail.contact_form.cancel')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postContactUsRequest: action.postContactUsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);

// export default ModalComponent;
