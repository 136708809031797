import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OwlCarousel from 'react-owl-carousel2';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { FixedFrame } from '../../../../layouts/commons/icons';
import { ICON_LEFT, ICON_RIGHT } from '../../../../utils/constants';

const MeetOurContent = props => {
  const { data } = props.aboutIsn;
  const options = {
    items: 1,
    margin: 0,
    nav: true,
    dots: true,
    loop: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    autoHeight: true,
    slideTransition: 'linear',
    autoplaySpeed: true,
    autoplay: true,
    autoplayTimeout: 5000,
    responsive: {},
    animateOut: 'fadeOut',
  };

  return (
    <div className="our-team-carousel">
      {data && data.length ? (
        <OwlCarousel options={options}>
          {data.map((item, index) => (
            <div className="thumbnail" key={index}>
              <span>
                {/* <LazyLoadImage alt="" src={item.image} className="img-our-team" effect="blur" /> */}
                <img alt="" src={item.image} className="img-our-team" />
                <FixedFrame />
              </span>
            </div>
          ))}
        </OwlCarousel>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MeetOurContent);
