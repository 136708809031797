import React from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';

import imgOur from '../../../../assets/images/our-customer/banner-2-1.jpg';
import imgOurJp from '../../../../assets/images/our-customer/banner-2-JP-1.jpg';
import imgOurMobile from '../../../../assets/images/our-customer/banner-2-Mobile-En-1.jpg';
import imgOurJpMobile from '../../../../assets/images/our-customer/banner-2-Mobile-1.jpg';
// import iconSecureSupport from '../../../../assets/images/our-customer/Phone-24.svg';
import iconPhone365 from '../../../../assets/images/our-customer/Phone-365.svg';

import iconSupport1 from '../../../../assets/images/our-customer/Mask Group 175.svg';
import iconSupport2 from '../../../../assets/images/our-customer/Mask Group 176.svg';
import iconSupport3 from '../../../../assets/images/our-customer/Mask Group 177.svg';
import iconSupport4 from '../../../../assets/images/our-customer/Mask Group 178.svg';

import iconPhone from '../../../../assets/images/our-customer/Path 3569.svg';
import iconEmail from '../../../../assets/images/our-customer/Path 3570.svg';

import ImgReceptionEn from '../../../../assets/images/our-customer/ImgReceptionEn.svg';
import ImgReceptionJp from '../../../../assets/images/our-customer/ImgReceptionJp.svg';
import ImgReceptionSmallEn from '../../../../assets/images/our-customer/ImgReceptionSmallEn.svg';
import ImgReceptionSmallJp from '../../../../assets/images/our-customer/ImgReceptionSmallJp.svg';

import { getLanguage } from '../../../../utils/params';

const OurCustomer = () => {
  const { t } = useTranslation();
  const language = getLanguage(useLocation());

  return (
    <div className="page-our-customer">
      <div className="image-our">
        {/iPhone|Android/i.test(navigator.userAgent) ? (
          <LazyLoadImage src={language === 'en' ? imgOurMobile : imgOurJpMobile} alt="" effect="blur" />
        ) : (
          <LazyLoadImage src={language === 'en' ? imgOur : imgOurJp} alt="" effect="blur" />
        )}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 secure-support">
            <div className="row">
              {/* <div className="col-12 col-md-3 content-right-secure">
                <div className="title-left-secure">
                  <img src={iconSecureSupport} alt="" className="icon-secure-support" />
                  <div
                    className="text-secure-support"
                    dangerouslySetInnerHTML={{ __html: t('useful_page.our_customer.24_hour_secure') || '' }}
                  ></div>
                </div>
              </div> */}
              <div className="col-12 col-md-12">
                <div
                  className="content-secure-support jp-font-sawarabi-gothic"
                  dangerouslySetInnerHTML={{
                    __html: t('useful_page.our_customer.it_is_one_of_the_real_pleasures') || '',
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div className="col-12 trouble-shooting">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="title-trouble-shooting d-none d-md-block">
                  {t('useful_page.our_customer.service_flow')}
                </div>
                <div
                  className="title-trouble-shooting d-block d-md-none"
                  dangerouslySetInnerHTML={{
                    __html: t('useful_page.our_customer.service_flow_mobile') || '',
                  }}
                ></div>
                <div className="description-trouble-shooting jp-font-sawarabi-gothic">
                  {t('useful_page.our_customer.we_will_provide_support')}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 reception-correspondence">
            <div className="row">
              <div className="col-12 col-md-3 content-left">
                <div className="title-secure">
                  <img src={iconPhone365} alt="" className="icon-phone-reception" />
                  <div className="text-time">
                    <div className="text-24-hours">{t('useful_page.our_customer.24_hours')}</div>
                    <div className="text-365-days">{t('useful_page.our_customer.365_days')}</div>
                    <div className="text-reception-correspondence">
                      {t('useful_page.our_customer.reception_correspondence')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9">
                <div className="content-right d-none d-md-block">
                  <img
                    src={language === 'en' ? ImgReceptionEn : ImgReceptionJp}
                    alt=""
                    className="img-description-reception"
                  />
                  <div className="description jp-font-sawarabi-gothic">
                    {t('useful_page.our_customer.we_accept_inquiries')}
                  </div>
                </div>
                <div className="content-right content-reception-mobile d-flex d-md-none">
                  <img
                    src={language === 'en' ? ImgReceptionSmallEn : ImgReceptionSmallJp}
                    alt=""
                    className="img-description-reception"
                  />
                  <div className="description jp-font-sawarabi-gothic">
                    {t('useful_page.our_customer.we_accept_inquiries')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 trouble-support">
            <div className="row">
              <div className="col-12 title-trouble-support">
                <div className="text-trouble-support">{t('useful_page.our_customer.trouble_support')}</div>
                <div className="description-trouble-support jp-font-sawarabi-gothic">
                  {t('useful_page.our_customer.we_will_support_a_wide_range_of_problems')}
                </div>
              </div>
            </div>
            <div className="row detail-support">
              <div className="col-md-1"></div>
              <div className="col-12 col-md-2 icon-left-detail-support">
                <img src={iconSupport1} alt="" className="icon-support" />
              </div>
              <div className="col-12 col-md-8">
                <div className="content-right-support">
                  <div className="title-support">{t('useful_page.our_customer.trouble_shooting')}</div>
                  <div
                    className="description-support jp-font-sawarabi-gothic d-none d-md-block"
                    dangerouslySetInnerHTML={{
                      __html: t('useful_page.our_customer.we_will_respond_to_the_troubles') || '',
                    }}
                  ></div>
                  <div className="description-support jp-font-sawarabi-gothic d-block d-md-none">
                    {t('useful_page.our_customer.we_will_respond_to_the_troubles_mobile')}
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-1"></div>
              <div className="col-12 col-md-2 icon-left-detail-support">
                <img src={iconSupport2} alt="" className="icon-support" />
              </div>
              <div className="col-12 col-md-8">
                <div className="content-right-support">
                  <div className="title-support">{t('useful_page.our_customer.arrangement_for_repair')}</div>
                  <div className="description-support jp-font-sawarabi-gothic">
                    {t('useful_page.our_customer.please_call_the_isn_vietnam')}
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-3"></div>
              <div className="col-12 col-md-8">
                <div className="row content-right-support d-none d-md-flex">
                  <div className="col-6">
                    <div className="example-support">
                      <div className="title"> {t('useful_page.our_customer.around_water')}</div>
                      <div className="text-example jp-font-sawarabi-gothic">
                        {t('useful_page.our_customer.around_water_example')}
                      </div>
                    </div>
                    <div className="example-support">
                      <div className="title"> {t('useful_page.our_customer.house_cleaning')}</div>
                      <div className="text-example jp-font-sawarabi-gothic">
                        {t('useful_page.our_customer.house_cleaning_example')}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="example-support">
                      <div className="title"> {t('useful_page.our_customer.electrical_equipment')}</div>
                      <div className="text-example jp-font-sawarabi-gothic">
                        {t('useful_page.our_customer.electrical_equipment_example')}
                      </div>
                    </div>
                    <div className="example-support">
                      <div className="title"> {t('useful_page.our_customer.key_trouble_support')}</div>
                      <div className="text-example jp-font-sawarabi-gothic">
                        {t('useful_page.our_customer.key_trouble_support_example')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row content-right-support d-block d-md-none">
                  <div className="example-support example-support-mobile col-11">
                    <div className="title"> {t('useful_page.our_customer.around_water')}</div>
                    <div className="text-example jp-font-sawarabi-gothic">
                      {t('useful_page.our_customer.around_water_example')}
                    </div>
                  </div>
                  <div className="col-1"></div>

                  <div className="example-support example-support-mobile col-11">
                    <div className="title"> {t('useful_page.our_customer.house_cleaning')}</div>
                    <div className="text-example jp-font-sawarabi-gothic">
                      {t('useful_page.our_customer.house_cleaning_example')}
                    </div>
                  </div>
                  <div className="col-1"></div>

                  <div className="example-support example-support-mobile col-11">
                    <div className="title"> {t('useful_page.our_customer.electrical_equipment')}</div>
                    <div className="text-example jp-font-sawarabi-gothic">
                      {t('useful_page.our_customer.electrical_equipment_example')}
                    </div>
                  </div>
                  <div className="col-1"></div>

                  <div className="example-support example-support-mobile col-11">
                    <div className="title"> {t('useful_page.our_customer.key_trouble_support')}</div>
                    <div className="text-example jp-font-sawarabi-gothic">
                      {t('useful_page.our_customer.key_trouble_support_example')}
                    </div>
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
              <div className="col-md-1"></div>

              <div className="col-md-1"></div>
              <div className="col-12 col-md-2 icon-left-detail-support">
                <img src={iconSupport3} alt="" className="icon-support" />
              </div>
              <div className="col-12 col-md-8">
                <div className="content-right-support">
                  <div className="title-support">{t('useful_page.our_customer.arrangement_of_drinking')}</div>
                  <div className="description-support jp-font-sawarabi-gothic">
                    {t('useful_page.our_customer.unlike_Japan_you_cannot_drink_tap_water_in_vietnam')}
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>

          <div className="col-12 other-support">
            <div className="row">
              <div className="col-12 col-md-4 text-other-support">{t('useful_page.our_customer.other_support')}</div>
              <div className="col-md-8"></div>
            </div>
            <div className="row detail-other-support">
              <div className="col-md-1"></div>
              <div className="col-12 col-md-2 icon-left-other">
                <img src={iconSupport4} alt="" className="icon-orther-support" />
              </div>
              <div className="col-12 col-md-8 content-right pr-0">
                <div className="title-orther-support">
                  {t('useful_page.our_customer.information_on_useful_information')}
                </div>
                <div className="description-orther-support jp-font-sawarabi-gothic">
                  {t('useful_page.our_customer.there_are_many_uncertainties')}
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>

          <div className="col-12 contact-support d-none d-md-flex">
            <div className="content">
              <div className="title-contact-support">
                {t('useful_page.our_customer.24_hour_secure_support_contact')}
              </div>
              <div className="info-contact">
                <div className="phone">
                  <img src={iconPhone} alt="" />
                  091-868-4649
                </div>
                <div className="email">
                  <img src={iconEmail} alt="" />
                  support@vietnamhousing.vn
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 d-block d-md-none">
            <div className="contact-support-mobile">
              <div
                className="title-contact-support"
                dangerouslySetInnerHTML={{
                  __html: t('useful_page.our_customer.24_hour_secure_support_contact_mobile') || '',
                }}
              ></div>
              <div className="phone">
                <img src={iconPhone} alt="" />
                091-868-4649
              </div>
              <div className="email">
                <img src={iconEmail} alt="" />
                support@vietnamhousing.vn
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurCustomer;
