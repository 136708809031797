import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getPathLanguage } from '../../../utils/params';

const SectionTitle = props => {
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const history = useHistory();
  const params = useParams();

  const redirectLink = () => {
    let refLink = '';
    if (location.pathname.includes('/residence/detail') || location.pathname.includes('/office/detail')) {
      if (location.pathname.includes('/residence/detail')) {
        refLink = `${pathLanguage}/list-properties/similar/residence?properties=${params.id}`;
      } else if (location.pathname.includes('/office/detail')) {
        refLink = `${pathLanguage}/list-properties/similar/office?properties=${params.id}`;
      }
    }

    history.push(refLink);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {!props.title 
          ? <span></span>
          : <h2
            onClick={() => redirectLink()}
            className={`block-title ${
              props.title === 'レジデンス' ||
              props.title === 'レジデンス特集' ||
              props.title === 'featured residence'
                ? 'color-white'
                : ''
            }`}
            style={{ cursor: 'pointer' }}
          >
            {props.title}
          </h2>
          }
        </div>
      </div>
    </>
  );
};

export default SectionTitle;
