const GET_USEFUL_INFORMATION_REQUEST = 'GET_USEFUL_INFORMATION_REQUEST';
const GET_USEFUL_INFORMATION_SUCCESS = 'GET_USEFUL_INFORMATION_SUCCESS';
const GET_USEFUL_INFORMATION_FAILURE = 'GET_USEFUL_INFORMATION_FAILURE';

const GET_ABOUT_RENTING_REQUEST = 'GET_ABOUT_RENTING_REQUEST';
const GET_ABOUT_RENTING_SUCCESS = 'GET_ABOUT_RENTING_SUCCESS';
const GET_ABOUT_RENTING_FAILURE = 'GET_ABOUT_RENTING_FAILURE';

const GET_TOPICS_APARTMENT_REQUEST = 'GET_TOPICS_APARTMENT_REQUEST';
const GET_TOPICS_APARTMENT_SUCCESS = 'GET_TOPICS_APARTMENT_SUCCESS';
const GET_TOPICS_APARTMENT_FAILURE = 'GET_TOPICS_APARTMENT_FAILURE';

const GET_DETAIL_APARTMENT_REQUEST = 'GET_DETAIL_APARTMENT_REQUEST';
const GET_DETAIL_APARTMENT_SUCCESS = 'GET_DETAIL_APARTMENT_SUCCESS';
const GET_DETAIL_APARTMENT_FAILURE = 'GET_DETAIL_APARTMENT_FAILURE';

const GET_LIST_AREA_REQUEST = 'GET_LIST_AREA_REQUEST';
const GET_LIST_AREA_SUCCESS = 'GET_LIST_AREA_SUCCESS';
const GET_LIST_AREA_FAILURE = 'GET_LIST_AREA_FAILURE';

export {
  GET_USEFUL_INFORMATION_REQUEST,
  GET_USEFUL_INFORMATION_SUCCESS,
  GET_USEFUL_INFORMATION_FAILURE,
  GET_ABOUT_RENTING_REQUEST,
  GET_ABOUT_RENTING_SUCCESS,
  GET_ABOUT_RENTING_FAILURE,
  GET_TOPICS_APARTMENT_REQUEST,
  GET_TOPICS_APARTMENT_SUCCESS,
  GET_TOPICS_APARTMENT_FAILURE,
  GET_DETAIL_APARTMENT_REQUEST,
  GET_DETAIL_APARTMENT_SUCCESS,
  GET_DETAIL_APARTMENT_FAILURE,
  GET_LIST_AREA_REQUEST,
  GET_LIST_AREA_SUCCESS,
  GET_LIST_AREA_FAILURE,
};
