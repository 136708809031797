import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

const BannerAbout = props => {
  return (
    <div className="group-banner">
      <img src={props.banner.img} className="w-100 h-100" alt="" />
      {/* <LazyLoadImage className="w-100 h-100" alt="" src={props.banner.img} effect="blur" /> */}
      <div className="group-company-overlay w-100 h-100"></div>
      <div className="container">
        <h1 className="group-company-title jp-bold-700">{props.banner.title}</h1>
      </div>
    </div>
  );
};

export default BannerAbout;
