const FETCH_FOR_OWNERS_REQUEST = 'FETCH_FOR_OWNERS_REQUEST';
const FETCH_FOR_OWNERS_SUCCESS = 'FETCH_FOR_OWNERS_SUCCESS';
const FETCH_FOR_OWNERS_FAILURE = 'FETCH_FOR_OWNERS_FAILURE';

const POST_INFORMATION_REQUEST = 'POST_INFORMATION_REQUEST';
const POST_INFORMATION_SUCCESS = 'POST_INFORMATION_SUCCESS';
const POST_INFORMATION_FAILURE = 'POST_INFORMATION_FAILURE';

const GET_FAQS_OWNERS_REQUEST = 'GET_FAQS_OWNERS_REQUEST';
const GET_FAQS_OWNERS_SUCCESS = 'GET_FAQS_OWNERS_SUCCESS';
const GET_FAQS_OWNERS_FAIILURE = 'GET_FAQS_OWNERS_FAIILURE';

export {
  FETCH_FOR_OWNERS_REQUEST,
  FETCH_FOR_OWNERS_SUCCESS,
  FETCH_FOR_OWNERS_FAILURE,
  POST_INFORMATION_REQUEST,
  POST_INFORMATION_SUCCESS,
  POST_INFORMATION_FAILURE,
  GET_FAQS_OWNERS_REQUEST,
  GET_FAQS_OWNERS_SUCCESS,
  GET_FAQS_OWNERS_FAIILURE,
};
