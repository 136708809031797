import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPathLanguage } from '../../../../utils/params';

const BreadcrumbProject = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const params = useParams();

  const optionSize = [
    { value: 9, label: 9 },
    { value: 18, label: 18 },
    { value: 27, label: 27 },
    { value: 36, label: 36 },
    { value: 45, label: 45 },
    { value: 60, label: 60 },
  ];

  return (
    <div className="section-block background-gray p-0">
      <div className="container">
        <div className="row breadcrumb align-items-center">
          <div className="col-12 col-md-6">
            <div className="breadcrumb-item row">
              <ul>
                <li className="first">
                  <Link className="home" to={pathLanguage}>
                    <span>{t('project_page.home')}</span>
                  </Link>
                </li>
                <li className="item">
                  {params.id ? (
                    <Link to={`${pathLanguage}/projects`}>
                      <span className="breadcrumb-dot">&#8226;</span>
                      <span className="normal">{t('project_page.project')}</span>
                    </Link>
                  ) : (
                    <>
                      <span className="breadcrumb-dot">&#8226;</span>
                      <span className="normal">{t('project_page.project')}</span>
                    </>
                  )}
                </li>
                {props.title ? (
                  <li className="item">
                    <span className="breadcrumb-dot">&#8226;</span>
                    <span className="active">{props.title}</span>
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 text-right">
            <div className="sort-page sort-projects row">
              <div className="wrap-sort no-sort">
                <div className="sort-item first"></div>
                <div className="page-of sort-item second second-none">
                  <span className="bold">
                    {props.data && props.data.pageIndex && props.data.pageSize
                      ? props.data.pageIndex * props.data.pageSize - props.data.pageSize + 1
                      : ''}
                    {' - '}
                    {props.data && props.data.totalRecords && props.data.pageIndex && props.data.pageSize
                      ? props.data.totalRecords < props.data.pageIndex * props.data.pageSize
                        ? props.data.totalRecords
                        : props.data.pageIndex * props.data.pageSize
                      : ''}
                  </span>{' '}
                  <span>{t('search_page.option_sort_by.of')}</span>{' '}
                  <span>{(props.data && props.data.totalRecords) || ''}</span>
                </div>
                <div className="page-size sort-item third third-none">
                  <div className="select-control show-search">
                    <select
                      value={props.data ? props.data.pageSize : 9}
                      onChange={e => props.changeItemInPage({ pageSize: parseInt(e.target.value) })}
                    >
                      {optionSize.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbProject;
