import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './residence.action';
import * as types from './residence.types';
import * as API from './residence.api';
import { get } from 'lodash';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';
import Service from '../../services';

export function* getResidence(action) {
  const { params } = action;

  try {
    yield put(setLoading(true));

    let residence = null;
    if (Object.keys(params).length) residence = yield call(API.getResidence, getQueryString(params));

    yield put(actions.getResidenceSuccess(residence));
  } catch (error) {
    yield put(actions.getResidenceFailure(error));
  } finally {
    window.scrollTo(0, 0);
    yield put(setLoading(false));
  }
}

export function* getSimilarProperties(action) {
  const { params } = action;

  try {
    yield put(setLoading(true));
    const data = yield call(API.getSimilarProperties, getQueryString(params));

    yield put(actions.getSimilarPropertiesSuccess(data));
  } catch (error) {
    yield put(actions.getSimilarPropertiesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchResidencesSearchSaga(action) {
  const { params } = action;

  try {
    yield put(setLoading(true));
    const data = yield call(API.fetchResidencesSearch, getQueryString(params));

    yield put(actions.fetchResidencesSearchSuccess(data));
  } catch (error) {
    yield put(actions.fetchResidencesSearchFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* postContactUs(action) {
  const { info, onSuccess, onError } = action.params;
  try {
    yield put(setLoading(true));
    yield Service.post(`/api/contacts`, info, status => {
      if (status === 200) onSuccess();
    });
  } catch (error) {
    const messageError = get(error, 'response.data.message', null);
    if (messageError) onError(messageError);
  } finally {
    yield put(setLoading(false));
  }
}

export function* residenceSaga() {
  yield takeEvery(types.GET_RESIDENCE_REQUEST, getResidence);
  yield takeEvery(types.GET_SIMILAR_PROPERTIES_REQUEST, getSimilarProperties);
  yield takeEvery(types.FETCH_RESIDECES_SEARCH_REQUEST, fetchResidencesSearchSaga);
  yield takeEvery(types.POST_CONTACT_US_REQUEST, postContactUs);
}
