import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { getPathLanguage } from '../../../utils/params';

const Breadcrumb = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);

  let textBreadcrumb = '';
  if (location.pathname.includes('useful-information/faq')) {
    textBreadcrumb = t('useful_page.faq_slide');
  } else if (location.pathname.includes('/useful-information/useful-service-apartment')) {
    textBreadcrumb = t('useful_page.renting_apartment_office');
  } else if (location.pathname.includes('/useful-information/our-customer-support-service')) {
    textBreadcrumb = t('useful_page.support_service');
  } else if (location.pathname.includes('/useful-information/useful-information-for-tenants')) {
    textBreadcrumb = t('useful_page.infomation_tenants');
  } else if (location.pathname.includes('/useful-information/rental-flow')) {
    textBreadcrumb = t('useful_page.rental_flow');
  }

  return (
    <div className="background-gray breadcrumb-useful">
      <div className="container">
        <div className="row breadcrumb">
          <div className="col-12">
            <div className="breadcrumb-item row">
              <ul>
                <li className="first">
                  <Link className="home" to={pathLanguage}>
                    {t('search_page.home')}
                  </Link>
                </li>
                <li className="item">
                  <span>
                    &#8226;&nbsp;&nbsp;&nbsp;
                    <Link to={`${pathLanguage}/useful-information/all`}> {t('navigation.usefull-information')}</Link>
                  </span>
                </li>
                <li className="item">
                  <span>
                    &#8226;&nbsp;&nbsp;&nbsp;<span className="active"> {textBreadcrumb}</span>
                  </span>
                </li>
              </ul>
            </div>
          </div>{' '}
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
