import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbAboutIsn, AboutIsnContent } from '../common';

const AboutIsnComponent = () => {
  const { t } = useTranslation();
  const breadCrumb = [{ title: t('about_isn.home'), url: '/' }, { title: t('about_isn.about_isn') }];

  return (
    <>
      <BreadcrumbAboutIsn breadCrumb={breadCrumb} />
      <AboutIsnContent />
    </>
  );
};

export default AboutIsnComponent;
