import React, { useState } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useTranslation } from 'react-i18next';

const MapComponent = props => {
  const [googleMarket, setGoogleMarket] = useState({ showingInfoWindow: false, activeMarker: {}, selectedPlace: {} });
  const { t } = useTranslation();
  const onMarkerClick = (props, marker) =>
    setGoogleMarket({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  const onMapClicked = props => {
    if (googleMarket.showingInfoWindow) {
      setGoogleMarket({ selectedPlace: props, showingInfoWindow: false, activeMarker: null });
    }
  };
  return (
    <div className="hidden-print">
      <h2 className="uppercase jp-bold-600">{t('project_page.maps')}</h2>
      <div style={{ height: 400 }}>
        {props.longitude && props.latitude ? (
          <Map
            google={props.google}
            zoom={14}
            initialCenter={{
              lat: props.latitude,
              lng: props.longitude,
            }}
            center={{
              lat: props.latitude,
              lng: props.longitude,
            }}
            style={{ height: 400, width: '96%' }}
            onClick={onMapClicked}
          >
            <Marker onClick={onMarkerClick} name={props.name || ''}
              position={{
                lat: props.latitude,
                lng: props.longitude,
              }}
            />
          </Map>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_KEY_GOOGLE_MAP,
})(MapComponent);
