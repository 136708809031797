import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './useful.action';
import * as types from './useful.types';
import * as API from './useful.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* getUsefulInformationSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getUsefulInformation, getQueryString(params));
    yield put(actions.getUsefulInformationSuccess(data));
  } catch (error) {
    yield put(actions.getUsefulInformationFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getAboutRentingFunc(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getAboutRenting, getQueryString(params));
    yield put(actions.getAboutRentingSuccess(data));
  } catch (error) {
    yield put(actions.getAboutRentingFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getTopicsApartmentSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getTopicsApartment, getQueryString(params));
    yield put(actions.getTopicsApartmentSuccess(data));
  } catch (error) {
    yield put(actions.getTopicsApartmentFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getDetailApartmentSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getDetailApartment, getQueryString(params));
    yield put(actions.getDetailApartmentSuccess(data));
  } catch (error) {
    yield put(actions.getDetailApartmentFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getListAreaSaga(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));
    const data = yield call(API.getUsefulInformation, getQueryString(params));
    yield put(actions.getListAreaSuccess(data));
  } catch (error) {
    yield put(actions.getListAreaFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* usefulInformationSaga() {
  yield takeEvery(types.GET_USEFUL_INFORMATION_REQUEST, getUsefulInformationSaga);
  yield takeEvery(types.GET_ABOUT_RENTING_REQUEST, getAboutRentingFunc);
  yield takeEvery(types.GET_TOPICS_APARTMENT_REQUEST, getTopicsApartmentSaga);
  yield takeEvery(types.GET_DETAIL_APARTMENT_REQUEST, getDetailApartmentSaga);
  yield takeEvery(types.GET_LIST_AREA_REQUEST, getListAreaSaga);
}
