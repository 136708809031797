import * as types from './blogs.types';

const initialState = {
  blogs: null,
  blog: null,
  otherBlogs: [],
  error: null,
  mainBlog: null,
};

const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_BLOGS_LIST_REQUEST:
      return { ...state };
    case types.FETCH_BLOGS_LIST_SUCCESS:
      return { ...state, blogs: action.data.blogs };
    case types.FETCH_BLOGS_LIST_FAILURE:
      return { ...state, error: action.error };

    case types.GET_MAIN_BLOGS_REQUEST:
      return { ...state };
    case types.GET_MAIN_BLOGS_SUCCESS:
      return { ...state, mainBlog: action.data.mainBlog };
    case types.GET_MAIN_BLOGS_FAILURE:
      return { ...state, error: action.error };

    case types.GET_BLOG_REQUEST:
      return { ...state };
    case types.GET_BLOG_SUCCESS:
      return { ...state, blog: action.data.blog };
    case types.GET_BLOG_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_OTHER_BLOGS_REQUEST:
      return { ...state };
    case types.FETCH_OTHER_BLOGS_SUCCESS:
      return { ...state, otherBlogs: action.data.otherBlogs };
    case types.FETCH_OTHER_BLOGS_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default blogsReducer;
