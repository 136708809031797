import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  //   UsefulAreaComponent,
  //   UsefulPetsComponent,
  //   SupportComponent,
  //   ServiceOfficeComponent,
  ServiceApartmentComponent,
  FaqsComponent,
} from '../pages';

import {
  getAboutRentingRequest,
  getUsefulInformationRequest,
  getTopicsApartmentRequest,
  getDetailApartmentRequest,
  getListAreaRequest,
} from '../useful.action';

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAboutRentingRequest,
      getUsefulInformationRequest,
      getTopicsApartmentRequest,
      getDetailApartmentRequest,
      getListAreaRequest,
    },
    dispatch,
  );

export default {
  //   UsefulAreaContainer: connect(mapStateToProps, mapDispatchToProps)(UsefulAreaComponent),
  //   UsefulPetsContainer: connect(mapStateToProps, mapDispatchToProps)(UsefulPetsComponent),
  //   SupportContainer: connect(mapStateToProps, mapDispatchToProps)(SupportComponent),
  //   ServiceOfficeContainer: connect(mapStateToProps, mapDispatchToProps)(ServiceOfficeComponent),
  ServiceApartmentContainer: connect(mapStateToProps, mapDispatchToProps)(ServiceApartmentComponent),
  FaqsContainer: connect(mapStateToProps, mapDispatchToProps)(FaqsComponent),
};
