import Service from '../../services';

export const fetchLastProperties = async params => {
  const response = await Service.get(`/api/properties/latest${params}`, res => res);
  return response;
};

export const fetchHotProperties = async params => {
  const response = await Service.get(`/api/properties/hot${params}`, res => res);
  return response;
};

export const fetchBlogs = async params => {
  const response = await Service.get(`/api/blogs/latest${params}`, res => res);
  return response;
};
