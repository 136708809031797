import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '../../../../layouts/commons/icons';

const UsefulWidgetLeft = ({ changeTopic, topics, topicActive }) => {
  const { t } = useTranslation();
  const renderData = useMemo(() => {
    if (topics && topics.length) {
      return topics.map((item, index) => {
        return (
          <div
            className={`widget-left-menu jp-400-sawarabi ${topicActive && item.id === topicActive.id ? 'actived' : ''}`}
            key={index}
            onClick={() => changeTopic(item)}
          >
            <div className="w-100 widget-animation">
              <span className="icon-chevron">
                <ChevronDownIcon />
              </span>
              <span className="widget-text">{item.title}</span>
            </div>
          </div>
        );
      });
    }
  }, [topics, changeTopic, topicActive]);

  return (
    <>
      <div className="widget-left jp-font-sawarabi-gothic">
        <h2 className="widget-title jp-bold-600">{t('useful_page.topics')}</h2>
        <div className="widget-border"></div>
        {renderData}
      </div>
    </>
  );
};

export default UsefulWidgetLeft;
