import React, { useState, useEffect } from 'react';
import BreadcrumbProject from '../common/breadcrumb/Breadcrumb.componnent';
import ProjecstList from '../common/projects/ProjecstList.component';
import { useLocation } from 'react-router-dom';
import { getLanguage } from '../../../utils/params';
import { get } from 'react-hook-form';

const Projects = props => {
  const language = getLanguage(useLocation());
  const [page, setPage] = useState({ pageIndex: 1, pageSize: 9 });
  const [cityId, setCityId] = useState('');

  useEffect(() => {
    const cityId = get(props, 'layout.cityActive.defaultLocationId', null) || localStorage.getItem('LOCATION_CITY');

    if (cityId) {
      setCityId(cityId);
      props.fetchListProjectsRequest({
        cityId,
        language,
        pageIndex: page.pageIndex,
        pageSize: page.pageSize,
      });
    }
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const changeItemInPage = data => {
    if (data && data.pageSize) {
      setPage({ pageIndex: 1, pageSize: data.pageSize });
    }

    if (data && data.pageIndex) {
      setPage({ ...page, pageIndex: data.pageIndex });
    }

    props.fetchListProjectsRequest({
      cityId,
      language,
      pageIndex: (data && data.pageIndex) || 1,
      pageSize: (data && data.pageSize) || page.pageSize,
    });
    window.scrollTo(0, 0);
  };

  const projects = get(props, 'projects.projects', []);

  return (
    <>
      <BreadcrumbProject data={projects} changeItemInPage={changeItemInPage} />
      <ProjecstList
        projects={projects}
        pageCount={projects && projects.pageCount ? projects.pageCount : 0}
        page={page}
        changeItemInPage={changeItemInPage}
      />
    </>
  );
};

export default Projects;
