import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import bannerUseful from '../../../assets/images/useful-banner.png';
import useful1 from '../../../assets/images/useful1.png';
import useful2 from '../../../assets/images/useful2.png';
import useful3 from '../../../assets/images/useful3.png';
import useful4 from '../../../assets/images/useful4.png';
import useful5 from '../../../assets/images/useful5.png';
import { getPathLanguage, getLanguage } from '../../../utils/params';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../../../utils/constants';

const HeadUseful = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const localeLanguage = getPathLanguage(location);
  const language = getLanguage(location);
  const arrayPath = location.pathname.split('/');
  const pathName = arrayPath.length === 4 ? arrayPath[3] : arrayPath[2];

  const menu = [
    {
      categoryName: t('useful_page.renting_apartment_office'),
      id: 'd1b78075-a5c7-a790-80dd-5f73f3d45a3e',
      ordering: 1,
      slug: 'about-renting-an-apartment-office',
      image: useful5,
      url: `${localeLanguage}/useful-information/useful-service-apartment`,
      tag: 'useful-service-apartment',
      isH1: location.pathname.endsWith('/useful-service-apartment') 
        || location.pathname.endsWith('/useful-service-apartment/'),
    },
    {
      categoryName: t('useful_page.support_service'),
      id: '35f24270-a2bd-1e0c-2c9d-5f3ce9bf2f03',
      ordering: 2,
      slug: 'our-customer-support-service',
      image: useful3,
      url: `${localeLanguage}/useful-information/our-customer-support-service`,
      tag: 'our-customer-support-service',
      isH1: true,
    },
    {
      categoryName: t('useful_page.infomation_tenants'),
      id: '75ee8356-cc33-c9e0-3d9e-5f616d030c3d',
      ordering: 3,
      slug: 'useful-information-for-tenants',
      image: useful2,
      url: `${localeLanguage}/useful-information/useful-information-for-tenants`,
      tag: 'useful-information-for-tenants',
      isH1: false,
    },
    {
      categoryName: t('useful_page.rental_flow'),
      id: '4d8bd68a-4837-13e9-8010-5f73ddfa6835',
      ordering: 4,
      slug: 'rental-flow',
      image: useful1,
      url: `${localeLanguage}/useful-information/rental-flow`,
      tag: 'rental-flow',
      isH1: true,
    },
    {
      categoryName: t('useful_page.faq_slide'),
      id: '5e322647-bf14-f392-74ad-5f3cea04e58f',
      ordering: 5,
      slug: 'faq',
      image: useful4,
      url: `${localeLanguage}/useful-information/faq`,
      tag: 'faq',
      isH1: false,
    }
  ];

  return (
    <>
      {!location.pathname.includes('/useful-information/all') && !isMobile ? (
        ''
      ) : (
        <div className="useful-banner">
          <div className="banner-wrap-text">
            <h1
              className="text-head jp-bold-700"
              dangerouslySetInnerHTML={{
                __html: t('useful_page.page_title') || '',
              }}
              style={{ fontFamily: `${language === 'en' ? 'Noto Serif' : ''}` }}
            ></h1>
            <div className="div-horizontal"></div>
            <div
              className="text-content jp-font-sawarabi-gothic"
              dangerouslySetInnerHTML={{
                __html: t('useful_page.page_subtitle') || '',
              }}
            ></div>
          </div>
          <img style={{ height: '100%', width: '100%' }} src={bannerUseful} alt="" />
        </div>
      )}
      <div className="container">
        <div className="slide-category d-none d-md-block">
          <div id="useful-position-scroll"></div>
          <div className="row">
            {menu && menu.length
              ? menu.map((item, index) => (
                  <div className="col-md menu-item" key={index}>
                    <Link to={item.url}>
                      <div
                        className={`slug ${
                          location.pathname.includes(item.tag) || (!isMobile && index === 0 && pathName === 'all')
                            ? 'actived'
                            : ''
                        } `}
                        slug={item.slug}
                      >
                        <img className="image" src={item.image} alt="" />
                        { location.pathname.includes(item.tag) && item.isH1
                          ? <h1 className="category-name">{item.categoryName}</h1>
                          : <span className="category-name">{item.categoryName}</span>
                        }
                      </div>
                    </Link>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>

      <div className="slide-category d-block d-md-none">
        <div className="container">
          <div className="row">
            {menu && menu.length
              ? menu.map((item, index) => (
                  <div className="col-12" key={index}>
                    <Link to={item.url}>
                      <div
                        className={`slug ${location.pathname.includes(item.tag) ? 'actived' : ''} `}
                        slug={item.slug}
                      >
                        <img className="image" src={item.image} alt="" />
                        <span className="category-name">{item.categoryName}</span>
                      </div>
                    </Link>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeadUseful;
