import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { fromPairs, get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ResetIcon, SearchIcon, CloseIcon } from '../../../layouts/commons/icons';
import { InputCustom, Select, Checkbox, RangeComponent } from '../../../components';
import { getLanguage, getPathLanguage } from '../../../utils/params';
import * as actions from '../../redux/layout.action';
import { formatMoney } from '../../../utils/objects';
import { getQueryString } from '../../../utils/params';
import { history } from '../../../utils/history';
import { fetchResidencesSearchRequest } from '../../../modules/residencePage/residence.action';

const SearchPopup = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const paramsUrl = useParams();
  const [cityId, setCityId] = useState('');
  const [isShowMore, setIsShowMore] = useState({ key: '', value: false });
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(useLocation());
  const [cityMap, setCityMap] = useState('');
  const wrapperRef = useRef(null);
  const [isChange, setIsChange] = useState(false);

  const urlParams = fromPairs(
    location.search
      .substring(1)
      .split('&')
      .map(item => item.split('=')),
  );

  const [unit, setRangeSlider] = useState(() => {
    return [
      urlParams.leasePriceMin && typeof parseInt(urlParams.leasePriceMin) === 'number'
        ? parseInt(urlParams.leasePriceMin)
        : 0,

      urlParams.leasePriceMax && typeof parseInt(urlParams.leasePriceMax) === 'number'
        ? parseInt(urlParams.leasePriceMax)
        : 5000,
    ];
  });

  const [keyWord, setKeyWord] = useState(urlParams.keyword ? decodeURIComponent(urlParams.keyword) : '');

  const [formData, setFormData] = useState(() => {
    const data = {};

    if (urlParams.language) data.language = urlParams.language;
    if (urlParams.pageIndex) data.pageIndex = parseInt(urlParams.pageIndex);
    if (urlParams.pageSize) data.pageSize = parseInt(urlParams.pageSize);
    data.propertyType = parseInt(urlParams.propertyType);
    data.districts = urlParams.districtId ? urlParams.districtId.replace(/%2C/gi, ',').split(',') : [];
    data.types = urlParams.type
      ? urlParams.type
          .replace(/%2C/gi, ',')
          .split(',')
          .map(i => parseInt(i))
      : [];
    data.sizes = urlParams.size
      ? urlParams.size
          .replace(/%2C/gi, ',')
          .split(',')
          .map(i => parseInt(i))
      : [];
    data.bedrooms = urlParams.bedroom
      ? urlParams.bedroom
          .replace(/%2C/gi, ',')
          .split(',')
          .map(i => parseInt(i))
      : [];
    data.facilities = urlParams.facilities ? urlParams.facilities.replace(/%2C/gi, ',').split(',') : [];
    data.projects = urlParams.project ? urlParams.project.replace(/%2C/gi, ',').split(',') : [];

    return data;
  });

  const [displayOption, setDisplayOption] = useState({
    districts: t('home_page.search.district'),
    bedrooms: t('home_page.search.bedroom'),
    projects: t('home_page.search.building_name'),
    sizes: t('home_page.search.size_range'),
    types: t('home_page.search.property_type'),
    facilities: t('home_page.search.facilities'),
  });

  const nonAccentVietnamese =(str)=> {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
}

  const handleClickOutside = e => {
    if (isShowMore.value) {
      if (e.target.className && !e.target.className.toString().includes('flag-dropdown')) {
        setIsShowMore({ key: '', value: false });
      }
    }
  };

  const handleClickMap = e => {
    if (e.target && e.target.nodeName && e.target.nodeName === 'path') {
      const parentDom = e.target.closest('[id]');
      if (parentDom && parentDom.id && parentDom.id !== 'Layer_1') {
        if (formData.districts.includes(parentDom.id)) {
          const cloneFormData = { ...formData };
          cloneFormData.districts = formData.districts.filter(i => i !== parentDom.id);
          const pathTagsInGTag = parentDom.querySelectorAll('path');
          pathTagsInGTag.forEach(element => element.removeAttribute('style'));
          setFormData(cloneFormData);
        } else {
          const cloneFormData = { ...formData };
          cloneFormData.districts = [...formData.districts, parentDom.id];
          const pathTagsInGTag = parentDom.querySelectorAll('path');
          pathTagsInGTag.forEach(element => {
            element.style.fill = '#b3091b';
          });
          setFormData(cloneFormData);
        }

        setIsChange(true);
      }
    }
  };

  const defaultLocationId = get(props, 'layout.cityActive.defaultLocationId', null);

  useEffect(() => {
    props.fetchListCondition({ language });
    if (!props.layout.range) props.fetchRange();

    if (urlParams.cityId) {
      setCityId(urlParams.cityId);
      props.fetchDistrictsByCityIdRequest({ cityId: urlParams.cityId, language });
    } else if (defaultLocationId) {
      setCityId(defaultLocationId);
      props.fetchDistrictsByCityIdRequest({ cityId: defaultLocationId, language });
    }

    const paramsProject = { language, cityId: urlParams.cityId || defaultLocationId, pageSize: 999, pageIndex: 1 };

    if (formData.districts && formData.districts.length) paramsProject.districtId = formData.districts.toString();

    props.fetchProjectsSearch(paramsProject);
    fetchUnitData(urlParams.cityId || defaultLocationId, formData, keyWord, { min: unit[0], max: unit[1] });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isShowMore.value) document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };

    // eslint-disable-next-line
  }, [isShowMore]);

  const fetchUnitData = (cityId, formData, keyWord, unit) => {
    const params = { language, propertyType: 0 };
    if (cityId) params.cityId = cityId;
    if (formData.districts && formData.districts.length) params.districtId = formData.districts.toString();
    if (keyWord) params.keyword = keyWord;
    if (formData.types && formData.types.length) params.type = formData.types.toString();
    if (formData.bedrooms && formData.bedrooms.length) params.bedroom = formData.bedrooms.toString();
    if (formData.sizes && formData.sizes.length) params.size = formData.sizes.toString();
    if (formData.projects && formData.projects.length) params.project = formData.projects.toString();

    if (formData.facilities && formData.facilities.length) {
      params.facilities = formData.facilities.toString();
    }

    params.leasePriceMin = unit.min || 0;
    params.leasePriceMax = unit.max;

    props.getUnitsRequest(params);
  };

  const onChangeKeyWord = value => {
    setKeyWord(value);
    setIsChange(true);
  };

  const onChangeFormData = (key, value) => {
    setIsChange(true);
    const cloneFormData = { ...formData };
    if (Object.keys(cloneFormData).includes(key) && value.length < 5) {
      if (cloneFormData[key] && cloneFormData[key].includes(parseInt(value))) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== parseInt(value));
      } else {
        cloneFormData[key].push(parseInt(value));
      }
    } else if (Object.keys(cloneFormData.facilities).includes(key)) {
      if (cloneFormData.facilities[key] && cloneFormData.facilities[key].includes(value)) {
        cloneFormData.facilities[key] = cloneFormData.facilities[key].filter(item => item !== value);
      } else {
        cloneFormData.facilities[key].push(value);
      }
    } else {
      if (cloneFormData[key].includes(value)) {
        cloneFormData[key] = cloneFormData[key].filter(item => item !== value);
      } else {
        cloneFormData[key].push(value);
      }
    }

    setFormData(cloneFormData);
  };

  const handleOnChange = value => {
    setIsChange(true);
    setCityId(value);
    setFormData({ ...formData, ...{ districts: [], projects: [] } });
    if (value) props.fetchDistrictsByCityIdRequest({ cityId: value, language });
    // else props.fetchDistrictsByCityIdRequest({ language });
    props.fetchProjectsSearch({
      language,
      cityId: value,
      districtId: formData.districts.toString(),
      pageSize: 999,
      pageIndex: 1,
    });
  };

  const propertiesType = get(props, 'officeDetail.detail.propertyType', null);

  const handleSubmit = e => {
    e.preventDefault();
    /* eslint-disable */
    const params = { ...formData, ...{ cityId, keyword: nonAccentVietnamese(keyWord), leasePriceMin: unit[0], leasePriceMax: unit[1] } };
    /* eslint-enable */
    const newParams = { ...params };

    if (params.bedrooms && params.bedrooms.length) newParams.bedroom = params.bedrooms.toString();
    if (params.districts && params.districts.length) newParams.districtId = params.districts.toString();
    if (params.types && params.types.length) newParams.type = params.types.toString();
    if (params.sizes && params.sizes.length) newParams.size = params.sizes.toString();
    if (params.facilities && params.facilities.length) newParams.facilities = params.facilities.toString();
    if (params.projects && params.projects.length) newParams.project = params.projects.toString();

    const paramSubmit = {};
    paramSubmit.language = newParams.language || language;

    if (urlParams.propertyType) paramSubmit.propertyType = newParams.propertyType;
    else if (propertiesType) paramSubmit.propertyType = propertiesType;

    if (newParams.keyword) paramSubmit.keyword = newParams.keyword;
    if (newParams.cityId) paramSubmit.cityId = newParams.cityId;
    if (newParams.districtId) paramSubmit.districtId = newParams.districtId;

    if (newParams.project) paramSubmit.project = newParams.project;

    if (newParams.type) paramSubmit.type = newParams.type;
    if (newParams.bedroom) paramSubmit.bedroom = newParams.bedroom;
    if (newParams.size) paramSubmit.size = newParams.size;
    if (newParams.facilities) paramSubmit.facilities = newParams.facilities;
    if (newParams.leasePriceMin) paramSubmit.leasePriceMin = newParams.leasePriceMin;
    else paramSubmit.leasePriceMin = 0;
    if (newParams.leasePriceMax) paramSubmit.leasePriceMax = newParams.leasePriceMax;
    // paramSubmit.pageIndex = newParams.pageIndex || 1;
    paramSubmit.pageIndex = 1;
    paramSubmit.pageSize = newParams.pageSize || 15;
    paramSubmit.sort = urlParams.sort || '-leasePriceMin';
    paramSubmit.propertyType = 0;
    const residenceDetail = location.pathname.includes(`${pathLanguage}/residence`) && paramsUrl.id;
    if (residenceDetail) {
      window.location.href = `${pathLanguage}/residences/search${getQueryString(paramSubmit)}`;
    } else {
      props.fetchResidencesSearchRequest(paramSubmit);
      history.push(`${pathLanguage}/residences/search${getQueryString(paramSubmit)}`);
    }

    props.onClosePopup();
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!formData.districts.length) {
        setFormData({ ...formData, projects: [] });
      }
      if (formData.districts.length && isShowMore.key === 'showMoreDistrict') {
        props.fetchProjectsSearch({
          language,
          cityId,
          districtId: formData.districts.toString(),
          pageSize: 999,
          pageIndex: 1,
        });
      }
    }, 1000);
    clearTimeout(handler);
    const mapDOM = document.getElementById('render_map');
    mapDOM.addEventListener('click', handleClickMap, true);

    if (cityMap) {
      const mapDOM = document.getElementById('render_map');
      const listGWrapper = mapDOM.querySelectorAll('g[id]');
      listGWrapper.forEach(gTag => {
        if (formData.districts.length && formData.districts.includes(gTag.id)) {
          const pathTagsInGTag = gTag.querySelectorAll('path');
          pathTagsInGTag.forEach(pathTag => {
            pathTag.style.fill = '#b3091b';
          });
        } else {
          const pathTagsInGTag = gTag.querySelectorAll('path');
          pathTagsInGTag.forEach(pathTag => {
            pathTag.removeAttribute('style');
          });
        }
      });
    }

    return () => {
      clearTimeout(handler);
      mapDOM.removeEventListener('click', handleClickMap, true);
    };

    // eslint-disable-next-line
  }, [formData, cityMap]);

  const resetForm = () => {
    const defaultFormData = {
      districts: [],
      bedrooms: [],
      projects: [],
      sizes: [],
      types: [],
      facilities: [],
    };
    setFormData(defaultFormData);
    setKeyWord('');
    setRangeSlider([0, 5000]);

    // if (defaultLocationId && cityId !== defaultLocationId) {
    //   setCityId(defaultLocationId);
    //   props.fetchDistrictsByCityIdRequest({ cityId: defaultLocationId, language });
    // }
    fetchUnitData(cityId, defaultFormData, '', { min: 0, max: 5000 });
  };

  const { cities, districts, projectsSearch } = props.layout;

  const { residencePriceMax, residencePriceMin, stepResidencePrice } = props.layout.range ? props.layout.range : {};

  const conditions = props.layout && props.layout.listCondition ? props.layout.listCondition : {};
  const { bedrooms, sizes, types, facilities } = conditions;
  const { buildingFacilities, interiorFacilities } = facilities || {};
  const listProjects = get(props, 'layout.projectsSearch.items', []);

  const returnTextOption = (option, listData, name, defaultText) => {
    if (option.length === 1) {
      const detail = listData.find(i => i.id === option[0]);
      return detail && detail[name] ? detail[name] : defaultText;
    } else if (option.length > 1) {
      return t('home_page.search.selected');
    }
    return defaultText;
  };

  useEffect(() => {
    const textDistrict =
      districts && districts.length
        ? returnTextOption(formData.districts, districts, 'districtName', t('home_page.search.district'))
        : t('home_page.search.district');
    const textTypes =
      types && types.length
        ? returnTextOption(formData.types, types, 'name', t('home_page.search.property_type'))
        : t('home_page.search.property_type');
    const textBedroom =
      bedrooms && bedrooms.length
        ? returnTextOption(formData.bedrooms, bedrooms, 'name', t('home_page.search.bedroom'))
        : t('home_page.search.bedroom');
    const textBuildingName =
      listProjects && listProjects.length
        ? returnTextOption(formData.projects, listProjects, 'projectName', t('home_page.search.building_name'))
        : t('home_page.search.building_name');
    const textSize =
      sizes && sizes.length
        ? returnTextOption(formData.sizes, sizes, 'name', t('home_page.search.size_range'))
        : t('home_page.search.size_range');

    let textFacilities = t('home_page.search.facilities');
    if (formData.facilities.length === 1) {
      let list = [];
      if (interiorFacilities && interiorFacilities.length && buildingFacilities && buildingFacilities.length) {
        list = [...interiorFacilities, ...buildingFacilities];
      }
      const detail = list.find(i => i.id === formData.facilities[0]);
      textFacilities = detail && detail.name ? detail.name : t('home_page.search.facilities');
    } else if (formData.facilities.length > 1) {
      textFacilities = t('home_page.search.selected');
    }

    setDisplayOption({
      ...displayOption,
      districts: textDistrict,
      bedrooms: textBedroom,
      projects: textBuildingName,
      sizes: textSize,
      types: textTypes,
      facilities: textFacilities,
    });

    const { cities } = props.layout;
    if (cities && cities.length) {
      const cityDefault = cities.find(i => i.cityId === cityId);
      setCityMap(cityDefault ? cityDefault.mapSVG : '');
    }

    let handlerUnit;
    if (isChange) {
      handlerUnit = setTimeout(() => {
        fetchUnitData(cityId, formData, keyWord, { min: unit[0], max: unit[1] });
      }, 1000);
    }

    return () => {
      clearTimeout(handlerUnit);
    };

    // eslint-disable-next-line
  }, [formData, cityId, unit, keyWord, isChange, types, bedrooms, sizes, buildingFacilities, interiorFacilities]);

  const listCitiesDropdown = useMemo(() => {
    if (cities && cities.length) {
      return (
        <Select
          defaultValue={cityId}
          options={cities.map(item => ({ value: item.cityId, label: item.cityName }))}
          class_name="form-control"
          name="location"
          onChange={handleOnChange}
        />
      );
    }

    return (
      <Select
        disabled
        options={[]}
        isAll={{ value: '', label: t('home_page.search.city') }}
        class_name="form-control"
      />
    );

    // eslint-disable-next-line
  }, [cities, cityId]);

  const listDistrictsDropdown = useMemo(() => {
    if (districts && districts.length) {
      return districts.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown sub-text" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'districts'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.districtName}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="districts"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.districts}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [districts, formData]);

  const listTypesDropdown = useMemo(() => {
    if (types && types.length) {
      return types.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'types'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.name}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="types"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.types}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [types, formData]);

  const listBedroomsDropdown = useMemo(() => {
    if (bedrooms && bedrooms.length) {
      return bedrooms.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'bedrooms'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.name}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="bedrooms"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.bedrooms}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [bedrooms, formData]);

  const listProjectsDropdown = useMemo(() => {
    if (listProjects && listProjects.length) {
      return listProjects.map((item, index) => (
        <div className="col-12 col-md-6 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'projects'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.projectName}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="projects"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.projects}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [formData, listProjects]);

  const listSizesDropdown = useMemo(() => {
    if (sizes && sizes.length) {
      return sizes.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'size'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.name}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="sizes"
            onChangeFormData={onChangeFormData}
            defaultValue={formData.sizes}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [sizes, formData]);

  const listBuildingsDropdown = useMemo(() => {
    if (buildingFacilities && buildingFacilities.length) {
      return buildingFacilities.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'buidling'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.name}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.facilities}
            onChangeFormData={onChangeFormData}
            formName="facilities"
          />
        </div>
      ));
    }
    // eslint-disable-next-line
  }, [buildingFacilities, formData]);

  const listInteriorDropdown = useMemo(() => {
    if (interiorFacilities && interiorFacilities.length) {
      return interiorFacilities.map((item, i) => (
        <div className="col-12 col-md-4 flag-dropdown" key={i}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'interior'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.name}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            defaultValue={formData.facilities}
            onChangeFormData={onChangeFormData}
            formName="facilities"
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [formData, interiorFacilities]);

  const handleClickOutsidePopup = event => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !event.target.className.toString().includes('flag-dropdown')
    )
      props.onClosePopup();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);

    return () => document.removeEventListener('mousedown', handleClickOutsidePopup);
    // eslint-disable-next-line
  }, [wrapperRef]);

  return (
    <div
      className={`modal fade show ${props.isShow ? 'show' : ''}`}
      id="searchReidenceModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="searchReidenceModallLabel"
      aria-hidden="true"
      style={{ display: props.isShow ? 'unset' : 'none' }}
    >
      <div className="modal-dialog modal-xl" role="document" ref={wrapperRef}>
        <div className="modal-content jp-font-sawarabi-gothic">
          <div className="modal-header">
            <h5 className="modal-title" id="searchReidenceModallLabel">
              {t('home_page.search.search')}
            </h5>
            <button
              onClick={() => props.onClosePopup()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="search-form mt-2" onSubmit={e => handleSubmit(e)}>
              <div className="group-control">
                <div className="row">
                  <div className="col-12 order-md-2 col-md-7 mb-1">
                    <div className={`slide-bar ${language === 'jp' ? 'slide-bar-jp' : ''}`}>
                      <div className="rent-slider">
                        <p className="rent">{t('home_page.search.rent')}</p>
                      </div>
                      <div className="slide-prices">
                        {/* <InputRange
                          className="slider min-slider"
                          maxValue={parseInt(residencePriceMax) || 5000}
                          minValue={parseInt(residencePriceMin) || 0}
                          formatLabel={value => `$${formatMoney(value, 0, 0)}`} //-> $4,3455
                          onChange={value => {
                            setIsChange(true);
                            if (value.min < parseInt(residencePriceMin)) value.min = parseInt(residencePriceMin);
                            else if (value.max > parseInt(residencePriceMax)) value.max = parseInt(residencePriceMax);
                            setRangeSlider(value);
                          }}
                          value={unit}
                          step={50}
                        /> */}
                        <RangeComponent
                          maxValue={parseInt(residencePriceMax) || 5000}
                          minValue={parseInt(residencePriceMin) || 0}
                          value={unit}
                          step={stepResidencePrice}
                          onChangeUnit={values => {
                            if (values[0] < values[1]) {
                              setIsChange(true);
                              setRangeSlider(values);
                            }
                          }}
                        />
                        <div className="slide-price-numner">
                          <span className="min">${residencePriceMin && formatMoney(residencePriceMin, 0, 0)}</span>
                          <span className="max float-right">
                            ${residencePriceMax && formatMoney(residencePriceMax, 0, 0)}
                            {unit.length && unit[1] === parseInt(residencePriceMax) ? '~' : ''}
                          </span>
                        </div>
                      </div>
                      <div className="units units-search-popup residence-search-popup d-none d-md-block">
                        <div className="search-popup-number">
                          {props.layout && props.layout.units ? props.layout.units : 0}
                        </div>
                        <div className="units-text">{t('home_page.search.units')}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-9 order-md-1 col-md-5 mb-1 search-name">
                    <InputCustom
                      class_name="form-control"
                      type="text"
                      placeholder={t('home_page.search.key_word_vinhomes')}
                      name="residence_keyword"
                      defaultValue={keyWord}
                      onChange={onChangeKeyWord}
                    />
                  </div>
                  <div className="col-3 d-block d-md-none pl-0">
                    <div className="units units-search-popup residence-search-popup text-right">
                      <div className="search-popup-number">
                        {props.layout && props.layout.units ? props.layout.units : 0}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">{listCitiesDropdown}</div>
                      <div className="col-12 col-md-6 mb-3">
                        <div
                          className={`form-control select-control${
                            displayOption.districts !== t('home_page.search.district') ? ' search-selected' : ''
                          }`}
                          id="facilities"
                          onClick={() => {
                            if (types && types.length) {
                              setIsShowMore({ key: 'showMoreDistrict', value: true });
                            }
                          }}
                        >
                          {displayOption.districts}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`facilities fade-in flag-dropdown ${
                        (isShowMore.key === 'showMoreDistrict' && isShowMore.value ? 'show' : '') || ''
                      }`}
                      id="fcDropDown"
                    >
                      <div className="row flag-dropdown">
                        <div className="col-10 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">{t('home_page.search.district')}</span>
                        </div>
                        <div className="col-2 text-right flag-dropdown">
                          <span
                            className="close-more flag-dropdown"
                            onClick={() => {
                              setIsShowMore({ key: 'showMoreDistrict', value: false });
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                      <div className="row building flag-dropdown">{listDistrictsDropdown}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <div
                          className={`form-control select-control${
                            displayOption.types !== t('home_page.search.property_type') ? ' search-selected' : ''
                          }`}
                          id="facilities"
                          onClick={() => {
                            if (types && types.length) {
                              setIsShowMore({ key: 'showMoreType', value: true });
                            }
                          }}
                        >
                          {displayOption.types}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <div
                          className={`form-control select-control ${
                            displayOption.bedrooms !== t('home_page.search.bedroom') ? ' search-selected' : ''
                          }`}
                          id="facilities"
                          onClick={() => {
                            if (bedrooms && bedrooms.length) {
                              setIsShowMore({ key: 'showMoreBedRoom', value: true });
                            }
                          }}
                        >
                          {displayOption.bedrooms}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`facilities add-width fade-in flag-dropdown ${
                        (isShowMore.key === 'showMoreType' && isShowMore.value ? 'show ' : '') || ''
                      }`}
                      id="fcDropDown"
                    >
                      <div className="row flag-dropdown">
                        <div className="col-10 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">
                            {t('home_page.search.property_type')}
                          </span>
                        </div>
                        <div className="col-2 text-right flag-dropdown">
                          <span
                            className="close-more flag-dropdown"
                            onClick={() => {
                              setIsShowMore({ key: 'showMoreType', value: false });
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                      <div className="row building flag-dropdown">{listTypesDropdown}</div>
                    </div>
                    <div
                      className={`facilities fade-in flag-dropdown ${
                        (isShowMore.key === 'showMoreBedRoom' && isShowMore.value ? 'show ' : '') || ''
                      }`}
                      id="fcDropDown"
                    >
                      <div className="row flag-dropdown">
                        <div className="col-10 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">{t('home_page.search.bedroom')}</span>
                        </div>
                        <div className="col-2 text-right flag-dropdown">
                          <span
                            className="close-more flag-dropdown"
                            onClick={() => {
                              setIsShowMore({ key: 'showMoreBedRoom', value: false });
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                      <div className="row building flag-dropdown">{listBedroomsDropdown}</div>
                    </div>
                  </div>
                </div>
                <div className="row more" id="moreConditions">
                  <div className="col-12 col-md-5 search-facilities">
                    <div
                      className={`form-control select-control${
                        displayOption.projects !== t('home_page.search.building_name') ? ' search-selected' : ''
                      }`}
                      id="facilities"
                      onClick={() => {
                        if (projectsSearch && projectsSearch.items && projectsSearch.items.length) {
                          setIsShowMore({ key: 'showMoreProject', value: true });
                        }
                      }}
                    >
                      {displayOption.projects}
                    </div>
                    <div
                      className={`facilities fade-in flag-dropdown sub-text  project-popups ${
                        (isShowMore.key === 'showMoreProject' && isShowMore.value ? 'show ' : '') || ''
                      }`}
                      id="fcDropDown"
                    >
                      <div className="row flag-dropdown">
                        <div className="col-10 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">
                            {t('home_page.search.building_name')}
                          </span>
                        </div>
                        <div className="col-2 text-right flag-dropdown">
                          <span
                            className="close-more flag-dropdown"
                            onClick={() => {
                              setIsShowMore({ key: 'showMoreProject', value: false });
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                      <div className="row building flag-dropdown">{listProjectsDropdown}</div>
                    </div>
                  </div>

                  <div className="col-12 col-md-7">
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">
                        <div
                          className={`form-control select-control${
                            displayOption.sizes !== t('home_page.search.size_range') ? ' search-selected' : ''
                          }`}
                          id="facilities"
                          onClick={() => {
                            if (sizes && sizes.length) {
                              setIsShowMore({ key: 'showMoreSize', value: true });
                            }
                          }}
                        >
                          {displayOption.sizes}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-3">
                        <div
                          className={`form-control select-control${
                            displayOption.facilities !== t('home_page.search.facilities') ? ' search-selected' : ''
                          }`}
                          id="facilities"
                          onClick={() => {
                            if (sizes && sizes.length) {
                              setIsShowMore({ key: 'showMoreFacilities', value: true });
                            }
                          }}
                        >
                          {displayOption.facilities}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`facilities fade-in flag-dropdown ${
                        (isShowMore.key === 'showMoreSize' && isShowMore.value ? 'show' : '') || ''
                      }`}
                      id="fcDropDown"
                    >
                      <div className="row flag-dropdown">
                        <div className="col-10 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">{t('home_page.search.size_range')}</span>
                        </div>
                        <div className="col-2 text-right flag-dropdown">
                          <span
                            className="close-more flag-dropdown"
                            onClick={() => {
                              setIsShowMore({ key: 'showMoreSize', value: false });
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                      <div className="row building flag-dropdown">{listSizesDropdown}</div>
                    </div>
                    <div
                      className={`facilities  add-width fade-in flag-dropdown ${
                        (isShowMore.key === 'showMoreFacilities' && isShowMore.value ? 'show' : '') || ''
                      }`}
                      id="fcDropDown"
                    >
                      <div className="row flag-dropdown">
                        <div className="col-10 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">
                            {t('home_page.search.building_facilities')}
                          </span>
                        </div>
                        <div className="col-2 text-right flag-dropdown">
                          <span
                            className="close-more flag-dropdown"
                            onClick={() => {
                              setIsShowMore({ key: 'showMoreFacilities', value: false });
                            }}
                          >
                            <CloseIcon />
                          </span>
                        </div>
                      </div>
                      <div className="row building flag-dropdown">{listBuildingsDropdown}</div>
                      <div className="row interior flag-dropdown">
                        <div className="col-12 flag-dropdown">
                          <span className="link-red uppercase flag-dropdown">
                            {t('home_page.search.interior_facilities')}
                          </span>
                        </div>
                      </div>
                      <div className="row flag-dropdown">{listInteriorDropdown}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-7 offset-md-5 d-block d-md-none">
                  <div className="row align-items-center advance-search">
                    <div className="col-6 col-md-3 mt-3 offset-md-6 btn-submit">
                      <button type="submit" className="button button-red button-hover modal-button">
                        <SearchIcon /> {t('home_page.search.text_btn_search')}
                      </button>
                    </div>
                    <div className="col-5 col-md-3 mt-3">
                      <button
                        onClick={() => resetForm()}
                        type="button"
                        className="button button-no-border button-grayish
                        button-transparent button-hover modal-button button-reset-popup"
                      >
                        <ResetIcon /> {t('home_page.search.reset_criteria')}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-7 offset-md-5 d-none d-md-block">
                  <div className="row align-items-center advance-search">
                    <div className="col-6 col-md-6 mt-3"></div>
                    <div className="col-6 col-md-6 mt-3">
                      <div className="row btn-popup-search">
                        <div className="col-6 col-md-7 btn-submit">
                          <button type="submit" className="button button-red button-hover modal-button">
                            <SearchIcon /> {t('home_page.search.text_btn_search')}
                          </button>
                        </div>
                        <div className="col-5 col-md-5 btn-reset">
                          <button
                            onClick={() => resetForm()}
                            type="button"
                            className="button button-no-border button-grayish
                            button-transparent button-hover modal-button button-reset-popup"
                          >
                            <ResetIcon /> {t('home_page.search.reset_criteria')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  id="render_map"
                  className="col-12 text-center"
                  dangerouslySetInnerHTML={{ __html: cityMap || '' }}
                ></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDistrictsByCityIdRequest: actions.fetchDistrictsByCityIdRequest,
      fetchListCondition: actions.fetchListConditionRequest,
      getUnitsRequest: actions.getUnitsRequest,
      fetchResidencesSearchRequest: fetchResidencesSearchRequest,
      fetchProjectsSearch: actions.fetchProjectsSearchRequest,
      fetchRange: actions.fetchRangeRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(SearchPopup);
