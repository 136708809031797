import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './blogs.action';
import * as types from './blogs.types';
import * as API from './blogs.api';
import { setLoading } from '../../layouts/loading/loading.action';
import { getQueryString } from '../../utils/params';

export function* fetchBlogsSagas(action) {
  const { params = {} } = action;
  try {
    yield put(setLoading(true));

    const blogs = yield call(API.fetchBlogs, getQueryString(params));
    yield put(actions.fetchBlogsSuccess({ blogs }));
  } catch (error) {
    yield put(actions.fetchBlogsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getMainBlogsSaga(action) {
  const { params = {} } = action;

  try {
    yield put(setLoading(true));

    const mainBlog = yield call(API.getMainBlog, getQueryString(params));

    yield put(actions.getMainBlogsSuccess({ mainBlog }));
  } catch (error) {
    yield put(actions.getMainBlogsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* getBlogSaga(action) {
  const { params = {} } = action;

  try {
    yield put(setLoading(true));

    const blog = yield call(API.getBlog, getQueryString(params));

    yield put(actions.getBlogSuccess({ blog }));
  } catch (error) {
    yield put(actions.getBlogFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* fetchOtherBlogsSaga(action) {
  const { params = {} } = action;

  try {
    yield put(setLoading(true));

    const otherBlogs = yield call(API.fetchOtherBlogs, getQueryString(params));

    yield put(actions.fetchOtherBlogsSuccess({ otherBlogs }));
  } catch (error) {
    yield put(actions.fetchOtherBlogsFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* blogsSaga() {
  yield takeEvery(types.FETCH_BLOGS_LIST_REQUEST, fetchBlogsSagas);
  yield takeEvery(types.GET_MAIN_BLOGS_REQUEST, getMainBlogsSaga);
  yield takeEvery(types.GET_BLOG_REQUEST, getBlogSaga);
  yield takeEvery(types.FETCH_OTHER_BLOGS_REQUEST, fetchOtherBlogsSaga);
}
