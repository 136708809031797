import React, { Fragment, useEffect } from 'react';
import { OfficeImage, DetailsInfo, Lists, Description, Map, ButtonNextPrev } from './details/';
import { getPathLanguage } from '../../../utils/params';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

const DetailsComponent = props => {
  const pathLanguage = getPathLanguage(useLocation());
  const history = useHistory();
  const dataHistory = localStorage.getItem('HISTORY') ? localStorage.getItem('HISTORY').split(',') : [];
  let resultSearch = [];
  if ('resultOfficeSearch' in localStorage) {
    resultSearch = JSON.parse(localStorage.getItem('resultOfficeSearch'));
  }
  const handleClickNext = (id, action) => {
    if (resultSearch.length != 0) {
      const index = resultSearch.findIndex(x => x.id == id);
      let idNext = 0;
      let slugItem = '';
      if (index === 0 && action == "PREV") {
        const resultSearchLength = resultSearch.length;
        idNext = resultSearch[resultSearchLength - 1].id;
        slugItem = resultSearch[resultSearchLength - 1].slug;
      } else if (index === (resultSearch.length - 1) && action == "NEXT") {
        idNext = resultSearch[0].id;
        slugItem = resultSearch[0].slug;
      } else {
        if (action == "NEXT") {
          idNext = resultSearch[index + 1].id;
          slugItem = resultSearch[index + 1].slug;
        } else if (action == "PREV") {
          idNext = resultSearch[index - 1].id;
          slugItem = resultSearch[index - 1].slug;
        }
      }
      history.push(`${pathLanguage}/office/detail/${idNext}/${slugItem}`);
    }
  };
  useEffect(() => {
    if (props.data && props.data.id) {
      const { id } = props.data;
      let dataHistoryClone = dataHistory;
      if (!dataHistory.includes(id)) dataHistoryClone = [...dataHistoryClone, id];

      localStorage.setItem('HISTORY', dataHistoryClone);
    }

    // eslint-disable-next-line
  }, [dataHistory]);

  const { residencesSearch } = props.residence;
  useEffect(() => {
    if (residencesSearch?.items.length > 0) {
      localStorage.setItem('resultOfficeSearch', JSON.stringify(residencesSearch?.items))
    }
  }, [residencesSearch])

  return (
    <Fragment>
      {resultSearch.length > 0 &&
        <div className="background-gray pt-4">
          <ButtonNextPrev onHandleChange={handleClickNext} dataDetai={props.data} />
        </div>
      }
      <div className="section-block background-gray pt-4">
        <div className="container">
          <div className="post-details jp-font-sawarabi-gothic">
            <div className="row mb-5">
              <div className="col-12 col-md-4">
                <OfficeImage imgSrc={props.data && props.data.image} />
              </div>
              <div className="col-12 col-md-8 summary">
                <DetailsInfo data={props.data || null} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-8 mb-3 block-content">
                {props.data.videoLink ? (
                  <div className="properties-detail-video">
                    <iframe
                      className="video-iframe"
                      width="100%"
                      height={502}
                      src={`https://www.youtube.com/embed/${props.data.videoLink}`}
                      frameBorder={0}
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="frame"
                    />
                  </div>
                ) : (
                  ''
                )}
                <Description content={(props.data && props.data.description) || ''} />
                <Lists
                  services={props.data && props.data.funitures && props.data.funitures.length ?
                    props.data.funitures : []}
                />
                {/* <Project link={(props.data && props.data.projectName) || ''} /> */}
                <Map
                  longitude={(props.data && props.data.longitude) || null}
                  latitude={(props.data && props.data.latitude) || null}
                  name={(props.data && props.data.name) || ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  ...state,
});

export default connect(mapStateToProps)(DetailsComponent);
