import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fromPairs, get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { Select, InputCustom, Checkbox, RangeComponent } from '../../../components';
import * as actions from '../../redux/layout.action';
import { fetchResidencesSearchRequest } from '../../../modules/residencePage/residence.action';
import { CloseIcon, ResetIcon, SearchIcon } from '../icons';
import { history } from '../../../utils/history';
import { formatMoney } from '../../../utils/objects';
import { getLanguage, getPathLanguage, getQueryString } from '../../../utils/params';

const SearchOfficesPopup = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(useLocation());
  const [isShowMore, setIsShowMore] = useState({ key: '', value: false });
  const wrapperRef = useRef(null);

  const [urlParams] = useState(
    fromPairs(
      location.search
        .substring(1)
        .split('&')
        .map(item => item.split('=')),
    ) || '',
  );
  const [unitSize, setUnitSize] = useState(() => {
    return [
      urlParams.officeSizeFrom && typeof parseInt(urlParams.officeSizeFrom) === 'number'
        ? parseInt(urlParams.officeSizeFrom)
        : 0,
      urlParams.officeSizeTo && typeof parseInt(urlParams.officeSizeTo) === 'number'
        ? parseInt(urlParams.officeSizeTo)
        : 3000,
    ];
  });
  const [unitPrice, setUnitPrice] = useState(() => {
    return urlParams.officePriceSearchType && parseInt(urlParams.officePriceSearchType) === 1
      ? [
          urlParams.leasePriceMin && typeof parseInt(urlParams.leasePriceMin) === 'number'
            ? parseInt(urlParams.leasePriceMin)
            : 0,
          urlParams.leasePriceMax && typeof parseInt(urlParams.leasePriceMax) === 'number'
            ? parseInt(urlParams.leasePriceMax)
            : 100,
        ]
      : [0, 100];
  });
  const [budgetSize, setBudgetSize] = useState(() => {
    return urlParams.officePriceSearchType && parseInt(urlParams.officePriceSearchType) === 2
      ? [
          urlParams.leasePriceMin && typeof parseInt(urlParams.leasePriceMin) === 'number'
            ? parseInt(urlParams.leasePriceMin)
            : 0,
          urlParams.leasePriceMax && typeof parseInt(urlParams.leasePriceMax) === 'number'
            ? parseInt(urlParams.leasePriceMax)
            : 30000,
        ]
      : [0, 30000];
  });
  const [togglePrice, setTogglePrice] = useState(() => {
    return urlParams.officePriceSearchType && parseInt(urlParams.officePriceSearchType) === 2 ? 'budget' : 'price';
  });

  const handleChangeUnitBudget = value => setTogglePrice(value);

  const options = [
    { label: t('home_page.search.unit_price'), value: 'price' },
    { label: t('home_page.search.total_budget'), value: 'budget' },
  ];

  const [cityId, setCityId] = useState(
    urlParams.cityId
      ? urlParams.cityId
      : props.layout.cityActive && props.layout.cityActive.defaultLocationId
      ? props.layout.cityActive.defaultLocationId
      : '',
  );
  const [keyWord, setKeyWord] = useState(() => {
    return urlParams.keyword ? decodeURIComponent(urlParams.keyword) : '';
  });
  const [districtId, setDistrictId] = useState(() => {
    return urlParams.districtId ? urlParams.districtId.replace(/%2C/gi, ',').split(',') : [];
  });

  const nonAccentVietnamese =(str)=> {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  }

  const handleClickOutside = e => {
    if (isShowMore.value) {
      if (e.target.className && !e.target.className.toString().includes('flag-dropdown')) {
        setIsShowMore({ key: '', value: false });
      }
    }
  };

  useEffect(() => {
    if (isShowMore.value) document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line
  }, [isShowMore]);

  useEffect(() => {
    props.fetchListCondition({ language });
    if (!props.layout.range) props.fetchRange();

    if (cityId) {
      props.fetchDistrictsByCityIdRequest({ cityId: cityId, language });
    } else if (props.layout.cityActive && props.layout.cityActive.defaultLocationId) {
      setCityId(props.layout.cityActive.defaultLocationId);
      props.fetchDistrictsByCityIdRequest({ cityId: props.layout.cityActive.defaultLocationId, language });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let handlerUnit = setTimeout(() => {
      const params = { language, propertyType: 2 };
      if (cityId) params.cityId = cityId;
      if (districtId.length) params.districtId = districtId.toString();

      if (togglePrice === 'price') {
        params.officePriceSearchType = 1;
        if (unitPrice.length && unitPrice[0]) params.leasePriceMin = unitPrice[0];
        else params.leasePriceMin = unitPrice[0] || 0;
        if (unitPrice.length && unitPrice[1]) params.leasePriceMax = unitPrice[1];
        else params.leasePriceMax = 100;
      } else if (togglePrice === 'budget') {
        params.officePriceSearchType = 2;
        if (budgetSize.length && budgetSize[0]) params.leasePriceMin = budgetSize[0];
        else params.leasePriceMin = budgetSize[0] || 0;
        if (budgetSize.length && budgetSize[1]) params.leasePriceMax = budgetSize[1];
        else params.leasePriceMax = 30000;
      }

      if (keyWord) params.keyword = keyWord;
      if (unitSize.length && unitSize[0]) params.officeSizeFrom = unitSize[0];
      else params.officeSizeFrom = unitSize[0] || 0;
      if (unitSize.length && unitSize[1]) params.officeSizeTo = unitSize[1];
      else params.officeSizeTo = 3000;

      props.getUnitsRequest(params);
    }, 1000);
    // clearTimeout(handlerUnit);
    return () => {
      clearTimeout(handlerUnit);
    };

    // eslint-disable-next-line
  }, [unitPrice, budgetSize, unitSize, districtId, cityId, togglePrice, keyWord]);

  const handleOnChange = value => {
    setCityId(value);
    setDistrictId([]);
    if (value) props.fetchDistrictsByCityIdRequest({ cityId: value, language });
    else props.fetchDistrictsByCityIdRequest({ language });
  };

  const onChangeDistrict = (key, value) => {
    let cloneFormData = [...districtId];
    if (districtId.includes(value)) {
      cloneFormData = districtId.filter(item => item !== value);
    } else {
      cloneFormData.push(value);
    }
    setDistrictId(cloneFormData);
  };

  const onChangeKeyWord = value => setKeyWord(value);

  const propertiesType = get(props, 'officeDetail.detail.propertyType', null);

  const handleSubmit = e => {
    e.preventDefault();

    const paramSubmit = {};
    paramSubmit.language = urlParams.language || language;
    if (urlParams.propertyType) paramSubmit.propertyType = urlParams.propertyType;
    else if (propertiesType) paramSubmit.propertyType = propertiesType;

    if (keyWord) paramSubmit.keyword = nonAccentVietnamese(keyWord);
    if (cityId) paramSubmit.cityId = cityId;
    if (districtId && districtId.length) paramSubmit.districtId = districtId.toString();

    if (togglePrice === 'price') {
      paramSubmit.officePriceSearchType = 1;
      paramSubmit.leasePriceMin = unitPrice.length && unitPrice[0] ? unitPrice[0] : 0;
      paramSubmit.leasePriceMax = unitPrice.length && unitPrice[1] ? unitPrice[1] : 100;
    } else if (togglePrice === 'budget') {
      paramSubmit.officePriceSearchType = 2;
      paramSubmit.leasePriceMin = budgetSize.length && budgetSize[0] ? budgetSize[0] : 0;
      paramSubmit.leasePriceMax = budgetSize.length && budgetSize[1] ? budgetSize[1] : 30000;
    }

    paramSubmit.officeSizeFrom = unitSize.length && unitSize[0] ? unitSize[0] : 0;
    paramSubmit.officeSizeTo = unitSize.length && unitSize[1] ? unitSize[1] : 3000;

    // paramSubmit.pageIndex = parseInt(urlParams.pageIndex || 1);
    paramSubmit.pageIndex = 1;
    paramSubmit.pageSize = parseInt(urlParams.pageSize || 16);
    paramSubmit.sort = urlParams.sort || '-leasePriceMin';

    const isOfficeSearch = location.pathname.includes(`${pathLanguage}/offices/search`);
    paramSubmit.propertyType = 2;
    if (isOfficeSearch) {
      props.fetchResidencesSearchRequest(paramSubmit);
      history.push(`${pathLanguage}/offices/search${getQueryString(paramSubmit)}`);
    } else {
      window.location.href = `${pathLanguage}/offices/search${getQueryString(paramSubmit)}`;
    }

    props.onClosePopup();
  };

  const resetForm = () => {
    setDistrictId([]);
    setKeyWord('');

    setUnitSize([0, 3000]);
    setBudgetSize([0, 30000]);
    setUnitPrice([0, 100]);
    setTogglePrice('price');

    // if (
    //   props.layout.cityActive &&
    //   props.layout.cityActive.defaultLocationId &&
    //   cityId !== props.layout.cityActive.defaultLocationId
    // ) {
    //   setCityId(props.layout.cityActive.defaultLocationId);
    //   props.fetchDistrictsByCityIdRequest({ cityId: props.layout.cityActive.defaultLocationId, language });
    // }
    // setKeyWord('')
  };

  const { cities, districts } = props.layout;

  const {
    officeBudgetMax,
    officeBudgetMin,
    officePriceMax,
    officePriceMin,
    officeSizeMax,
    officeSizeMin,
    stepOfficeBudget,
    stepOfficePrice,
    stepOfficeSize,
  } = props.layout.range ? props.layout.range : {};

  const listCitiesDropdown = useMemo(() => {
    if (cities && cities.length) {
      return (
        <Select
          defaultValue={cityId}
          options={cities.map(item => ({ value: item.cityId, label: item.cityName }))}
          class_name="form-control"
          name="location"
          onChange={handleOnChange}
        />
      );
    }
    return (
      <Select
        disabled
        options={[]}
        isAll={{ value: '', label: t('home_page.search.city') }}
        class_name="form-control"
      />
    );

    // eslint-disable-next-line
  }, [cities, cityId]);

  const listOptionsCheck = useMemo(() => {
    if (options && options.length) {
      return options.map((item, index) => (
        <div className={`radio-item form-item`} key={index}>
          <label htmlFor={`chk-${index}`}>
            <input
              type="radio"
              id={`chk-${index}`}
              name="unit_budget"
              value={item.value}
              checked={item.value === togglePrice}
              onChange={event => handleChangeUnitBudget(event.target.value)}
            />
            <span htmlFor={`chk-${index}`}>{item.label}</span>
          </label>
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [options]);

  const listDistrictsDropdown = useMemo(() => {
    if (districts && districts.length) {
      return districts.map((item, index) => (
        <div className="col-12 col-md-4 flag-dropdown sub-text" key={index}>
          <Checkbox
            class_name="form-control flag-dropdown"
            name={'districts'}
            id={`${Math.random().toString(16).slice(2)}`}
            label={item.districtName}
            value={item.id}
            checkItemClass="form-item"
            multiple={true}
            formName="districts"
            onChangeFormData={onChangeDistrict}
            defaultValue={districtId}
          />
        </div>
      ));
    }

    // eslint-disable-next-line
  }, [districts, districtId]);

  const handleClickOutsidePopup = event => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target) &&
      !event.target.className.toString().includes('flag-dropdown')
    )
      props.onClosePopup();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);

    return () => document.removeEventListener('mousedown', handleClickOutsidePopup);
    // eslint-disable-next-line
  }, [wrapperRef]);

  const textDistrict = useMemo(() => {
    if (districtId.length === 1) {
      const detail = districts.find(i => i.id === districtId[0]);
      return detail && detail.districtName ? detail.districtName : t('home_page.search.district');
    } else if (districtId.length > 1) {
      return t('home_page.search.selected');
    }

    return t('home_page.search.district');
  }, [districts, districtId, t]);

  const renderRange = () => {
    return togglePrice === 'price' ? (
      <div className={`row conditions ${togglePrice === 'price' ? '' : 'd-none'}`} id="filterBudget">
        <div className="col-12 mb-3">
          <div className="slide-bar slide-bar-office">
            <div className="slide-prices">
              <RangeComponent
                maxValue={parseInt(officePriceMax) || 100}
                minValue={parseInt(officePriceMin) || 0}
                value={unitPrice}
                step={stepOfficePrice}
                onChangeUnit={values => {
                  if (values[0] < values[1]) {
                    // setBudgetSize([0, 30000]);
                    setUnitPrice(values);
                  }
                }}
              />

              <div className="slide-price-numner">
                <span className="min bubble d-none d-md-block" id="minBubble">
                  ${officePriceMin && formatMoney(officePriceMin, 0, 0)}
                </span>
                <span id="maxBubble" className="max bubble float-right d-none d-md-block">
                  ${officePriceMax && formatMoney(officePriceMax, 0, 0)}
                  {unitPrice.length && unitPrice[1] === parseInt(officePriceMax) ? '~' : ''}
                </span>
                <span id="maxBubble" className="max bubble float-right d-block d-md-none">
                  ($/m<sup>2</sup>/{t('home_page.partial.month')})
                </span>
              </div>
            </div>
            <div className="m2-month d-none d-md-block">
              ($/m<sup>2</sup>/{t('home_page.partial.month')})
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={`row conditions ${togglePrice === 'budget' ? '' : 'd-none'}`} id="filterBudget">
        <div className="col-12 mb-3">
          <div className="slide-bar slide-bar-office">
            <div className="slide-prices">
              <RangeComponent
                maxValue={parseInt(officeBudgetMax) || 30000}
                minValue={parseInt(officeBudgetMin) || 0}
                value={budgetSize}
                step={stepOfficeBudget}
                onChangeUnit={values => {
                  if (values[0] < values[1]) {
                    // setUnitPrice([0, 100]);
                    setBudgetSize(values);
                  }
                }}
              />
              <div className="slide-price-numner">
                <span className="min d-none d-md-block">${officeBudgetMin && formatMoney(officeBudgetMin, 0, 0)}</span>
                <span className="max float-right d-none d-md-block">
                  ${officeBudgetMax && formatMoney(officeBudgetMax, 0, 0)}
                  {budgetSize.length && budgetSize[1] === parseInt(officeBudgetMax) ? '~' : ''}
                </span>
                <span className="max float-right d-block d-md-none">($/{t('home_page.partial.month')})</span>
              </div>
            </div>
            <div className="m2-month d-none d-md-block">($/{t('home_page.partial.month')})</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`modal fade ${props.isShow ? 'show' : ''}`}
      id="searchOfficeModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="searchOfficeModallLabel"
      aria-hidden="true"
      style={{ display: props.isShow ? 'unset' : 'none' }}
    >
      <div className="modal-dialog modal-xl" role="document" ref={wrapperRef}>
        <div className="modal-content jp-font-sawarabi-gothic">
          <div className="modal-header">
            <h5 className="modal-title" id="searchOfficeModallLabel">
              {t('home_page.search.search')}
            </h5>
            <button
              onClick={() => props.onClosePopup()}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form className="search-form mt-2" onSubmit={e => handleSubmit(e)}>
              <div className="group-control">
                <div className="row">
                  <div className="col-12 col-md-5">
                    <div className="row">
                      <div className="col-9 col-md-12 mb-3">
                        <InputCustom
                          class_name="form-control"
                          type="text"
                          placeholder={t('home_page.search.hint_popup_office')}
                          name="residence_keyword"
                          defaultValue={keyWord}
                          onChange={onChangeKeyWord}
                        />
                      </div>
                      <div className="col-3 d-block d-md-none pl-0">
                        <div className="units units-search-popup office-search-popup text-right">
                          <div>{props.layout && props.layout.units ? props.layout.units : 0}</div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 mb-3">{listCitiesDropdown}</div>
                      <div className="col-12 col-md-6 mb-3">
                        <div
                          className={`form-control select-control${
                            textDistrict !== t('home_page.search.district') ? ' search-selected' : ''
                          }`}
                          id="facilities"
                          onClick={() => {
                            setIsShowMore({ key: 'showMoreDistrict', value: true });
                          }}
                        >
                          {textDistrict}
                        </div>
                      </div>
                      <div
                        className={`office-search-modal facilities fade-in flag-dropdown ${
                          (isShowMore.key === 'showMoreDistrict' && isShowMore.value ? 'show' : '') || ''
                        }`}
                        id="fcDropDown"
                      >
                        <div className="row flag-dropdown">
                          <div className="col-10 flag-dropdown">
                            <span className="link-red uppercase flag-dropdown">{t('home_page.search.district')}</span>
                          </div>
                          <div className="col-2 text-right flag-dropdown">
                            <span
                              className="close-more flag-dropdown"
                              onClick={() => {
                                setIsShowMore({ key: 'showMoreDistrict', value: false });
                              }}
                            >
                              <CloseIcon />
                            </span>
                          </div>
                        </div>
                        <div className="row building flag-dropdown district-popup-search-office">
                          {listDistrictsDropdown}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-7">
                    <div className="row">
                      <div className="col-12 mb-3 offices-size">
                        <div className="slide-bar slide-bar-square">
                          <div className="slide-prices area-icon">
                            <RangeComponent
                              maxValue={parseInt(officeSizeMax) || 3000}
                              minValue={parseInt(officeSizeMin) || 0}
                              value={unitSize}
                              step={stepOfficeSize}
                              onChangeUnit={values => {
                                if (values[0] < values[1]) {
                                  setUnitSize(values);
                                }
                              }}
                              isPrice={true}
                            />
                            {/* <div className="slide-price-numner">
                              <span className="min bubble" id="minBubble">
                                {officeSizeMin && formatMoney(officeSizeMin, 0, 0)}m<sup>2</sup>
                              </span>
                              <span id="maxBubble" className="max bubble">
                                {officeSizeMax && formatMoney(officeSizeMax, 0, 0)}m<sup>2</sup>
                              </span>
                            </div> */}
                            <div className="mt-4"></div>
                          </div>
                          <div className="units units-search-popup office-search-popup d-none d-md-block">
                            <div>{props.layout && props.layout.units ? props.layout.units : 0}</div>
                            <div className="units-text">{t('home_page.search.units')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="row conditions" id="filterPrice"> */}
                    {renderRange()}
                    {/* </div> */}

                    <div className="row">
                      <div className="col-12">
                        <div className="row align-items-center" id="officePriceBugget">
                          <div className="col-12 col-md-6 mb-2">
                            <div className="unit-price-total-budget">{listOptionsCheck}</div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="row btn-popup-search">
                              <div className="col-6 col-md-7 btn-submit">
                                <button type="submit" className="button button-red button-hover modal-button">
                                  <SearchIcon /> {t('home_page.search.text_btn_search')}
                                </button>
                              </div>
                              <div className="col-5 col-md-5 btn-reset">
                                <button
                                  onClick={() => resetForm()}
                                  type="button"
                                  className="button button-no-border button-grayish
                                  button-transparent button-hover modal-button button-reset-popup"
                                >
                                  <ResetIcon /> {t('home_page.search.reset_criteria')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDistrictsByCityIdRequest: actions.fetchDistrictsByCityIdRequest,
      fetchListCondition: actions.fetchListConditionRequest,
      getUnitsRequest: actions.getUnitsRequest,
      fetchResidencesSearchRequest: fetchResidencesSearchRequest,
      fetchRange: actions.fetchRangeRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(SearchOfficesPopup);
