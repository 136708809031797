import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FacebookShareButton } from 'react-share';
import { useLocation } from 'react-router-dom';
import EventBus from 'eventing-bus';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Location, SizeIcon, EmailIcon, FloorArea, HeartIcon, Printer } from '../../../../layouts/commons/icons';
import ModalComponent from '../../../residencePage/pages/details/Modal.component';
import fb from '../../../../assets/images/bxl-facebook-circle.svg';
import BuildingStorey from '../../../../assets/images/BuildingStorey.svg';
import CompletionYear from '../../../../assets/images/CompletionYear.svg';
import WorkingHours from '../../../../assets/images/WorkingHours.svg';
import { formatMoney } from '../../../../utils/objects';
import { getLanguage } from '../../../../utils/params';

const ResidenceInfo = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const [modalShow, setModalShow] = useState(false);
  const { data } = props;
  const { t } = useTranslation();
  const urlShare = data ? `${process.env.REACT_APP_URL}${location.pathname}` : process.env.REACT_APP_URL;

  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const listResidencesLike = localStorage.getItem('OFFICES_LIKE')
      ? localStorage.getItem('OFFICES_LIKE').split(',')
      : [];

    if (listResidencesLike.length && data && data.id) {
      setLiked(listResidencesLike.includes(data.id));
    }

    // eslint-disable-next-line
  }, [data]);

  const handleLikePost = () => {
    if (data && data.id) {
      const dataLocal = localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : [];
      let listData = [];
      if (dataLocal.includes(data.id)) {
        listData = dataLocal.filter(i => i !== data.id);
        setLiked(false);
      } else {
        listData = [...dataLocal, data.id];
        setLiked(true);
      }
      localStorage.setItem('OFFICES_LIKE', listData);

      EventBus.publish('RESET_LIKE', true);
    }
  };

  const showModel = isShow => {
    EventBus.publish('checkBodyScroll', isShow);
    setModalShow(isShow);
  };

  return (
    <>
      <div className="box-white">
        <div className="row code-created">
          <div className="col-5 col-md-6">
            <span className="link-red">{data && data.propertyId}</span>
          </div>
          <div className="col-7 col-md-6 text-right">
            <span className="link-gray created date-update">
              {t('residence_detail.last_update')}:{' '}
              {data && data.lastUpdateDate
                ? language === 'en'
                  ? moment(data.lastUpdateDate).format('MMM DD, YYYY')
                  : moment(data.lastUpdateDate).format('YYYY-MM-DD')
                : ''}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <h1 className="post-title jp-bold-700">{data && data.name}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {data && (data.priceFrom || data.priceTo) ? (
              <div className="price-details link-red">
                <span>
                  ${data && data.priceFrom} {data && data.priceTo ? ` ~ $${data.priceTo}` : ''}
                </span>
                <span className="office-detail-unit">
                  /m<sup>2</sup>/{t('home_page.partial.month')}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tool-bar">
              <span className={`favourite jp-300-sawarabi ${liked ? 'actived' : ''} `} onClick={() => handleLikePost()}>
                <HeartIcon /> {t('residence_detail.favourite')}
              </span>
              <span
                className="print jp-300-sawarabi"
                onClick={() => {
                  window.print();
                }}
              >
                <Printer /> {t('residence_detail.print')}
              </span>
              <FacebookShareButton url={urlShare} quote={''} hashtag={''}>
                <span className="share icon-face jp-300-sawarabi">
                  <LazyLoadImage alt="" src={fb} effect="blur" /> {t('residence_detail.share')}
                </span>
              </FacebookShareButton>
            </div>
            <hr className="office-detail-line" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="facilities-bar">
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">
                  <span>
                    <Location />
                  </span>
                  {t('office_detail.location')}
                </span>
                {data && data.district} {language === 'en' ? 'District' : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">
                  <span>
                    <SizeIcon />
                  </span>
                  {t('office_detail.size')}
                </span>
                {data && (data.size || data.sizeMax) ? formatMoney(data.size, 0, 0) : ''}
                {data && data.sizeMax ? ' ∼ ' : ''}
                {data && data.sizeMax ? formatMoney(data.sizeMax, 0, 0) : ''}
                {data && (data.size || data.sizeMax) ? (
                  <>
                    m<sup>2</sup>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title">
                  <span>
                    <FloorArea />
                  </span>
                  {t('office_detail.floor_area')}
                </span>
                {data && data.floorArea ? formatMoney(data.floorArea, 0, 0) : ''}m<sup>2</sup>
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title">
                  <span>
                    {/* <img alt="" src={BuildingStorey} /> */}
                    <LazyLoadImage alt="" src={BuildingStorey} effect="blur" />
                  </span>
                  {t('office_detail.building_storey')}
                </span>
                {data && data.storey} {data && data.storey ? t('office_detail.storeys') : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title">
                  <span>
                    {/* <img alt="" src={CompletionYear} /> */}
                    <LazyLoadImage alt="" src={CompletionYear} effect="blur" />
                  </span>
                  {t('office_detail.completion_year')}
                </span>
                {data && data.completionYear}
                {language === 'jp' && data && data.completionYear ? '年' : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title">
                  <span>
                    {/* <img alt="" src={WorkingHours} /> */}
                    <LazyLoadImage alt="" src={WorkingHours} effect="blur" />
                  </span>
                  {t('office_detail.working_hours')}
                </span>
                {data && data.workingHour}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="facilities-bar">
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title">{t('office_detail.min_contract_period')}</span>
                {data && data.minContractPeriod && data.minContractPeriod !== '0' && !isNaN(data.minContractPeriod)
                  ? data.minContractPeriod
                  : ''}{' '}
                {data && data.minContractUnit}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon ">{t('office_detail.deposit')}</span>
                {data && data.deposit && data.deposit !== '0' && !isNaN(data.deposit) ? data.deposit : ''}{' '}
                {data && data.depositUnit}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon "> {t('office_detail.payment')}</span>
                {data && data.payment && data.payment !== '0' && !isNaN(data.payment) ? data.payment : ''}{' '}
                {data && data.paymentUnit}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  vat"> {t('office_detail.vat')}</span>
                {data && data.vatInclude ? t('office_detail.included') : ''}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title"> {t('office_detail.service_charge')}</span>
                {data && data.serviceChargeSqm ? formatMoney(data.serviceChargeSqm, 0, 0) : ''}
                {data && data.serviceChargeSqm ? (
                  <span className="service-charge-unit">
                    $/m<sup>2</sup>
                  </span>
                ) : (
                  ''
                )}
              </div>
              <div className="item jp-font-sawarabi-gothic">
                <span className="icon  long-title"> {t('office_detail.overtime_charge')}</span>
                {data && data.otCharge}
              </div>
            </div>
            <div className="contact-us contact-us-office">
              <button className="button button-red uppercase jp-700-sawarabi" onClick={() => showModel(true)}>
                <EmailIcon /> {t('residence_detail.contact_us')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent show={modalShow} onCloseModal={() => showModel(false)} />
    </>
  );
};

export default ResidenceInfo;
