// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import Service from '../../services';

export const fetchLastProperties = async params => {
  const response = await Service.get(`/api/properties/latest${params}`, res => res);
  return response;
};

export const fetchHotProperties = async params => {
  const response = await Service.get(`/api/properties/hot${params}`, res => res);
  return response;
};

export const fetchBlogs = async params => {
  const response = await Service.get(`/api/blogs/latest${params}`, res => res);
  return response;
};

export const fetchProjects = async params => {
  const response = await Service.get(`/api/projects${params}`, res => res);
  return response;
};

export const fetchSpecialList = async params => {
  const response = await Service.get(`/api/speciallists${params}`, res => res);
  return response;
};
