import * as types from './properties.types';

const initialState = {
  properties: null,
};

const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LIST_PROPERTIES_REQUEST:
      return { ...state };
    case types.FETCH_LIST_PROPERTIES_SUCCESS:
      return { ...state, properties: action.data.properties };
    case types.FETCH_LIST_PROPERTIES_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default propertiesReducer;
