import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import clock from '../../../../assets/images/clock.svg';
import * as actions from '../../aboutIsn.action';
import { getLanguage, getPathLanguage, getIdDetail } from '../../../../utils/params';

const OtherNews = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const id = getIdDetail(location);

  useEffect(() => {
    props.fetchOtherNewsRequest({ language, id });

    // eslint-disable-next-line
  }, [id]);

  const { otherNews } = props.aboutIsn;

  return (
    <div className="col-12 col-md-4">
      <div className="right-sidebar">
        <h3 className="sidebar-title text-transform-capitalize">{t('about_isn.other_news')}</h3>
        <div className="posts-list">
          {otherNews && otherNews.length
            ? otherNews.map((item, index) => (
                <div key={index} className="row">
                  <div className="col-12 col-md-6 mb-4">
                    <div className="block-image">
                      <Link to={`${pathLanguage}/about-isn/news-release-detail/${item.id}/${item.slug}`}>
                        <img className="w-100 image-blog-right" alt="" src={item.blogThumnail} />
                        {/* <LazyLoadImage
                          className="w-100 image-blog-right"
                          alt=""
                          src={item.blogThumnail}
                          effect="blur"
                        /> */}
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <div className="block-text">
                      <div className="post-title blog-title">
                        <Link
                          className="blog-title"
                          to={`${pathLanguage}/about-isn/news-release-detail/${item.id}/${item.slug}`}
                        >
                          {item.blogTitle}
                        </Link>
                      </div>
                      <div className="created blog-time">
                        <img src={clock} alt="" /> <span>{item.date}</span>
                        {/* <LazyLoadImage src={clock} alt="" effect="blur" /> <span>{item.date}</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOtherNewsRequest: actions.fetchOtherNewsRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OtherNews);
