import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ResidenceComponent from '../pages/Residence.component';
import { getResidenceRequest, getSimilarPropertiesRequest } from '../residence.action';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getResidenceRequest, getSimilarPropertiesRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResidenceComponent);
