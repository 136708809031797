import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getLanguage } from '../../../utils/params';
import { Faqs, HeadUseful, OurCustomer, Breadcrumb } from '../common/index';
import RentalBannerEN from '../../../assets/images/rental-flow-en.png';
import RentalBannerJP from '../../../assets/images/rental-flow-jp.png';
import RentalBannerENMobile from '../../../assets/images/rental-flow-mobile-en.png';
import RentalBannerJPMobile from '../../../assets/images/rental-flow-mobile-jp.png';
import { isMobile } from '../../../utils/constants';

const FaqsComponent = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const { t } = useTranslation();

  const slitLocation = location.pathname.split('/');
  let params = '';
  if (slitLocation.length) {
    params = slitLocation[slitLocation.length - 1];
  }

  useEffect(() => {
    if (!location.pathname.includes('/useful-information/all')) {
      if (params === 'rental-flow') {
        props.getUsefulInformationRequest({
          language,
          category: 3,
        });
      }
    }
    window.scrollTo(0, 0);
    // const scrollTo = document.querySelector('#useful-position-scroll');
    // if (scrollTo) {
    //   scrollTo.scrollIntoView({ behavior: 'smooth' });
    // }
    // eslint-disable-next-line
  }, [params]);

  const { data } = props.usefulInfo;

  const renderRentalFlow = useMemo(() => {
    return (
      <div className="col-12">
        <div className="row row-data">
          {data && data.length
            ? data.map((item, index) => {
                if (item.description) item.description = item.description.replace(/\n/g, '<br />');
                if (item.description && item.name !== 'null') {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-2 col-vertical">
                        <div className="div">
                          <div className="dot"></div>
                        </div>
                      </div>
                      <div className="col-9 col-content">
                        <div className="wrap-content">
                          <div
                            className="content jp-font-sawarabi-gothic"
                            dangerouslySetInnerHTML={{ __html: item.description || '' }}
                          ></div>
                          <div className="label">
                            <div className="top jp-600-sawarabi">{`STEP ${index + 1}`}</div>
                            <div className="middle">
                              <div></div>
                            </div>
                            <div className="bottom jp-400-sawarabi" title={item.name}>
                              {item.name}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-1 col-empty"></div>
                    </React.Fragment>
                  );
                }
                return '';
              })
            : ''}
        </div>
      </div>
    );
    // eslint-disable-next-line
  }, [data]);

  const renderOther = useMemo(() => {
    return (
      <div className="page-faq-tenants">
        <div className="row">
          <div className="col-1"></div>
          <div className="title-faq-tenants col-10">
            <h1 className="text-title jp-bold-600">
              {params === 'faq' ? t('useful_page.faq.title') : t('useful_page.useful_for_tenants.title')}
            </h1>
            <div className="lower-border-title"></div>
            <div
              className="text-description jp-400-sawarabi"
              dangerouslySetInnerHTML={{
                __html:
                  params === 'faq' ? t('useful_page.faq.description') : t('useful_page.useful_for_tenants.description'),
              }}
            ></div>
          </div>
          <div className="col-1"></div>
        </div>
        <div className="row">
          <div className="col-1 col-md-1"></div>
          <div className="col-10 col-md-10 p-0">
            <Faqs />
          </div>
          <div className="col-1 col-md-1"></div>
        </div>
      </div>
    );
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <section
        className={`useful-information ${location.pathname.includes('/useful-information/all') ? 'all-category' : ''} ${
          params === 'faq' || params === 'useful-information-for-tenants' ? 'faq-tenants' : ''
        } ${params === 'our-customer-support-service' ? 'our-customer' : ''}`}
      >
        {location.pathname.includes('/useful-information/all') ? (
          <HeadUseful />
        ) : isMobile ? (
          <Breadcrumb />
        ) : (
          <HeadUseful />
        )}
        {!location.pathname.includes('/useful-information/all') ? (
          <>
            {params === 'rental-flow' ? (
              <div className="container">
                <div className="col-12 banner-rental">
                  {/iPhone|Android/i.test(navigator.userAgent) ? (
                    <img
                      alt=""
                      className="img-banner"
                      src={language === 'jp' ? RentalBannerJPMobile : RentalBannerENMobile}
                    />
                  ) : (
                    <img alt="" className="img-banner" src={language === 'jp' ? RentalBannerJP : RentalBannerEN} />
                  )}
                </div>
                {renderRentalFlow}
              </div>
            ) : (
              ''
            )}
            {params === 'our-customer-support-service' ? <OurCustomer /> : ''}
            {params === 'faq' || params === 'useful-information-for-tenants' ? (
              <div className="container p-0"> {renderOther} </div>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
      </section>
    </div>
  );
};

export default FaqsComponent;
