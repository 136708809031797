/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import EventBus from 'eventing-bus';

import { Location, ChevronDownIcon, GlobeIcon } from '../icons';
import { getPathLanguage, getLanguage } from '../../../utils/params';
import JapanFlag from '../../../assets/images/japan.svg';
import EnglishFlag from '../../../assets/images/united-kingdom.svg';
import {
  fetchCitiesByLocaleRequest,
  fetchCitiesRequest,
  fetchDistrictsByCityIdRequest,
} from '../../redux/layout.action';

const HeaderTop = props => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState()
  const residenceDetail = props.residence?.detail;
  const officeDetail = props.officeDetail?.detail;
  const projectDetail = props.projects?.project;
  const specialDetail = props.special;
  const blogDetail = props.blogs?.blog;

  // const a = props.officeDetail?.detail;
  const handlePosition = position => {
    if (position && position.coords && position.coords.longitude && position.coords.latitude) {
      localStorage.setItem('LOCATION_ADDRESS', [position.coords.longitude, position.coords.latitude]);
      props.fetchCitiesByLocaleRequest({ lon: position.coords.longitude, lat: position.coords.latitude, language });
    }
  };

  const handleBlock = () => {
    props.fetchCitiesRequest({ language });
  };

  useEffect(() => {
    setName(residenceDetail?.name)
  }, [residenceDetail])

  useEffect(() => {
    setName(officeDetail?.name)
  }, [officeDetail])

  useEffect(() => {
    setName(projectDetail?.name)
  }, [projectDetail])

  useEffect(() => {
    setName(specialDetail?.specialName)
  }, [specialDetail])

  useEffect(() => {
    setName(blogDetail?.title)
  }, [blogDetail])

  useEffect(() => {
    handleBlock();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentLocale = localStorage.getItem('LOCATION_CITY');

    if (currentLocale) {
      handleBlock();
    } else {
      const locationLocale = localStorage.getItem('LOCATION_ADDRESS')
        ? localStorage.getItem('LOCATION_ADDRESS').split(',')
        : null;
      if (locationLocale) {
        props.fetchCitiesByLocaleRequest({ lon: locationLocale[0], lat: locationLocale[1] });
      } else {
        if (navigator && navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(handlePosition, handleBlock);
        }
      }
    }

    // eslint-disable-next-line
  }, []);

  const { cities, cityActive } = props.layout;

  const onChangeLanguage = langPath => {
    const lang = langPath.split('/')[1];
    const listParams = location.pathname.split('/');

    localStorage.setItem('language', lang);

    if (pathLanguage) {
      if (langPath === pathLanguage) return;
      else {
        i18n.changeLanguage(lang);
        listParams[1] = lang;
        const newPathname = listParams.join('/');
        const refUrl = location.search.replace(/language=(.*?(jp|en))/gi, `language=${lang}`);
        window.location.href = newPathname + refUrl;
      }
    } else {
      if (langPath === '/en') return;
      else {
        i18n.changeLanguage(lang);
        const newPathname = langPath + location.pathname;
        const refUrl = location.search.replace(/language=(.*?(jp|en))/gi, `language=${lang}`);
        window.location.href = newPathname + refUrl;
      }
    }
  };

  const handleChangeLocation = async id => {
    if (id && cities && cities.length) {
      const refUrl = location.search.replace(
        /cityId=[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi,
        `cityId=${id}`,
      );

      await localStorage.setItem('LOCATION_CITY', id);
      window.location.href = location.pathname + refUrl;
    }
  };

  return (
    <section className="section-header-top header-top background-black">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 offset-lg-12 col-md-12 col-lg-12">
            <div className="nav-menu-top">
              <ul className="menu-top">
                {/* eslint-disable */}
                <li>
                  <a className="item-link">
                    <GlobeIcon />
                    <span>{t('text_language')}</span>
                    <ChevronDownIcon />
                  </a>
                  <ul className="wrap-dropdown-flag">
                    <li className="flag-item" onClick={() => onChangeLanguage('/en')}>
                      <div>
                        <img height="25" width="25" alt="" src={EnglishFlag} />
                        {/* <LazyLoadImage height="25" width="25" alt="" src={EnglishFlag} effect="blur" /> */}
                        <span className="item-link">English</span>
                      </div>
                    </li>
                    <li className="flag-item" onClick={() => onChangeLanguage('/jp')}>
                      <div>
                        <img height="25" width="25" alt="" src={JapanFlag} />
                        {/* <LazyLoadImage height="25" width="25" alt="" src={JapanFlag} effect="blur" /> */}
                        <span className="item-link">日本語</span>
                      </div>
                    </li>
                  </ul>
                </li>
                {cityActive && (
                  <li className="list-location">
                    <a className="item-link">
                      <Location />
                      <span data={`${cityActive ? cityActive.defaultLocationId : ''}`}>
                        {cityActive ? cityActive.defaultLocationName : ''}
                      </span>
                      <ChevronDownIcon />
                    </a>
                    <ul className="wrap-list-location">
                      {cities && cities.length
                        ? cities.map((item, index) => (
                          <li
                            className="header-top-location"
                            onClick={() => handleChangeLocation(item.cityId)}
                            key={index}
                          >
                            <span value={item.cityId}>
                              <span className="city-name">{item.cityName}</span>
                            </span>
                          </li>
                        ))
                        : ''}
                    </ul>
                  </li>
                )}
                {language === 'jp' ?
                  <li>
                    <span>
                      <a href="https://lin.ee/UGeV0LI" target="_blank" rel="noopener noreferrer">
                        <img
                          src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
                          alt="友だち追加" height="36" border="0" />
                      </a>
                    </span>
                  </li> : ''
                }

                {/* eslint-enable */}
                {/* Not used yet
                <li>
                  <Link className="item-link" to={`${pathLanguage}/member`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span>
                      <span className="member-title">{t('member_title')}</span>
                    </span>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchCitiesByLocaleRequest, fetchCitiesRequest, fetchDistrictsByCityIdRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);
