import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const HistoryContent = props => {
  const data = props.aboutIsn.history;

  return (
    <div className="history-list">
      <div className="row">
        <div className="col-1" />
        <div className="col-10">
          {data && data.length
            ? data.map((item, index) => (
                <div className="history-data" key={index}>
                  <div className="row">
                    <div className="col-3 col-md-2 history-year jp-500-sawarabi">{item.year}</div>
                    <div className="col-9 col-md-10">
                      <div className="row">
                        <div className="col-3 col-md-2 history-month jp-500-sawarabi">{item.month}</div>
                        <div className="col-9 col-md-10 history-text jp-font-sawarabi-gothic">{item.name}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ''}
        </div>
        <div className="col-1" />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContent);
