import React from 'react';
import { Carousel } from 'react-responsive-carousel';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageSlides = props => {
  const { images } = props;

  return (
    <div className="block-slides">
      <div className="slide-image">
        <Carousel infiniteLoop showThumbs={true} axis="horizontal" thumbWidth="56px">
          {images &&
            images.map((imgSrc, i) => (
              <div className="slide-item" key={i}>
                <img
                  alt={'images ' + i}
                  className="list-image-detail"
                  src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${imgSrc}`}
                />
                {/* <LazyLoadImage
                  alt={'images ' + i}
                  className="list-image-detail"
                  src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${imgSrc}`}
                  effect="blur"
                /> */}
              </div>
            ))}
        </Carousel>
      </div>
    </div>
  );
};

export default ImageSlides;
