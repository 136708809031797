import * as types from './useful.types';

const initialState = {
  error: null,
  aboutRenting: null,
  data: null,
  topicsApartment: null,
  detailApartment: null,
  listArea: null,
};

const usefulInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USEFUL_INFORMATION_REQUEST:
      return { ...state };
    case types.GET_USEFUL_INFORMATION_SUCCESS:
      return { ...state, data: action.data };
    case types.GET_USEFUL_INFORMATION_FAILURE:
      return { ...state, error: action.error };

    case types.GET_ABOUT_RENTING_REQUEST:
      return { ...state };
    case types.GET_ABOUT_RENTING_SUCCESS:
      return { ...state, aboutRenting: action.data };
    case types.GET_ABOUT_RENTING_FAILURE:
      return { ...state, error: action.error };

    case types.GET_TOPICS_APARTMENT_REQUEST:
      return { ...state };
    case types.GET_TOPICS_APARTMENT_SUCCESS:
      return { ...state, topicsApartment: action.data };
    case types.GET_TOPICS_APARTMENT_FAILURE:
      return { ...state, error: action.error };

    case types.GET_DETAIL_APARTMENT_REQUEST:
      return { ...state };
    case types.GET_DETAIL_APARTMENT_SUCCESS:
      return { ...state, detailApartment: action.data };
    case types.GET_DETAIL_APARTMENT_FAILURE:
      return { ...state, error: action.error };

    case types.GET_LIST_AREA_REQUEST:
      return { ...state };
    case types.GET_LIST_AREA_SUCCESS:
      return { ...state, listArea: action.data };
    case types.GET_LIST_AREA_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default usefulInformationReducer;
