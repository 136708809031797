const FETCH_BLOGS_LIST_REQUEST = 'FETCH_BLOGS_LIST_REQUEST';
const FETCH_BLOGS_LIST_SUCCESS = 'FETCH_BLOGS_LIST_SUCCESS';
const FETCH_BLOGS_LIST_FAILURE = 'FETCH_BLOGS_LIST_FAILURE';

const GET_MAIN_BLOGS_REQUEST = 'GET_MAIN_BLOGS_REQUEST';
const GET_MAIN_BLOGS_SUCCESS = 'GET_MAIN_BLOGS_SUCCESS';
const GET_MAIN_BLOGS_FAILURE = 'GET_MAIN_BLOGS_FAILURE';

const GET_BLOG_REQUEST = 'GET_BLOG_REQUEST';
const GET_BLOG_SUCCESS = 'GET_BLOG_SUCCESS';
const GET_BLOG_FAILURE = 'GET_BLOG_FAILURE';

const FETCH_OTHER_BLOGS_REQUEST = 'FETCH_OTHER_BLOGS_REQUEST';
const FETCH_OTHER_BLOGS_SUCCESS = 'FETCH_OTHER_BLOGS_SUCCESS';
const FETCH_OTHER_BLOGS_FAILURE = 'FETCH_OTHER_BLOGS_FAILURE';

const FETCH_ABOUT_ISN_REQUEST = 'FETCH_ABOUT_ISN_REQUEST';
const FETCH_ABOUT_ISN_SUCCESS = 'FETCH_ABOUT_ISN_SUCCESS';
const FETCH_ABOUT_ISN_FAILURE = 'FETCH_ABOUT_ISN_FAILURE';

export {
  FETCH_BLOGS_LIST_REQUEST,
  FETCH_BLOGS_LIST_SUCCESS,
  FETCH_BLOGS_LIST_FAILURE,
  GET_MAIN_BLOGS_REQUEST,
  GET_MAIN_BLOGS_SUCCESS,
  GET_MAIN_BLOGS_FAILURE,
  GET_BLOG_REQUEST,
  GET_BLOG_SUCCESS,
  GET_BLOG_FAILURE,
  FETCH_OTHER_BLOGS_REQUEST,
  FETCH_OTHER_BLOGS_SUCCESS,
  FETCH_OTHER_BLOGS_FAILURE,
  FETCH_ABOUT_ISN_REQUEST,
  FETCH_ABOUT_ISN_SUCCESS,
  FETCH_ABOUT_ISN_FAILURE
};
