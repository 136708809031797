import React from 'react';

const FloorArea = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path
          opacity="0.6"
          id="prefix___001-parquet"
          fill="#131313"
          d="M21.609 22H.391A.391.391 0 0 1 0 21.609V.391A.391.391
          0 0 1 .391 0h21.218A.391.391 0 0 1 22 .391v21.218a.391.391
          0 0 1-.391.391zm-5.16-.782h4.768v-4.769l-4.423 4.423zm-6.09 0
          h4.983l.62-.62-2.492-2.492zm-5.685 0h4.579l6.8-6.8-2.289-2.289-7.221
          7.22zm-3.892 0h2.785l2.146-2.146-2.4-2.4-2.531 2.523zm13.242-3.665l2.492 2.492
          4.7-4.7v-4.986l-4.334 4.334zM3.863 16.115l2.4 2.4 12-12-2.4-2.4-4.7
          4.7zM.782 12.833v5.255l2.246-2.246 7.289-7.289-2.564-2.581zm13.535-1.259l2.289 2.289
          4.6-4.6.011-.011V4.674zM.782 6.448v5.287l3.1-3.049-2.656-2.655zM1.8
          5.494l2.638 2.643L11.884.808H6.77zm6.51-.071l2.569 2.569 7.209-7.21h-5.063zm8.1-1.859l2.4 2.4
          2.4-2.4V.782h-2.015zM.782.782v4.592l.146-.138.026-.029A.389.389 0 0 1 1 5.166L5.657.782z"
          data-name="001-parquet"
        />
      </svg>
    </>
  );
};

export default FloorArea;
