import { get } from 'lodash';
import Service from '../../services';

export const getCompanyProfile = async params => {
  const response = await Service.get(`/api/generalinformations/get-by-slug-category${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getGroupCompany = async params => {
  const response = await Service.get(`/api/generalinformations/isn-group${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getHistory = async params => {
  const response = await Service.get(`/api/generalinformations/histories${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getNewsRelease = async params => {
  const response = await Service.get(`/api/blogs${params}`, res => res);
  return response;
};

export const getOfficeInformation = async params => {
  const response = await Service.get(`/api/generalinformations/get-contact-us${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getNewsContent = async params => {
  const response = await Service.get(`/api/blogs/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchOtherNews = async params => {
  const response = await Service.get(`/api/blogs/other${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};
