import * as types from './useful.types';

export const getUsefulInformationRequest = params => ({ type: types.GET_USEFUL_INFORMATION_REQUEST, params });
export const getUsefulInformationSuccess = data => ({ type: types.GET_USEFUL_INFORMATION_SUCCESS, data });
export const getUsefulInformationFailure = error => ({ type: types.GET_USEFUL_INFORMATION_FAILURE, error });

export const getAboutRentingRequest = params => ({ type: types.GET_ABOUT_RENTING_REQUEST, params });
export const getAboutRentingSuccess = data => ({ type: types.GET_ABOUT_RENTING_SUCCESS, data });
export const getAboutRentingFailure = error => ({ type: types.GET_ABOUT_RENTING_FAILURE, error });

export const getTopicsApartmentRequest = params => ({ type: types.GET_TOPICS_APARTMENT_REQUEST, params });
export const getTopicsApartmentSuccess = data => ({ type: types.GET_TOPICS_APARTMENT_SUCCESS, data });
export const getTopicsApartmentFailure = error => ({ type: types.GET_TOPICS_APARTMENT_FAILURE, error });

export const getDetailApartmentRequest = params => ({ type: types.GET_DETAIL_APARTMENT_REQUEST, params });
export const getDetailApartmentSuccess = data => ({ type: types.GET_DETAIL_APARTMENT_SUCCESS, data });
export const getDetailApartmentFailure = error => ({ type: types.GET_DETAIL_APARTMENT_FAILURE, error });

export const getListAreaRequest = params => ({ type: types.GET_LIST_AREA_REQUEST, params });
export const getListAreaSuccess = data => ({ type: types.GET_LIST_AREA_SUCCESS, data });
export const getListAreaFailure = error => ({ type: types.GET_LIST_AREA_FAILURE, error });
