const FETCH_LASTEST_PROPERTIES_REQUEST = 'FETCH_LASTEST_PROPERTIES_REQUEST';
const FETCH_LASTEST_PROPERTIES_SUCCESS = 'FETCH_LASTEST_PROPERTIES_SUCCESS';
const FETCH_LASTEST_PROPERTIES_FAILURE = 'FETCH_LASTEST_PROPERTIES_FAILURE';

const FETCH_HOT_PROPERTIES_REQUEST = 'FETCH_HOT_PROPERTIES_REQUEST';
const FETCH_HOT_PROPERTIES_SUCCESS = 'FETCH_HOT_PROPERTIES_SUCCESS';
const FETCH_HOT_PROPERTIES_FAILURE = 'FETCH_HOT_PROPERTIES_FAILURE';

const FETCH_BLOGS_REQUEST = 'FETCH_BLOGS_REQUEST';
const FETCH_BLOGS_SUCCESS = 'FETCH_BLOGS_SUCCESS';
const FETCH_BLOGS_FAILURE = 'FETCH_BLOGS_FAILURE';

const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

const FETCH_SPECIAL_LIST_REQUEST = 'FETCH_SPECIAL_LIST_REQUEST';
const FETCH_SPECIAL_LIST_SUCCESS = 'FETCH_SPECIAL_LIST_SUCCESS';
const FETCH_SPECIAL_LIST_FAILURE = 'FETCH_SPECIAL_LIST_FAILURE';

export {
  FETCH_LASTEST_PROPERTIES_REQUEST,
  FETCH_LASTEST_PROPERTIES_SUCCESS,
  FETCH_LASTEST_PROPERTIES_FAILURE,
  FETCH_HOT_PROPERTIES_REQUEST,
  FETCH_HOT_PROPERTIES_SUCCESS,
  FETCH_HOT_PROPERTIES_FAILURE,
  FETCH_BLOGS_REQUEST,
  FETCH_BLOGS_SUCCESS,
  FETCH_BLOGS_FAILURE,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_SPECIAL_LIST_REQUEST,
  FETCH_SPECIAL_LIST_SUCCESS,
  FETCH_SPECIAL_LIST_FAILURE,
};
