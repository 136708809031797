import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTranslation } from 'react-i18next';

import * as action from '../../useful.action';
import minus from '../../../../assets/images/minus.svg';
import plus from '../../../../assets/images/plus.svg';
import { getLanguage } from '../../../../utils/params';

const Faqs = props => {
  const location = useLocation();
  const language = getLanguage(location);
  // const { t } = useTranslation();
  const [isShowCollapse, setIsShowCollapse] = useState({ key: '', value: false });

  const slitLocation = location.pathname.split('/');
  let params = '';
  if (slitLocation.length) {
    params = slitLocation[slitLocation.length - 1];
  }

  useEffect(() => {
    if (!location.pathname.includes('/useful-information/all')) {
      props.getUsefulInformationRequest({
        language,
        category: params === 'faq' ? 1 : 2,
      });
    }
    //eslint-disable-next-line
  }, [params]);

  const { data } = props.usefulInfo;

  const renderData = useMemo(() => {
    if (data && data.length) {
      return data.map((item, index) => {
        if (item.description) {
          item.description = item.description.replace(/\n/g, '<br />');
        }
        if (item.name !== 'null') {
          return (
            <div key={index} className="main-content">
              <div
                className={`useful-content click-collapse ${
                  isShowCollapse.key === item.id && isShowCollapse.value ? 'actived' : 'collapsed'
                }`}
                onClick={() =>
                  setIsShowCollapse({
                    key: item.id,
                    value: !(isShowCollapse.key === item.id && isShowCollapse.value),
                  })
                }
              >
                <div className="useful-title jp-font-sawarabi-gothic">{item.name || ''}</div>
                <img
                  src={isShowCollapse.key === item.id && isShowCollapse.value ? minus : plus}
                  className="img-plus"
                  alt=""
                />
                {/* <LazyLoadImage
                  src={isShowCollapse.key === item.id && isShowCollapse.value ? minus : plus}
                  className="img-plus"
                  alt=""
                  effect="blur"
                /> */}
              </div>
              <div
                className={`useful-description jp-font-sawarabi-gothic collapse ${
                  isShowCollapse.key === item.id && isShowCollapse.value ? 'show' : ''
                }`}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.description || '',
                  }}
                ></div>
              </div>
            </div>
          );
        }
        return null;
      });
    }
  }, [data, isShowCollapse]);

  return (
    <>
      <div className="main-useful-right">
        <div className="useful-right">{renderData}</div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getUsefulInformationRequest: action.getUsefulInformationRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
