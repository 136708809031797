import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { fromPairs } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPaginate from 'react-paginate';

import * as actions from '../../residence.action';
import BreadcrumbResidence from '../../../../layouts/commons/breadcrumb/Breadcrumb.ResidenceSearch';
import ResidenceSearchResutls from './ResidenceSearchResutls.component';
// import Pagination from 'react-js-pagination';
import previousIcon from '../../../../assets/images/arrow-left.png';
import nextIcon from '../../../../assets/images/arrow-right.png';
import { history } from '../../../../utils/history';
import { getPathLanguage, getQueryString } from '../../../../utils/params';
import favorite404 from '../../../../assets/images/notfound-icon.svg';
import { isMobile } from '../../../../utils/constants';

const ResidenceComponent = props => {
  const location = useLocation();
  const { t } = useTranslation();

  const pathLanguage = getPathLanguage(useLocation());
  const urlParams = fromPairs(
    location.search
      .substring(1)
      .split('&')
      .map(item => item.split('=')),
  );
  const [sectionName, setSectionName] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [pageNo, setPageNo] = useState(parseInt(urlParams.pageIndex));
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(parseInt(urlParams.pageSize));

  const parseParamsFunc = params => {
    if ('keyword' in params) params.keyword = decodeURIComponent(params.keyword);

    if ('districtId' in params) params.districtId = params.districtId.replace(/%2C/gi, ',').split(',').toString();

    if ('type' in params) params.type = params.type.replace(/%2C/gi, ',').split(',').toString();

    if ('bedroom' in params) params.bedroom = params.bedroom.replace(/%2C/gi, ',').split(',').toString();

    if ('size' in params) params.size = params.size.replace(/%2C/gi, ',').split(',').toString();

    if ('project' in params) params.project = params.project.replace(/%2C/gi, ',').split(',').toString();

    if ('facilities' in params) params.facilities = params.facilities.replace(/%2C/gi, ',').split(',').toString();

    return {
      ...params,
      ...{
        pageIndex: parseInt(params.pageIndex),
        pageSize: parseInt(params.pageSize),
        propertyType: parseInt(params.propertyType),
        leasePriceMax: parseInt(params.leasePriceMax),
        leasePriceMin: parseInt(params.leasePriceMin),
      },
    };
  };

  useEffect(() => {
    if (urlParams.keyword) urlParams.keyword = decodeURIComponent(urlParams.keyword) || '';
    const parseParams = parseParamsFunc(urlParams);
    props.fetchResidencesSearch(parseParams);

    const listParams = location.pathname.split('/');
    let propertiesName = '';
    if (listParams.length === 3) propertiesName = listParams[1];
    else if (listParams.length > 3) propertiesName = listParams[2];

    setSectionName(propertiesName);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const handlePageChange = pageNumber => {
    pageNumber = parseInt(pageNumber) + 1;
    setPageNo(pageNumber);
    const parseParams = parseParamsFunc({ ...urlParams, ...{ pageIndex: pageNumber } });

    if (parseParams.propertyType === 0) {
      history.push(`${pathLanguage}/residences/search${getQueryString(parseParams)}`);
    } else {
      history.push(`${pathLanguage}/offices/search${getQueryString(parseParams)}`);
    }

    props.fetchResidencesSearch(parseParams);
    window.scrollTo(0, 0);
  };

  const handleChangeSortBy = type => {
    const parseParams = parseParamsFunc(urlParams);
    if (type) parseParams.sort = type;

    if (parseParams.propertyType === 0) {
      history.push(`${pathLanguage}/residences/search${getQueryString(parseParams)}`);
    } else {
      history.push(`${pathLanguage}/offices/search${getQueryString(parseParams)}`);
    }

    props.fetchResidencesSearch(parseParams);
  };

  const handleChangeShowItem = number => {
    const parseParams = parseParamsFunc({ ...urlParams, ...{ pageSize: parseInt(number), pageIndex: 1 } });

    if (parseParams.propertyType === 0) {
      history.push(`${pathLanguage}/residences/search${getQueryString(parseParams)}`);
    } else {
      history.push(`${pathLanguage}/offices/search${getQueryString(parseParams)}`);
    }
    setPageSize(parseInt(number));
    setPageNo(1);
    props.fetchResidencesSearch(parseParams);
  };

  const { residencesSearch } = props.residence;

  const showListResidencesSearch = useMemo(() => {
    return residencesSearch ? (
      residencesSearch.items && residencesSearch.items.length ? (
        <ResidenceSearchResutls
          residencesSearch={residencesSearch}
          propertyType={urlParams.propertyType}
          section={sectionName}
        />
      ) : (
        <div className="section-block background-gray pt-4">
          <div className="image-notfound">
            <div className="not-found-message"> {t('search_page.not_found')}</div>
            <img src={favorite404} className="favourite-notfound" alt="" />
            {/* <LazyLoadImage src={favorite404} className="favourite-notfound" alt="" effect="blur" /> */}
          </div>
        </div>
      )
    ) : (
      ''
    );

    // eslint-disable-next-line
  }, [residencesSearch]);

  return (
    <>
      <BreadcrumbResidence
        residencesSearch={residencesSearch}
        handleChangeSortBy={handleChangeSortBy}
        handleChangeShowItem={handleChangeShowItem}
        propertyType={urlParams.propertyType}
      />

      {showListResidencesSearch}

      <div className="row">
        {residencesSearch &&
        residencesSearch.totalRecords &&
        residencesSearch.totalRecords > residencesSearch.pageSize ? (
          <div className="pagination">
            <ReactPaginate
              //   activePage={pageNo}
              //   itemsCountPerPage={pageSize}
              //   totalItemsCount={residencesSearch.totalRecords || 0}
              //   pageRangeDisplayed={5}
              //   hideFirstLastPages={true}
              //   nextPageText={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
              //   prevPageText={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
              //   activeClass={'pagination-active'}
              //   onChange={page => handlePageChange(page)}
              //   itemClass={'pagination-pager-item'}
              forcePage={residencesSearch.pageIndex - 1}
              pageCount={residencesSearch.pageCount}
              pageRangeDisplayed={1}
              marginPagesDisplayed={isMobile ? 1 : 2}
              previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
              nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
              activeClassName={'pagination-active'}
              onPageChange={({ selected }) => handlePageChange(selected)}
              pageClassName={'pagination-pager-item'}
              disabledClassName={'opacity-disable'}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchResidencesSearch: actions.fetchResidencesSearchRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResidenceComponent);
