/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { connect } from 'react-redux';
import EventBus from 'eventing-bus';
import { Helmet } from 'react-helmet';

import Header from './commons/header/Header';
import Footer from './commons/footer/Footer';
import Subscribe from './commons/subscribe/Subscribe';
import { getLanguage, getPathLanguage } from '../utils/params';
import PartnerSlides from './commons/bannerSlides/PartnerSlides';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../utils/constants';

const PublicLayout = props => {
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);
  const params = useParams();
  const { t } = useTranslation();
  const blogSeoAll = props.state.aboutIsn.data || [];
  const [isHiddenScroll, setIsHiddenScroll] = useState(false);

  useEffect(() => {
    return () => EventBus.unregisterCallbacksForEvent('checkBodyScroll');
  }, []);

  const args = location.pathname.split('/').filter(x => x.trim() !== '');

  const [langPath, pagePath, subPath] = args;

  let page = '';
  let subPage = '';
  if (langPath === 'en' || langPath === 'jp') {
    page = pagePath;
    subPage = subPath;
  } else {
    page = langPath;
    subPage = pagePath;
  }

  let hiddenH1 = '';
  let pageTitle = '';
  let pageKeywords = '';
  let pageDescription = '';
  let listPage = ['useful-information', 'blogs', 'for-owners', 'about-isn',
    'favorites', 'histories', 'projects', 'residences', 'offices'];
  /* eslint-disable */
  if (params.type && params.props) { // for list-properties page
    switch(params.type + params.props){
      case 'latest' + 'residence':
        pageTitle = `${t('title.latest_residences')}`;
        pageKeywords = `${t('seo.residences.meta_keywords_lastest')}`;
        pageDescription = `${t('seo.residences.meta_description_lastest')}`;
        break;
      case 'latest' + 'office':
        pageTitle = `${t('title.latest_offices')}`;
        pageKeywords = `${t('seo.offices.meta_keywords_lastest')}`;
        pageDescription = `${t('seo.offices.meta_description_lastest')}`;
        break;
      case 'hot' + 'residence':
        pageTitle = `${t('title.hot_residences')}`;
        pageKeywords = `${t('seo.residences.meta_keywords_hot')}`;
        pageDescription = `${t('seo.residences.meta_description_hot')}`;
        break;
      case 'hot' + 'office':
        pageTitle = `${t('title.hot_offices')}`;
        pageKeywords = `${t('seo.offices.meta_keywords_hot')}`;
        pageDescription = `${t('seo.offices.meta_description_hot')}`;
        break;
      case 'similar' + 'residence':
        pageTitle = `${t('title.similar_residences')}｜ISN Vietnamhousing`;
        break;
      case 'similar' + 'office':
        pageTitle = `${t('title.similar_offices')}｜ISN Vietnamhousing`;
        break;
    }
  } else if (params.category) { // for blogs page
    switch(params.category){
      case 'all':
        pageTitle = `${t('title.blogs.all')}｜${t('title.latest_title')}`;
        pageKeywords = `${t('seo.blogs.meta_keywords')}`;
        pageDescription = `${t('seo.blogs.meta_description')}`;
        if (blogSeoAll.length > 0)  {
          blogSeoAll.map(blog => {
            if (blog.name === 'meta-description' && blog.description)
              pageDescription = blog.description;
            else if (blog.name === 'meta-keywords' && blog.description)
              pageKeywords = blog.description;
          })
        }
        break;
      case 'news':
        pageTitle = `${t('title.blogs.news')} ${t('title.blogs.blog_latest_title')}`;
        pageKeywords = `${t('seo.blogs.meta_keywords_news')}`;
        pageDescription = `${t('seo.blogs.meta_description_news')}`;
        break;
      case 'newly-launched-properties':
        pageTitle = `${t('title.blogs.newly_launched_properties')} ${t('title.blogs.blog_latest_title')}`;
        pageKeywords = `${t('seo.blogs.meta_keywords_newly_launched')}`;
        pageDescription = `${t('seo.blogs.meta_description_newly_launched')}`;
        break;
      case 'c-s-statistics':
        pageTitle = `${t('title.blogs.c_s_statistics')} ${t('title.blogs.blog_latest_title')}`;
        pageKeywords = `${t('seo.blogs.meta_keywords_c_s_statistics')}`;
        pageDescription = `${t('seo.blogs.meta_description_c_s_statistics')}`;
        break;
      case 'living-in-vietnam':
        pageTitle = `${t('title.blogs.living_in_vietnam')} ${t('title.blogs.blog_latest_title')}`;
        pageKeywords = `${t('seo.blogs.meta_keywords_living')}`;
        pageDescription = `${t('seo.blogs.meta_description_living')}`;
        break;
    }
  } else if (params.id) { // for detail page
    let detail;
    switch(page){
      case 'residence':
        detail = get(props, 'state.residence.detail', null);
        if (detail && detail.name) {
          pageTitle = `${detail.name}`
            +`｜${t('title.recedence_office_detail')}`;
          pageKeywords = `${detail.name}, ${t('seo.residences.meta_keywords_detail')}`;
          pageDescription = `${detail.description}`||'';
        }
        break;
      case 'office':
        detail = get(props, 'state.officeDetail.detail', null);
        if (detail && detail.name) {
          pageTitle =`${detail.name}`
            +`｜${t('title.recedence_office_detail')}`;
          pageKeywords = `${t('seo.offices.meta_keywords_detail')}`;
          pageDescription = `${detail.description}`||'';
        }
        break;
      case 'blog':
        detail = get(props, 'state.blogs.blog', null);
        if (detail && detail.title) {
          pageTitle = `${detail.title}｜${t('title.blogs.blog_detail')}`
              +`｜${t('title.blogs.blog_latest_title')}` ;
          pageKeywords = `${t('seo.blogs.meta_keywords_detail')}`;
          pageDescription = `${t('seo.blogs.meta_description_detail')}`;
        }
        break;
      case 'project':
        detail = get(props, 'state.projects.project', null);
        if (detail && detail.name) {
          pageTitle = (`${detail.seo.title}`||`${detail.name}${t('title.list_property')}`)
            + `｜${t('title.latest_title')}`;
          pageKeywords = `${t('seo.projects.meta_keywords_detail')}`;
          pageDescription = `${detail.seo.metaDescription}`||detail.description||'';
        }
        break;
      case 'special':
        detail = get(props, 'state.special.detail', null);
        if (detail && detail.name) {
          pageTitle =  `${detail.seo.title||detail.specialName}`;
          pageKeywords = `${detail.seo.metaKeywords}`;
          pageDescription = `${detail.seo.metaDescription}`;
        }
        break;
    }
  } else if (listPage.includes(page)) {
    switch(page){
      case 'useful-information':
        if (location.pathname.includes('/useful-service-apartment/area-guide')) {
          pageTitle = `${t('title.useful_information.area_guide')}｜${t('title.latest_title')}`;
          pageKeywords = `${t('seo.useful_information.useful_service_apartment.meta_keywords_area_guide')}`;
          pageDescription = `${t('seo.useful_information.useful_service_apartment.meta_description_area_guide')}`;
        }
        else if (location.pathname.includes('/useful-service-apartment/apartment-renting-with-a-pet')) {
          pageTitle = `${t('title.useful_information.apartment_renting_with_a_pet')}｜${t('title.latest_title',)} `;
          pageKeywords = `${t('seo.useful_information.useful_service_apartment.meta_keywords_apartment_renting')}`;
          pageDescription = `${t('seo.useful_information.useful_service_apartment.meta_description_apartment_renting')}`;
        }
        else if (location.pathname.includes('/useful-service-apartment/difference-between-office-and-serviced-office')) {
          pageTitle = `${t('title.useful_information.difference_between_office_and_serviced_office')}｜${t(
            'title.latest_title',
          )} `;
          pageKeywords = `${t('seo.useful_information.useful_service_apartment.meta_keywords_difference_between_office')}`;
          pageDescription = `${t('seo.useful_information.useful_service_apartment.meta_description_difference_between_office')}`;
        }
        else if (location.pathname.includes('/useful-service-apartment/difference-between-condominium-and-serviced-apartment')) {
          pageTitle = `${t('title.useful_information.difference_between_condominium_and_serviced_apartment')}｜${t(
            'title.latest_title',
          )} `;
          pageKeywords = `${t('seo.useful_information.useful_service_apartment.meta_keywords_difference_between_condominium')}`;
          pageDescription = `${t('seo.useful_information.useful_service_apartment.meta_description_difference_between_condominium')}`;
        }
        else if (location.pathname.includes('/useful-service-apartment')) {
          if(isMobile) hiddenH1 = `${t('title.h1_useful_service_apartment')}`;
          pageTitle = `${t('title.useful_information.useful_service_apartment')}｜${t('title.latest_title',)} `;
          pageKeywords = `${t('seo.useful_information.useful_service_apartment.meta_keywords')}`;
          pageDescription = `${t('seo.useful_information.useful_service_apartment.meta_description')}`;
        }
        else if (subPage === 'faq') {
          pageTitle = `${t('title.useful_information.faqs')}｜${t('title.latest_title',)}`;
          pageKeywords = `${t('seo.useful_information.faq.meta_keywords')}`;
          pageDescription = `${t('seo.useful_information.faq.meta_description')}`;
        } else if (subPage === 'our-customer-support-service') {
          if(isMobile) hiddenH1 = `${t('title.h1_our_customer_support_service')}`;
          pageTitle = `${t('title.useful_information.our_customer_support_service')}｜${t('title.latest_title',)}`;
          pageKeywords = `${t('seo.useful_information.our_customer_support_service.meta_keywords')}`;
          pageDescription = `${t('seo.useful_information.our_customer_support_service.meta_description')}`;
        } else if (subPage === 'useful-information-for-tenants') {
          pageTitle = `${t('title.useful_information.useful_information_for_tenants')}｜${t('title.latest_title',)}`;
          pageKeywords = `${t('seo.useful_information.useful_information_for_tenants.meta_keywords')}`;
          pageDescription = `${t('seo.useful_information.useful_information_for_tenants.meta_description')}`;
        } else if (subPage === 'rental-flow') {
          if(isMobile) hiddenH1 = `${t('title.h1_rental_flow')}`;
          pageTitle = `${t('title.useful_information.rental_flow')}｜${t('title.latest_title',)}`;
          pageKeywords = `${t('seo.useful_information.rental_flow.meta_keywords')}`;
          pageDescription = `${t('seo.useful_information.rental_flow.meta_description')}`;
        } else {
          pageTitle = `${t('title.useful_information.useful_information')}`;
          pageKeywords = `${t('seo.useful_information.meta_keywords')}`;
          pageDescription = `${t('seo.useful_information.meta_description')}`;
        }
        break;
      case 'for-owners':
        switch(subPage){
          case 'create-your-property':
            pageTitle = `${t('title.for_owners.create_your_property')}`;
            pageKeywords = `${t('seo.for_owners.create_your_property.meta_keywords')}`;
            pageDescription = `${t('seo.for_owners.create_your_property.meta_description')}`;
            break;
          case 'faqs':
            pageTitle = `${t('title.for_owners.faqs')}`;
            pageKeywords = `${t('seo.for_owners.faq.meta_keywords')}`;
            pageDescription = `${t('seo.for_owners.faq.meta_description')}`;
            break;
          default:
            hiddenH1 = `${t('title.h1_for_owners')}`;
            pageTitle = `${t('title.for_owners.for_owners')}`;
            pageKeywords = `${t('seo.for_owners.meta_keywords')}`;
            pageDescription = `${t('seo.for_owners.meta_description')}`;
        }
        break;
      case 'about-isn':
        switch(subPage){
          case 'company-profile':
            pageTitle = `${t('title.about_isn.company_profile')}`;
            pageKeywords = `${t('seo.about_isn.company_profile.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.company_profile.meta_description')}`;
            break;
          case 'company-philosophy':
            pageTitle = `${t('title.about_isn.company_philosophy')}`;
            pageKeywords = `${t('seo.about_isn.company_philosophy.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.company_philosophy.meta_description')}`;
            break;
          case 'our-company-feature':
            pageTitle = `${t('title.about_isn.our_company_feature')}`;
            pageKeywords = `${t('seo.about_isn.our_company_feature.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.our_company_feature.meta_description')}`;
            break;
          case 'meet-our-team':
            pageTitle = `${t('title.about_isn.meet_our_team')}`;
            pageKeywords = `${t('seo.about_isn.meet_our_team.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.meet_our_team.meta_description')}`;
            break;
          case 'mess-from-rep':
            pageTitle = `${t('title.about_isn.mess-from-rep')}`;
            pageKeywords = `${t('seo.about_isn.mess-from-rep.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.mess-from-rep.meta_description')}`;
            break;
          case 'group-companies':
            pageTitle = `${t('title.about_isn.group_companies')}`;
            pageKeywords = `${t('seo.about_isn.group_companies.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.group_companies.meta_description')}`;
            break;
          case 'preparation-page':
            pageTitle = `${t('title.about_isn.preparation_page')}`;
            break;
          case 'history':
            pageTitle = `${t('title.about_isn.history')}`;
            pageKeywords = `${t('seo.about_isn.history.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.history.meta_description')}`;
            break;
          case 'our-main-services':
            pageTitle = `${t('title.about_isn.our_main_services')}`;
            pageKeywords = `${t('seo.about_isn.our_main_services.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.our_main_services.meta_description')}`;
            break;
          case 'why-choose-us':
            pageTitle = `${t('title.about_isn.why_choose_us')}`;
            pageKeywords = `${t('seo.about_isn.why_choose_us.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.why_choose_us.meta_description')}`;
            break;
          case 'news-release':
            pageTitle = `${t('title.about_isn.news_release')}`;
            break;
          case 'contact-us':
            pageTitle = `${t('title.about_isn.contact_us')}`;
            pageKeywords = `${t('seo.about_isn.contact_us.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.contact_us.meta_description')}`;
            break;
          case 'notification':
            pageTitle = `${t('title.about_isn.notification')}`;
            break;
          default:
            hiddenH1 = `${t('h1_about_isn')}`;
            pageTitle = `${t('title.about_isn.about_isn')}`;
            pageKeywords = `${t('seo.about_isn.meta_keywords')}`;
            pageDescription = `${t('seo.about_isn.meta_description')}`;
        }
        pageTitle = pageTitle + `｜${t('title.about_isn.about_isn_latest_title')}`;
        break;
      case 'favorites':
        hiddenH1 = `${t('title.h1_favorites')}`;
        pageTitle = `${t('title.favorite')}｜${t('title.latest_title')}`;
        pageKeywords = `${t('seo.favorites.meta_keywords')}`;
        pageDescription = `${t('seo.favorites.meta_description')}`;
        break;
      case 'histories':
        hiddenH1 = `${t('title.h1_histories')}`;
        pageTitle = `${t('title.history')}｜${t('title.latest_title')}`;
        pageKeywords = `${t('seo.histories.meta_keywords')}`;
        pageDescription = `${t('seo.histories.meta_description')}`;
        break;
      case 'projects':
        pageTitle = `${t('title.projects')}｜${t('title.latest_title')}`;
        pageKeywords = `${t('seo.projects.meta_keywords')}`;
        pageDescription = `${t('seo.projects.meta_description')}`;
        break;
      case 'residences':
        hiddenH1 = `${t('title.h1_residences')}`;
        pageTitle = `${t('title.residences')}｜${t('title.latest_title')}`;
        pageKeywords = `${t('seo.residences.meta_keywords')}`;
        pageDescription = `${t('seo.residences.meta_description')}`;
        break;
      case 'offices':
        hiddenH1 = `${t('title.h1_office')}`;
        pageTitle = `${t('title.offices')}｜${t('title.latest_title')}`;
        pageKeywords = `${t('seo.offices.meta_keywords')}`;
        pageDescription = `${t('seo.offices.meta_description')}`;
        break;
    }
  } else {
    hiddenH1 = `${t('title.h1')}`;
    pageTitle = `${t('title.home')}`;
    pageKeywords = `${t('seo.home.meta_keywords')}`
    pageDescription = `${t('seo.home.meta_description')}`
  }
  /* eslint-enable */

  EventBus.on('checkBodyScroll', data => setIsHiddenScroll(data));
  return (
    <div className={`wrapper ${language} ${isHiddenScroll ? 'h-100vh' : ''}`}>
      <Helmet>
        <html lang={language}/>
        <title>{pageTitle || `${t('title.home')}`}</title>
        <meta name="keywords" content={pageKeywords || `${t('seo.home.meta_keywords')}`}/>
        <meta name="description"
          content={(pageDescription || `${t('seo.home.meta_description')}`).replace(/<[^>]*>/g, ' ').slice(0, 300)}/>
      </Helmet>
      <Header />
      {!hiddenH1 ? '' : (<h1 className="header-title-hide">{hiddenH1}</h1>)}
      <div className="public-layout">{props.children}</div>
      {location.pathname === '/' ||
      location.pathname === pathLanguage ||
      location.pathname === `${pathLanguage}/` ||
      location.pathname === `${pathLanguage}/residences` ||
      location.pathname === `${pathLanguage}/offices` ? (
        <Subscribe />
      ) : (
        ''
      )}
      {location.pathname === '/' ||
      location.pathname === pathLanguage ||
      location.pathname === `${pathLanguage}/` ||
      location.pathname === `${pathLanguage}/residences` ||
      location.pathname === `${pathLanguage}/offices` ? (
        <PartnerSlides />
      ) : (
        ''
      )}
      <Footer />
      {props.loading && props.loading.loading ? (
        <div className="wrap-loading">
          <ReactLoading type={'spinningBubbles'} height={'80px'} color="#fff" width={'80px'} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = state => ({ state });

export default connect(mapStateToProps, null)(PublicLayout);
