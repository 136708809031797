import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IconOutCompany } from '../../../commons/iconService';

const OurCompanyContent = props => {
  const { data } = props.aboutIsn;

  return (
    <div className="row">
      {data && data.length
        ? data.slice(0, 5).map((item, index) => (
            <>
              <div className="col-2 col-md-1"></div>
              <div className="col-10 col-md-11 company-feature-block" key={index}>
                <div className="company-feature-image">
                  <img src={IconOutCompany[`company${index + 1}`]} alt="" />
                  {/* <LazyLoadImage src={IconOutCompany[`company${index + 1}`]} alt="" effect="blur" /> */}
                </div>
                <div className="company-feature">
                  <div className="feature-title jp-bold-600">{item.name}</div>
                  <hr className="feature-line" />
                  <div className="feature-description jp-font-sawarabi-gothic">{item.description}</div>
                </div>
              </div>
            </>
          ))
        : ''}{' '}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OurCompanyContent);
