import React from 'react';

import { useTranslation } from 'react-i18next';

const PreparationPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="preparation-page jp-400-sawarabi">
        <span>{t('preparation.message')}</span>
      </div>
    </>
  );
};

export { PreparationPage };
