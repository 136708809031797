import * as types from './home.types';

const initialState = {
  lastProperties: [],
  hotProperties: [],
  blogs: [],
  projects: [],
  specialList: [],
  error: null,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_LASTEST_PROPERTIES_REQUEST:
      return { ...state };
    case types.FETCH_LASTEST_PROPERTIES_SUCCESS:
      return { ...state, lastProperties: action.data.lastProperties };
    case types.FETCH_LASTEST_PROPERTIES_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_HOT_PROPERTIES_REQUEST:
      return { ...state };
    case types.FETCH_HOT_PROPERTIES_SUCCESS:
      return { ...state, hotProperties: action.data.hotProperties };
    case types.FETCH_HOT_PROPERTIES_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_BLOGS_REQUEST:
      return { ...state };
    case types.FETCH_BLOGS_SUCCESS:
      return { ...state, blogs: action.data.blogs };
    case types.FETCH_BLOGS_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_PROJECTS_REQUEST:
      return { ...state };
    case types.FETCH_PROJECTS_SUCCESS:
      return { ...state, projects: action.data.projects };
    case types.FETCH_PROJECTS_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_SPECIAL_LIST_REQUEST:
      return { ...state };
    case types.FETCH_SPECIAL_LIST_SUCCESS:
      return { ...state, specialList: action.data.specialList };
    case types.FETCH_SPECIAL_LIST_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default homeReducer;
