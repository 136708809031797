import React, { useRef, useState, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Pagination from 'react-js-pagination';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EventBus from 'eventing-bus';
import ReactPaginate from 'react-paginate';

import { ICON_LEFT, ICON_RIGHT } from '../../../../utils/constants';
import { Location, HeartIcon, BedIcon, SizeIcon } from '../../../../layouts/commons/icons';
import { formatMoney } from '../../../../utils/objects';
import previousIcon from '../../../../assets/images/arrow-left.png';
import nextIcon from '../../../../assets/images/arrow-right.png';
import { getPathLanguage } from '../../../../utils/params';
import imageDefault from '../../../../assets/images/loading-image.jpg';
import { isMobile } from '../../../../utils/constants';

const ProjectBlock = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const projectRef = useRef(null);
  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    dots: true,
    autoplay: false,
    autoHeight: true,
    responsive: {},
    loop: true,
  };

  const [likeData, setLikeData] = useState([]);

  useEffect(() => {
    setLikeData(localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : []);
    // eslint-disable-next-line
  }, []);

  const onClickLike = post => {
    const data = localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : [];
    let listData = [];
    if (data.includes(post.id)) listData = data.filter(i => i !== post.id);
    else listData = [...data, post.id];
    setLikeData(listData);
    localStorage.setItem(props.section, listData);
    EventBus.publish('RESET_LIKE', true);
  };

  return (
    <>
      <div className="section-block background-gray p-0">
        <div className="container">
          <div className="list-project">
            <div className="row">
              {props.data && props.data.items && props.data.items.length
                ? props.data.items.map((item, index) => {
                    if (item.name) item.name = item.name.replace('　', ' ');
                    return (
                      <div key={index} className="col-12 col-md-4 thumbnail-project">
                        <div className="block-inner project search element-carousel">
                          <div className="block-image">
                            <div className="thumbail-slides">
                              {item.images && item.images.length ? (
                                <OwlCarousel options={options} ref={projectRef}>
                                  {item.images.map((value, i) => (
                                    <Link
                                      to={`${pathLanguage}/${item.propertyType}/detail/${item.id}/${item.slug}`}
                                      key={i}
                                      className="w-100"
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${value}`}
                                        alt={`key-${i}`}
                                      />
                                      {/* <LazyLoadImage
                                      src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${value}`}
                                      alt={`key-${i}`}
                                      effect="blur"
                                    /> */}
                                    </Link>
                                  ))}
                                </OwlCarousel>
                              ) : (
                                <Link to={`${pathLanguage}/${item.propertyType}/detail/${item.id}/${item.slug}`}>
                                  <div className="w-100">
                                    <img src={imageDefault} className="favourite-notfound" alt="" />
                                  </div>
                                </Link>
                              )}
                              <span
                                onClick={() => onClickLike(item)}
                                className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                              >
                                <HeartIcon />
                              </span>
                            </div>
                          </div>
                          <Link to={`${pathLanguage}/${item.propertyType}/detail/${item.id}/${item.slug}`}>
                            <div className="block-text text-full-custom">
                              <h4>{item.name || ''}</h4>
                              <div className="property-info">
                                <ul>
                                  {item.district && (
                                    <li className="icon-district">
                                      <Location />
                                      {item.district}
                                    </li>
                                  )}
                                  <li className="icon-bedroom">
                                    <BedIcon />
                                    {item.bedroommin ? item.bedroommin : t('home_page.studio')}
                                    {item.bedroomMax ? ` ~ ${item.bedroomMax}` : ''}
                                    <span>&nbsp;{t('search_page.bedrooms')}</span>
                                  </li>
                                </ul>

                                <div className="info-residence">
                                  {item.leasePriceMin || item.leasePriceMax ? (
                                    <div className="price">
                                      <span className="project-prices">
                                        {item.leasePriceMin || item.leasePriceMax
                                          ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                          : ''}
                                        {item.leasePriceMax ? ` ~ $${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                      </span>
                                      {item.leasePriceMin || item.leasePriceMax ? (
                                        <span>/{t('search_page.month')}</span>
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                  <div className="size">
                                    {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                    {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                    {item.sqmMax ? ' ~ ' : ''}
                                    {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                    {item.sqmmin || item.sqmMax ? (
                                      <>
                                        m<sup>2</sup>
                                      </>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
            <div className="pagination">
              {props.data && props.data.totalRecords && props.data.totalRecords > 9 ? (
                <ReactPaginate
                  //   activePage={props.page && props.page.pageIndex}
                  //   itemsCountPerPage={(props.page && props.page.pageSize) || 9}
                  //   totalItemsCount={props.data.totalRecords || 0}
                  //   pageRangeDisplayed={5}
                  //   hideFirstLastPages={true}
                  //   nextPageText={<LazyLoadImage alt="" src={nextIcon} effect="blur" />}
                  //   prevPageText={<LazyLoadImage alt="" src={previousIcon} effect="blur" />}
                  //   activeClass={'pagination-active'}
                  //   onChange={page => props.changeItemInPage({ pageIndex: parseInt(page) })}
                  //   itemClass={'pagination-pager-item'}

                  forcePage={props.page && props.page.pageIndex - 1}
                  pageCount={props.pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={isMobile ? 1 : 2}
                  previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                  nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                  activeClassName={'pagination-active'}
                  onPageChange={page => props.changeItemInPage({ pageIndex: parseInt(page.selected) + 1 })}
                  pageClassName={'pagination-pager-item'}
                  disabledClassName={'opacity-disable'}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectBlock;
