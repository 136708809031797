import * as types from './aboutIsn.types';

export const fetchAboutIsnRequest = params => ({ type: types.FETCH_ABOUT_ISN_REQUEST, params });
export const fetchAboutIsnSuccess = data => ({ type: types.FETCH_ABOUT_ISN_SUCCESS, data });
export const fetchAboutIsnFailure = error => ({ type: types.FETCH_ABOUT_ISN_FAILURE, error });

export const getGroupCompanyRequest = params => ({ type: types.GET_GROUP_COMPANY_REQUEST, params });
export const getGroupCompanySuccess = data => ({ type: types.GET_GROUP_COMPANY_SUCCESS, data });
export const getGroupCompanyFailure = error => ({ type: types.GET_GROUP_COMPANY_FAILURE, error });

export const getHistoryRequest = params => ({ type: types.GET_HISTORY_REQUEST, params });
export const getHistorySuccess = data => ({ type: types.GET_HISTORY_SUCCESS, data });
export const getHistoryFailure = error => ({ type: types.GET_HISTORY_FAILURE, error });

export const getNewsReleaseRequest = params => ({ type: types.GET_NEWS_RELEASE_REQUEST, params });
export const getNewsReleaseSuccess = data => ({ type: types.GET_NEWS_RELEASE_SUCCESS, data });
export const getNewsReleaseFailure = error => ({ type: types.GET_NEWS_RELEASE_FAILURE, error });

export const getOfficeInformationRequest = params => ({ type: types.GET_OFFICE_INFORMATION_REQUEST, params });
export const getOfficeInformatioSuccess = data => ({ type: types.GET_OFFICE_INFORMATION_SUCCESS, data });
export const getOfficeInformatioFailure = error => ({ type: types.GET_OFFICE_INFORMATION_FAILURE, error });

export const postContactUsRequest = params => ({ type: types.POST_CONTACT_US_REQUEST, params });

export const getNewsContentRequest = params => ({ type: types.GET_NEWS_CONTENT_REQUEST, params });
export const getNewsContentSuccess = data => ({ type: types.GET_NEWS_CONTENT_SUCCESS, data });
export const getNewsContentFailure = error => ({ type: types.GET_NEWS_CONTENT_FAILURE, error });

export const fetchOtherNewsRequest = params => ({ type: types.FETCH_OTHER_NEWS_REQUEST, params });
export const fetchOtherNewsSuccess = data => ({ type: types.FETCH_OTHER_NEWS_SUCCESS, data });
export const fetchOtherNewsFailure = error => ({ type: types.FETCH_OTHER_NEWS_FAILURE, error });
