import { get } from 'lodash';
import Service from '../../services';

export const getProject = async params => {
  const response = await Service.get(`/api/projects/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchProperties = async params => {
  const response = await Service.get(`/api/properties/get-by-project${params}`, res => res);
  return response;
};

export const fetchProjects = async params => {
  const response = await Service.get(`/api/projects/all${params}`, res => res);
  return response;
};
