import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EventBus from 'eventing-bus';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Location, HeartIcon, SizeIcon, VideoIcon } from '../../../layouts/commons/icons';
import { getPathLanguage } from '../../../utils/params';
import imageDefault from '../../../assets/images/loading-image.jpg';
import { formatMoney } from '../../../utils/objects';

const BlockOfficeComponent = props => {
  const { post } = props;
  const { t } = useTranslation();
  const location = useLocation();
  let pathLanguage = getPathLanguage(location);

  const [likeData, setLikeData] = useState([]);

  useEffect(() => {
    setLikeData(localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : []);
    // eslint-disable-next-line
  }, []);

  const onClickLike = () => {
    const data = localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : [];
    let listData = [];
    if (data.includes(post.id)) listData = data.filter(i => i !== post.id);
    else listData = [...data, post.id];
    setLikeData(listData);
    localStorage.setItem(props.section, listData);
    EventBus.publish('RESET_LIKE', true);
  };

  if (post.name) post.name = post.name.replace('　', ' ');

  return (
    <>
      <div className="block-inner">
        <div className="block-image">
          <div className="thumbail-slides thubnail-custom">
            {post.images && post.images.length ? (
              <Link
                to={
                  `${pathLanguage}/office/detail/${post.id}/${post.slug}${
                    props.metaKey === 'last' ? '?last' : props.metaKey === 'hot' ? '?hot' : ''
                  }` || ''
                }
                className="block-handle-click"
              >
                <img alt="" src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${post.images[0]}`} />
                {/* <LazyLoadImage
                  alt=""
                  src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${post.images[0]}`}
                  effect="blur"
                /> */}
              </Link>
            ) : (
              !props.loading && (
                <Link
                  to={
                    `${pathLanguage}/office/detail/${post.id}/${post.slug}${
                      props.metaKey === 'last' ? '?last' : props.metaKey === 'hot' ? '?hot' : ''
                    }` || ''
                  }
                >
                  <img alt="" src={imageDefault} />
                  {/* <LazyLoadImage alt="" src={imageDefault} effect="blur" /> */}
                </Link>
              )
            )}
          </div>
          <span
            onClick={() => onClickLike()}
            className={`heart circle ${likeData.includes(post.id) ? 'active-like' : ''}`}
          >
            <HeartIcon />
          </span>
          {post.videoLink ? (
            <span className="icon-video">
              <VideoIcon />
            </span>
          ) : (
            ''
          )}
        </div>
        <Link
          className="w-100 h-100"
          to={
            `${pathLanguage}/office/detail/${post.id}/${post.slug}${
              props.metaKey === 'last' ? '?last' : props.metaKey === 'hot' ? '?hot' : ''
            }` || ''
          }
        >
          <div className="block-text block-office none-split">
            <h4>{post.name}</h4>
            <div className="property-info">
              <ul>
                <li className="icon-district">
                  <Location />
                  {post.district}
                </li>
                <li className="icon-size">
                  {post.sqmmin || post.sqmMax ? <SizeIcon /> : ''}
                  {post.sqmmin || post.sqmMax ? formatMoney(post.sqmmin, 0, 0) : ''}
                  {post.sqmMax ? ' ~ ' : ''}
                  {post.sqmMax ? formatMoney(post.sqmMax, 0, 0) : ''}
                  {post.sqmmin || post.sqmMax ? (
                    <>
                      m<sup>2</sup>
                    </>
                  ) : (
                    ''
                  )}
                </li>
              </ul>
              <div className="price">
                {post.leasePriceMin || post.leasePriceMax ? `$${formatMoney(post.leasePriceMin, 0, 0)}` : ''}
                {post.leasePriceMax ? ` ~ $${formatMoney(post.leasePriceMax, 0, 0)}` : ''}
                {post.leasePriceMin || post.leasePriceMax ? (
                  <span>
                    /m<sup>2</sup>/{t('home_page.partial.month')}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default BlockOfficeComponent;
