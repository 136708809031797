import React, { useState } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

const MapComponent = props => {
  const [googleMarket, setGoogleMarket] = useState({ showingInfoWindow: false, activeMarker: {}, selectedPlace: {} });

  const onMarkerClick = (props, marker) =>
    setGoogleMarket({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  const onMapClicked = props => {
    if (googleMarket.showingInfoWindow) {
      setGoogleMarket({ selectedPlace: props, showingInfoWindow: false, activeMarker: null });
    }
  };
  return (
    <div style={{ height: 400 }}>
      <Map
        google={props.google}
        zoom={3}
        initialCenter={{
          lat: 16.463483,
          lng: 107.595243,
        }}
        style={{ height: 400, width: '100%' }}
        onClick={onMapClicked}
      >
        {props.markers.map((item, index) => (
          <Marker key={index} name={item.name} position={{ lat: item.lat, lng: item.lng }} onClick={onMarkerClick} />
        ))}
        <InfoWindow marker={googleMarket.activeMarker} visible={googleMarket.showingInfoWindow}>
          <div className="p-2 marker-info-window">
            <h6>{googleMarket.selectedPlace.name}</h6>
            <div className="pt-2 pb-2">
              A module to transform a description of a location (i.e. street address, town name, etc.) into geographic
              coordinates (i.e. latitude and longitude) and vice versa.
            </div>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_KEY_GOOGLE_MAP,
})(MapComponent);
