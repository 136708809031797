import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams, Redirect } from 'react-router-dom';
import { getPathLanguage } from '../../../../utils/params';

const NotificationComponent = () => {
  const { t } = useTranslation();
  // const language = getLanguage(useLocation());
  const pathLanguage = getPathLanguage(useLocation());
  const params = useParams();
  window.scrollTo(0, 0);

  return (
    <div className="container">
      {params.type === 'contact-us' || params.type === 'subscribe' ? (
        <div className="notification-page">
          {params.type === 'contact-us' ? (
            <>
              <div className="notification-title">{t('notification.thank_you')}</div>

              <div
                className="notification-content jp-400-sawarabi"
                dangerouslySetInnerHTML={{ __html: t('notification.your_inquiry_sent_successfully') || '' }}
              ></div>
            </>
          ) : (
            <div className="notification-subscribe jp-400-sawarabi">
              {t('notification.subscribe_sent_successfully')}
            </div>
          )}
        </div>
      ) : (
        <Redirect to={pathLanguage} />
      )}
    </div>
  );
};

export default NotificationComponent;
