const ARROW_LEFT = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-left"><line x1="19" y1="12" x2="5" y2="12"></line>
                      <polyline points="12 19 5 12 12 5"></polyline></svg>`;
const ARROW_RIGHT = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline></svg>`;

const ICON_LEFT = `<svg xmlns="http://www.w3.org/2000/svg"
                    id="prefix__Icon_chevron-left" width="24" height="24" viewBox="0 0 24 24">
                    <path id="prefix__Rectangle_679" fill="none" d="M0 0H24V24H0z" data-name="Rectangle 679"/>
                    <path id="prefix__chevron-left" fill="none" stroke="#131313" stroke-linecap="round"
                    stroke-linejoin="round" d="M15 18l-6-6 6-6" transform="translate(.5 .5)"/></svg>`;
const ICON_RIGHT = `<svg xmlns="http://www.w3.org/2000/svg" id="prefix__Icon_chevron-left"
                    width="24" height="24" viewBox="0 0 24 24" style="transform: rotate(180deg);">
                    <path id="prefix__Rectangle_679" fill="none" d="M0 0H24V24H0z" data-name="Rectangle 679"/>
                    <path id="prefix__chevron-left" fill="none" stroke="#131313" stroke-linecap="round"
                    stroke-linejoin="round" d="M15 18l-6-6 6-6" transform="translate(.5 .5)"/></svg>`;

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export { ARROW_LEFT, ARROW_RIGHT, ICON_LEFT, ICON_RIGHT, isMobile };
