import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getPathLanguage } from '../../../../utils/params';
import img1 from '../../../../assets/images/blog-category1.png';
import img2 from '../../../../assets/images/blog-category2.png';
import img3 from '../../../../assets/images/blog-category3.png';
import img4 from '../../../../assets/images/blog-category4.png';
import img5 from '../../../../assets/images/blog-category5.png';

const Header = () => {
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const { t } = useTranslation();

  const categories = [
    {
      title: t('blogs.all_blogs'),
      image: img1,
      link_to: `${pathLanguage}/blogs/all`,
      active: location.pathname.includes('/blogs/all'),
    },
    {
      title: t('blogs.news'),
      image: img2,
      link_to: `${pathLanguage}/blogs/news/1`,
      active: location.pathname.includes('/blogs/news/1'),
    },
    {
      title: t('blogs.newly_launched_properties'),
      image: img3,
      link_to: `${pathLanguage}/blogs/newly-launched-properties/2`,
      active: location.pathname.includes('/blogs/newly-launched-properties/2'),
    },
    {
      title: t('blogs.c_s_statistics'),
      image: img4,
      link_to: `${pathLanguage}/blogs/c-s-statistics/3`,
      active: location.pathname.includes('/blogs/c-s-statistics/3'),
    },
    {
      title: t('blogs.living_in_vietnam'),
      image: img5,
      link_to: `${pathLanguage}/blogs/living-in-vietnam/4`,
      active: location.pathname.includes('/blogs/living-in-vietnam/4'),
    },
  ];

  return (
    <div className="row">
      {categories.map((item, index) => (
        <div className="col-12 col-md" key={index}>
          <Link to={item.link_to} className={`blog-category ${item.active ? 'actived' : ''}`}>
            <img src={item.image} alt="" className="img-category" />
            {/* <LazyLoadImage src={item.image} alt="" className="img-category" effect="blur" /> */}
            <h3 className="category-name">{item.title}</h3>
          </Link>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
