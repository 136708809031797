import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { getLanguage } from '../../../../utils/params';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

const Header = props => {
  const language = getLanguage(useLocation());
  return (
    <div className="section-page-header">
      <div className="page-header">
        <div className="caption">
          <img src={props.content.background} className="w-100 h-100" alt="" />
          {/* <LazyLoadImage src={props.content.background} className="w-100 h-100" alt="" effect="blur" /> */}
          <div className="for-owners-overlay"></div>
          <div className="header-title">
            <h1 className="main-title" style={{ fontFamily: `${language === 'en' ? 'Noto Serif' : ''}` }}>
              {props.content.vietnameseTitle}
            </h1>
            <span className="transalte-title">{props.content.englishTitle}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
