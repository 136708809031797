import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import MainServicesContentItem from './MainServicesContentItem';

const MainServicesContent = props => {
  const { t } = useTranslation();
  const { data } = props.aboutIsn;

  return (
    <>
      <MainServicesContentItem
        data={data && data.length && data.slice(0, 3)}
        title={t('about_isn.isn_group_page.real_estate_brokerage')}
        startAt={0}
      />
      <MainServicesContentItem
        data={data && data.length && data.slice(3, 6)}
        title={t('about_isn.isn_group_page.property_management')}
        startAt={3}
      />
      <MainServicesContentItem
        data={data && data.length && data.slice(6)}
        title={t('about_isn.isn_group_page.other_business')}
        startAt={6}
      />
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MainServicesContent);
