import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Location, PhoneCall, EmailIcon } from '../icons';
// import Twitter from '../../../assets/images/twitter.png';
import FBLogo from '../../../assets/images/bxl-facebook-circle.svg';
import Facebook from '../../../assets/images/facebook.png';
import BackTop from '../../../assets/images/chevrons-up.svg';
import * as action from '../../redux/layout.action';
import { getLanguage } from '../../../utils/params';

const Footer = props => {
  const language = getLanguage(useLocation());
  const { office } = props.layout;

  useEffect(() => {
    props.getOfficeInformationRequest({ language });
    props.downloadMagazine({ language });
    //eslint-disable-next-line
  }, []);

  // const { fileDownload } = props.layout;

  return (
    <>
      {/* <!-- Footer --> */}
      <footer className="section-block footer background-black">
        <div className="container">
          <div className="row offices">
            {office && office.length
              ? office.map((item, index) => {
                  item.phone = item.phone.replace(/\n/g, '<br />');
                  return (
                    index < 3 ? (
                      <div className="col-12 col-sm-6 col-md-4 col-lg" key={index}>
                        <div className="block">
                          <h4>{item.name || ''}</h4>
                          <div className="block-text block-location" style={{ marginBottom: 30 }}>
                            <div className="row">
                              <div className="col-1 col-md-2">
                                <Location/>
                              </div>
                              <div className="col-11 col-md-10">
                                <span>{item.address || ''}</span>
                              </div>
                            </div>
                          </div>
                          <div className="block-text block-phone">
                            <div className="row">
                              <div className="col-1 col-md-2">
                                <PhoneCall/>
                              </div>
                              <div className="col-11 col-md-10">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.phone || '',
                                }}
                              ></span>
                              </div>
                            </div>
                          </div>
                          <div className="block-text">
                            <div className="row">
                              <div className="col-1 col-md-2">
                                <EmailIcon/>
                              </div>
                              <div className="col-11 col-md-10">
                                <span>{item.email || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )
                })
              : ''}
            <div className="col-12 col-sm-6 col-md-4 col-lg" key={4}>
              <div className="block">
                <h4>{language == 'jp'? 'ダナン支店':'DA NANG OFFICE'}</h4>
                <div className="block-text block-location" style={{ marginBottom: 30 }}>
                  <div className="row">
                    <div className="col-1 col-md-2">
                      <Location/>
                    </div>
                    <div className="col-11 col-md-10">
                      <span>8F, Bach Dang Complex, 50 Bach Dang St., Hai Chau I Ward, Hai Chau District, Da Nang</span>
                    </div>
                  </div>
                </div>
                <div className="block-text block-phone">
                  <div className="row">
                    <div className="col-1 col-md-2">
                      <PhoneCall/>
                    </div>
                    <div className="col-11 col-md-10">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: '(+84) 91 - 678 - 4649',
                                }}
                              ></span>
                    </div>
                  </div>
                </div>
                <div className="block-text">
                  <div className="row">
                    <div className="col-1 col-md-2">
                      <EmailIcon/>
                    </div>
                    <div className="col-11 col-md-10">
                      <span>info@vietnamhousing.vn</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg" key={3}>
              <div className="block">
                <h4>{language == 'jp'? '東京支店':'TOKYO OFFICE'}</h4>
                <div className="block-text block-location" style={{ marginBottom: 30 }}>
                  <div className="row">
                    <div className="col-1 col-md-2">
                      <Location/>
                    </div>
                    <div className="col-11 col-md-10">
                      <span>6-3-4, Shinbashi, Minato-ku, Tokyo, 105-0004</span>
                    </div>
                  </div>
                </div>
                <div className="block-text block-phone">
                  <div className="row">
                    <div className="col-1 col-md-2">
                      <PhoneCall/>
                    </div>
                    <div className="col-11 col-md-10">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: '(+81) 3 - 6261 - 5166',
                                }}
                              ></span>
                    </div>
                  </div>
                </div>
                <div className="block-text">
                  <div className="row">
                    <div className="col-1 col-md-2">
                      <EmailIcon/>
                    </div>
                    <div className="col-11 col-md-10">
                      <span>info@vietnamhousing.vn</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{ position: 'relative' }}>
          <div className="row copy-right">
            <div className="col-12 col-6 col-md-4 order-md-2 copy-right-text text-center">
              © 2020 ISN Corporation. All rights reserved.
            </div>
            <div className="col-12 col-6 col-md-4 order-md-1 socials">
              <a href="https://www.facebook.com/ISNvietnamhousing" target="__blank">
                {/* <img src={FBLogo} alt="" /> <img src={Facebook} alt="" /> */}
                <LazyLoadImage alt="" src={FBLogo} effect="blur" />{' '}
                <LazyLoadImage alt="" src={Facebook} effect="blur" />
              </a>
              {/* <a href="https://twitter.com" target="__blank"> */}
                {/* <img src={AssetLogo} alt="" className="asset-logo" /> */}
                {/* <LazyLoadImage alt="" width={100} src={Twitter} effect="blur" /> */}
              {/* </a> */}
            </div>
            {/*<div className="col-12 col-6 col-md-4 order-md-3 download">*/}
            {/*  <a href={fileDownload} target="_blank" rel="noopener noreferrer" className="button-red button">*/}
            {/*    <Download /> Download ISN Magazine*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
          <div
            className="scroll-top"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <LazyLoadImage alt="" src={BackTop} effect="blur" className="icon-top" />
            {/* <img src={BackTop} alt="" className="icon-top" /> */}
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOfficeInformationRequest: action.getOfficeInformationRequest,
      downloadMagazine: action.downloadMagazineRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
