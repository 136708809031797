import React, { useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import { useTranslation } from 'react-i18next';
import { chunk } from 'lodash';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ARROW_LEFT, ARROW_RIGHT } from '../../../utils/constants';
import SectionTitle from '../../commons/sectionTitle/SectionTitle';
import { getPathLanguage } from '../../../utils/params';

const HomeProjectComponent = props => {
  const { t } = useTranslation();
  const owlProjects = useRef(null);
  const pathLanguage = getPathLanguage(useLocation());

  const options = {
    items: 1,
    margin: 0,
    nav: true,
    dots: false,
    autoplay: false,
    loop: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {},
  };

  const listProjects = chunk(props.data, 5);

  const rowItem = data => {
    if (!data.length) return '';
    return (
      <div className="row" style={{ minHeight: `${window.innerWidth > 576 ? '588px' : '500px'}` }}>
        <div className="col-12 col-md-6 col-left">
          <div className="block-project-inner">
            <Link className="project-link" to={`${pathLanguage}/project/detail/${data[0].id}/${data[0].slug}`}>
              <img src={data[0].thumnail} className="h-100 w-100 project-image" alt="" />

              <div className="overlay-project w-100 h-100"></div>
            </Link>
            <div className="block-caption">
              <div>
                <Link className="project-link" to={`${pathLanguage}/project/detail/${data[0].id}/${data[0].slug}`}>
                  <h3>{data[0].projectName || ''}</h3>
                </Link>
              </div>
              <Link to={`${pathLanguage}/project/list/${data[0].slug}/${data[0].id}`} className="white">
                <div className="listing">
                  {data[0].count || 0} {t('home_page.listing')}
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-right d-none d-md-block">
          <div className="row">
            {data.length > 1 && data.slice(1).length
              ? data.slice(1).map((value, index) => (
                  <div key={index} className={`col-12 col-md-6`}>
                    <div className="block-project-inner">
                      <Link to={`${pathLanguage}/project/detail/${value.id}/${value.slug}`} className="project-link">
                        <img src={value.thumnail} className="w-100 h-100 project-image" alt="" />
                        <div className="overlay-project w-100 h-100"></div>
                      </Link>
                      <div className="block-caption">
                        <div>
                          <Link to={`${pathLanguage}/project/detail/${value.id}/${value.slug}`} className="white">
                            <h3 className="white">{value.projectName || ''}</h3>
                          </Link>
                        </div>
                        <Link to={`${pathLanguage}/project/list/${value.slug}/${value.id}`} className="white">
                          <div className="listing">
                            {value.count || 0} {t('home_page.listing')}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="section-block projects background-black">
        <div className="container properties-title">
          <Link to={`${pathLanguage}/projects`}>
            <SectionTitle title={t('home_page.project')} />
          </Link>
          <span className="show-all-project">
            <Link to={`${pathLanguage}/projects`} className="jp-bold-700">
              {t('home_page.all')}
            </Link>
          </span>

          {listProjects && listProjects.length && listProjects.length === 1 ? (
            <OwlCarousel
              ref={owlProjects}
              options={options}
              className="nav-top nav-white owl-carousel owl-theme owl-loaded owl-drag"
            >
              {listProjects && listProjects[0] && listProjects[0].length ? rowItem(listProjects[0]) : ''}
            </OwlCarousel>
          ) : listProjects.length > 1 ? (
            <OwlCarousel
              ref={owlProjects}
              options={options}
              className="nav-top nav-white owl-carousel owl-theme owl-loaded owl-drag"
            >
              {listProjects && listProjects[0] && listProjects[0].length ? rowItem(listProjects[0]) : ''}
              {listProjects && listProjects[1] && listProjects[1].length ? rowItem(listProjects[1]) : ''}
            </OwlCarousel>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default HomeProjectComponent;
