/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Pagination from 'react-js-pagination';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPaginate from 'react-paginate';
import BannerProject from '../../../../assets/images/banner-projects.jpg';
import imageDefault from '../../../../assets/images/loading-image.jpg';
import previousIcon from '../../../../assets/images/arrow-left.png';
import nextIcon from '../../../../assets/images/arrow-right.png';
import { getPathLanguage } from '../../../../utils/params';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { isMobile } from '../../../../utils/constants';
import { BannerAbout } from '../../../aboutIsnPage/common';


const ProjecstList = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const cityID = localStorage.getItem('LOCATION_CITY');
  const cityArray= props.layout?.cities ? props.layout?.cities : [];
  const [nameCity, setNameCity] = useState();
  useEffect(() =>{
    if (cityArray.length > 0) {
      let name = cityArray.find(o => o.cityId == cityID);
      setNameCity(name.cityName)
    }
  },[cityArray])

  const banner = {
    img: BannerProject,
    title: (!nameCity ? '': nameCity + '・') + t('project_page.project'),
  };

  return (
    <>
      <div className="section-block background-gray p-0">
      <div className="group-company banner-project-list">
        <BannerAbout banner={banner} />
      </div>
        <div className="container">
          <div className="list-project">
            <div className="row">
              {props.projects.projects && props.projects.projects.items && props.projects.projects.items.length
                ? props.projects.projects.items.map((item, index) => {
                    if (item.projectName) item.projectName = item.projectName.replace('　', ' ');
                    return (
                      <div key={index} className="col-12 col-md-4 thumbnail-project single-owl-carousel">
                        <div className="block-inner project search element-carousel">
                          <div className="block-image">
                            <div className="thumbail-slides">
                              <Link to={`${pathLanguage}/project/detail/${item.id}/${item.slug}`} className="link">
                                {item.thumnail ? <img alt="" src={item.thumnail} /> : <img alt="" src={imageDefault} />}
                              </Link>
                            </div>
                          </div>

                          <div className="block-text">
                            <Link to={`${pathLanguage}/project/detail/${item.id}/${item.slug}`} className="text">
                              <h4>{item.projectName || ''}</h4>
                            </Link>
                            <div className="property-info">
                              <Link to={`${pathLanguage}/project/list/${item.slug}/${item.id}`} className="text">
                                <div className="price">
                                  {item.count} {t('home_page.listing')}
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
            <div className="pagination">
              {props.projects.projects && props.projects.projects.totalRecords && props.projects.projects.totalRecords > 9 ? (
                <ReactPaginate
                  //   activePage={props.page && props.page.pageIndex}
                  //   itemsCountPerPage={(props.page && props.page.pageSize) || 9}
                  //   totalItemsCount={props.projects.totalRecords || 0}
                  //   pageRangeDisplayed={5}
                  //   hideFirstLastPages={true}
                  //   nextPageText={<LazyLoadImage alt="" src={nextIcon} effect="blur" />}
                  //   prevPageText={<LazyLoadImage alt="" src={previousIcon} effect="blur" />}
                  //   activeClass={'pagination-active'}
                  //   onChange={page => props.changeItemInPage({ pageIndex: parseInt(page) })}
                  //   itemClass={'pagination-pager-item'}
                  forcePage={props.page.pageIndex - 1}
                  pageCount={props.pageCount ? props.pageCount : 0}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={isMobile ? 1 : 2}
                  previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                  nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                  activeClassName={'pagination-active'}
                  onPageChange={page => props.changeItemInPage({ pageIndex: parseInt(page.selected) + 1 })}
                  pageClassName={'pagination-pager-item'}
                  disabledClassName={'opacity-disable'}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = state => ({ ...state });
export default connect(mapStateToProps, null)(ProjecstList);
