import { get } from 'lodash';
import Service from '../../services';

export const fetchCities = async params => {
  const response = await Service.get(`/api/cities${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchCitiesByLocale = async params => {
  const response = await Service.get(`/api/locations/get-location${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchDistrictsByCityId = async params => {
  const response = await Service.get(`/api/districts/get-by-city${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getBanner = async params => {
  const response = await Service.get(`/api/banners${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchProjectsSearch = async params => {
  const response = await Service.get(`/api/projects/get-by-city-district${params}`, res => res);
  return response;
};

export const fetchListCondition = async params => {
  const response = await Service.get(`/api/filters${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getUnits = async params => {
  const response = await Service.get(`/api/properties/total-unit${params}`, res => res);
  const data = get(response, 'message', null);
  return data;
};

export const fetchCustomerContact = async params => {
  const response = await Service.get(`api/customersupports${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getOfficeInformation = async params => {
  const response = await Service.get(`/api/generalinformations/get-contact-us-no-long-lat${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const downloadMagazine = async params => {
  const response = await Service.get(`/api/magazine/download-pdf${params}`, res => res);
  return response;
};

export const fetchRange = async () => {
  const response = await Service.get(`api/filters/range-setting`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getPartner = async () => {
  const response = await Service.get(`api/partners`, res => res);
  const data = get(response, 'data', null);
  return data;
};
