import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import Mission from '../../../../assets/images/33@2x.png';
import Vission from '../../../../assets/images/276-pom7703-chim@2x.png';

const CompanyPhilosophyContent = props => {
  const { data } = props.aboutIsn;

  return (
    <>
      <div>
        {data && data.length
          ? data.map((item, index) => (
              <div className="philosophy-block" key={index}>
                <div className="row">
                  <div className={`${index === 0 || index % 2 === 0 ? 'offset-lg-2' : ''} col-lg-10 col-12`}>
                    <div className="company-philosophy">
                      <img
                        src={index === 0 || index % 2 === 0 ? Mission : Vission}
                        className="w-100 h-100 philosophy-block-image"
                        alt=""
                      />
                      {/* <LazyLoadImage
                        className="w-100 h-100 philosophy-block-image"
                        alt=""
                        src={index === 0 || index % 2 === 0 ? Mission : Vission}
                        effect="blur"
                      /> */}
                    </div>
                  </div>
                  <div className={`${index === 0 || index % 2 === 0 ? '' : 'offset-lg-6'} col-lg-6 col-12`}>
                    <div className="company-philosophy-text">
                      {/* <div className="company-philosophy-number">
                        {(index + 1).toString() && (index + 1).toString().length < 2 ? `0${index + 1}` : index + 1}
                      </div> */}
                      <div className="company-philosophy-title jp-bold-600">{item.name}</div>
                      <hr className="company-philosophy-line" />
                      <div className="company-philosophy-content jp-font-sawarabi-gothic">{item.description}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : ''}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPhilosophyContent);
