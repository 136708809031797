import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ResidenceSearchComponent from '../pages/search/ResidenceSearch.component';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResidenceSearchComponent);
