import React, { useEffect, useMemo, useState } from 'react';
import { get } from 'react-hook-form';
import BreadcrumbProject from '../common/Breadcrumb.componnent';
import PropertiesItem from '../common/PropertiesItem.component';
import { useParams, useLocation } from 'react-router-dom';
import { getLanguage } from '../../../utils/params';
import favorite404 from '../../../assets/images/notfound-icon.svg';

const Properties = props => {
  const params = useParams();
  const location = useLocation();
  const language = getLanguage(location);
  const [page, setPage] = useState({ pageIndex: 1, pageSize: params.props === 'residence' ? 15 : 16 });
  const [isCall, setIsCall] = useState(true);

  const cityId = get(props, 'layout.cityActive.defaultLocationId', null);

  useEffect(() => {
    if (
      (params.type !== 'hot' && params.type !== 'latest' && params.type !== 'similar') ||
      (params.props !== 'residence' && params.props !== 'office')
    ) {
      setIsCall(false);
      return;
    } else {
      if (cityId && !location.search) {
        props.fetchListPropertiesRequest({
          type: params.type,
          params: {
            cityId,
            language,
            pageIndex: page.pageIndex,
            pageSize: params.props === 'residence' ? 15 : 16,
            propertiesType: params.props === 'residence' ? 0 : 2,
          },
        });
      }
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [cityId]);

  useEffect(() => {
    if (
      (params.type !== 'hot' && params.type !== 'latest' && params.type !== 'similar') ||
      (params.props !== 'residence' && params.props !== 'office')
    ) {
      setIsCall(false);
      return;
    } else {
      if (location.search) {
        const listQuery = new URLSearchParams(location.search);
        if (listQuery.has('properties') && listQuery.get('properties')) {
          const propertyId = listQuery.get('properties');
          props.fetchListPropertiesRequest({
            type: params.type,
            params: {
              currentId: propertyId,
              language,
              pageIndex: page.pageIndex,
              pageSize: params.props === 'residence' ? 15 : 16,
              propertiesType: params.props === 'residence' ? 0 : 2,
            },
          });
        }
      }
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const changeItemInPage = data => {
    if (data && data.pageSize) {
      setPage({ ...page, pageSize: data.pageSize });
    }

    if (data && data.pageIndex) {
      setPage({ ...page, pageIndex: data.pageIndex });
    }

    if (location.search) {
      const listQuery = new URLSearchParams(location.search);
      if (listQuery.has('properties')) {
        const propertyId = listQuery.get('properties');
        props.fetchListPropertiesRequest({
          type: params.type,
          params: {
            currentId: propertyId,
            language,
            pageIndex: (data && data.pageIndex) || 1,
            pageSize: (data && data.pageSize) || page.pageSize,
            propertiesType: params.props === 'residence' ? 0 : 2,
          },
        });
      }
    } else {
      props.fetchListPropertiesRequest({
        type: params.type,
        params: {
          cityId,
          language,
          pageIndex: (data && data.pageIndex) || 1,
          pageSize: (data && data.pageSize) || page.pageSize,
          propertiesType: params.props === 'residence' ? 0 : 2,
        },
      });
    }
    window.scrollTo(0, 0);
  };

  const { properties } = props.properties;

  const renderData = useMemo(() => {
    const callbackChangeItemInPage = data => {
      if (data && data.pageSize) {
        setPage({ ...page, pageSize: data.pageSize });
      }

      if (data && data.pageIndex) {
        setPage({ ...page, pageIndex: data.pageIndex });
      }

      if (location.search) {
        const listQuery = new URLSearchParams(location.search);
        if (listQuery.has('properties')) {
          const propertyId = listQuery.get('properties');
          props.fetchListPropertiesRequest({
            type: params.type,
            params: {
              currentId: propertyId,
              language,
              pageIndex: (data && data.pageIndex) || 1,
              pageSize: (data && data.pageSize) || page.pageSize,
              propertiesType: params.props === 'residence' ? 0 : 2,
            },
          });
        }
      } else {
        props.fetchListPropertiesRequest({
          type: params.type,
          params: {
            cityId,
            language,
            pageIndex: (data && data.pageIndex) || 1,
            pageSize: (data && data.pageSize) || page.pageSize,
            propertiesType: params.props === 'residence' ? 0 : 2,
          },
        });
      }
      window.scrollTo(0, 0);
    };

    if (!isCall) {
      return (
        <div className="image-notfound">
          <img src={favorite404} className="favourite-notfound" alt="" />
          {/* <LazyLoadImage className="favourite-notfound" alt="" src={favorite404} effect="blur" /> */}
          {/* <div className="not-found-message">{t('blogs.no_blog_found')}</div> */}
        </div>
      );
    }
    return properties && properties.items && properties.items.length ? (
      <PropertiesItem
        data={properties || []}
        propertyType={params.props === 'residence' ? '0' : '2'}
        page={page}
        pageCount={properties && properties.pageCount ? properties.pageCount : 0}
        changeItemInPage={callbackChangeItemInPage}
      />
    ) : (
      ''
    );

    // eslint-disable-next-line
  }, [properties, isCall, page, params]);

  return (
    <>
      <BreadcrumbProject
        data={properties || []}
        isCall={isCall}
        changeItemInPage={changeItemInPage}
        propertyType={params.props === 'residence' ? '0' : '2'}
      />
      {renderData}
    </>
  );
};

export default Properties;
