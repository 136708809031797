import * as types from './residence.types';

const initialState = {
  detail: null,
  similarProperties: [],
  error: null,
  residencesSearch: null,
};

const residenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_RESIDENCE_REQUEST:
      return { ...state };
    case types.GET_RESIDENCE_SUCCESS:
      return { ...state, detail: action.data };
    case types.GET_RESIDENCE_FAILURE:
      return { ...state, error: action.error };

    case types.GET_SIMILAR_PROPERTIES_REQUEST:
      return { ...state };
    case types.GET_SIMILAR_PROPERTIES_SUCCESS:
      return { ...state, similarProperties: action.data };
    case types.GET_SIMILAR_PROPERTIES_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_RESIDECES_SEARCH_REQUEST:
      return { ...state };
    case types.FETCH_RESIDECES_SEARCH_SUCCESS:
      return { ...state, residencesSearch: action.data };
    case types.FETCH_RESIDECES_SEARCH_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default residenceReducer;
