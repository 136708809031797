import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import {  } from '../.action';
import { ForOwnersComponent, FormCreateComponent, FaqsComponent } from '../pages';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default {
  MainForOwnersContainer: connect(mapStateToProps, mapDispatchToProps)(ForOwnersComponent),
  FormCreateContainer: connect(mapStateToProps, mapDispatchToProps)(FormCreateComponent),
  FaqsContainer: connect(mapStateToProps, mapDispatchToProps)(FaqsComponent),
};
