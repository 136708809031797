import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  fetchLastestPropertiesRequest,
  fetchHotPropertiesRequest,
  fetchBlogsRequest,
  fetchProjectsRequest,
  fetchSpecialListRequest,
} from '../home.action';

import HomeComponent from '../pages/Home.component';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchLastestPropertiesRequest,
      fetchHotPropertiesRequest,
      fetchBlogsRequest,
      fetchProjectsRequest,
      fetchSpecialListRequest,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
