import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import EventBus from 'eventing-bus';
import { get } from 'lodash';

import { getLanguage } from '../../../utils/params';
import BannerSlides from '../../../layouts/commons/bannerSlides/BannerSlides';
import BlogsList from '../../commons/blog/Blog.component';
import PropertiesList from './Offices.PropertiesList.component';

const OfficesComponent = props => {
  const language = getLanguage(useLocation());
  const { t } = useTranslation();
  const [cityId, setCityId] = useState('');

  useEffect(() => {
    const propsId = get(props, 'layout.cityActive.defaultLocationId', null);
    if (props && propsId) setCityId(propsId);

    // eslint-disable-next-line
  }, [props.layout.cityActive]);

  useEffect(() => {
    if (cityId) {
      const params = { language, propertiesType: 2, pageIndex: 1, pageSize: 8, cityId };
      props.fetchOfficesLastestRequest(params);
      props.fetchOfficesHotRequest(params);
      props.fetchOfficesBlogRequest(params);
    }
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [cityId]);

  //   EventBus.on('change_location', data => {
  //     if (data) setCityId(data);
  //   });

  return (
    <>
      <BannerSlides showResidenceSearch={false} checkResidenceAndOffice={false}/>
      <PropertiesList
        headerTitle={t('home_page.lastest_properties')}
        section="OFFICES_LIKE"
        innerClass="latest-properties"
        wrapperClass="pb-0"
        data={props.offices.lastProperties || []}
        metaKey="last"
      />
      <PropertiesList
        section="OFFICES_LIKE"
        headerTitle={t('home_page.hot_properties')}
        innerClass="hot-properties"
        wrapperClass="p-0"
        data={props.offices.hotProperties || []}
        metaKey="hot"
      />
      <BlogsList data={props.offices.blogs || []} />
    </>
  );
};

export default OfficesComponent;
