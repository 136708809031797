import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchOfficesLastestRequest, fetchOfficesHotRequest, fetchOfficesBlogRequest } from '../offices.action';
import OfficesComponent from '../pages/Offices.component';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchOfficesLastestRequest,
      fetchOfficesHotRequest,
      fetchOfficesBlogRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(OfficesComponent);
