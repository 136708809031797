import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import OfficeDetailComponent from '../pages/OfficeDetail.component';
import { getOfficeDetailRequest, getSimilarListRequest } from '../officeDetail.action';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({ getOfficeDetailRequest, getSimilarListRequest }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OfficeDetailComponent);
