import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './en';
import { jp } from './jp';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: en,
    },
    jp: {
      translations: jp,
    },
  },
  fallbackLng: 'en',
  debug: false,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
  lng: 'en',
  keySeparator: '.',
});
export default i18n;
