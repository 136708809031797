import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IconMainServices } from '../../../commons/iconService';

const MainServicesContentItem = props => {
  return (
    <>
      <div className="main-services-title jp-bold-600">{props.title}</div>
      <div className="main-services-list">
        <div className="row">
          {props.data && props.data.length
            ? props.data.map((item, index) => {
                return (
                  <div className="col-12 col-md-4" key={index}>
                    <div className="service-block">
                      <div className="row">
                        <div className="col-6">
                          <div className="service-number">
                            {(index + props.startAt + 1).toString().length < 2
                              ? `0${index + props.startAt + 1}`
                              : index + props.startAt + 1}
                          </div>
                        </div>
                        <div className="col-6 text-right">
                          <img
                            src={
                              index + props.startAt < Object.keys(IconMainServices).length
                                ? IconMainServices[`service${index + props.startAt + 1}`]
                                : IconMainServices.service1
                            }
                            alt=""
                          />

                          {/* <LazyLoadImage
                            src={
                              index + props.startAt < Object.keys(IconMainServices).length
                                ? IconMainServices[`service${index + props.startAt + 1}`]
                                : IconMainServices.service1
                            }
                            alt=""
                            effect="blur"
                          /> */}
                        </div>
                      </div>
                      <div className="service-content-title jp-500-sawarabi">{item.name}</div>
                      <hr className="main-service-line" />
                      <div className="service-description jp-font-sawarabi-gothic">{item.description}</div>
                    </div>
                  </div>
                );
              })
            : ''}
        </div>
      </div>
    </>
  );
};
export default MainServicesContentItem;
