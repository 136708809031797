import React from 'react';
import { useTranslation } from 'react-i18next';
import { BreadcrumbForOwners, ForOwnersContent } from '../common';

const ForOwnersComponent = () => {
  const { t } = useTranslation();
  const breadCrumb = [
    { title: t('for_owners.home'), url: '/' },
    { title: t('for_owners.for_owners'), url: '/for-owners' },
  ];
  return (
    <>
      <BreadcrumbForOwners breadCrumb={breadCrumb} />
      <ForOwnersContent />
    </>
  );
};

export default ForOwnersComponent;
