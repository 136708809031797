import * as types from './special.types';

const initialState = {
  detail: {
    name: ''
  },
  properties: null,
  error: null,
};

const specialReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SPECIAL_REQUEST:
      return { ...state };
    case types.FETCH_SPECIAL_SUCCESS: {
      const { detail, properties } = action.data;
      return { ...state, detail, properties };
    }
    case types.FETCH_SPECIAL_FAILURE:
      return { ...state, detail: initialState.detail, properties: null, error: action.error };

    default:
      return state;
  }
};

export default specialReducer;
