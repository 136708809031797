import * as types from './forOwners.types';

export const fetchForOwnersRequest = params => ({ type: types.FETCH_FOR_OWNERS_REQUEST, params });
export const fetchForOwnersSuccess = data => ({ type: types.FETCH_FOR_OWNERS_SUCCESS, data });
export const fetchForOwnersFailure = error => ({ type: types.FETCH_FOR_OWNERS_FAILURE, error });

export const postInformationRequest = params => ({ type: types.POST_INFORMATION_REQUEST, params });
export const postInformationSuccess = data => ({ type: types.POST_INFORMATION_SUCCESS, data });
export const postInformationFailure = error => ({ type: types.POST_INFORMATION_FAILURE, error });

export const getFaqsOwnersRequest = params => ({ type: types.GET_FAQS_OWNERS_REQUEST, params });
export const getFaqsOwnersSuccess = data => ({ type: types.GET_FAQS_OWNERS_SUCCESS, data });
export const getFaqsOwnersFailure = error => ({ type: types.GET_FAQS_OWNERS_FAIILURE, error });
