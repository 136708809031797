import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getLanguage, getPathLanguage } from '../../../../utils/params';
import { get } from 'react-hook-form';

const BreadcrumbOfficeDetail = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const language = getLanguage(useLocation());
  const location = useLocation();

  const renderText = () => {
    if (location.search) {
      if (location.search === '?last') return t('detail.lastest_properties');
      else if (location.search === '?hot') return t('detail.hot_properties');
    } else {
      if (props.data && props.data.propertyType) {
        if (props.data.propertyType === 'residence') return t('detail.residence');
        return t('detail.office');
      }
    }
  };

  const cityActive = get(props.allState, 'layout.cityActive.defaultLocationId', null);

  const renderLink = () => {
    if (location.search) {
      return `${pathLanguage}/list-properties/${location.search === '?last' ? 'latest' : 'hot'}/office`;
    }
    // cityid, leasePriceMin, leasePriceMax, pageSize, pageIndex, sort=-leasePriceMin
    else if (cityActive) {
      // eslint-disable-next-line
      return `${pathLanguage}/offices/search?language=${language}&propertyType=2&officePriceSearchType=1&cityId=${cityActive}&leasePriceMin=0&leasePriceMax=100&pageSize=16&pageIndex=1&sort=-leasePriceMin&officeSizeFrom=0&officeSizeTo=3000`;
    }
  };

  return (
    <div className="section-block background-gray p-0">
      <div className="container">
        <div className="row breadcrumb align-items-center">
          <div className="col-12 col-md-6">
            <div className="breadcrumb-item row">
              <ul>
                <li className="first">
                  <Link className="home" to={pathLanguage}>
                    <span>{t('residence_detail.home')}</span>
                  </Link>
                </li>
                <li className="item">
                  <span className="breadcrumb-dot">&#8226;</span>
                  <span className="normal">
                    {/* {t('residence_detail.search_results')} */}
                    <Link to={renderLink()}>{renderText()}</Link>
                  </span>
                </li>
                <li className="item">
                  <span className="breadcrumb-dot">&#8226;</span>
                  <span className="active">{t('office_detail.detail_office')}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbOfficeDetail;
