import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from '../../../utils/constants';
import { getLanguage } from '../../../utils/params';
import { UsefulWidgetLeft, ServiceApartment, HeadUseful, Breadcrumb } from '../common/index';
import { history } from '../../../utils/history';
import { getPathLanguage } from '../../../utils/params';

const ServiceApartmentComponent = props => {
  const language = getLanguage(useLocation());
  const location = useLocation();
  const [topicActive, setTopicActive] = useState(null);
  const { topicsApartment, detailApartment, listArea } = props.usefulInfo;
  const pathLanguage = getPathLanguage(location);

  useEffect(() => {
    // if (!location.pathname.includes('/useful-information/all')) {
    if (!isMobile || (isMobile && !location.pathname.includes('/useful-information/all'))) {
      props.getTopicsApartmentRequest({ language, takeNumber: 999 });
      window.scrollTo(0, 0);
    }
    // }
    // const scrollTo = document.querySelector('#useful-position-scroll');
    // if (scrollTo) {
    //   scrollTo.scrollIntoView({ behavior: 'smooth' });
    // }
    // eslint-disable-next-line
  }, [isMobile, location]);

  useEffect(() => {
    if (topicsApartment && topicsApartment.length) {
      setTopicActive(topicsApartment[0]);
      topicsApartment.forEach(item => {
        if (location.pathname.includes(item.slug)) setTopicActive(item);
      });
    }
    // eslint-disable-next-line
  }, [topicsApartment]);

  useEffect(() => {
    if (topicActive) {
      if (topicActive.slug === 'area-guide') props.getListAreaRequest({ language, category: 4 });
      else props.getDetailApartmentRequest({ language, id: topicActive.id });
    }
    // eslint-disable-next-line
  }, [topicActive]);

  const changeTopic = topic => {
    if (topic) {
      setTopicActive(topic);
      history.push(`${pathLanguage}/useful-information/useful-service-apartment/${topic.slug}`);
    }
  };

  return (
    <>
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <section className="useful-information">
          {location.pathname.includes('/useful-information/all') ? (
            <HeadUseful />
          ) : isMobile ? (
            <Breadcrumb />
          ) : (
            <HeadUseful />
          )}
          {!isMobile || (isMobile && !location.pathname.includes('/useful-information/all')) ? (
            <div className="container">
              {topicsApartment && topicsApartment.length ? (
                <div className="row">
                  <div className="col-12 col-md-4">
                    <UsefulWidgetLeft changeTopic={changeTopic} topics={topicsApartment} topicActive={topicActive} />
                  </div>
                  <div className="col-12 col-md-8">
                    <ServiceApartment detail={detailApartment} topicActive={topicActive} listArea={listArea} />
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
    </>
  );
};

export default ServiceApartmentComponent;
