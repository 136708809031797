import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from './properties.action';
import * as types from './properties.types';
import * as API from './properties.api';
import { setLoading } from '../../layouts/loading/loading.action';
// import { getQueryString } from '../../utils/params';

export function* fetchPropertiesListFunc(action) {
  const { params = {} } = action;
  yield put(setLoading(true));
  try {
    const properties = yield call(API.fetchListProperties, params);

    yield put(actions.fetchListPropertiesSuccess({ properties }));
  } catch (error) {
    yield put(actions.fetchListPropertiesFailure(error));
  } finally {
    yield put(setLoading(false));
  }
}

export function* propertiesSaga() {
  yield takeEvery(types.FETCH_LIST_PROPERTIES_REQUEST, fetchPropertiesListFunc);
}
