import { get } from 'lodash';
import Service from '../../services';

export const getResidence = async params => {
  const response = await Service.get(`/api/properties/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const getSimilarProperties = async params => {
  const response = await Service.get(`/api/properties/similar${params}`, res => res);
  return response;
};

export const fetchResidencesSearch = async params => {
  const response = await Service.get(`/api/properties/filter${params}`, res => res);
  return response;
};
