const FETCH_OFFICES_LASTEST_REQUEST = 'FETCH_OFFICES_LASTEST_REQUEST';
const FETCH_OFFICES_LASTEST_SUCCESS = 'FETCH_OFFICES_LASTEST_SUCCESS';
const FETCH_OFFICES_LASTEST_FAILURE = 'FETCH_OFFICES_LASTEST_FAILURE';

const FETCH_OFFICES_HOT_REQUEST = 'FETCH_OFFICES_HOT_REQUEST';
const FETCH_OFFICES_HOT_SUCCESS = 'FETCH_OFFICES_HOT_SUCCESS';
const FETCH_OFFICES_HOT_FAILURE = 'FETCH_OFFICES_HOT_FAILURE';

const FETCH_OFFICES_BLOGS_REQUEST = 'FETCH_OFFICES_BLOGS_REQUEST';
const FETCH_OFFICES_BLOGS_SUCCESS = 'FETCH_OFFICES_BLOGS_SUCCESS';
const FETCH_OFFICES_BLOGS_FAILURE = 'FETCH_OFFICES_BLOGS_FAILURE';

export {
  FETCH_OFFICES_LASTEST_REQUEST,
  FETCH_OFFICES_LASTEST_SUCCESS,
  FETCH_OFFICES_LASTEST_FAILURE,
  FETCH_OFFICES_HOT_REQUEST,
  FETCH_OFFICES_HOT_SUCCESS,
  FETCH_OFFICES_HOT_FAILURE,
  FETCH_OFFICES_BLOGS_REQUEST,
  FETCH_OFFICES_BLOGS_SUCCESS,
  FETCH_OFFICES_BLOGS_FAILURE,
};
