import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPathLanguage } from '../../../utils/params';

const BreadcrumbProject = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const params = useParams();

  const optionSize =
    props.propertyType === '0'
      ? [
          { value: 15, label: 15 },
          { value: 30, label: 30 },
          { value: 45, label: 45 },
          { value: 60, label: 60 },
          { value: 75, label: 75 },
          { value: 90, label: 90 },
        ]
      : [
          { value: 16, label: 16 },
          { value: 32, label: 32 },
          { value: 48, label: 48 },
          { value: 64, label: 64 },
          { value: 80, label: 80 },
          { value: 96, label: 96 },
        ];

  const renderBreadcrumb = () => {
    if (!props.isCall) return;
    if (params.type === 'latest') {
      return (
        <>
          <span className="breadcrumb-dot">&#8226;</span>
          <span className="active">{t('detail.lastest_properties')}</span>
        </>
      );
    } else if (params.type === 'hot') {
      return (
        <>
          <span className="breadcrumb-dot">&#8226;</span>
          <span className="active">{t('detail.hot_properties')}</span>
        </>
      );
    } else if (params.type === 'similar') {
      return (
        <>
          <span className="breadcrumb-dot">&#8226;</span>
          <span className="active">{t('detail.similar_properties')}</span>
        </>
      );
    }
    return '';
  };

  return (
    <div className="section-block background-gray p-0">
      <div className="container">
        <div className="row breadcrumb align-items-center">
          <div className="col-12 col-md-6">
            <div className="breadcrumb-item row">
              <ul>
                <li className="first">
                  <Link className="home" to={pathLanguage}>
                    <span>{t('project_page.home')}</span>
                  </Link>
                </li>
                <li className="item">
                  {/* {params.id ? (
                    <Link to={`${pathLanguage}/projects`}>
                      <span className="breadcrumb-dot">&#8226;</span>
                      <span className="normal">{t('project_page.project')}</span>
                    </Link>
                  ) : (
                    <>
                      <span className="breadcrumb-dot">&#8226;</span>
                      <span className="normal">{t('project_page.project')}</span>
                    </>
                  )} */}
                  {renderBreadcrumb()}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-6 text-right">
            <div className="sort-page sort-projects row">
              <div className="wrap-sort no-sort">
                <div className="sort-item first"></div>
                <div className="page-of sort-item second second-none">
                  <span className="bold">
                    {props.data && props.data.pageIndex && props.data.pageSize
                      ? props.data.pageIndex * props.data.pageSize - props.data.pageSize + 1
                      : ''}
                    {' - '}
                    {props.data && props.data.totalRecords && props.data.pageIndex && props.data.pageSize
                      ? props.data.totalRecords < props.data.pageIndex * props.data.pageSize
                        ? props.data.totalRecords
                        : props.data.pageIndex * props.data.pageSize
                      : ''}
                  </span>{' '}
                  <span>{t('search_page.option_sort_by.of')}</span>{' '}
                  <span>{(props.data && props.data.totalRecords) || ''}</span>
                </div>
                <div className="page-size sort-item third third-none">
                  <div className="select-control show-search">
                    <select
                      value={props.data ? props.data.pageSize : 9}
                      onChange={e => props.changeItemInPage({ pageSize: parseInt(e.target.value) })}
                    >
                      {optionSize.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbProject;
