import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

import about1 from './../../../../assets/images/about1.jpg';
import about2 from './../../../../assets/images/about2.jpg';
import about3 from './../../../../assets/images/about3.jpg';
import about4 from './../../../../assets/images/about4.jpg';
import { getPathLanguage } from '../../../../utils/params';
import { ArrowRightCircle } from '../../../../layouts/commons/icons';

const AboutIsnContent = () => {
  const pathLanguage = getPathLanguage(useLocation());
  const { t } = useTranslation();

  return (
    <div className="overview-company">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 pl-0 pr-0">
            <div className="overview-company-image">
              <Link className="h-100 w-100 company-image-link" to={`${pathLanguage}/about-isn/company-profile`}>
                <img src={about1} className="w-100 h-100" alt="" />
                {/* <LazyLoadImage className="w-100 h-100" alt="" src={about1} effect="blur" /> */}
                <div className="overview-company-overlay w-100 h-100" />
                <h2 className="overview-company-title">
                  {t('about_isn.company_overview')}
                  <div className="see-more">
                    <ArrowRightCircle />
                  </div>
                </h2>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 pl-0 pr-0">
            <div className="overview-company-image">
              <Link className="h-100 w-100 company-image-link" to={`${pathLanguage}/about-isn/group-companies`}>
                <img src={about2} className="overview-company-image w-100 h-100" alt="" />
                {/* <LazyLoadImage className="overview-company-image 
                w-100 h-100" alt="" src={about2} effect="blur" /> */}
                <div className="overview-company-overlay w-100 h-100" />
                <h2 className="overview-company-title">
                  {t('about_isn.isn_group')}
                  <div className="see-more">
                    <ArrowRightCircle />
                  </div>
                </h2>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 pl-0 pr-0">
            <div className="overview-company-image">
              <Link className="h-100 w-100 company-image-link" to={`${pathLanguage}/blogs/news/1`}>
                <img src={about3} className="overview-company-image w-100 h-100" alt="" />
                {/* <LazyLoadImage className="overview-company-image 
                w-100 h-100" alt="" src={about3} effect="blur" /> */}
                <div className="overview-company-overlay w-100 h-100" />
                <h2 className="overview-company-title">
                  {t('about_isn.new_relaese')}
                  <div className="see-more">
                    <ArrowRightCircle />
                  </div>
                </h2>
              </Link>
            </div>
          </div>
          <div className="col-12 col-md-6 pl-0 pr-0">
            <div className="overview-company-image">
              <Link className="h-100 w-100 company-image-link" to={`${pathLanguage}/about-isn/contact-us`}>
                <img src={about4} className="overview-company-image w-100 h-100" alt="" />
                {/* <LazyLoadImage className="overview-company-image 
                w-100 h-100" alt="" src={about4} effect="blur" /> */}
                <div className="overview-company-overlay w-100 h-100" />
                <h2 className="overview-company-title">
                  {t('about_isn.contact_us')}
                  <div className="see-more">
                    <ArrowRightCircle />
                  </div>
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AboutIsnContent);
