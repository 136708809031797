import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import parse from "html-react-parser";
import { isMobile } from '../../../../utils/constants';

const MessageFromRepresentativeContent = props => {
  const dataMessageFromRepresentative = props.aboutIsn?.data ? props.aboutIsn?.data : [];

  return (
    <div className="profile-table">
      {
          dataMessageFromRepresentative.length ?
          <div>
              {isMobile ? 
              <div style={{backgroundColor: "#1e2430"}}>
                <img className="rounded mx-auto d-block mb-2" 
                style={{width: "40%"}} src={dataMessageFromRepresentative[0].image} alt="Avatar"/>
              </div>
              :
              <img className="float-left mr-3" 
                style={{width: "40%"}} src={dataMessageFromRepresentative[0].image} alt="Avatar"/>
              }
              {parse(dataMessageFromRepresentative[0].description)}
          </div>
      : ''}
    </div>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageFromRepresentativeContent);
