const FETCH_FAVORITES_REQUEST = 'FETCH_FAVORITES_REQUEST';
const FETCH_FAVORITES_SUCCESS = 'FETCH_FAVORITES_SUCCESS';
const FETCH_FAVORITES_FAILURE = 'FETCH_FAVORITES_FAILURE';

const FETCH_HISTORIES_REQUEST = 'FETCH_HISTORIES_REQUEST';
const FETCH_HISTORIES_SUCCESS = 'FETCH_HISTORIES_SUCCESS';
const FETCH_HISTORIES_FAILURE = 'FETCH_HISTORIES_FAILURE';

const POST_CONTACT_FAVORITE_REQUEST = 'POST_CONTACT_FAVORITE_REQUEST';

const POST_CONTACT_HISTORY_REQUEST = 'POST_CONTACT_HISTORY_REQUEST';

export {
  FETCH_FAVORITES_REQUEST,
  FETCH_FAVORITES_SUCCESS,
  FETCH_FAVORITES_FAILURE,
  FETCH_HISTORIES_REQUEST,
  FETCH_HISTORIES_SUCCESS,
  FETCH_HISTORIES_FAILURE,
  POST_CONTACT_FAVORITE_REQUEST,
  POST_CONTACT_HISTORY_REQUEST,
};
