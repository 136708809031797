import * as types from './home.types';

export const fetchLastestPropertiesRequest = params => ({ type: types.FETCH_LASTEST_PROPERTIES_REQUEST, params });
export const fetchLastestPropertiesSuccess = data => ({ type: types.FETCH_LASTEST_PROPERTIES_SUCCESS, data });
export const fetchLastestPropertiesFailure = error => ({ type: types.FETCH_LASTEST_PROPERTIES_FAILURE, error });

export const fetchHotPropertiesRequest = params => ({ type: types.FETCH_HOT_PROPERTIES_REQUEST, params });
export const fetchHotPropertiesSuccess = data => ({ type: types.FETCH_HOT_PROPERTIES_SUCCESS, data });
export const fetchHotPropertiesFailure = error => ({ type: types.FETCH_HOT_PROPERTIES_FAILURE, error });

export const fetchBlogsRequest = params => ({ type: types.FETCH_BLOGS_REQUEST, params });
export const fetchBlogsSuccess = data => ({ type: types.FETCH_BLOGS_SUCCESS, data });
export const fetchBlogsFailure = error => ({ type: types.FETCH_BLOGS_FAILURE, error });

export const fetchProjectsRequest = params => ({ type: types.FETCH_PROJECTS_REQUEST, params });
export const fetchProjectsSuccess = data => ({ type: types.FETCH_PROJECTS_SUCCESS, data });
export const fetchProjectsFailure = error => ({ type: types.FETCH_PROJECTS_FAILURE, error });

export const fetchSpecialListRequest = params => ({ type: types.FETCH_SPECIAL_LIST_REQUEST, params });
export const fetchSpecialListSuccess = data => ({ type: types.FETCH_SPECIAL_LIST_SUCCESS, data });
export const fetchSpecialListFailure = error => ({ type: types.FETCH_SPECIAL_LIST_FAILURE, error });
