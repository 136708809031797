import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';

import Image1 from '../../../../assets/images/asian-business-team-having-a-meeting-picture-id1131095961@3x.png';
import Image2 from '../../../../assets/images/276-pom7736-jj@3x.png';
import Image3 from '../../../../assets/images/photography-of-city-buildings-941195@3x.png';
import { getLanguage } from '../../../../utils/params';

const WhyChooseContent = props => {
  const { data } = props.aboutIsn;
  const images = { Image1, Image2, Image3 };
  const language = getLanguage(useLocation());

  return (
    <>
      {data && data.length
        ? data.slice(1).map((item, index) => {
            if (index % 2 === 0)
              return (
                <div className="choose-us-block" key={index}>
                  <div className="row">
                    <div className="col-12 col-md-3">
                      <div className="why-choose-image">
                        <img src={images[`Image${index + 1}`]} className="h-100 w-100" alt="" />
                        {/* <LazyLoadImage
                        src={images[`Image${index + 1}`]}
                        className="h-100 w-100" alt="" effect="blur" /> */}
                      </div>
                    </div>
                    <div className="col-1" />
                    <div className="col-12 col-md-8">
                      <div className="choose-us-content">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="choose-us-title jp-bold-600">{item.name}</div>
                          </div>
                        </div>
                        <div className="choose-us-description jp-font-sawarabi-gothic">{item.description}</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            return (
              <div className="choose-us-block" key={index}>
                <div className="row">
                  <div className="col-1" />
                  <div className="col-12 col-md-8">
                    <div className="choose-us-content">
                      <div className="row">
                        <div className={`col-12 ${language === 'jp' ? '' : 'col-md-6'}`}>
                          <div className="choose-us-title jp-bold-600">{item.name}</div>
                        </div>
                      </div>
                      <div className="choose-us-description jp-font-sawarabi-gothic">{item.description}</div>
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="why-choose-image">
                      <img src={images[`Image${index + 1}`]} className="h-100 w-100" alt="" />
                      {/* <LazyLoadImage
                      src={images[`Image${index + 1}`]}
                      className="h-100 w-100" alt="" effect="blur" /> */}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : ''}
      {data && data[0] && data[0].description ? (
      <>
      <div className="choose-us-block-title jp-bold-600">{data && data[0] && data[0].name ? data[0].name : ''}</div>
        <div className="choose-us-video">
          <iframe
            className="video-iframe"
            width="100%"
            height={502}
            src={`https://www.youtube.com/embed/${data[0].description.split('=')[1]}`}
            frameBorder={0}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="frame"
          />
        </div>
      </>
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WhyChooseContent);
