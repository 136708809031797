import React, { useRef } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel2';
import SectionTitle from '../../../commons/sectionTitle/SectionTitle';
import BlockOfficeComponent from '../../../commons/block/BlockOffice.component';
import { ARROW_LEFT, ARROW_RIGHT } from '../../../../utils/constants';

const Properties = props => {
  let listItems = props.data && props.data.items && props.data.items.length ? props.data.items : null;

  const options = {
    items: 4,
    margin: 30,
    nav: true,
    dots: false,
    loop: false,
    navText: [ARROW_LEFT, ARROW_RIGHT],
    responsive: {
      0: { items: 1, nav: true },
      600: { items: 3, nav: true },
      1000: { items: 4, nav: true },
    },
  };
  const propertiesRef = useRef(null);

  return (
    <div className="section-block hidden-print">
      <div className={(props.class || '') + ' container'}>
        <SectionTitle title={props.headerTitle || ''} />
        <Row>
          <Col xs={12}>
            {listItems && listItems.length ? (
              <OwlCarousel
                ref={propertiesRef}
                options={options}
                className="nav-top owl-carousel owl-theme owl-loaded owl-drag owl-custom-carousel"
              >
                {listItems.map((element, index) => {
                  if (!element) return null;
                  return (
                    <div key={index} className="col-item">
                      <BlockOfficeComponent section="OFFICES_LIKE" post={element} />
                    </div>
                  );
                })}
              </OwlCarousel>
            ) : (
              ''
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Properties;
