import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Header, ListBlogs } from '../common';
import { getPathLanguage } from '../../../utils/params';
import { isMobile } from '../../../utils/constants';

const BlogsComponent = () => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const params = useParams();
  const urlBlogs =
    params.category !== 'all' &&
    params.category !== 'news' &&
    params.category !== 'newly-launched-properties' &&
    params.category !== 'c-s-statistics' &&
    params.category !== 'living-in-vietnam';

  if (params.category && urlBlogs) {
    window.location.href = pathLanguage + '/blogs/all';
  }

  return (
    <>
      <div className="section-block section-breadcrumb background-gray">
        <div className="container">
          <div className="row breadcrumb align-items-center">
            <div className="col-6">
              <div className="breadcrumb-item row">
                <ul>
                  <li className="first">
                    <Link className="home" to={pathLanguage}>
                      {t('blogs.home')}
                    </Link>
                  </li>
                  <li className="item">
                    <span className="breadcrumb-dot">&#8226;</span>
                    {isMobile ? (
                      <Link to={`${pathLanguage}/blogs`}>
                        <span className="active"> {t('blogs.blog')}</span>
                      </Link>
                    ) : (
                      <span className="active"> {t('blogs.blog')}</span>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="title-blog-page">
        <h1>{t('blogs.head_title')}</h1>
        <div className="lower-border-category-blog-page"></div>
      </div>
      <div
        className={`section-block background-gray section-page-content ${
          !params.category && isMobile ? 'blog-mobile' : ''
        }`}
      >
        <div className="container">
          <div className="header-details">
            {params.category ? (
              isMobile ? (
                ''
              ) : (
                <>
                  <h2 className="title-blog">{t('blogs.categories')}</h2>
                  <div className="lower-border-category"></div>
                  <div id="blog-position-scroll"></div>
                  <Header />
                </>
              )
            ) : (
              <>
                <Header />
              </>
            )}

            <ListBlogs />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsComponent;
