import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import IconContact from '../../../assets/images/Group2676.svg';
import { getLanguage, getPathLanguage } from '../../../utils/params';
import * as actions from '../../redux/layout.action';
import { history } from '../../../utils/history';
import ReCAPTCHA from 'react-google-recaptcha';

const CustomerSupportPopup = props => {
  const { show, onCloseModal } = props;
  const { handleSubmit, register, errors, reset } = useForm();
  const { t } = useTranslation();
  const location = useLocation();
  const language = getLanguage(location);
  const pathLanguage = getPathLanguage(location);

  const [locationId, setLocationId] = useState('');
  const [isShowSubmit, setIsShowSubmit] = useState(false);
  const wrapperRef = useRef(null);

  const onSubmit = values => {
    if (values) {
      const info = {};

      info.language = language;
      info.cityId = locationId;
      info.name = values.name;
      info.email = values.email;
      info.phone = values.phone;
      if (values.address) info.address = values.address;
      if (values.inquiryContent) info.contentRequire = values.inquiryContent;
      props.postCustomerSupport({ info, onSuccess, onError });
      onCloseModal();
      setIsShowSubmit(false);
    }
  };

  const onSuccess = () => {
    // toast.success(t('home_page.customer_form.message_success'));
    history.push(`${pathLanguage}/notification/contact-us`);
    props.onCloseSupport();
  };
  const onError = err => toast.error(err);

  useEffect(() => {
    const propsId = get(props, 'layout.cityActive.defaultLocationId', null);
    if (propsId) setLocationId(propsId);

    return () => reset();
    // eslint-disable-next-line
  }, [props.layout.cityActive]);

  const checkCaptcha = value => {
    if (value) setIsShowSubmit(true);
  };

  const handleClickOutsidePopup = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) props.onCloseModal();
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsidePopup);

    return () => document.removeEventListener('mousedown', handleClickOutsidePopup);
    // eslint-disable-next-line
  }, [wrapperRef]);

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <form
          className={`search-form contact-us-form customer-support-form ${language}`}
          onSubmit={handleSubmit(onSubmit)}
          ref={wrapperRef}
        >
          <div className="group-control">
            <div className="row">
              <div className="col-12 col-md-12">
                <div className="title-form-contact jp-font-sawarabi-gothic">
                  <div className="row">
                    <div className="col-9 col-md-9">
                      <div
                        className={`title jp-bold-600 ${language === 'en' ? 'title-en' : ''}`}
                        dangerouslySetInnerHTML={{
                          __html: t('home_page.customer_form.title'),
                        }}
                      ></div>
                    </div>
                    <div className="col-3 col-md-3 img-right">
                      <img src={IconContact} alt="" />
                    </div>
                    <div className="col-12">
                      <div>{t('home_page.customer_form.content1')}</div>
                      <div>{t('home_page.customer_form.content2')}</div>
                      <div
                        className="content-title"
                        dangerouslySetInnerHTML={{
                          __html: t('home_page.customer_form.content3'),
                        }}
                      ></div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            document.body.clientWidth > 576
                              ? t('home_page.customer_form.content4')
                              : t('home_page.customer_form.content4_mobile'),
                        }}
                      ></div>
                      <div
                        className="content-title"
                        dangerouslySetInnerHTML={{
                          __html: t('home_page.customer_form.support'),
                        }}
                      ></div>
                      {/* <div className="phone-support">091-868-4649</div>
                      <div className="email-support">support@vietnamhousing.vn</div> */}
                      <hr className="contact-line" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>
                    {t('home_page.customer_form.name')} <span className="link-red-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    ref={register({ required: t('home_page.customer_form.err_required') })}
                  />
                  <small className="error">
                    {errors.fullName && errors.fullName.message ? errors.fullName.message : ''}
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>{t('home_page.customer_form.address')}</label>
                  <input type="text" className="form-control" name="address" ref={register()} />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>
                    {t('home_page.customer_form.email')} <span className="link-red-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    ref={register({
                      required: t('home_page.customer_form.err_required'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: t('home_page.customer_form.err_email'),
                      },
                    })}
                  />
                  <small className="error">{errors.email && errors.email.message ? errors.email.message : ''}</small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>
                    {t('home_page.customer_form.phone')} <span className="link-red-bold ">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    ref={register({
                      required: t('home_page.customer_form.err_required'),
                      // pattern: {
                      //   value: /^[0-9]+$/,
                      //   message: t('home_page.customer_form.err_phone'),
                      // },
                    })}
                  />
                  <small className="error">{errors.phone && errors.phone.message ? errors.phone.message : ''}</small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="form-group">
                  <label>{t('home_page.customer_form.inquiry_content')}</label>
                  <textarea
                    className="form-control content-requirement"
                    name="inquiryContent"
                    ref={register()}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 pb-2">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_KEY_CAPTCHA}
                onChange={checkCaptcha}
                hl={`${language === 'en' ? 'en' : 'ja'}`}
              />
            </div>
            <div className="col-12 col-md-6 button-handle">
              <button type="submit" className="button button-red uppercase modal-send-button" disabled={!isShowSubmit}>
                {t('home_page.customer_form.send')}
              </button>
              <button
                type="button"
                className="button button-red button-white uppercase modal-send-button"
                onClick={() => {
                  setIsShowSubmit(false);
                  onCloseModal();
                }}
              >
                {t('home_page.customer_form.cancel')}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postCustomerSupport: actions.postCustomerSupportRequest,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSupportPopup);
