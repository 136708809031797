import * as types from './aboutIsn.types';

const initialState = {
  groupCompany: null,
  history: null,
  newsRelease: [],
  newsContent: null,
  otherNews: [],
  office: null,
  error: null,
};

const aboutIsnReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ABOUT_ISN_REQUEST:
      return { ...state, data: null };
    case types.FETCH_ABOUT_ISN_SUCCESS: {
      return { ...state, data: action.data };
    }
    case types.FETCH_ABOUT_ISN_FAILURE:
      return { ...state, error: action.error };

    case types.GET_GROUP_COMPANY_REQUEST:
      return { ...state };
    case types.GET_GROUP_COMPANY_SUCCESS:
      return { ...state, groupCompany: action.data };
    case types.GET_GROUP_COMPANY_FAILURE:
      return { ...state, error: action.error };

    case types.GET_HISTORY_REQUEST:
      return { ...state };
    case types.GET_HISTORY_SUCCESS:
      return { ...state, history: action.data };
    case types.GET_HISTORY_FAILURE:
      return { ...state, error: action.error };

    case types.GET_NEWS_RELEASE_REQUEST:
      return { ...state };
    case types.GET_NEWS_RELEASE_SUCCESS:
      return { ...state, newsRelease: action.data.news };
    case types.GET_NEWS_RELEASE_FAILURE:
      return { ...state, error: action.error };

    case types.GET_OFFICE_INFORMATION_REQUEST:
      return { ...state };
    case types.GET_OFFICE_INFORMATION_SUCCESS:
      return { ...state, office: action.data };
    case types.GET_OFFICE_INFORMATION_FAILURE:
      return { ...state, error: action.error };

    case types.GET_NEWS_CONTENT_REQUEST:
      return { ...state };
    case types.GET_NEWS_CONTENT_SUCCESS:
      return { ...state, newsContent: action.data.newsContent };
    case types.GET_NEWS_CONTENT_FAILURE:
      return { ...state, error: action.error };

    case types.FETCH_OTHER_NEWS_REQUEST:
      return { ...state };
    case types.FETCH_OTHER_NEWS_SUCCESS:
      return { ...state, otherNews: action.data.otherNews };
    case types.FETCH_OTHER_NEWS_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default aboutIsnReducer;
