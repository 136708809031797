import React, { useRef, useEffect, useState } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import Pagination from 'react-js-pagination';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import EventBus from 'eventing-bus';
import { Location, HeartIcon, SizeIcon, BedIcon, VideoIcon } from '../../../layouts/commons/icons';
import ReactPaginate from 'react-paginate';
import BannerLatestResidence from '../../../assets/images/banner-latest-residence.jpg';
import BannerHotResidence from '../../../assets/images/banner-hot-residence.jpg';
import { ICON_LEFT, ICON_RIGHT } from '../../../utils/constants';
import { formatMoney } from '../../../utils/objects';
import previousIcon from '../../../assets/images/arrow-left.png';
import nextIcon from '../../../assets/images/arrow-right.png';
import { getPathLanguage } from '../../../utils/params';
// import { formatMoney } from '../../../../utils/objects';
import imageDefault from '../../../assets/images/loading-image.jpg';
import { isMobile } from '../../../utils/constants';
import { BannerAbout } from '../../aboutIsnPage/common';

const PropertiesItem = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathLanguage = getPathLanguage(location);
  const imageThumbnails = useRef(null);
  const options = {
    items: 1,
    margin: 0,
    nav: true,
    navText: [ICON_LEFT, ICON_RIGHT],
    dots: true,
    autoplay: false,
    autoHeight: true,
    responsive: {},
    loop: true,
  };

  const [likeData, setLikeData] = useState([]);

  useEffect(() => {
    if (props.propertyType === '0') {
      setLikeData(localStorage.getItem('RESIDENCES_LIKE') ? localStorage.getItem('RESIDENCES_LIKE').split(',') : []);
    } else if (props.propertyType === '2') {
      setLikeData(localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : []);
    }

    // eslint-disable-next-line
  }, []);

  const bannerLatestResidence = {
    img: BannerLatestResidence,
    title: t('title.h1_residences_lastest'),
  };
  const bannerHotResidence = {
    img: BannerHotResidence,
    title: t('title.h1_residences_hot'),
  };
  const bannerLatestOffice = {
    img: BannerHotResidence,
    title: t('title.h1_office_lastest'),
  };
  const bannerHotOffice = {
    img: BannerHotResidence,
    title: t('title.h1_office_hot'),
  };

  const onClickLike = id => {
    // const data = localStorage.getItem(props.section) ? localStorage.getItem(props.section).split(',') : [];
    let data = [];
    if (props.propertyType === '0') {
      data = localStorage.getItem('RESIDENCES_LIKE') ? localStorage.getItem('RESIDENCES_LIKE').split(',') : [];
    } else if (props.propertyType === '2') {
      data = localStorage.getItem('OFFICES_LIKE') ? localStorage.getItem('OFFICES_LIKE').split(',') : [];
    }

    let listData = [];
    if (data.includes(id)) listData = data.filter(i => i !== id);
    else listData = [...data, id];
    setLikeData(listData);
    localStorage.setItem(props.propertyType === '0' ? 'RESIDENCES_LIKE' : 'OFFICES_LIKE', listData);
    EventBus.publish('RESET_LIKE', true);
  };

  return (
    <div className="section-block background-gray pt-4">
      <div className="group-company banner-detail">
        <BannerAbout banner={
         location.pathname.includes("/list-properties/latest/residence") ? bannerLatestResidence :
         location.pathname.includes("/list-properties/hot/residence") ? bannerHotResidence :
         location.pathname.includes("/list-properties/latest/office") ? bannerLatestOffice :
         location.pathname.includes("/list-properties/hot/office") ? bannerHotOffice : ""
         } />
      </div>
      <div className="container">
        <div className="seach-results">
          <div className="row">
            {props.data &&
              props.data.items &&
              props.data.items.length &&
              props.data.items.map((item, index) => {
                if (item.name) item.name = item.name.replace('　', ' ');
                return (
                  <div
                    key={index}
                    className={`col-12 ${props.propertyType === '0' ? 'col-md-4 mb-4' : 'col-md-3 mb-3'}`}
                  >
                    {props.propertyType === '0' ? (
                      <div className="block-inner favorite search element-carousel">
                        <div className="block-image">
                          {item.images && item.images.length ? (
                            <div className="thumbail-slides">
                              <OwlCarousel ref={imageThumbnails} options={options}>
                                {item.images.map((image, i) => (
                                  <Link
                                    to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}
                                    key={i}
                                    className="w-100"
                                  >
                                    <div>
                                      <img
                                        alt=""
                                        src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${image}`}
                                        className="w-100"
                                      />
                                    </div>
                                  </Link>
                                ))}
                              </OwlCarousel>
                            </div>
                          ) : (
                            <Link to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}>
                              <div className="w-100">
                                <img src={imageDefault} className="favourite-notfound" alt="" />
                              </div>
                            </Link>
                          )}
                          <span
                            onClick={() => onClickLike(item.id)}
                            className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                          >
                            <HeartIcon />
                          </span>
                          {item.videoLink ? (
                            <span className="icon-video">
                              <VideoIcon />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <Link className="w-100" to={`${pathLanguage}/residence/detail/${item.id}/${item.slug}`}>
                          <div className="block-text none-split">
                            <h3>{item.name}</h3>
                            <div className="property-info">
                              <ul>
                                <li className="icon-district">
                                  {item.district && (
                                    <>
                                      <Location />
                                      {item.district}
                                    </>
                                  )}
                                </li>
                                <li className="bedroom-icon-detail icon-bedroom">
                                  <BedIcon />
                                  {item.bedroommin ? item.bedroommin : t('home_page.studio')}
                                  {item.bedroomMax ? ` ~ ${item.bedroomMax}` : ''}
                                  <span>&nbsp;{t('search_page.bedrooms')}</span>
                                </li>
                              </ul>
                              <div className="info-residence">
                                {item.leasePriceMin || item.leasePriceMax ? (
                                  <div className="price">
                                    {item.leasePriceMin || item.leasePriceMax
                                      ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                      : ''}
                                    {item.leasePriceMax ? ` ~ $${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                    {item.leasePriceMin || item.leasePriceMax ? (
                                      <span>/{t('search_page.month')}</span>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div className="size">
                                  {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                  {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                  {item.sqmMax ? ' ~ ' : ''}
                                  {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                  {item.sqmmin || item.sqmMax ? (
                                    <>
                                      m<sup>2</sup>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <div className="block-inner owl-carousel-office">
                        <div className="block-image">
                          <div className="thumbail-slides">
                            {item.images && item.images.length ? (
                              <Link
                                to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}
                                className="w-100"
                              >
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_URL_CRM_FILE}/images/${item.images[0]}`}
                                  className="w-100"
                                />
                              </Link>
                            ) : (
                              <Link to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}>
                                <img alt="" src={imageDefault} />
                              </Link>
                            )}
                          </div>
                          <span
                            onClick={() => onClickLike(item.id)}
                            className={`heart circle ${likeData.includes(item.id) ? 'active-like' : ''}`}
                          >
                            <HeartIcon />
                          </span>
                          {item.videoLink ? (
                            <span className="icon-video">
                              <VideoIcon />
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <Link className="w-100" to={`${pathLanguage}/office/detail/${item.id}/${item.slug}` || ''}>
                          <div className="block-text block-office none-split">
                            <h4>{item.name}</h4>
                            <div className="property-info">
                              <ul>
                                <li className="icon-district">
                                  <Location />
                                  {item.district}
                                </li>
                                <li className="icon-size">
                                  {item.sqmmin || item.sqmMax ? <SizeIcon /> : ''}
                                  {item.sqmmin || item.sqmMax ? formatMoney(item.sqmmin, 0, 0) : ''}
                                  {item.sqmMax ? ' ~ ' : ''}
                                  {item.sqmMax ? formatMoney(item.sqmMax, 0, 0) : ''}
                                  {item.sqmmin || item.sqmMax ? (
                                    <>
                                      m<sup>2</sup>
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </li>
                              </ul>
                              <div className="price">
                                {item.leasePriceMin || item.leasePriceMax
                                  ? `$${formatMoney(item.leasePriceMin, 0, 0)}`
                                  : ''}
                                {item.leasePriceMax ? ` ~ $${formatMoney(item.leasePriceMax, 0, 0)}` : ''}
                                {item.leasePriceMin || item.leasePriceMax ? (
                                  <span>
                                    /m<sup>2</sup>/{t('search_page.month')}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="row">
            {props.data && props.data.totalRecords && props.data.totalRecords > props.data.pageSize ? (
              <div className="pagination">
                <ReactPaginate
                  //   activePage={props.page.pageIndex}
                  //   itemsCountPerPage={props.page.pageSize}
                  //   totalItemsCount={props.data.totalRecords || 0}
                  //   pageRangeDisplayed={5}
                  //   hideFirstLastPages={true}
                  //   nextPageText={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                  //   prevPageText={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                  //   activeClass={'pagination-active'}
                  //   onChange={page => props.changeItemInPage({ pageIndex: page })}
                  //   itemClass={'pagination-pager-item'}
                  forcePage={props.page && props.page.pageIndex - 1}
                  pageCount={props.pageCount}
                  pageRangeDisplayed={1}
                  marginPagesDisplayed={isMobile ? 1 : 2}
                  previousLabel={<LazyLoadImage src={previousIcon} alt="" effect="blur" />}
                  nextLabel={<LazyLoadImage src={nextIcon} alt="" effect="blur" />}
                  activeClassName={'pagination-active'}
                  onPageChange={page => props.changeItemInPage({ pageIndex: parseInt(page.selected) + 1 })}
                  pageClassName={'pagination-pager-item'}
                  disabledClassName={'opacity-disable'}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertiesItem;
