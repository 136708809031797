import * as types from './forOwners.types';

const initialState = {
  bathrooms: [],
  bedrooms: [],
  propertyTypes: [],
  questions: [],
  cities: [],
  error: null,
};

const forOwnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FOR_OWNERS_REQUEST:
      return { ...state };
    case types.FETCH_FOR_OWNERS_SUCCESS: {
      const { bathrooms, bedrooms, propertyTypes, cities } = action.data;
      return { ...state, bathrooms, bedrooms, propertyTypes, cities };
    }
    case types.FETCH_FOR_OWNERS_FAILURE:
      return { ...state, error: action.error };
    case types.GET_FAQS_OWNERS_REQUEST:
      return { ...state };
    case types.GET_FAQS_OWNERS_SUCCESS:
      return { ...state, questions: action.data };
    case types.GET_FAQS_OWNERS_FAIILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
};

export default forOwnersReducer;
