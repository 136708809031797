// included Services
import included1 from '../../../assets/images/icon-service/included1.svg';
import included2 from '../../../assets/images/icon-service/included8.svg';
import included3 from '../../../assets/images/icon-service/included3.svg';
import included4 from '../../../assets/images/icon-service/included5.svg';
import included5 from '../../../assets/images/icon-service/included4.svg';
import included6 from '../../../assets/images/icon-service/included7.svg';
import included7 from '../../../assets/images/icon-service/included2.svg';
import included8 from '../../../assets/images/icon-service/included6.svg';
import included9 from '../../../assets/images/icon-service/included9.svg';
//Building facilities
import building1 from '../../../assets/images/icon-service/building1.svg';
import building2 from '../../../assets/images/icon-service/building7.svg';
import building3 from '../../../assets/images/icon-service/building8.svg';
import building4 from '../../../assets/images/icon-service/building4.svg';
import building5 from '../../../assets/images/icon-service/building5.svg';
import building6 from '../../../assets/images/icon-service/building3.svg';
import building7 from '../../../assets/images/icon-service/building6.svg';
import building8 from '../../../assets/images/icon-service/building2.svg';
import building9 from '../../../assets/images/icon-service/building9.svg';
import building10 from '../../../assets/images/icon-service/building10.svg';
import building11 from '../../../assets/images/icon-service/building11.svg';
//Interior Facilities
import interior1 from '../../../assets/images/icon-service/interior2.svg';
import interior2 from '../../../assets/images/icon-service/interior1.svg';
import interior3 from '../../../assets/images/icon-service/interior3.svg';
import interior4 from '../../../assets/images/icon-service/interior4.svg';
import interior5 from '../../../assets/images/icon-service/interior5.svg';
import interior6 from '../../../assets/images/icon-service/interior6.svg';
//Funitures
import funitures1 from '../../../assets/images/icon-service/funitures1.svg';
import funitures2 from '../../../assets/images/icon-service/funitures2.svg';
import funitures3 from '../../../assets/images/icon-service/funitures3.svg';
import funitures4 from '../../../assets/images/icon-service/funitures4.svg';
import funitures5 from '../../../assets/images/icon-service/funitures5.svg';
import funitures6 from '../../../assets/images/icon-service/funitures6.svg';
import funitures7 from '../../../assets/images/icon-service/funitures7.svg';
import funitures8 from '../../../assets/images/icon-service/funitures8.svg';
//default
import defaultIcon from '../../../assets/images/icon-service/default.svg';

// group-company
import company1 from '../../../assets/images/Group1585.svg';
import company2 from '../../../assets/images/Expand.svg';
import company3 from '../../../assets/images/Group1673.svg';
import company4 from '../../../assets/images/Group1675.svg';
import company5 from '../../../assets/images/support-svg.svg';

//our main services
import service1 from '../../../assets/images/icon-service/service1.svg';
import service2 from '../../../assets/images/icon-service/service2.svg';
import service3 from '../../../assets/images/icon-service/service3.svg';
import service4 from '../../../assets/images/icon-service/service4.svg';
import service5 from '../../../assets/images/icon-service/service5.svg';
import service6 from '../../../assets/images/icon-service/service6.svg';
import service7 from '../../../assets/images/icon-service/service7.svg';
import service8 from '../../../assets/images/icon-service/service8.svg';

//map ha noi
import hanoi1 from '../../../assets/images/map-svg/hn-hoan-kiem.svg';
import hanoi2 from '../../../assets/images/map-svg/hn-hai-ba-trung.svg';
import hanoi3 from '../../../assets/images/map-svg/hn-tay-ho.svg';
import hanoi4 from '../../../assets/images/map-svg/hn-ba-dinh.svg';
import hanoi5 from '../../../assets/images/map-svg/hn-cau-giay.svg';
import hanoi6 from '../../../assets/images/map-svg/hn-nam-tu-lien.svg';

//map ho chi minh
import hcm1 from '../../../assets/images/map-svg/hcm-district1.svg';
import hcm2 from '../../../assets/images/map-svg/hcm-district2.svg';
import hcm3 from '../../../assets/images/map-svg/hcm-district3.svg';
import hcm4 from '../../../assets/images/map-svg/hcm-district7.svg';
import hcm5 from '../../../assets/images/map-svg/hcm-binh-thanh.svg';

const Image = {
  included: { included1, included2, included3, included4, included5, included6, included7, included8, included9 },
  building: { building1, building2, building3, building4, building5, building6, building7, building8, building9, 
  building10, building11 },
  interior: { interior1, interior2, interior3, interior4, interior5, interior6 },
  funitures: { funitures1, funitures2, funitures3, funitures4, funitures5, funitures6, funitures7, funitures8 },

  defaultIcon,
};

const IconOutCompany = {
  company1: company5,
  company2: company1,
  company3: company4,
  company4: company2,
  company5: company3,
};
const IconMainServices = { service1, service2, service3, service4, service5, service6, service7, service8 };

const MapHaNoi = {
  mapsvg1: hanoi1,
  mapsvg2: hanoi2,
  mapsvg3: hanoi3,
  mapsvg4: hanoi4,
  mapsvg5: hanoi5,
  mapsvg6: hanoi6,
};
const MapHoChiMinh = { mapsvg1: hcm1, mapsvg2: hcm2, mapsvg3: hcm3, mapsvg4: hcm4, mapsvg5: hcm5 };

export { Image, IconOutCompany, IconMainServices, MapHaNoi, MapHoChiMinh };
