import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import { fetchBlogsRequest } from '../blogs.action';
import { BlogsComponent, BlogDetailComponent } from '../pages';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default {
  BlogsContainer: connect(mapStateToProps, mapDispatchToProps)(BlogsComponent),
  BlogDetailContainer: connect(mapStateToProps, mapDispatchToProps)(BlogDetailComponent),
};
// export default connect(mapStateToProps, mapDispatchToProps)(BlogsComponent);
