const GET_RESIDENCE_REQUEST = 'GET_RESIDENCE_REQUEST';
const GET_RESIDENCE_SUCCESS = 'GET_RESIDENCE_SUCCESS';
const GET_RESIDENCE_FAILURE = 'GET_RESIDENCE_FAILURE';

const GET_SIMILAR_PROPERTIES_REQUEST = 'GET_SIMILAR_PROPERTIES_REQUEST';
const GET_SIMILAR_PROPERTIES_SUCCESS = 'GET_SIMILAR_PROPERTIES_SUCCESS';
const GET_SIMILAR_PROPERTIES_FAILURE = 'GET_SIMILAR_PROPERTIES_FAILURE';

const FETCH_RESIDECES_SEARCH_REQUEST = 'FETCH_RESIDECES_SEARCH_REQUEST';
const FETCH_RESIDECES_SEARCH_SUCCESS = 'FETCH_RESIDECES_SEARCH_SUCCESS';
const FETCH_RESIDECES_SEARCH_FAILURE = 'FETCH_RESIDECES_SEARCH_FAILURE';

const POST_CONTACT_US_REQUEST = 'POST_CONTACT_US_REQUEST';

export {
  GET_RESIDENCE_REQUEST,
  GET_RESIDENCE_SUCCESS,
  GET_RESIDENCE_FAILURE,
  GET_SIMILAR_PROPERTIES_REQUEST,
  GET_SIMILAR_PROPERTIES_SUCCESS,
  GET_SIMILAR_PROPERTIES_FAILURE,
  FETCH_RESIDECES_SEARCH_REQUEST,
  FETCH_RESIDECES_SEARCH_SUCCESS,
  FETCH_RESIDECES_SEARCH_FAILURE,
  POST_CONTACT_US_REQUEST,
};
