// eslint-disable-next-line no-unused-vars
import { get } from 'lodash';
import Service from '../../services';

export const fetchBlogs = async params => {
  const response = await Service.get(`/api/blogs/get-by-category${params}`, res => res);
  return response;
};

export const getMainBlog = async params => {
  const response = await Service.get(`/api/blogs/main-blog${params}`, res => res);
  return response;
};

export const getBlog = async params => {
  const response = await Service.get(`/api/blogs/detail${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

export const fetchOtherBlogs = async params => {
  const response = await Service.get(`/api/blogs/other${params}`, res => res);
  const data = get(response, 'data', null);
  return data;
};

// export const getCompanyProfile = async params => {
//   const response = await Service.get(`/api/generalinformations/get-by-slug-category${params}`, res => res);
//   const data = get(response, 'data', null);
//   return data;
// };
