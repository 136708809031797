import React, { useEffect } from 'react';
import { LeftContent, RightContent, ButtonNextPrev } from '../common';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { getPathLanguage } from '../../../utils/params';
import { isMobile } from '../../../utils/constants';
import { connect } from 'react-redux';



const BlogDetail = props => {
  const { t } = useTranslation();
  const pathLanguage = getPathLanguage(useLocation());
  const history = useHistory();
  const breadCrumb = [
    { title: t('blog_detail.home'), url: pathLanguage },
    {
      title: t('blog_detail.blog'),
      url: isMobile ? `/blogs` : `/blogs/all`,
    },
    { title: t('blog_detail.blog_detail') },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let resultSearch = [];
  if ('resultBlogSearch' in localStorage) {
    resultSearch = JSON.parse(localStorage.getItem('resultBlogSearch'));
  }
  const { blog } = props.blogs;
  const { blogs } = props.blogs;
  useEffect(() => {
    if (blogs?.items.length > 0) {
      localStorage.setItem('resultBlogSearch', JSON.stringify(blogs?.items))
    }
  }, [blogs])

  const handleClickNext = (id, action) => {
    if (resultSearch.length != 0) {
      const index = resultSearch.findIndex(x => x.id == id);
      let idNext = 0;
      let slugItem = '';
      if (index === 0 && action == "PREV") {
        const resultSearchLength = resultSearch.length;
        idNext = resultSearch[resultSearchLength - 1].id;
        slugItem = resultSearch[resultSearchLength - 1].slug;
      } else if (index === (resultSearch.length - 1) && action == "NEXT") {
        idNext = resultSearch[0].id;
        slugItem = resultSearch[0].slug;
      } else {
        if (action == "NEXT") {
          idNext = resultSearch[index + 1].id;
          slugItem = resultSearch[index + 1].slug;
        } else if (action == "PREV") {
          idNext = resultSearch[index - 1].id;
          slugItem = resultSearch[index - 1].slug;
        }
      }
      history.push(`${pathLanguage}/blog/detail/${idNext}/${slugItem}`);
    }
  };

  return (
    <>
      <div className="section-block section-breadcrumb background-gray">
        <div className="container">
          <div className="row breadcrumb align-items-center">
            <div className="col-12 col-md-6">
              <div className="breadcrumb-item row">
                <ul>
                  {breadCrumb && breadCrumb.length
                    ? breadCrumb.map((item, index) => {
                      return (
                        <li key={index}>
                          {index === 0 ? (
                            <Link to={`${pathLanguage}${item.url}`}>{item.title}</Link>
                          ) : index === breadCrumb.length - 1 ? (
                            <>
                              <span className="breadcrumb-dot">&#8226;</span>
                              <span className="active">{item.title}</span>
                            </>
                          ) : (
                            <>
                              <span className="breadcrumb-dot">&#8226;</span>
                              <Link to={`${pathLanguage}${item.url}`}>{item.title}</Link>
                            </>
                          )}
                        </li>
                      );
                    })
                    : ''}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-block background-gray section-page-content">
        {resultSearch.length > 0 &&
          <div style={{ paddingBottom: "1.5rem" }}>
            <ButtonNextPrev onHandleChange={handleClickNext} dataDetai={blog} />
          </div>}
        <div className="container">
          <div className="post-content-detail">
            <div className="row">
              <LeftContent />
              <RightContent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state,
});
export default connect(mapStateToProps)(BlogDetail);
