import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getPathLanguage } from '../../../../utils/params';

const BreadcrumbAboutIsn = props => {
  const pathLanguage = getPathLanguage(useLocation());
  return (
    <div className="section-block section-breadcrumb background-gray">
      <div className="container">
        <div className="row breadcrumb align-items-center">
          <div className="col-12 col-md-6">
            <div className="breadcrumb-item row">
              <ul>
                {props.breadCrumb && props.breadCrumb.length
                  ? props.breadCrumb.map((item, index) => {
                      return (
                        <li key={index}>
                          {index === 0 ? (
                            <Link to={`${pathLanguage}${item.url}`}>{item.title}</Link>
                          ) : index === props.breadCrumb.length - 1 ? (
                            <>
                              <span className="breadcrumb-dot">&#8226;</span>
                              <span className="active">{item.title}</span>
                            </>
                          ) : (
                            <>
                              <span className="breadcrumb-dot">&#8226;</span>
                              <Link to={`${pathLanguage}${item.url}`}>{item.title}</Link>
                            </>
                          )}
                        </li>
                      );
                    })
                  : ''}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbAboutIsn;
